/* This file is generated, do not modify manually */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  GraphQLJSON: { [key: string]: string | number | boolean };
  IANATimezone: string;
  Timezone: string;
  Upload: File;
};

export type AccidentCost = {
  __typename: 'AccidentCost';
  accidentCostValue: Scalars['Float'];
  transportMode: Scalars['String'];
};

export enum Action {
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE',
}

export type ActionMetadata = FlagCreatedMetadata | FlagUpdatedMetadata;

export type AddCredentialsToUserInput = {
  type: CredentialType;
  userId: Scalars['String'];
  value: Scalars['String'];
};

export type AddOrUpdateConsentInput = {
  fromCompanyId: Scalars['String'];
  toCompanyId: Scalars['String'];
  tourStatusConsent?: InputMaybe<AddOrUpdateConsentState>;
};

export enum AddOrUpdateConsentState {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export type AddUserToCompanyInput = {
  companyId: Scalars['String'];
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRoleInCompany>;
};

export type AddVehiclesToCompanyVehicleGroupInput = {
  companyId: Scalars['String'];
  companyVehicleGroupId: Scalars['String'];
  vehicleIds: Array<Scalars['String']>;
};

export type Address = {
  __typename: 'Address';
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  full_address: Maybe<Scalars['String']>;
  shortAreaCode: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  stopAddressId: Maybe<Scalars['String']>;
  street_address: Maybe<Scalars['String']>;
  zipcode: Maybe<Scalars['String']>;
};

export type AllowedCompanyInviteSignup = {
  __typename: 'AllowedCompanyInviteSignup';
  platformId: Maybe<Scalars['String']>;
  type: SignupType;
};

export type ApplicationDeepLink = {
  __typename: 'ApplicationDeepLink';
  applicationName: Scalars['String'];
  url: Scalars['String'];
};

export type AssignUserToTourInput = {
  assignedToUserId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  tourId: Scalars['String'];
};

export enum BackofficeRole {
  admin = 'admin',
  readonly = 'readonly',
}

export type BigQueryMessageEntry = {
  __typename: 'BigQueryMessageEntry';
  message: Scalars['String'];
  message_type: Scalars['String'];
  produced_at: Scalars['DateTime'];
};

export type Bounds = {
  __typename: 'Bounds';
  ne: PointLocation;
  sw: PointLocation;
};

export type BreakCenterPosition = {
  __typename: 'BreakCenterPosition';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type BulkInvitationMetadata = {
  __typename: 'BulkInvitationMetadata';
  fileName: Maybe<Scalars['String']>;
  fileSize: Maybe<Scalars['String']>;
};

export type BulkInvitationRecipient = {
  __typename: 'BulkInvitationRecipient';
  email: Scalars['String'];
  locale: Maybe<Scalars['String']>;
};

export enum BulkInvitationSendSelectType {
  EXCLUDE = 'EXCLUDE',
  INCLUDE = 'INCLUDE',
}

export type BulkInvitationSet = {
  __typename: 'BulkInvitationSet';
  bulkInvitationSetId: Scalars['String'];
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  items: CountedBulkInvitationSetItemConnection;
  metadata: BulkInvitationMetadata;
  statistics: BulkInvitationSetStatistics;
  updatedAt: Scalars['DateTime'];
  updatedByUserId: Scalars['String'];
};

export type BulkInvitationSetItemsArgs = {
  filter?: InputMaybe<BulkInvitationSetItemsFilterInput>;
  sort?: InputMaybe<BulkInvitationSetItemsSortOrderInput>;
};

export type BulkInvitationSetStatisticsArgs = {
  filter?: InputMaybe<BulkInvitationSetStatisticsFilterInput>;
};

export type BulkInvitationSetConnection = {
  __typename: 'BulkInvitationSetConnection';
  edges: Maybe<Array<Maybe<BulkInvitationSetEdge>>>;
  pageInfo: PageInfo;
};

export type BulkInvitationSetEdge = {
  __typename: 'BulkInvitationSetEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<BulkInvitationSet>;
};

export type BulkInvitationSetItem = {
  __typename: 'BulkInvitationSetItem';
  bulkInvitationSetId: Scalars['String'];
  bulkInvitationSetItemId: Scalars['String'];
  companyInvitationId: Maybe<Scalars['String']>;
  /** @deprecated Use companyReferenceName instead */
  companyName: Maybe<Scalars['String']>;
  companyReferenceName: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  invitedCompanyId: Maybe<Scalars['String']>;
  isDataValid: Scalars['Boolean'];
  recipients: Array<BulkInvitationRecipient>;
  relationCompanyName: Maybe<Scalars['String']>;
  status: BulkInvitationSetItemStatus;
  updatedAt: Scalars['DateTime'];
  updatedByUserId: Scalars['String'];
};

export type BulkInvitationSetItemConnection = {
  __typename: 'BulkInvitationSetItemConnection';
  edges: Maybe<Array<Maybe<BulkInvitationSetItemEdge>>>;
  pageInfo: PageInfo;
};

export type BulkInvitationSetItemEdge = {
  __typename: 'BulkInvitationSetItemEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<BulkInvitationSetItem>;
};

export enum BulkInvitationSetItemStatus {
  INVITED = 'INVITED',
  INVITE_WITHDRAWN = 'INVITE_WITHDRAWN',
  IN_NETWORK = 'IN_NETWORK',
  NOT_INVITED = 'NOT_INVITED',
}

export type BulkInvitationSetItemsFilterInput = {
  companyName?: InputMaybe<Scalars['String']>;
  isInvalid?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<BulkInvitationSetItemStatus>;
};

export type BulkInvitationSetItemsSortOrderInput = {
  direction: SortDirection;
  key: BulkInvitationSetsSortKey;
};

export type BulkInvitationSetStatistics = {
  __typename: 'BulkInvitationSetStatistics';
  itemCountByStatus: Array<ItemCountByStatus>;
};

export type BulkInvitationSetStatisticsFilterInput = {
  isDataValid?: InputMaybe<Scalars['Boolean']>;
};

export enum BulkInvitationSetsSortKey {
  COMPANY_NAME = 'COMPANY_NAME',
  STATUS = 'STATUS',
}

export type CalculationError = {
  __typename: 'CalculationError';
  code: EmissionsCalculationErrorCode;
  type: EmissionsCalculationErrorType;
};

export type Cargo = {
  __typename: 'Cargo';
  containerEmptyWeight: Scalars['Float'];
  tonsPerFeu: Scalars['Float'];
  tonsPerTeu: Scalars['Float'];
  unit: Scalars['String'];
  volumeWeight: Scalars['String'];
  weight: Scalars['Float'];
};

export type CarriageModeParameter = {
  __typename: 'CarriageModeParameter';
  aircraftType: Maybe<Scalars['String']>;
  driveClass: Maybe<Scalars['String']>;
  emissionClass: Maybe<Scalars['String']>;
  emptyRunFactor: Maybe<Scalars['Int']>;
  ferryRouting: Maybe<Scalars['String']>;
  fuelType: Maybe<Scalars['String']>;
  inlandShipType: Maybe<Scalars['String']>;
  loadFactor: Maybe<Scalars['Float']>;
  lorryClass: Maybe<Scalars['String']>;
  mode: Scalars['String'];
  seaShipType: Maybe<Scalars['String']>;
};

export type CarriageParameters = {
  __typename: 'CarriageParameters';
  bioFuelShare: Scalars['Float'];
  carriageModeParameters: Array<CarriageModeParameter>;
};

export enum CarrierConnectionRelationshipType {
  CARRIER = 'CARRIER',
  SUBCARRIER = 'SUBCARRIER',
}

export type CarrierReport = {
  __typename: 'CarrierReport';
  allocatedToursCount: Scalars['Int'];
  carrier: Maybe<CompanyProfile>;
  toursCount: Scalars['Int'];
  visibilityIndex: Maybe<Scalars['Float']>;
};

export type CarrierReportConnection = {
  __typename: 'CarrierReportConnection';
  edges: Maybe<Array<Maybe<CarrierReportEdge>>>;
  pageInfo: PageInfo;
};

export type CarrierReportEdge = {
  __typename: 'CarrierReportEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<CarrierReport>;
};

export type ChangeUserMetadataInCompanyInput = {
  companyId: Scalars['String'];
  metadata?: InputMaybe<UserMetadataInCompanyInput>;
  userId: Scalars['String'];
};

export type ChangeUserRoleInCompanyInput = {
  companyId: Scalars['String'];
  role: UserRoleInCompany;
  userId: Scalars['String'];
};

export type CircleGeofence = {
  __typename: 'CircleGeofence';
  origin: PointLocation;
  radius_meters: Scalars['Int'];
};

export type CircleGeofenceInput = {
  origin: PointLocationInput;
  radius_meters: Scalars['Int'];
};

export type CombinedEvent = StopEvent | TourEvent;

export type Company = {
  __typename: 'Company';
  address: Maybe<CompanyAddress>;
  arePlacesEnabled: Maybe<Scalars['Boolean']>;
  assets: CompanyAssets;
  bookingLocations: CountedCompanyBookingLocation;
  bulkInvitationSet: Maybe<BulkInvitationSet>;
  bulkInvitationSetItem: Maybe<BulkInvitationSetItem>;
  bulkInvitationSets: Array<BulkInvitationSet>;
  canChangeTourStatusConsent: Scalars['Boolean'];
  carrierToursCount: Scalars['Int'];
  carriersConnection: CountedCarrierConnection;
  companyIdentifiers: Array<CompanyIdentifier>;
  companyInvitation: Maybe<CompanyInvite>;
  companyTourFiltersConnection: CountedCompanyTourFilterConnection;
  companyTransportGroupsConnection: CountedCompanyTransportGroupConnection;
  companyTransportNetworkFiltersConnection: CountedCompanyTransportNetworkFilterConnection;
  companyVehicleGroup: Maybe<CompanyVehicleGroup>;
  companyVehicleGroups: Maybe<Array<Maybe<CompanyVehicleGroup>>>;
  company_id: Scalars['String'];
  company_name: Maybe<Scalars['String']>;
  configuration: Maybe<CompanyConfiguration>;
  connectedCustomFields: Array<ConnectedCustomField>;
  createdAt: Maybe<Scalars['DateTime']>;
  customExceptionTypes: Array<Scalars['String']>;
  customField: Maybe<ConnectedCustomField>;
  dataNetworkVisibilityReport: Maybe<DataNetworkVisibilityReport>;
  defaultVehicleDwellTimeThresholdMinutes: Maybe<Scalars['Int']>;
  featureFlag: Scalars['Boolean'];
  featureFlagAuditLogConnection: FeatureFlagAuditLogConnection;
  feature_flags: Maybe<Scalars['GraphQLJSON']>;
  fieldDataSources: Maybe<CompanyFieldDataSources>;
  filterBasedTransportNotification: Maybe<FilterBasedTransportNotification>;
  filterBasedTransportNotifications: Array<FilterBasedTransportNotification>;
  fms_integrated_at: Maybe<Scalars['DateTime']>;
  /**
   * Utility for internal processes that allows checking whether a feature flag
   * is defined for a company, i.e distinguish between false and a flag that is
   * not defined at all
   */
  hasFeatureFlag: Scalars['Boolean'];
  hasHadAnyDocuments: Scalars['Boolean'];
  hasHadAnyExceptions: Scalars['Boolean'];
  hasHadAnyNotes: Scalars['Boolean'];
  invitationHistoryLogs: Array<InvitationHistoryItem>;
  isPendingSignup: Maybe<Scalars['Boolean']>;
  isPresentOnTransporeonPlatform: Maybe<Scalars['Boolean']>;
  isTrimbleVisibilityCustomer: Scalars['Boolean'];
  is_carrier: Maybe<Scalars['Boolean']>;
  is_paying_customer: Maybe<Scalars['Boolean']>;
  is_pilot: Maybe<Scalars['Boolean']>;
  is_shipper: Maybe<Scalars['Boolean']>;
  is_test: Maybe<Scalars['Boolean']>;
  latestBulkInvitationSet: Maybe<BulkInvitationSet>;
  latestBulkInvitationSetId: Maybe<Scalars['String']>;
  lightdashEmbeddings: LightdashEmbeddings;
  mainCarriersConnection: CountedMainCarrierConnection;
  me: Maybe<UserInCompany>;
  networkRelationStatistics: NetworkRelationStatistics;
  networkRelations: Maybe<CountedCompanyRelationConnection>;
  onboardingAction: Maybe<OnboardingAction>;
  orderItemCustomFieldIdentifiersConnection: CountedOrderItemCustomFieldConnection;
  orderItemIdentifiersConnection: CountedOrderItemConnection;
  place: Maybe<CompanyPlace>;
  placesConnection: CountedCompanyPlaceConnection;
  platforms: Array<CompanyPlatform>;
  providingVisibilityCompanies: Maybe<CountedProvidingVisibilityCompaniesConnection>;
  providingVisibilityCompany: Maybe<ProvidingVisibilityCompany>;
  receivingVisibilityCompanies: Maybe<CountedReceivingVisibilityCompaniesConnection>;
  receivingVisibilityCompany: Maybe<ReceivingVisibilityCompany>;
  relationIdentifiersConnection: Maybe<CompanyIdentifierConnection>;
  settings: CompanySettings;
  shipperToursCount: Scalars['Int'];
  shippersConnection: CountedShipperConnection;
  shouldSeeUpcomingTransportsTab: Scalars['Boolean'];
  signup: Maybe<CompanySignup>;
  signupAction: Maybe<SignupAction>;
  signupCompletedAt: Maybe<Scalars['DateTime']>;
  signupVisibilitySources: Maybe<Array<CompanyVisibilitySource>>;
  signupVisibilitySourcesViaSubcarriers: Maybe<Array<CompanyVisibilitySource>>;
  sitesConnection: CountedSiteConnection;
  stopAddress: Maybe<CompanyStopAddress>;
  stopAddressesConnection: CountedCompanyStopAddressConnection;
  stopCompanyNameIdentifiersConnection: CountedStopCompanyNameConnection;
  stopStatesIdentifiersConnection: CountedCompanyStopStatesConnection;
  tags: Array<CompanyTag>;
  telematicsIntegration: Maybe<CompanyTelematicsIntegration>;
  telematicsIntegrations: Array<CompanyTelematicsIntegration>;
  /** @deprecated use camelCased telematicsIntegrations instead */
  telematics_integrations: Array<TelematicsIntegration>;
  totalVehiclesCount: Scalars['Int'];
  tour: Maybe<Tour>;
  tourReportsByCarrier: CarrierReportConnection;
  tourReportsByShipper: ShipperReportConnection;
  tours: CountedTourConnection;
  tripsIdentifiersConnection: CountedTripsIdentifiersConnection;
  tripsOptionsConnection: CountedTripsOptionConnection;
  tripsTours: CountedTripsTourConnection;
  unifiedOnboardingEvents: Maybe<Array<UnifiedCompanyOnboardingEvent>>;
  unifiedOnboardingStatus: Maybe<UnifiedCompanyOnboardingStatus>;
  unifiedOnboardingStuckReason: Maybe<UnifiedCompanyOnboardingStuckReason>;
  unifiedSignupEvents: Maybe<Array<UnifiedCompanySignupEvent>>;
  unifiedSignupStatus: Maybe<UnifiedCompanySignupStatus>;
  user: Maybe<UserInCompanyProfile>;
  userGroupOnPlatform: Maybe<UserGroupOnPlatform>;
  userGroupsOnPlatform: UserGroupOnPlatformConnection;
  users: CountedUserInCompanyProfileConnection;
  vatNumbers: Array<CompanyVatNumber>;
  vehicle: Maybe<Vehicle>;
  vehicleGrantPartner: Maybe<VehicleGrantPartner>;
  vehicleGrantPartners: CountedVehicleGrantPartnerConnection;
  vehicles: Maybe<CompanyVehicles>;
  visibilitySources: Maybe<Array<CompanyVisibilitySource>>;
  visibilitySourcesViaSubcarriers: Maybe<Array<CompanyVisibilitySource>>;
  widgetPreview: Maybe<WidgetPreview>;
};

export type CompanyBookingLocationsArgs = {
  search?: InputMaybe<Scalars['String']>;
};

export type CompanyBulkInvitationSetArgs = {
  bulkInvitationSetId: Scalars['String'];
};

export type CompanyBulkInvitationSetItemArgs = {
  bulkInvitationSetId: Scalars['String'];
  bulkInvitationSetItemId: Scalars['String'];
};

export type CompanyCarriersConnectionArgs = {
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  relationshipTypes: Array<CarrierConnectionRelationshipType>;
  textSearch?: InputMaybe<Scalars['String']>;
};

export type CompanyCompanyInvitationArgs = {
  invitationId: Scalars['String'];
};

export type CompanyCompanyTourFiltersConnectionArgs = {
  filterType?: InputMaybe<FilterType>;
  textSearch?: InputMaybe<Scalars['String']>;
};

export type CompanyCompanyTransportGroupsConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']>;
  transportGroupIds?: InputMaybe<Array<Scalars['String']>>;
};

export type CompanyCompanyTransportNetworkFiltersConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']>;
};

export type CompanyCompanyVehicleGroupArgs = {
  companyVehicleGroupId: Scalars['String'];
};

export type CompanyConnectedCustomFieldsArgs = {
  transportMode: TourFilteringTransportMode;
};

export type CompanyCustomFieldArgs = {
  fieldName: Scalars['String'];
  transportMode: TourFilteringTransportMode;
};

export type CompanyDataNetworkVisibilityReportArgs = {
  filter: DataNetworkReportFilterInput;
  sort?: InputMaybe<DataNetworkReportSortOrderInput>;
  type: DataNetworkReportType;
};

export type CompanyFeatureFlagArgs = {
  flagName: Scalars['String'];
};

export type CompanyFeatureFlagAuditLogConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CompanyFilterBasedTransportNotificationArgs = {
  notificationId: Scalars['String'];
};

export type CompanyHasFeatureFlagArgs = {
  flagName: Scalars['String'];
};

export type CompanyInvitationHistoryLogsArgs = {
  acceptingCompanyId: Scalars['String'];
};

export type CompanyMainCarriersConnectionArgs = {
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  textSearch?: InputMaybe<Scalars['String']>;
};

export type CompanyNetworkRelationsArgs = {
  filter?: InputMaybe<NetworkRelationFilter>;
  includeSignupStatusInReport?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<NetworkRelationsSortOrderInput>;
};

export type CompanyOrderItemCustomFieldIdentifiersConnectionArgs = {
  search?: InputMaybe<OrderItemCustomFieldSearch>;
};

export type CompanyOrderItemIdentifiersConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']>;
};

export type CompanyPlaceArgs = {
  place_id: Scalars['String'];
};

export type CompanyPlacesConnectionArgs = {
  includeSixfoldGenerated?: InputMaybe<Scalars['Boolean']>;
  placeIds?: InputMaybe<Array<Scalars['String']>>;
  textSearch?: InputMaybe<PlaceTextSearch>;
};

export type CompanyProvidingVisibilityCompaniesArgs = {
  filter: ProvidingVisibilityCompaniesFilterInput;
  sort?: InputMaybe<ProvidingVisibilityCompaniesSortOrderInput>;
};

export type CompanyProvidingVisibilityCompanyArgs = {
  companyId: Scalars['String'];
};

export type CompanyReceivingVisibilityCompaniesArgs = {
  filter: ReceivingVisibilityCompaniesFilterInput;
  sort?: InputMaybe<ReceivingVisibilityCompaniesSortOrderInput>;
};

export type CompanyReceivingVisibilityCompanyArgs = {
  companyId: Scalars['String'];
};

export type CompanyRelationIdentifiersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  carrierCompanyIds?: InputMaybe<Array<Scalars['String']>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  relationIdentifierIds?: InputMaybe<Array<Scalars['String']>>;
  textSearch?: InputMaybe<Scalars['String']>;
};

export type CompanyShippersConnectionArgs = {
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  textSearch?: InputMaybe<Scalars['String']>;
};

export type CompanySitesConnectionArgs = {
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  textSearch?: InputMaybe<Scalars['String']>;
};

export type CompanyStopAddressArgs = {
  addressId: Scalars['String'];
};

export type CompanyStopAddressesConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']>;
};

export type CompanyStopCompanyNameIdentifiersConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']>;
};

export type CompanyStopStatesIdentifiersConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']>;
};

export type CompanyTelematicsIntegrationArgs = {
  telematicsIntegrationId: Scalars['String'];
};

export type CompanyTourArgs = {
  tour_id: Scalars['Int'];
};

export type CompanyTourReportsByCarrierArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CompanyTourReportsByShipperArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CompanyToursArgs = {
  allocationStatus?: InputMaybe<TourAllocationStatus>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']>>;
  carrierIds?: InputMaybe<Array<Scalars['String']>>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch>>;
  delayStatus?: InputMaybe<TourDelayStatusFilter>;
  delayTime?: InputMaybe<TimeRangeInput>;
  documentTypes?: InputMaybe<Array<DocumentType>>;
  endTimeEnd?: InputMaybe<Scalars['DateTime']>;
  endTimeStart?: InputMaybe<Scalars['DateTime']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  exceptionTypes?: InputMaybe<Array<Scalars['String']>>;
  failureReasons?: InputMaybe<Array<TourFailureReasonType>>;
  hasNotes?: InputMaybe<Scalars['Boolean']>;
  mainCarrierIds?: InputMaybe<Array<Scalars['String']>>;
  mainVisibilityIssues?: InputMaybe<Array<VisibilityIssueType>>;
  orderItemCustomFields?: InputMaybe<OrderItemCustomFieldInput>;
  orderItemGroups?: InputMaybe<OrderItemGroupsInput>;
  orderItemNames?: InputMaybe<Array<Scalars['String']>>;
  placeIds?: InputMaybe<Array<Scalars['String']>>;
  role: TourCompanyRole;
  shipperIds?: InputMaybe<Array<Scalars['String']>>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  sortOrder?: InputMaybe<TourSortOrder>;
  startTimeEnd?: InputMaybe<Scalars['DateTime']>;
  startTimeStart?: InputMaybe<Scalars['DateTime']>;
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  status?: InputMaybe<TourStatusFilter>;
  statuses?: InputMaybe<Array<TourStatusFilter>>;
  stopBookingLocationNames?: InputMaybe<Array<Scalars['String']>>;
  stopCompanyNames?: InputMaybe<Array<Scalars['String']>>;
  stopCountries?: InputMaybe<Array<Scalars['String']>>;
  stopFilters?: InputMaybe<Array<StopFilterInput>>;
  stopStates?: InputMaybe<Array<StopStatesInput>>;
  textSearch?: InputMaybe<TourTextSearch>;
  timeSpentAtStop?: InputMaybe<TimeRangeInput>;
  tourIds?: InputMaybe<Array<Scalars['String']>>;
  transportMode?: InputMaybe<Array<TourFilteringTransportMode>>;
  vesselName?: InputMaybe<VesselNameFilterInput>;
  warnings?: InputMaybe<Array<TourWarningType>>;
  workingStopStatus?: InputMaybe<WorkingStopStatusFilter>;
  workingStopTimeslotBeginEnd?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyTripsIdentifiersConnectionArgs = {
  identifierValues?: InputMaybe<Array<Scalars['String']>>;
  textSearch?: InputMaybe<Scalars['String']>;
  transportMode: TripsTransportMode;
  types: Array<TripsIdentifierType>;
};

export type CompanyTripsOptionsConnectionArgs = {
  subType: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  transportMode: TripsTransportMode;
  type?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<Scalars['String']>>;
};

export type CompanyTripsToursArgs = {
  airWayBillNumbers?: InputMaybe<Array<Scalars['String']>>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']>>;
  billOfLadingNumbers?: InputMaybe<Array<Scalars['String']>>;
  bookingNumbers?: InputMaybe<Array<Scalars['String']>>;
  carrierCodes?: InputMaybe<Array<Scalars['String']>>;
  carrierIds?: InputMaybe<Array<Scalars['String']>>;
  carrierNames?: InputMaybe<Array<Scalars['String']>>;
  containerNumbers?: InputMaybe<Array<Scalars['String']>>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch>>;
  demurrage?: InputMaybe<OceanDemurrageFilter>;
  impactDays?: InputMaybe<ImpactDaysFilter>;
  locations?: InputMaybe<Array<TransportLocation>>;
  mainCarrierIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<TripsTextSearch>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<TourStatusFilter>;
  statuses?: InputMaybe<Array<TourStatusFilter>>;
  transportAlerts?: InputMaybe<TransportAlertsFilterInput>;
  transportGroupIds?: InputMaybe<Array<Scalars['String']>>;
  transportMode?: InputMaybe<Array<TourFilteringTransportMode>>;
  tripsSearch?: InputMaybe<TripsTextSearch>;
  vesselName?: InputMaybe<VesselNameFilterInput>;
};

export type CompanyUserArgs = {
  userId: Scalars['String'];
};

export type CompanyUserGroupOnPlatformArgs = {
  groupId: Scalars['String'];
};

export type CompanyUserGroupsOnPlatformArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CompanyUsersArgs = {
  search?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type CompanyVehicleArgs = {
  vehicleId: Scalars['String'];
};

export type CompanyVehicleGrantPartnerArgs = {
  grantDirection: GrantDirection;
  partnerId: Scalars['String'];
};

export type CompanyVehicleGrantPartnersArgs = {
  grantDirection: GrantDirection;
  partnerNameSearch?: InputMaybe<Scalars['String']>;
};

export type CompanyWidgetPreviewArgs = {
  filters: Array<WidgetFilterInput>;
  operator?: InputMaybe<OperatorType>;
  type: WidgetType;
};

export type CompanyAddress = {
  __typename: 'CompanyAddress';
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  street: Scalars['String'];
};

export type CompanyAddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  street: Scalars['String'];
};

export type CompanyAsset = {
  __typename: 'CompanyAsset';
  url: Scalars['String'];
};

export type CompanyAssets = {
  __typename: 'CompanyAssets';
  consigneeBackground: Maybe<CompanyAsset>;
  consigneeLogo: Maybe<CompanyAsset>;
};

export type CompanyBookingLocation = {
  __typename: 'CompanyBookingLocation';
  bookingLocationName: Scalars['String'];
};

export type CompanyBookingLocationConnection = {
  __typename: 'CompanyBookingLocationConnection';
  edges: Maybe<Array<Maybe<CompanyBookingLocationEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyBookingLocationEdge = {
  __typename: 'CompanyBookingLocationEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<CompanyBookingLocation>;
};

export type CompanyBulkInvitation = {
  companyName?: InputMaybe<Scalars['String']>;
  recipients: Array<CompanyBulkInvitationRecipient>;
};

export type CompanyBulkInvitationRecipient = {
  email: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
};

export type CompanyBulkInviteMetadata = {
  fileName: Scalars['String'];
  fileSize: Scalars['String'];
};

export type CompanyConfiguration = {
  __typename: 'CompanyConfiguration';
  configurations: Configuration;
};

export type CompanyConnection = {
  __typename: 'CompanyConnection';
  edges: Maybe<Array<Maybe<CompanyEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyCountByOnboardingStatus = {
  __typename: 'CompanyCountByOnboardingStatus';
  count: Scalars['Int'];
  onboardingStatus: Maybe<UnifiedCompanyRelationshipOnboardingStatus>;
};

export type CompanyCountBySignupStatus = {
  __typename: 'CompanyCountBySignupStatus';
  count: Scalars['Int'];
  signupStatus: Maybe<UnifiedCompanyRelationshipSignupStatus>;
};

export enum CompanyDataSource {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export type CompanyEdge = {
  __typename: 'CompanyEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Company>;
};

export type CompanyFieldDataSources = {
  __typename: 'CompanyFieldDataSources';
  companyName: Maybe<CompanyDataSource>;
  isCarrier: Maybe<CompanyDataSource>;
  isShipper: Maybe<CompanyDataSource>;
  isTest: Maybe<CompanyDataSource>;
};

export type CompanyIdAndVisibilityServices = {
  __typename: 'CompanyIdAndVisibilityServices';
  companyId: Scalars['String'];
  visibilityServices: Maybe<Array<VisibilityService>>;
};

export type CompanyIdentifier = {
  __typename: 'CompanyIdentifier';
  companyId: Scalars['String'];
  companyIdentifierId: Scalars['String'];
  discriminatorCountry: Maybe<Scalars['String']>;
  identifierType: CompanyIdentifierType;
  identifierValue: Scalars['String'];
  source: Scalars['String'];
};

export type CompanyIdentifierConnection = {
  __typename: 'CompanyIdentifierConnection';
  edges: Maybe<Array<Maybe<CompanyIdentifierEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyIdentifierEdge = {
  __typename: 'CompanyIdentifierEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<CompanyIdentifier>;
};

export enum CompanyIdentifierType {
  MC_NUMBER = 'MC_NUMBER',
  MISCELLANEOUS = 'MISCELLANEOUS',
  SCAC = 'SCAC',
  TAX_IDENTIFICATION_NUMBER = 'TAX_IDENTIFICATION_NUMBER',
  USDOT_NUMBER = 'USDOT_NUMBER',
  VAT_NUMBER = 'VAT_NUMBER',
}

export type CompanyInvitationRecipient = {
  __typename: 'CompanyInvitationRecipient';
  email: Scalars['String'];
  locale: Scalars['String'];
};

export type CompanyInvite = {
  __typename: 'CompanyInvite';
  acceptedByCompany: Maybe<CompanyProfile>;
  companyInviteId: Scalars['String'];
  companyName: Maybe<Scalars['String']>;
  deadlineAt: Scalars['DateTime'];
  historyLog: Array<InvitationHistoryItem>;
  identifiers: Maybe<Array<CompanyIdentifier>>;
  inviteMethod: Maybe<CompanyInviteMethod>;
  invitedAt: Scalars['DateTime'];
  invitedByCompany: Maybe<CompanyProfile>;
  invitedByUser: Maybe<UserProfile>;
  invitedCompany: Maybe<CompanyProfile>;
  metadata: CompanyInviteMetadata;
  recipient: CompanyInviteRecipient;
  recipients: Array<CompanyInvitationRecipient>;
  status: CompanyInviteStatus;
};

export type CompanyInviteMetadata = {
  __typename: 'CompanyInviteMetadata';
  locale: Scalars['String'];
};

export enum CompanyInviteMethod {
  BULK = 'BULK',
  STANDALONE = 'STANDALONE',
}

export type CompanyInviteRecipient = {
  __typename: 'CompanyInviteRecipient';
  email: Scalars['String'];
  name: Scalars['String'];
};

export enum CompanyInviteStatus {
  COMPLETED = 'COMPLETED',
  IGNORED = 'IGNORED',
  PENDING = 'PENDING',
  WITHDRAWN = 'WITHDRAWN',
}

export type CompanyOverview = {
  __typename: 'CompanyOverview';
  assignmentPercentage: Maybe<Scalars['Float']>;
  assignmentPercentageChange: Maybe<Scalars['Float']>;
  companyId: Scalars['String'];
  tourCancelledCount: Scalars['Int'];
  tourCompletedCount: Scalars['Int'];
  tourTotalCount: Scalars['Int'];
  tourUnassignedCount: Scalars['Int'];
  tourUpcomingCount: Scalars['Int'];
  tourWorkingCount: Scalars['Int'];
  visibilityIndex: Maybe<Scalars['Float']>;
  visibilityIndexChange: Maybe<Scalars['Float']>;
};

export type CompanyPlace = {
  __typename: 'CompanyPlace';
  addresses: Array<CompanyPlaceAddress>;
  geofences: Array<PlaceGeofenceWithZone>;
  isSixfoldGenerated: Scalars['Boolean'];
  location: PointLocation;
  name: Scalars['String'];
  place_id: Scalars['String'];
  possibleAddresses: CompanyStopAddressConnection;
  referenceId: Maybe<Scalars['String']>;
  stopAddresses: Array<CompanyStopAddress>;
  tours: CountedPlaceTourConnection;
};

export type CompanyPlacePossibleAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CompanyPlaceToursArgs = {
  arrivalTimeAfter?: InputMaybe<Scalars['DateTime']>;
  arrivalTimeBefore?: InputMaybe<Scalars['DateTime']>;
  status: SiteTourStatusFilter;
};

export type CompanyPlaceAddress = {
  __typename: 'CompanyPlaceAddress';
  addressId: Maybe<Scalars['String']>;
  city: Scalars['String'];
  companyName: Maybe<Scalars['String']>;
  country: Scalars['String'];
  fullAddress: Maybe<Scalars['String']>;
  gate: Maybe<Scalars['String']>;
  gateId: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  streetAddress: Scalars['String'];
  zipcode: Scalars['String'];
};

export type CompanyPlaceConnection = {
  __typename: 'CompanyPlaceConnection';
  edges: Maybe<Array<Maybe<CompanyPlaceEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyPlaceEdge = {
  __typename: 'CompanyPlaceEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<CompanyPlace>;
};

export type CompanyPlatform = {
  __typename: 'CompanyPlatform';
  company_id: Scalars['String'];
  company_id_on_platform: Scalars['String'];
  company_platform_id: Scalars['String'];
  platform_id: Scalars['String'];
  platform_name: Scalars['String'];
};

export type CompanyPlatformInput = {
  company_id_on_platform: Scalars['String'];
  platform_id: Scalars['String'];
};

export type CompanyProfile = {
  __typename: 'CompanyProfile';
  company_id: Scalars['String'];
  company_name: Maybe<Scalars['String']>;
};

export type CompanyProfileConnection = {
  __typename: 'CompanyProfileConnection';
  edges: Maybe<Array<Maybe<CompanyProfileEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyProfileEdge = {
  __typename: 'CompanyProfileEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<CompanyProfile>;
};

export type CompanyRelationship = {
  __typename: 'CompanyRelationship';
  companyRelationshipId: Scalars['String'];
  dataSharingConsent: Maybe<DataSharingConsent>;
  relationIdentifiers: Array<CompanyIdentifier>;
  relationshipType: CompanyRelationshipType;
  sourceCompany: Company;
  targetCompany: Company;
  unifiedOnboardingEvents: Maybe<Array<UnifiedCompanyRelationshipOnboardingEvent>>;
  unifiedOnboardingStatus: Maybe<UnifiedCompanyRelationshipOnboardingStatus>;
  unifiedOnboardingStuckReason: Maybe<UnifiedCompanyOnboardingStuckReason>;
  unifiedSignupEvents: Maybe<Array<UnifiedCompanyRelationshipSignupEvent>>;
  unifiedSignupStatus: Maybe<UnifiedCompanyRelationshipSignupStatus>;
};

export type CompanyRelationshipConnection = {
  __typename: 'CompanyRelationshipConnection';
  edges: Maybe<Array<Maybe<CompanyRelationshipEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyRelationshipEdge = {
  __typename: 'CompanyRelationshipEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<CompanyRelationship>;
};

export enum CompanyRelationshipRole {
  SOURCE = 'SOURCE',
  TARGET = 'TARGET',
}

export enum CompanyRelationshipType {
  CARRIER = 'CARRIER',
  REFERRER = 'REFERRER',
  SUBCARRIER = 'SUBCARRIER',
}

export enum CompanyRole {
  CARRIER = 'CARRIER',
  SHIPPER = 'SHIPPER',
}

export type CompanySettings = {
  __typename: 'CompanySettings';
  allowAllocatingFromGlobalPool: Scalars['Boolean'];
  shareOwnVehiclesInGlobalPool: Scalars['Boolean'];
};

export type CompanySettingsInput = {
  allowAllocatingFromGlobalPool?: InputMaybe<Scalars['Boolean']>;
  shareOwnVehiclesInGlobalPool?: InputMaybe<Scalars['Boolean']>;
};

export type CompanySignup = {
  __typename: 'CompanySignup';
  companySignupId: Scalars['String'];
  contactPerson: Maybe<CompanySignupContactPerson>;
  hasFms: Maybe<Scalars['Boolean']>;
  isSubcontracting: Maybe<Scalars['Boolean']>;
  status: CompanySignupStatus;
  surveyAnswers: Maybe<Array<CompanySignupSurveyAnswer>>;
  surveySubmittedAt: Maybe<Scalars['DateTime']>;
};

export type CompanySignupCompleted = {
  __typename: 'CompanySignupCompleted';
  company: Company;
  companySignupId: Scalars['String'];
  status: CompanySignupStatus;
  user: User;
};

export type CompanySignupContactPerson = {
  __typename: 'CompanySignupContactPerson';
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
};

export enum CompanySignupStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
}

export type CompanySignupSurveyAnswer = {
  __typename: 'CompanySignupSurveyAnswer';
  fieldName: Scalars['String'];
  value: Scalars['String'];
};

export type CompanyStopAddress = {
  __typename: 'CompanyStopAddress';
  city: Maybe<Scalars['String']>;
  closeByAddresses: CompanyStopAddressConnection;
  companyId: Scalars['String'];
  companyName: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  invalidFields: Maybe<Array<CompanyStopAddressField>>;
  location: Maybe<PointLocation>;
  place: Maybe<CompanyPlace>;
  postalCode: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  stopAddressId: Scalars['String'];
  streetAddress: Maybe<Scalars['String']>;
};

export type CompanyStopAddressCloseByAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CompanyStopAddressConnection = {
  __typename: 'CompanyStopAddressConnection';
  edges: Maybe<Array<Maybe<CompanyStopAddressEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyStopAddressEdge = {
  __typename: 'CompanyStopAddressEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<CompanyStopAddress>;
};

export enum CompanyStopAddressField {
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
  POSTAL_CODE = 'POSTAL_CODE',
  STREET_ADDRESS = 'STREET_ADDRESS',
}

export type CompanyStopStates = {
  __typename: 'CompanyStopStates';
  country: Scalars['String'];
  state: Scalars['String'];
};

export type CompanyStopStatesConnection = {
  __typename: 'CompanyStopStatesConnection';
  edges: Maybe<Array<Maybe<CompanyStopStatesEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyStopStatesEdge = {
  __typename: 'CompanyStopStatesEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<CompanyStopStates>;
};

export type CompanyTag = {
  __typename: 'CompanyTag';
  name: Scalars['String'];
};

export type CompanyTagInput = {
  companyId: Scalars['String'];
  tagName: Scalars['String'];
};

export type CompanyTelematicsIntegration = {
  __typename: 'CompanyTelematicsIntegration';
  companyId: Scalars['String'];
  connectionData: Maybe<Scalars['GraphQLJSON']>;
  createdAt: Scalars['DateTime'];
  diagnosticStatus: Maybe<TelematicsIntegrationDiagnosticStatus>;
  enabled: Scalars['Boolean'];
  isAllowedToUpdateCredentials: Scalars['Boolean'];
  lastSuccess: Maybe<Scalars['DateTime']>;
  telematicsIntegrationId: Scalars['String'];
  telematicsProvider: TelematicsProvider;
  updatedAt: Scalars['DateTime'];
};

export type CompanyTourFilter = {
  __typename: 'CompanyTourFilter';
  companyTourFilterId: Scalars['String'];
  filter: TourFilter;
  name: Scalars['String'];
  sharedByUser: Maybe<UserProfile>;
  sharingToken: Maybe<Scalars['String']>;
  tourCount: Scalars['Int'];
  tours: Array<Tour>;
};

export type CompanyTourFilterToursArgs = {
  included_filter_ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type CompanyTourFilterConnection = {
  __typename: 'CompanyTourFilterConnection';
  edges: Maybe<Array<Maybe<CompanyTourFilterEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyTourFilterEdge = {
  __typename: 'CompanyTourFilterEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<CompanyTourFilter>;
};

export type CompanyTransportGroup = {
  __typename: 'CompanyTransportGroup';
  companyId: Scalars['String'];
  transportGroupId: Scalars['String'];
};

export type CompanyTransportGroupConnection = {
  __typename: 'CompanyTransportGroupConnection';
  edges: Maybe<Array<Maybe<CompanyTransportGroupEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyTransportGroupEdge = {
  __typename: 'CompanyTransportGroupEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<CompanyTransportGroup>;
};

export type CompanyTransportNetworkFilter = {
  __typename: 'CompanyTransportNetworkFilter';
  companyTransportNetworkFilterId: Scalars['String'];
  filter: TransportNetworkFilter;
  name: Scalars['String'];
};

export type CompanyTransportNetworkFilterConnection = {
  __typename: 'CompanyTransportNetworkFilterConnection';
  edges: Maybe<Array<Maybe<CompanyTransportNetworkFilterEdge>>>;
  pageInfo: PageInfo;
};

export type CompanyTransportNetworkFilterEdge = {
  __typename: 'CompanyTransportNetworkFilterEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<CompanyTransportNetworkFilter>;
};

export type CompanyTypeInput = {
  is_carrier?: InputMaybe<Scalars['Boolean']>;
  is_paying_customer?: InputMaybe<Scalars['Boolean']>;
  is_pending_signup?: InputMaybe<Scalars['Boolean']>;
  is_pilot?: InputMaybe<Scalars['Boolean']>;
  is_shipper?: InputMaybe<Scalars['Boolean']>;
  is_test?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyVatNumber = {
  __typename: 'CompanyVatNumber';
  vatNumber: Scalars['String'];
};

export type CompanyVatNumberChangeResult = {
  __typename: 'CompanyVatNumberChangeResult';
  success: Scalars['Boolean'];
};

export type CompanyVatNumberInput = {
  discriminatorCountry?: InputMaybe<Scalars['String']>;
  vatNumber: Scalars['String'];
};

export type CompanyVehicleGroup = {
  __typename: 'CompanyVehicleGroup';
  companyVehicleGroupId: Scalars['String'];
  name: Scalars['String'];
  vehiclesConnection: CountedVehicleInGroupConnection;
};

export type CompanyVehicleGroupVehiclesConnectionArgs = {
  licensePlateSearch?: InputMaybe<Scalars['String']>;
  vehicleState?: InputMaybe<VehicleState>;
  vehicleStateFilter?: InputMaybe<VehicleStateFilterInput>;
};

export type CompanyVehicles = {
  __typename: 'CompanyVehicles';
  all: Maybe<CountedVehicleConnection>;
  allocatable: Maybe<CountedVehicleConnection>;
  distinctDedicatedToPartnersVehiclesCount: Scalars['Int'];
  distinctDedicatedToPartnersVehiclesCountByStatus: VehicleCountByStatus;
  grantedByPartners: Maybe<CountedVehicleConnection>;
  grantedToPartners: Maybe<CountedVehicleConnection>;
};

export type CompanyVehiclesAllArgs = {
  grantedByPartnersFilter?: InputMaybe<Scalars['Boolean']>;
  hasAllocationsFilter?: InputMaybe<HasAllocationsFilterInput>;
  integrationIds?: InputMaybe<Array<Scalars['String']>>;
  licensePlateSearch?: InputMaybe<Scalars['String']>;
  ownVehiclesOnly?: InputMaybe<Scalars['Boolean']>;
  partnerNameSearch?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<VehicleFilterSortOrderInput>;
  timeBasedStatusFilter?: InputMaybe<TimeBasedStatus>;
  trackerTypes?: InputMaybe<Array<VehicleTrackerType>>;
  vehicleGroupIds?: InputMaybe<Array<Scalars['String']>>;
  vehicleState?: InputMaybe<VehicleState>;
  vehicleStateFilter?: InputMaybe<VehicleStateFilterInput>;
};

export type CompanyVehiclesAllocatableArgs = {
  hasAllocationsFilter?: InputMaybe<HasAllocationsFilterInput>;
  licensePlateSearch?: InputMaybe<Scalars['String']>;
  partnerNameSearch?: InputMaybe<Scalars['String']>;
  trackerTypes?: InputMaybe<Array<VehicleTrackerType>>;
};

export type CompanyVehiclesGrantedByPartnersArgs = {
  grantedByPartnerIds?: InputMaybe<Array<Scalars['String']>>;
  licensePlateSearch?: InputMaybe<Scalars['String']>;
  partnerNameSearch?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<VehicleFilterSortOrderInput>;
  timeBasedStatusFilter?: InputMaybe<TimeBasedStatus>;
  trackerTypes?: InputMaybe<Array<VehicleTrackerType>>;
};

export type CompanyVehiclesGrantedToPartnersArgs = {
  grantedToPartnerIds?: InputMaybe<Array<Scalars['String']>>;
  licensePlateSearch?: InputMaybe<Scalars['String']>;
  partnerNameSearch?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<VehiclePermissionsInput>;
  sort?: InputMaybe<VehicleFilterSortOrderInput>;
  timeBasedStatusFilter?: InputMaybe<TimeBasedStatus>;
  trackerTypes?: InputMaybe<Array<VehicleTrackerType>>;
};

export type CompanyVisibilitySource = {
  __typename: 'CompanyVisibilitySource';
  status: CompanyVisibilitySourceStatus;
  type: TourVisibilitySourceType;
};

export enum CompanyVisibilitySourceStatus {
  ACTIVE = 'ACTIVE',
  IDENTIFIED = 'IDENTIFIED',
}

export enum ComparisonOperator {
  GTE = 'GTE',
  LTE = 'LTE',
  RANGE = 'RANGE',
}

export enum ComparisonTerm {
  DELAY_GREATER_THAN = 'DELAY_GREATER_THAN',
}

export enum ComparisonUnit {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
}

export type Configuration = {
  __typename: 'Configuration';
  customerPortal: Maybe<CustomerPortalConfiguration>;
  sharedStopView: Maybe<SharedStopViewConfiguration>;
  sharedTourView: Maybe<SharedTourViewConfiguration>;
};

export type ConfigurationUpdateInput = {
  customerPortal?: InputMaybe<CustomerPortalConfigurationValueInput>;
  sharedStopView?: InputMaybe<SharedStopViewConfigurationValueInput>;
  sharedTourView?: InputMaybe<SharedTourViewConfigurationValueInput>;
};

export type ConnectedCustomField = {
  __typename: 'ConnectedCustomField';
  fieldName: Scalars['String'];
  valuesConnection: CountedCustomFieldValueConnection;
};

export type ConnectedCustomFieldValuesConnectionArgs = {
  textSearch?: InputMaybe<Scalars['String']>;
};

export type ConnectionParameter = {
  __typename: 'ConnectionParameter';
  defaultValue: Maybe<Scalars['String']>;
  enumFieldValues: Array<ConnectionParameterEnumValue>;
  fieldDisplayName: Maybe<Scalars['String']>;
  fieldName: Scalars['String'];
  fieldType: ConnectionParameterFieldType;
  isInternal: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isSensitive: Scalars['Boolean'];
};

export type ConnectionParameterEnumValue = {
  __typename: 'ConnectionParameterEnumValue';
  display_name: Maybe<Scalars['String']>;
  is_default: Scalars['Boolean'];
  value: Scalars['String'];
};

export enum ConnectionParameterFieldType {
  BOOLEAN = 'BOOLEAN',
  ENUM = 'ENUM',
  JSON = 'JSON',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  URL = 'URL',
}

export enum ConsentSource {
  AUTO_INHERITED = 'AUTO_INHERITED',
  BACKOFFICE_USER = 'BACKOFFICE_USER',
  INHERITED = 'INHERITED',
  USER = 'USER',
}

export enum ConsentState {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  REQUESTED = 'REQUESTED',
}

export type CopyDashboardInput = {
  companyId: Scalars['String'];
  dashboardId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CountedBulkInvitationSetConnection = {
  __typename: 'CountedBulkInvitationSetConnection';
  bulkInvitationSets: BulkInvitationSetConnection;
  count: Scalars['Int'];
};

export type CountedBulkInvitationSetConnectionBulkInvitationSetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedBulkInvitationSetItemConnection = {
  __typename: 'CountedBulkInvitationSetItemConnection';
  count: Scalars['Int'];
  items: BulkInvitationSetItemConnection;
  statistics: BulkInvitationSetStatistics;
};

export type CountedBulkInvitationSetItemConnectionItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedBulkInvitationSetItemConnectionStatisticsArgs = {
  filter?: InputMaybe<BulkInvitationSetStatisticsFilterInput>;
};

export type CountedCarrierConnection = {
  __typename: 'CountedCarrierConnection';
  carriers: CompanyProfileConnection;
  count: Scalars['Int'];
  hasAny: Scalars['Boolean'];
};

export type CountedCarrierConnectionCarriersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedCompanyBookingLocation = {
  __typename: 'CountedCompanyBookingLocation';
  bookingLocations: CompanyBookingLocationConnection;
  count: Scalars['Int'];
  hasAny: Scalars['Boolean'];
};

export type CountedCompanyBookingLocationBookingLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedCompanyPlaceConnection = {
  __typename: 'CountedCompanyPlaceConnection';
  count: Scalars['Int'];
  hasAny: Scalars['Boolean'];
  mapTilesBounds: Maybe<Bounds>;
  mapTilesTemplateURI: Maybe<Scalars['String']>;
  places: CompanyPlaceConnection;
};

export type CountedCompanyPlaceConnectionPlacesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedCompanyRelationConnection = {
  __typename: 'CountedCompanyRelationConnection';
  count: Scalars['Int'];
  networkRelations: NetworkRelationConnection;
  reportDownloadURI: Scalars['String'];
};

export type CountedCompanyRelationConnectionNetworkRelationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedCompanyStopAddressConnection = {
  __typename: 'CountedCompanyStopAddressConnection';
  addresses: CompanyStopAddressConnection;
  count: Scalars['Int'];
  mapTilesBounds: Maybe<Bounds>;
  mapTilesTemplateURI: Maybe<Scalars['String']>;
};

export type CountedCompanyStopAddressConnectionAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedCompanyStopStatesConnection = {
  __typename: 'CountedCompanyStopStatesConnection';
  hasAny: Scalars['Boolean'];
  stopStatesIdentifiers: CompanyStopStatesConnection;
};

export type CountedCompanyStopStatesConnectionStopStatesIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedCompanyTourFilterConnection = {
  __typename: 'CountedCompanyTourFilterConnection';
  companyTourFilters: CompanyTourFilterConnection;
  count: Scalars['Int'];
};

export type CountedCompanyTourFilterConnectionCompanyTourFiltersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedCompanyTransportGroupConnection = {
  __typename: 'CountedCompanyTransportGroupConnection';
  companyTransportGroups: CompanyTransportGroupConnection;
  hasAny: Scalars['Boolean'];
};

export type CountedCompanyTransportGroupConnectionCompanyTransportGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedCompanyTransportNetworkFilterConnection = {
  __typename: 'CountedCompanyTransportNetworkFilterConnection';
  companyTransportNetworkFilters: CompanyTransportNetworkFilterConnection;
  count: Scalars['Int'];
};

export type CountedCompanyTransportNetworkFilterConnectionCompanyTransportNetworkFiltersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedCustomFieldValueConnection = {
  __typename: 'CountedCustomFieldValueConnection';
  count: Scalars['Int'];
  values: CustomFieldValueConnection;
};

export type CountedCustomFieldValueConnectionValuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedDashboardsConnection = {
  __typename: 'CountedDashboardsConnection';
  count: Scalars['Int'];
  dashboards: DashboardConnection;
};

export type CountedDashboardsConnectionDashboardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedDataNetworkVisibilityReportCompanyConnection = {
  __typename: 'CountedDataNetworkVisibilityReportCompanyConnection';
  companies: DataNetworkVisibilityReportCompanyConnection;
  count: Scalars['Int'];
};

export type CountedDataNetworkVisibilityReportCompanyConnectionCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedMainCarrierConnection = {
  __typename: 'CountedMainCarrierConnection';
  count: Scalars['Int'];
  hasAny: Scalars['Boolean'];
  mainCarriers: CompanyProfileConnection;
};

export type CountedMainCarrierConnectionMainCarriersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedOrderItemConnection = {
  __typename: 'CountedOrderItemConnection';
  hasAny: Scalars['Boolean'];
  orderItemIdentifiers: OrderItemIdentifierConnection;
};

export type CountedOrderItemConnectionOrderItemIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedOrderItemCustomFieldConnection = {
  __typename: 'CountedOrderItemCustomFieldConnection';
  hasAny: Scalars['Boolean'];
  orderItemCustomFieldIdentifiers: OrderItemCustomFieldConnection;
};

export type CountedOrderItemCustomFieldConnectionOrderItemCustomFieldIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedPlaceTourConnection = {
  __typename: 'CountedPlaceTourConnection';
  count: Scalars['Int'];
  tours: TourConnection;
};

export type CountedPlaceTourConnectionToursArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedProvidingVisibilityCompaniesConnection = {
  __typename: 'CountedProvidingVisibilityCompaniesConnection';
  companies: ProvidingVisibilityCompanyConnection;
  count: Scalars['Int'];
};

export type CountedProvidingVisibilityCompaniesConnectionCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedReceivingVisibilityCompaniesConnection = {
  __typename: 'CountedReceivingVisibilityCompaniesConnection';
  companies: ReceivingVisibilityCompanyConnection;
  count: Scalars['Int'];
};

export type CountedReceivingVisibilityCompaniesConnectionCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedShipperConnection = {
  __typename: 'CountedShipperConnection';
  count: Scalars['Int'];
  hasAny: Scalars['Boolean'];
  shippers: CompanyProfileConnection;
};

export type CountedShipperConnectionShippersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedSiteConnection = {
  __typename: 'CountedSiteConnection';
  count: Scalars['Int'];
  hasAny: Scalars['Boolean'];
  sites: SiteConnection;
};

export type CountedSiteConnectionSitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedStopCompanyNameConnection = {
  __typename: 'CountedStopCompanyNameConnection';
  hasAny: Scalars['Boolean'];
  stopCompanyNameIdentifiers: StopCompanyNameConnection;
};

export type CountedStopCompanyNameConnectionStopCompanyNameIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedTelematicsIntegrationConnection = {
  __typename: 'CountedTelematicsIntegrationConnection';
  count: Scalars['Int'];
  integrations: TelematicsIntegrationConnection;
};

export type CountedTelematicsIntegrationConnectionIntegrationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedTourConnection = {
  __typename: 'CountedTourConnection';
  count: Scalars['Int'];
  mapTilesBounds: Maybe<Bounds>;
  mapTilesTemplateURI: Maybe<Scalars['String']>;
  reportDownloadURI: Maybe<Scalars['String']>;
  tours: TourConnection;
};

export type CountedTourConnectionReportDownloadUriArgs = {
  timezone?: InputMaybe<Scalars['IANATimezone']>;
};

export type CountedTourConnectionToursArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedTripsIdentifiersConnection = {
  __typename: 'CountedTripsIdentifiersConnection';
  hasAny: Scalars['Boolean'];
  tourIdentifiers: TripsTourIdentifierConnection;
};

export type CountedTripsIdentifiersConnectionTourIdentifiersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedTripsOptionConnection = {
  __typename: 'CountedTripsOptionConnection';
  count: Scalars['Int'];
  options: Maybe<TripsOptionConnection>;
};

export type CountedTripsOptionConnectionOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  subType?: InputMaybe<Scalars['String']>;
  textSearch?: InputMaybe<Scalars['String']>;
  transportMode?: InputMaybe<TripsTransportMode>;
  type?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CountedTripsTourConnection = {
  __typename: 'CountedTripsTourConnection';
  count: Scalars['Int'];
  reportDownloadURI: Maybe<Scalars['String']>;
  tours: TourConnection;
};

export type CountedTripsTourConnectionReportDownloadUriArgs = {
  timezone?: InputMaybe<Scalars['IANATimezone']>;
};

export type CountedTripsTourConnectionToursArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedUserFilterConnection = {
  __typename: 'CountedUserFilterConnection';
  count: Scalars['Int'];
  userFilters: UserFilterConnection;
};

export type CountedUserFilterConnectionUserFiltersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedUserInCompanyProfileConnection = {
  __typename: 'CountedUserInCompanyProfileConnection';
  count: Scalars['Int'];
  hasAny: Scalars['Boolean'];
  users: UserInCompanyProfileConnection;
};

export type CountedUserInCompanyProfileConnectionUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedVehicleConnection = {
  __typename: 'CountedVehicleConnection';
  boundingBox: Maybe<Array<PointLocation>>;
  count: Scalars['Int'];
  mapTilesTemplateURI: Maybe<Scalars['String']>;
  vehicles: VehicleConnection;
  vehiclesReportDownloadURI: Maybe<Scalars['String']>;
};

export type CountedVehicleConnectionMapTilesTemplateUriArgs = {
  dwellTime?: InputMaybe<Scalars['Int']>;
  returnTimeBasedStatus?: InputMaybe<Scalars['Boolean']>;
  returnVehicleState?: InputMaybe<Scalars['Boolean']>;
};

export type CountedVehicleConnectionVehiclesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedVehicleGrantPartnerConnection = {
  __typename: 'CountedVehicleGrantPartnerConnection';
  count: Scalars['Int'];
  partners: VehicleGrantPartnerConnection;
};

export type CountedVehicleGrantPartnerConnectionPartnersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedVehicleInGroupConnection = {
  __typename: 'CountedVehicleInGroupConnection';
  count: Scalars['Int'];
  vehicles: Maybe<VehicleConnection>;
};

export type CountedVehicleInGroupConnectionVehiclesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CountedWidgetsConnection = {
  __typename: 'CountedWidgetsConnection';
  count: Scalars['Int'];
  widgets: WidgetConnection;
};

export type CountedWidgetsConnectionWidgetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['IANATimezone']>;
};

export type CreateBulkInvitationSetInput = {
  invitations: Array<CompanyBulkInvitation>;
  metadata: CompanyBulkInviteMetadata;
};

export type CreateBulkInvitationSetItemInput = {
  companyName?: InputMaybe<Scalars['String']>;
  recipients: Array<CompanyBulkInvitationRecipient>;
};

export type CreateBulkInvitationSetResult = {
  __typename: 'CreateBulkInvitationSetResult';
  bulkInvitationSetId: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreateCompanyIdentifierInput = {
  companyId: Scalars['String'];
  discriminatorCountry?: InputMaybe<Scalars['String']>;
  identifierType: CompanyIdentifierType;
  identifierValue: Scalars['String'];
};

export type CreateCompanyIdentifierResult = {
  __typename: 'CreateCompanyIdentifierResult';
  success: Scalars['Boolean'];
};

export type CreateCompanyInput = {
  company_name: Scalars['String'];
  feature_flags?: InputMaybe<Scalars['GraphQLJSON']>;
  is_carrier?: InputMaybe<Scalars['Boolean']>;
  is_paying_customer?: InputMaybe<Scalars['Boolean']>;
  is_pending_signup?: InputMaybe<Scalars['Boolean']>;
  is_pilot?: InputMaybe<Scalars['Boolean']>;
  is_shipper?: InputMaybe<Scalars['Boolean']>;
  is_test?: InputMaybe<Scalars['Boolean']>;
};

export type CreateCompanyInvitationInput = {
  externalInvitations: Array<ExternalCompanyInvitation>;
};

export type CreateCompanyInvitationsResult = {
  __typename: 'CreateCompanyInvitationsResult';
  invalidEmails: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CreateCompanyPlaceInput = {
  companyId: Scalars['String'];
  geodata?: InputMaybe<Array<PlaceGeodataInput>>;
  location: PointLocationInput;
  name: Scalars['String'];
  referenceId?: InputMaybe<Scalars['String']>;
  stopAddressIds: Array<Scalars['String']>;
};

export type CreateCompanyRelationshipInput = {
  relationshipType: CompanyRelationshipType;
  sourceCompanyId: Scalars['String'];
  targetCompanyId: Scalars['String'];
};

export type CreateCompanyTourFilterInput = {
  companyId: Scalars['String'];
  companyRole?: InputMaybe<CompanyRole>;
  filter: TourFilterInput;
  filterType?: InputMaybe<FilterType>;
  name: Scalars['String'];
};

export type CreateCompanyTransportNetworkFilterInput = {
  companyId: Scalars['String'];
  filter: DataNetworkReportFilterInput;
  name: Scalars['String'];
};

export type CreateCompanyVehicleGroupInput = {
  companyId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateDashboardInput = {
  companyId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateExampleDashboardInput = {
  companyId: Scalars['String'];
  translatedName: Scalars['String'];
  translatedWidgets: Array<ExampleWidgetInput>;
};

export type CreateNoteInput = {
  companyId: Scalars['String'];
  deliveryIds?: InputMaybe<Array<Scalars['String']>>;
  noteText: Scalars['String'];
  noteVisibility: NoteVisibility;
  stopId?: InputMaybe<Scalars['String']>;
  tourId: Scalars['String'];
};

export type CreateOrUpdateFilterBasedTransportNotificationInput = {
  companyId: Scalars['String'];
  name: Scalars['String'];
  notificationId?: InputMaybe<Scalars['String']>;
  notificationTrigger?: InputMaybe<NotificationTriggerInput>;
  recipients?: InputMaybe<Array<EmailRecipientInput>>;
  recipientsV2?: InputMaybe<Array<RecipientInput>>;
  transportFilter: TourFilterInput;
};

export type CreateOrUpdateRelationIdentifiersInput = {
  companyId: Scalars['String'];
  companyInviteId?: InputMaybe<Scalars['String']>;
  identifierValues?: InputMaybe<Array<IdentifierValueInput>>;
  ownerCompanyId: Scalars['String'];
};

export type CreateOrUpdateRelationIdentifiersResult = {
  __typename: 'CreateOrUpdateRelationIdentifiersResult';
  invalidIdentifiers: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CreateOrUpdateTransportNotificationInput = {
  companyId: Scalars['String'];
  notificationId?: InputMaybe<Scalars['String']>;
  notificationTrigger?: InputMaybe<NotificationTriggerInput>;
  recipients?: InputMaybe<Array<EmailRecipientInput>>;
  recipientsV2?: InputMaybe<Array<RecipientInput>>;
  tourId: Scalars['String'];
};

export type CreatePlatformSupportTicketInput = {
  businessImpact: Scalars['String'];
  companyId: Scalars['String'];
  content: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  sourceUrl: Scalars['String'];
  title: Scalars['String'];
  tourId?: InputMaybe<Scalars['String']>;
};

export type CreateTelematicsIntegrationInput = {
  company_id: Scalars['String'];
  company_integration_id: Scalars['String'];
  connection_data: Scalars['GraphQLJSON'];
  enabled: Scalars['Boolean'];
  telematics_provider_id: Scalars['String'];
};

export type CreateUserFilterInput = {
  companyId: Scalars['String'];
  companyRole?: InputMaybe<CompanyRole>;
  filter: TourFilterInput;
  filterType?: InputMaybe<FilterType>;
  name: Scalars['String'];
};

export type CreateVehicleGrantsInput = {
  grantedByCompany: Scalars['String'];
  grantedToCompany: Scalars['String'];
};

export type CreateVehicleInput = {
  licensePlate: Scalars['String'];
  remoteId: Scalars['String'];
};

export type CreateVehicleMobileAppTrackerInput = {
  alias: Scalars['String'];
  companyId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CreateVehiclesInput = {
  telematicsIntegrationId: Scalars['String'];
  vehicles: Array<CreateVehicleInput>;
};

export type CreateVisibilitySolutionsTicketInput = {
  companyId: Scalars['String'];
  content: Scalars['String'];
  sourceUrl: Scalars['String'];
  title: Scalars['String'];
};

export type CreateWidgetInput = {
  dashboardId: Scalars['String'];
  filters: Array<WidgetFilterInput>;
  name: Scalars['String'];
  operator?: InputMaybe<OperatorType>;
  type: WidgetType;
};

export enum CredentialType {
  API_KEY = 'API_KEY',
  EXT_SHIPMENT_STATUS_API_KEY = 'EXT_SHIPMENT_STATUS_API_KEY',
}

export type Credentials = {
  __typename: 'Credentials';
  addedAt: Scalars['DateTime'];
  addedByUserId: Scalars['String'];
  type: CredentialType;
  userId: Scalars['String'];
  value: Scalars['String'];
};

export type CustomField = {
  __typename: 'CustomField';
  fieldName: Scalars['String'];
  values: Array<CustomFieldValue>;
};

export type CustomFieldValue = {
  __typename: 'CustomFieldValue';
  value: Scalars['String'];
};

export type CustomFieldValueConnection = {
  __typename: 'CustomFieldValueConnection';
  edges: Maybe<Array<Maybe<CustomFieldValueEdge>>>;
  pageInfo: PageInfo;
};

export type CustomFieldValueEdge = {
  __typename: 'CustomFieldValueEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<CustomFieldValue>;
};

export type CustomerPortalConfiguration = {
  __typename: 'CustomerPortalConfiguration';
  detailViewTransportIdentifiers: Array<TransportIdentifier>;
  primaryTransportIdentifier: TransportIdentifier;
};

export type CustomerPortalConfigurationInput = {
  detailViewTransportIdentifiers: Array<TransportIdentifier>;
  primaryTransportIdentifier: TransportIdentifier;
};

export type CustomerPortalConfigurationValueInput = {
  value?: InputMaybe<CustomerPortalConfigurationInput>;
};

export type Dashboard = {
  __typename: 'Dashboard';
  canUserEdit: Scalars['Boolean'];
  dashboardId: Scalars['String'];
  description: Maybe<Scalars['String']>;
  isExample: Scalars['Boolean'];
  isOwnedByMe: Scalars['Boolean'];
  name: Scalars['String'];
  owner: UserProfile;
  sharedWithUsers: Maybe<Array<UserProfile>>;
  sharingType: DashboardSharingType;
  widget: Maybe<Widget>;
  widgets: CountedWidgetsConnection;
};

export type DashboardWidgetArgs = {
  timezone?: InputMaybe<Scalars['IANATimezone']>;
  widgetId: Scalars['String'];
};

export type DashboardConnection = {
  __typename: 'DashboardConnection';
  edges: Maybe<Array<Maybe<DashboardEdge>>>;
  pageInfo: PageInfo;
};

export type DashboardEdge = {
  __typename: 'DashboardEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Dashboard>;
};

export enum DashboardQueryType {
  CREATED_BY_ME = 'CREATED_BY_ME',
  SHARED_WITH_ME = 'SHARED_WITH_ME',
}

export enum DashboardSharingType {
  ONLY_ME = 'ONLY_ME',
  WITH_COMPANY = 'WITH_COMPANY',
  WITH_SPECIFIC_USERS = 'WITH_SPECIFIC_USERS',
}

export type DataNetworkReportFilterInput = {
  carrierIds?: InputMaybe<Array<Scalars['String']>>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch>>;
  endTimeEnd?: InputMaybe<Scalars['DateTime']>;
  endTimeStart?: InputMaybe<Scalars['DateTime']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  mainVisibilityIssues?: InputMaybe<Array<VisibilityIssueType>>;
  onboardingStatus?: InputMaybe<Array<UnifiedCompanyRelationshipOnboardingStatus>>;
  placeIds?: InputMaybe<Array<Scalars['String']>>;
  relationIdentifierIds?: InputMaybe<Array<Scalars['String']>>;
  shipperIds?: InputMaybe<Array<Scalars['String']>>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  startTimeEnd: Scalars['DateTime'];
  startTimeStart: Scalars['DateTime'];
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  stopBookingLocationNames?: InputMaybe<Array<Scalars['String']>>;
  stopCountries?: InputMaybe<Array<Scalars['String']>>;
  stopFilters?: InputMaybe<Array<StopFilterInput>>;
  stopStates?: InputMaybe<Array<StopStatesInput>>;
};

export enum DataNetworkReportSortKey {
  ASSIGNMENT_PERCENTAGE = 'ASSIGNMENT_PERCENTAGE',
  ASSIGNMENT_PERCENTAGE_CHANGE = 'ASSIGNMENT_PERCENTAGE_CHANGE',
  CANCELLED_TOURS_COUNT = 'CANCELLED_TOURS_COUNT',
  COMPANY_NAME = 'COMPANY_NAME',
  COMPLETED_TOURS_COUNT = 'COMPLETED_TOURS_COUNT',
  DATE_ADDED = 'DATE_ADDED',
  ONBOARDING_STATUS = 'ONBOARDING_STATUS',
  ONBOARDING_STATUS_TRANSITIONED_AT = 'ONBOARDING_STATUS_TRANSITIONED_AT',
  TOTAL_TOURS_COUNT = 'TOTAL_TOURS_COUNT',
  TOUR_STATUS_CONSENT = 'TOUR_STATUS_CONSENT',
  UNASSIGNED_TOURS_COUNT = 'UNASSIGNED_TOURS_COUNT',
  UPCOMING_TOURS_COUNT = 'UPCOMING_TOURS_COUNT',
  VISIBILITY_INDEX = 'VISIBILITY_INDEX',
  VISIBILITY_INDEX_CHANGE = 'VISIBILITY_INDEX_CHANGE',
  WORKING_TOURS_COUNT = 'WORKING_TOURS_COUNT',
}

export type DataNetworkReportSortOrderInput = {
  direction: SortDirection;
  key: DataNetworkReportSortKey;
};

export enum DataNetworkReportType {
  PROVIDING_VISIBILITY = 'PROVIDING_VISIBILITY',
  RECEIVING_VISIBILITY = 'RECEIVING_VISIBILITY',
}

export type DataNetworkVisibilityReport = {
  __typename: 'DataNetworkVisibilityReport';
  companies: CountedDataNetworkVisibilityReportCompanyConnection;
  companyOverview: CompanyOverview;
  reportDownloadURI: Scalars['String'];
  toursCountByStatus: ToursCountByStatus;
};

export type DataNetworkVisibilityReportToursCountByStatusArgs = {
  status: TourStatusFilter;
};

export type DataNetworkVisibilityReportCompany = {
  __typename: 'DataNetworkVisibilityReportCompany';
  assignmentPercentage: Maybe<Scalars['Float']>;
  assignmentPercentageChange: Maybe<Scalars['Float']>;
  companyId: Scalars['String'];
  companyName: Maybe<Scalars['String']>;
  latestPendingInvite: Maybe<CompanyInvite>;
  mainVisibilityIssueType: Maybe<VisibilityIssueType>;
  onboardingStatus: Maybe<UnifiedCompanyRelationshipOnboardingStatus>;
  onboardingStuckReason: Maybe<UnifiedCompanyOnboardingStuckReason>;
  platforms: Array<CompanyPlatform>;
  relationIdentifiers: Array<CompanyIdentifier>;
  tourCancelledCount: Scalars['Int'];
  tourCompletedCount: Scalars['Int'];
  tourTotalCount: Scalars['Int'];
  tourUnassignedCount: Scalars['Int'];
  tourUpcomingCount: Scalars['Int'];
  tourWorkingCount: Scalars['Int'];
  visibilityIndex: Maybe<Scalars['Float']>;
  visibilityIndexChange: Maybe<Scalars['Float']>;
};

export type DataNetworkVisibilityReportCompanyConnection = {
  __typename: 'DataNetworkVisibilityReportCompanyConnection';
  edges: Maybe<Array<Maybe<DataNetworkVisibilityReportCompanyEdge>>>;
  pageInfo: PageInfo;
};

export type DataNetworkVisibilityReportCompanyEdge = {
  __typename: 'DataNetworkVisibilityReportCompanyEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<DataNetworkVisibilityReportCompany>;
};

export type DataSharingConsent = {
  __typename: 'DataSharingConsent';
  history: Maybe<Array<DataSharingConsentHistory>>;
  tourStatusScope: ConsentState;
};

export type DataSharingConsentHistory = {
  __typename: 'DataSharingConsentHistory';
  consentSource: ConsentSource;
  tourStatusScope: ConsentState;
  updatedAt: Scalars['DateTime'];
  updatedByUser: Maybe<UserProfile>;
};

export type DateTimeWithTimezone = {
  __typename: 'DateTimeWithTimezone';
  dateTime: Scalars['DateTime'];
  timezone: Scalars['Timezone'];
};

export enum DelayComparisonOperator {
  GTE = 'GTE',
  LTE = 'LTE',
}

export type DelayTimeRange = {
  comparison: DelayComparisonOperator;
  delayUnit: DelayTimerangeUnit;
  startInSeconds: Scalars['Int'];
};

export type DelayTimeRangeOutput = {
  __typename: 'DelayTimeRangeOutput';
  comparison: DelayComparisonOperator;
  delayUnit: DelayTimerangeUnit;
  startInSeconds: Scalars['Int'];
};

export enum DelayTimerangeUnit {
  DAY = 'DAY',
  HOUR = 'HOUR',
}

export type DeleteBulkInvitationSetInput = {
  bulkInvitationSetId: Scalars['String'];
};

export type DeleteBulkInvitationSetResult = {
  __typename: 'DeleteBulkInvitationSetResult';
  success: Scalars['Boolean'];
};

export type DeleteCompanyIdentifierInput = {
  companyIdentifierId: Scalars['String'];
};

export type DeleteCompanyIdentifierResult = {
  __typename: 'DeleteCompanyIdentifierResult';
  success: Scalars['Boolean'];
};

export type DeleteCompanyPlatformInput = {
  platform_id: Scalars['String'];
};

export type DeleteCompanyRelationshipInput = {
  companyRelationshipId: Scalars['String'];
};

export type DeleteCompanyVehicleGroupInput = {
  companyId: Scalars['String'];
  companyVehicleGroupId: Scalars['String'];
};

export type DeleteFilterBasedTransportNotificationInput = {
  notificationId: Scalars['String'];
};

export type DeleteTransportNotificationInput = {
  notificationId: Scalars['String'];
};

export type DeleteVehicleInput = {
  vehicle_id: Scalars['String'];
};

export type DeleteVehicleTrackerInput = {
  trackerId: Scalars['String'];
};

export type DeleteVehiclesInput = {
  companyId: Scalars['String'];
  vehicleIds: Array<Scalars['String']>;
};

export type DeleteWidgetInput = {
  dashboardId: Scalars['String'];
  widgetId: Scalars['String'];
};

export type DeliveryStop = {
  __typename: 'DeliveryStop';
  stopId: Scalars['String'];
};

export enum DemurrageType {
  PORT_OF_DISCHARGE = 'PORT_OF_DISCHARGE',
  PORT_OF_LOADING = 'PORT_OF_LOADING',
}

export type Document = {
  __typename: 'Document';
  associatedDeliveries: Array<TourDelivery>;
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  documentId: Scalars['String'];
  documentType: DocumentType;
  documentUrl: Scalars['String'];
  stopId: Maybe<Scalars['String']>;
};

export enum DocumentType {
  BOL = 'BOL',
  Check = 'Check',
  DOTInspection = 'DOTInspection',
  DeliveryTicketReceiptOrder = 'DeliveryTicketReceiptOrder',
  Directions = 'Directions',
  FineTicket = 'FineTicket',
  FreightBill = 'FreightBill',
  FreightDamageDocumentation = 'FreightDamageDocumentation',
  FuelReceipt = 'FuelReceipt',
  Insurance = 'Insurance',
  Invoice = 'Invoice',
  Log = 'Log',
  LumperReceipt = 'LumperReceipt',
  Match = 'Match',
  ODPermit = 'ODPermit',
  Other = 'Other',
  POD = 'POD',
  PackingSlip = 'PackingSlip',
  ProofOfPerformance = 'ProofOfPerformance',
  RateConfirmation = 'RateConfirmation',
  ScaleReceipt = 'ScaleReceipt',
  SignatureCapture = 'SignatureCapture',
  TripSheet = 'TripSheet',
  TruckTrailerDamageDocumentation = 'TruckTrailerDamageDocumentation',
  WeightScaleCertificate = 'WeightScaleCertificate',
}

export type DummyMobileTourAllocationInput = {
  companyId: Scalars['String'];
  tourId: Scalars['String'];
  vehicleTrackerId: Scalars['String'];
};

export enum EtaType {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
  ML_MODEL = 'ML_MODEL',
  NOT_UPDATED = 'NOT_UPDATED',
  TRIMBLE_INTERNAL = 'TRIMBLE_INTERNAL',
}

export type EmailRecipient = {
  __typename: 'EmailRecipient';
  email: Scalars['String'];
  locale: Scalars['String'];
};

export type EmailRecipientInput = {
  email: Scalars['String'];
  locale: Scalars['String'];
};

export enum EmailType {
  GLOBAL_ALLOCATION_TOUR_FORWARDED = 'GLOBAL_ALLOCATION_TOUR_FORWARDED',
  TOUR_FORWARDED = 'TOUR_FORWARDED',
}

export type EmissionCost = {
  __typename: 'EmissionCost';
  name: Scalars['String'];
  tankToWheel: Scalars['Float'];
  total: Scalars['Float'];
  wellToTank: Scalars['Float'];
};

export enum EmissionsCalculationErrorCode {
  ECOTRANSIT_IATA_CODE_NOT_FOUND = 'ECOTRANSIT_IATA_CODE_NOT_FOUND',
  ECOTRANSIT_INDEX_OUT_OF_BOUNDS = 'ECOTRANSIT_INDEX_OUT_OF_BOUNDS',
  ECOTRANSIT_POSTAL_CODE_AND_COUNTRY_NOT_FOUND = 'ECOTRANSIT_POSTAL_CODE_AND_COUNTRY_NOT_FOUND',
  ECOTRANSIT_ROUTING_ERROR = 'ECOTRANSIT_ROUTING_ERROR',
  ECOTRANSIT_TOO_MANY_LEGS_RETURNED = 'ECOTRANSIT_TOO_MANY_LEGS_RETURNED',
  ECOTRANSIT_UIC_COUNTRY_AND_CODE_NOT_FOUND = 'ECOTRANSIT_UIC_COUNTRY_AND_CODE_NOT_FOUND',
  ECOTRANSIT_UN_LOCODE_NOT_FOUND = 'ECOTRANSIT_UN_LOCODE_NOT_FOUND',
  TOUR_DELIVERIES_MISSING_WEIGHT = 'TOUR_DELIVERIES_MISSING_WEIGHT',
  TOUR_DELIVERIES_NOT_ENOUGH_VALID = 'TOUR_DELIVERIES_NOT_ENOUGH_VALID',
  TOUR_DISTANCE_CALCULATION_FAILED = 'TOUR_DISTANCE_CALCULATION_FAILED',
  TOUR_HISTORY_POLYLINES_TOO_MANY_POINTS = 'TOUR_HISTORY_POLYLINES_TOO_MANY_POINTS',
  TOUR_HISTORY_POLYLINES_ZERO = 'TOUR_HISTORY_POLYLINES_ZERO',
  TOUR_INVALID_MAX_DEPARTURE = 'TOUR_INVALID_MAX_DEPARTURE',
  TOUR_INVALID_MIN_ARRIVAL = 'TOUR_INVALID_MIN_ARRIVAL',
  TOUR_MORE_THAN_ONE_VEHICLE = 'TOUR_MORE_THAN_ONE_VEHICLE',
  TOUR_STATUS_EVENT_TELEMETRY_MISMATCH = 'TOUR_STATUS_EVENT_TELEMETRY_MISMATCH',
  TOUR_STATUS_NOT_COMPLETED = 'TOUR_STATUS_NOT_COMPLETED',
  TOUR_STATUS_POSSIBLE_WRONG_TRUCK = 'TOUR_STATUS_POSSIBLE_WRONG_TRUCK',
  TOUR_STOPS_MISSING_COORDINATES = 'TOUR_STOPS_MISSING_COORDINATES',
  TOUR_STOPS_NOT_ENOUGH_VALID = 'TOUR_STOPS_NOT_ENOUGH_VALID',
  TOUR_STOPS_NOT_ENOUGH_VALID_UNIQUE = 'TOUR_STOPS_NOT_ENOUGH_VALID_UNIQUE',
  TOUR_STOPS_SOME_IGNORED = 'TOUR_STOPS_SOME_IGNORED',
  TOUR_VEHICLE_MISSING = 'TOUR_VEHICLE_MISSING',
  TOUR_WEIGHT_OUT_OF_ALLOWED_RANGE = 'TOUR_WEIGHT_OUT_OF_ALLOWED_RANGE',
}

export enum EmissionsCalculationErrorType {
  DISTANCE = 'DISTANCE',
  FUEL = 'FUEL',
  PLANNED = 'PLANNED',
  PRECALC = 'PRECALC',
  TELEMETRY = 'TELEMETRY',
}

export type EtaComparison = {
  __typename: 'EtaComparison';
  comparisonTerm: ComparisonTerm;
  comparisonUnit: ComparisonUnit;
  comparisonValueInSeconds: Scalars['Int'];
  remainingTimeInSeconds: Maybe<Scalars['Int']>;
  remainingTimeUnit: Maybe<ComparisonUnit>;
  stopId: Maybe<Scalars['String']>;
};

export type EtaComparisonInput = {
  comparisonTerm: ComparisonTerm;
  comparisonUnit: ComparisonUnit;
  comparisonValueInSeconds: Scalars['Int'];
  remainingTimeInSeconds?: InputMaybe<Scalars['Int']>;
  remainingTimeUnit?: InputMaybe<ComparisonUnit>;
  stopId?: InputMaybe<Scalars['String']>;
};

export type ExampleWidgetInput = {
  exampleWidgetType: ExampleWidgetType;
  translatedName: Scalars['String'];
};

export enum ExampleWidgetType {
  DELAYED_TOWARDS_DELIVERY = 'DELAYED_TOWARDS_DELIVERY',
  ON_TIME_TOWARDS_DELIVERY = 'ON_TIME_TOWARDS_DELIVERY',
  ON_TIME_TOWARDS_PICKUP = 'ON_TIME_TOWARDS_PICKUP',
}

export type ExceptionFile = {
  __typename: 'ExceptionFile';
  description: Maybe<Scalars['String']>;
  documentUrl: Scalars['String'];
  platformDocumentId: Scalars['String'];
};

export enum ExceptionType {
  AdditionalCarry = 'AdditionalCarry',
  DeliveryIssueCallCustomer = 'DeliveryIssueCallCustomer',
  DeliveryIssueNeedTechnician = 'DeliveryIssueNeedTechnician',
  Elevator = 'Elevator',
  Ferry = 'Ferry',
  JoybirdCallCustomer = 'JoybirdCallCustomer',
  JoybirdNeedTechnician = 'JoybirdNeedTechnician',
  JoybirdRefused = 'JoybirdRefused',
  Misc = 'Misc',
  NoDeliveryCustomerCancelled = 'NoDeliveryCustomerCancelled',
  NoDeliveryMissedDeliveryWindow = 'NoDeliveryMissedDeliveryWindow',
  NoDeliveryNotAtHome = 'NoDeliveryNotAtHome',
  NoDeliveryOther = 'NoDeliveryOther',
  RefusalCustomerPreference = 'RefusalCustomerPreference',
  RefusalDamage = 'RefusalDamage',
  RefusalDamageCover = 'RefusalDamageCover',
  RefusalDamageFrame = 'RefusalDamageFrame',
}

export type ExposeSixfoldGeneratedPlaceInput = {
  companyId: Scalars['String'];
  placeId: Scalars['String'];
};

export enum ExtendedComparisonOperator {
  ANY_TIME_RANGE = 'ANY_TIME_RANGE',
  GTE = 'GTE',
  LTE = 'LTE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  RANGE = 'RANGE',
}

export type ExternalCarrier = {
  __typename: 'ExternalCarrier';
  companyID: Maybe<Scalars['String']>;
  companyName: Maybe<Scalars['String']>;
};

export type ExternalCompanyInvitation = {
  companyName: Scalars['String'];
  identifierValues?: InputMaybe<Array<Scalars['String']>>;
  recipients: Array<ExternalCompanyInvitationRecipient>;
};

export type ExternalCompanyInvitationRecipient = {
  email: Scalars['String'];
  locale: Scalars['String'];
};

export type ExternalCosts = {
  __typename: 'ExternalCosts';
  accidentCosts: Array<AccidentCost>;
  currency: Scalars['String'];
  emissionCosts: Array<EmissionCost>;
  noiseCosts: Array<NoiseCost>;
  totalExternalCosts: Scalars['Float'];
};

export enum ExternalEtaSource {
  CARRIER_API = 'CARRIER_API',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  TTC = 'TTC',
}

export enum ExternalEventSource {
  CARRIER_API = 'CARRIER_API',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_API_TOUR_EVENTS = 'PLATFORM_API_TOUR_EVENTS',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  TTC = 'TTC',
}

export type ExternalTourDeliveryEvent = {
  __typename: 'ExternalTourDeliveryEvent';
  createdAt: Scalars['DateTime'];
  delivery: Maybe<TourDelivery>;
  deliveryId: Scalars['String'];
  eventId: Scalars['String'];
  eventSource: ExternalEventSource;
  externalEventId: Maybe<Scalars['String']>;
  externalEventQualifier: Scalars['String'];
  externalEventSource: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  qualifier: ExternalTourDeliveryEventQualifier;
  sentByCompanyId: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
};

export enum ExternalTourDeliveryEventQualifier {
  HEADING_TOWARDS_LOADING = 'HEADING_TOWARDS_LOADING',
  HEADING_TOWARDS_UNLOADING = 'HEADING_TOWARDS_UNLOADING',
  LOADING_ARRIVED = 'LOADING_ARRIVED',
  LOADING_COMPLETED = 'LOADING_COMPLETED',
  LOADING_DEPARTED = 'LOADING_DEPARTED',
  LOADING_STARTED = 'LOADING_STARTED',
  OTHER = 'OTHER',
  UNLOADING_ARRIVED = 'UNLOADING_ARRIVED',
  UNLOADING_COMPLETED = 'UNLOADING_COMPLETED',
  UNLOADING_DEPARTED = 'UNLOADING_DEPARTED',
  UNLOADING_STARTED = 'UNLOADING_STARTED',
}

export type ExternalTourEvent = {
  __typename: 'ExternalTourEvent';
  createdAt: Scalars['DateTime'];
  eventId: Scalars['String'];
  eventSource: ExternalEventSource;
  externalEventId: Maybe<Scalars['String']>;
  externalEventQualifier: Scalars['String'];
  externalEventSource: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  licensePlateNumber: Maybe<Scalars['String']>;
  locationName: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['Float']>;
  qualifier: ExternalTourEventQualifier;
  remoteId: Maybe<Scalars['String']>;
  sentByCompanyId: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  vehicleType: Maybe<VehicleType>;
};

export enum ExternalTourEventQualifier {
  ARRIVED = 'ARRIVED',
  COMPLETED = 'COMPLETED',
  DEPARTED = 'DEPARTED',
  HEADING_TOWARDS = 'HEADING_TOWARDS',
  LOADED = 'LOADED',
  OTHER = 'OTHER',
  TRACKING_FINISHED = 'TRACKING_FINISHED',
  TRACKING_STARTED = 'TRACKING_STARTED',
  UNLOADED = 'UNLOADED',
}

export type ExternalTourStopEvent = {
  __typename: 'ExternalTourStopEvent';
  createdAt: Scalars['DateTime'];
  eventId: Scalars['String'];
  eventSource: ExternalEventSource;
  externalEventId: Maybe<Scalars['String']>;
  externalEventQualifier: Scalars['String'];
  externalEventSource: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  qualifier: ExternalTourStopEventQualifier;
  sentByCompanyId: Maybe<Scalars['String']>;
  stopId: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export enum ExternalTourStopEventQualifier {
  ARRIVED = 'ARRIVED',
  DEPARTED = 'DEPARTED',
  HEADING_TOWARDS = 'HEADING_TOWARDS',
  LOADING_COMPLETED = 'LOADING_COMPLETED',
  LOADING_STARTED = 'LOADING_STARTED',
  OTHER = 'OTHER',
}

export type FmsVehicleTracker = {
  __typename: 'FMSVehicleTracker';
  createdAt: Maybe<Scalars['DateTime']>;
  remoteId: Scalars['String'];
  telematicsIntegration: Maybe<CompanyTelematicsIntegration>;
  trackerData: Maybe<Scalars['GraphQLJSON']>;
  trackerType: VehicleTrackerType;
  updatedAt: Maybe<Scalars['DateTime']>;
  vehicleId: Scalars['String'];
  vehicleTrackerId: Scalars['String'];
};

export type FeatureFlagAuditLog = {
  __typename: 'FeatureFlagAuditLog';
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  featureFlagAuditLogId: Scalars['String'];
  featureFlagId: Maybe<Scalars['String']>;
  metadata: Array<Maybe<ActionMetadata>>;
  user: Maybe<UserProfile>;
};

export type FeatureFlagAuditLogConnection = {
  __typename: 'FeatureFlagAuditLogConnection';
  edges: Maybe<Array<Maybe<FeatureFlagAuditLogEdge>>>;
  pageInfo: PageInfo;
};

export type FeatureFlagAuditLogEdge = {
  __typename: 'FeatureFlagAuditLogEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<FeatureFlagAuditLog>;
};

export type FeatureFlagMetadata = {
  __typename: 'FeatureFlagMetadata';
  description: Scalars['String'];
  display_name: Scalars['String'];
  domains: Array<Maybe<Scalars['String']>>;
  feature_flag_id: Scalars['String'];
  usage: Maybe<Scalars['String']>;
};

export type FeatureFlags = {
  __typename: 'FeatureFlags';
  feature_flags: Array<Maybe<Scalars['String']>>;
};

export type FilterBasedTransportNotification = {
  __typename: 'FilterBasedTransportNotification';
  createdAt: Scalars['DateTime'];
  createdByUserId: Scalars['String'];
  name: Scalars['String'];
  notificationId: Scalars['String'];
  notificationTrigger: Maybe<NotificationTrigger>;
  recipients: Array<EmailRecipient>;
  recipientsV2: Array<Recipient>;
  transportFilter: TourFilter;
};

export enum FilterRoadStopType {
  loading = 'loading',
  other = 'other',
  unloading = 'unloading',
  waypoint = 'waypoint',
}

export enum FilterType {
  AIR = 'AIR',
  OCEAN = 'OCEAN',
  ROAD = 'ROAD',
}

export type FlagCreatedMetadata = {
  __typename: 'FlagCreatedMetadata';
  action: Scalars['String'];
  value: Scalars['Boolean'];
};

export type FlagUpdatedMetadata = {
  __typename: 'FlagUpdatedMetadata';
  action: Scalars['String'];
  newValue: Scalars['Boolean'];
  oldValue: Maybe<Scalars['Boolean']>;
};

export type ForwardTourInput = {
  forwardByCompanyId: Scalars['String'];
  forwardToCompanyId: Scalars['String'];
  tourId: Scalars['String'];
};

export type FromPlatformData = {
  companyIdOnPlatform: Scalars['String'];
  platformId: Scalars['String'];
  userIdOnPlatform: Scalars['String'];
};

export type FuelBasedEmissions = {
  __typename: 'FuelBasedEmissions';
  accumulatedFuelConsumptionLiters: Maybe<Scalars['Float']>;
  co2TankToWheelTonnes: Maybe<Scalars['Float']>;
  co2WellToTankTonnes: Maybe<Scalars['Float']>;
  co2WellToWheelTonnes: Maybe<Scalars['Float']>;
  emissionsIntensityGramPerTonneKms: Maybe<Scalars['Float']>;
  fuelType: Maybe<Scalars['String']>;
  transportActivityTonneKms: Maybe<Scalars['Float']>;
};

export type GeoPosition = {
  __typename: 'GeoPosition';
  geocodingDirection: Maybe<Scalars['String']>;
  lat: Maybe<Scalars['Float']>;
  lng: Maybe<Scalars['Float']>;
  matchLevel: Maybe<GeoPositionMatchLevel>;
  provider: Maybe<Scalars['String']>;
};

export enum GeoPositionMatchLevel {
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
  EXTPOSTCODE = 'EXTPOSTCODE',
  HOUSE_NUMBER = 'HOUSE_NUMBER',
  STREET = 'STREET',
}

export type Geofence = GeofenceCircle | GeofencePolygon;

export type GeofenceCircle = {
  __typename: 'GeofenceCircle';
  origin: Position;
  radiusInMeters: Scalars['Float'];
  type: GeofenceType;
};

export type GeofencePolygon = {
  __typename: 'GeofencePolygon';
  points: Array<Position>;
  type: GeofenceType;
};

export enum GeofenceType {
  CIRCLE = 'CIRCLE',
  POLYGON = 'POLYGON',
}

export type GeofenceZone = {
  __typename: 'GeofenceZone';
  geofence: Geofence;
  zone: GeofenceZoneType;
};

export enum GeofenceZoneType {
  ARRIVAL = 'ARRIVAL',
  DEPARTURE = 'DEPARTURE',
}

export enum GrantDirection {
  GRANTED_BY_PARTNERS = 'GRANTED_BY_PARTNERS',
  GRANTED_TO_PARTNERS = 'GRANTED_TO_PARTNERS',
}

export type HandlingUnit = {
  __typename: 'HandlingUnit';
  handlingUnitDescription: Scalars['String'];
  handlingUnitId: Scalars['String'];
  handlingUnitInstruction: Scalars['String'];
  handlingUnitName: Scalars['String'];
  handlingUnitType: HandlingUnitType;
};

export enum HandlingUnitType {
  BALE = 'BALE',
  BOX = 'BOX',
  CASE = 'CASE',
  CONTAINER = 'CONTAINER',
  CRATE = 'CRATE',
  DRUM = 'DRUM',
  OTHER = 'OTHER',
  PALLET = 'PALLET',
  REEL = 'REEL',
}

export type HasAllocationsFilterInput = {
  fromTime: Scalars['DateTime'];
  toTime: Scalars['DateTime'];
};

export enum HasRoleRoles {
  admin = 'admin',
  readonly = 'readonly',
  service = 'service',
}

export type HazmatType = {
  __typename: 'HazmatType';
  category: HazmatTypeCategory;
  class: Scalars['Float'];
  name: Scalars['String'];
};

export enum HazmatTypeCategory {
  CORROSIVE = 'CORROSIVE',
  EXPLOSIVES = 'EXPLOSIVES',
  FLAMMABLE_LIQUIDS = 'FLAMMABLE_LIQUIDS',
  FLAMMABLE_SOLIDS = 'FLAMMABLE_SOLIDS',
  GASES = 'GASES',
  MISCELLANEOUS = 'MISCELLANEOUS',
  OXIDIZING_ORGANIC = 'OXIDIZING_ORGANIC',
  RADIOACTIVE = 'RADIOACTIVE',
  TOXIC_INFECTIOUS = 'TOXIC_INFECTIOUS',
}

export enum HistorySource {
  CARRIER_API = 'CARRIER_API',
  FMS = 'FMS',
  MOBILE = 'MOBILE',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  TTC = 'TTC',
}

export enum HistoryType {
  TOUR = 'TOUR',
  VEHICLE = 'VEHICLE',
}

export type IdentifierValueInput = {
  identifierId?: InputMaybe<Scalars['String']>;
  identifierValue: Scalars['String'];
};

export enum ImpactDaysComparison {
  GTE = 'GTE',
  LTE = 'LTE',
}

export type ImpactDaysFilter = {
  comparison: ImpactDaysComparison;
  startInSeconds: Scalars['Int'];
};

export type ImpactDaysOutput = {
  __typename: 'ImpactDaysOutput';
  comparison: ImpactDaysComparison;
  startInSeconds: Scalars['Int'];
};

export type IntegrationSyncResult = {
  __typename: 'IntegrationSyncResult';
  distinctVehicleNullIslandCount: Maybe<Scalars['Int']>;
  distinctVehicleStatusFutureCount: Maybe<Scalars['Int']>;
  distinctVehicleTemperatureFutureCount: Maybe<Scalars['Int']>;
  endTimeAt: Scalars['DateTime'];
  errorDescription: Maybe<Scalars['String']>;
  errorType: Maybe<IntegrationSyncResultErrorType>;
  latestStatusTimestamp: Maybe<Scalars['DateTime']>;
  latestTemperatureTimestamp: Maybe<Scalars['DateTime']>;
  resultType: Maybe<IntegrationSyncResultResultType>;
  startTimeAt: Scalars['DateTime'];
  statusCount: Maybe<Scalars['Int']>;
  temperatureCount: Maybe<Scalars['Int']>;
  vehicleAmbiguityCount: Maybe<Scalars['Int']>;
  vehicleCount: Maybe<Scalars['Int']>;
};

export enum IntegrationSyncResultErrorType {
  GLOBAL_TIMEOUT = 'GLOBAL_TIMEOUT',
  RATE_LIMIT = 'RATE_LIMIT',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN = 'UNKNOWN',
}

export enum IntegrationSyncResultResultType {
  HISTORICAL_RESULT = 'HISTORICAL_RESULT',
  LATEST_RESULT = 'LATEST_RESULT',
}

export type InvitationHistoryItem = {
  __typename: 'InvitationHistoryItem';
  inviteSentByLabel: Maybe<Scalars['String']>;
  performedByEmail: Maybe<Scalars['String']>;
  recipients: Maybe<Array<InvitationHistoryItemRecipient>>;
  time: Scalars['String'];
  type: Maybe<InvitationHistoryLogItemType>;
};

export type InvitationHistoryItemRecipient = {
  __typename: 'InvitationHistoryItemRecipient';
  email: Scalars['String'];
  locale: Scalars['String'];
};

export enum InvitationHistoryLogItemType {
  ACCEPTED = 'ACCEPTED',
  EMAIL_SENT = 'EMAIL_SENT',
  WITHDRAWN = 'WITHDRAWN',
}

export type ItemCountByStatus = {
  __typename: 'ItemCountByStatus';
  count: Scalars['Int'];
  status: BulkInvitationSetItemStatus;
};

export type LatestVehicleTemperature = {
  __typename: 'LatestVehicleTemperature';
  reeferId: Scalars['String'];
  sensorReadings: Array<VehicleTemperatureSensorReading>;
  timestamp: Scalars['DateTime'];
  visibilitySource: VehicleTemperatureVisibilitySource;
};

export type Leg = {
  __typename: 'Leg';
  carriageParameters: CarriageParameters;
  distanceKM: Maybe<Scalars['Float']>;
  emissions: Array<LegEmission>;
  endLocation: GeoPosition;
  externalCosts: Maybe<ExternalCosts>;
  routePolyline: Maybe<Scalars['String']>;
  startLocation: GeoPosition;
  transportMode: Scalars['String'];
  type: Scalars['String'];
};

export type LegEmission = {
  __typename: 'LegEmission';
  emissionMode: Scalars['String'];
  tankToWheel: Scalars['Float'];
  total: Scalars['Float'];
  type: Scalars['String'];
  unit: Scalars['String'];
  wellToTank: Scalars['Float'];
};

export type LightdashEmbeddings = {
  __typename: 'LightdashEmbeddings';
  allocationSourceAndSuccessUrl: Scalars['String'];
};

export enum LinearUnit {
  FEET = 'FEET',
  INCHES = 'INCHES',
  KILOMETERS = 'KILOMETERS',
  METERS = 'METERS',
  MILES = 'MILES',
}

export enum LoadType {
  BULK = 'BULK',
  COURIER_EXPRESS_PARCEL = 'COURIER_EXPRESS_PARCEL',
  CUSTOMER_PICKUP = 'CUSTOMER_PICKUP',
  FULL_CONTAINER_LOAD = 'FULL_CONTAINER_LOAD',
  FULL_TRUCKLOAD = 'FULL_TRUCKLOAD',
  LESS_THAN_CONTAINER_LOAD = 'LESS_THAN_CONTAINER_LOAD',
  LESS_THAN_TRUCKLOAD = 'LESS_THAN_TRUCKLOAD',
  LESS_THAN_TRUCKLOAD_GROUPAGE = 'LESS_THAN_TRUCKLOAD_GROUPAGE',
}

export type LocationIdentifier = {
  __typename: 'LocationIdentifier';
  type: LocationIdentifierType;
  value: Scalars['String'];
};

export enum LocationIdentifierType {
  UN_LOCODE = 'UN_LOCODE',
}

export type LocationMilestone = {
  delayTime?: InputMaybe<MilestoneDelay>;
  milestoneQualifier: Scalars['String'];
  timelineEvents?: InputMaybe<Array<LocationMilestoneTimelineEvent>>;
};

export type LocationMilestoneOutput = {
  __typename: 'LocationMilestoneOutput';
  delayTime: Maybe<MilestoneDelayOutput>;
  milestoneQualifier: Scalars['String'];
  timelineEvents: Maybe<Array<LocationMilestoneTimelineEventOutput>>;
};

export type LocationMilestoneTimelineEvent = {
  criteria: Scalars['String'];
  timerange?: InputMaybe<TimeRangeSugarDate>;
};

export type LocationMilestoneTimelineEventOutput = {
  __typename: 'LocationMilestoneTimelineEventOutput';
  criteria: Scalars['String'];
  timerange: Maybe<TimeRangeSugarDateOutput>;
};

export enum LocationType {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  DESTINATION = 'DESTINATION',
  EMPTY_CONTAINER_GATE_IN = 'EMPTY_CONTAINER_GATE_IN',
  EMPTY_CONTAINER_GATE_OUT = 'EMPTY_CONTAINER_GATE_OUT',
  EMPTY_POD = 'EMPTY_POD',
  EMPTY_POL = 'EMPTY_POL',
  IN_TRANSIT_DESTINATION = 'IN_TRANSIT_DESTINATION',
  IN_TRANSIT_ORIGIN = 'IN_TRANSIT_ORIGIN',
  LOADING = 'LOADING',
  ORIGIN = 'ORIGIN',
  OTHER = 'OTHER',
  PORT_OF_DISCHARGE = 'PORT_OF_DISCHARGE',
  PORT_OF_LOADING = 'PORT_OF_LOADING',
  TRANSSHIPMENT = 'TRANSSHIPMENT',
  TRANSSHIPMENT_PORT = 'TRANSSHIPMENT_PORT',
  UNLOADING = 'UNLOADING',
}

export type LocationsAndMilestones = {
  __typename: 'LocationsAndMilestones';
  locations: Array<MilestoneLocation>;
  vehicles: Array<VehicleData>;
};

export type LoginUser = {
  __typename: 'LoginUser';
  companies: Array<Company>;
  email: Maybe<Scalars['String']>;
  locale: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export enum MainMode {
  MODE_AIR = 'MODE_AIR',
  MODE_INLAND_WATER = 'MODE_INLAND_WATER',
  MODE_INTERMODAL = 'MODE_INTERMODAL',
  MODE_RAIL = 'MODE_RAIL',
  MODE_ROAD = 'MODE_ROAD',
  MODE_SEA = 'MODE_SEA',
}

export type Milestone = {
  __typename: 'Milestone';
  actualTimeOfArrival: Maybe<DateTimeWithTimezone>;
  actualTimeOfArrivalSource: Maybe<Scalars['String']>;
  estimatedTimeOfArrival: Maybe<DateTimeWithTimezone>;
  estimatedTimeOfArrivalSource: Maybe<Scalars['String']>;
  milestone: Scalars['String'];
  qualifier: Maybe<MilestoneQualifier>;
  scheduledTimeOfArrival: Maybe<DateTimeWithTimezone>;
  scheduledTimeOfArrivalSource: Maybe<Scalars['String']>;
  vehicleId: Maybe<Scalars['String']>;
};

export type MilestoneDelay = {
  anyTimerangeCriteria?: InputMaybe<Scalars['Boolean']>;
  criteria: Scalars['String'];
  timerange?: InputMaybe<DelayTimeRange>;
};

export type MilestoneDelayOutput = {
  __typename: 'MilestoneDelayOutput';
  anyTimerangeCriteria: Maybe<Scalars['Boolean']>;
  criteria: Scalars['String'];
  timerange: Maybe<DelayTimeRangeOutput>;
};

export type MilestoneLocation = {
  __typename: 'MilestoneLocation';
  country: Maybe<Scalars['String']>;
  iataCode: Maybe<Scalars['String']>;
  locationCode: Maybe<Scalars['String']>;
  milestones: Array<Milestone>;
  name: Scalars['String'];
  position: Maybe<Position>;
  type: Maybe<LocationType>;
};

export enum MilestoneQualifier {
  ARRIVAL = 'ARRIVAL',
  ARRIVED = 'ARRIVED',
  BOOKING_CONFIRMED = 'BOOKING_CONFIRMED',
  COLLECTED = 'COLLECTED',
  CONTAINER_DISCHARGED = 'CONTAINER_DISCHARGED',
  CONTAINER_LOADED = 'CONTAINER_LOADED',
  DELIVERED = 'DELIVERED',
  DEPARTED = 'DEPARTED',
  DEPARTURE = 'DEPARTURE',
  DOCUMENTS_DELIVERED = 'DOCUMENTS_DELIVERED',
  EMPTY_CONTAINER_GATE_IN = 'EMPTY_CONTAINER_GATE_IN',
  EMPTY_CONTAINER_GATE_OUT = 'EMPTY_CONTAINER_GATE_OUT',
  EMPTY_CONTAINER_PICKUP = 'EMPTY_CONTAINER_PICKUP',
  EMPTY_RETURN = 'EMPTY_RETURN',
  FLIGHT_ARRIVED = 'FLIGHT_ARRIVED',
  FLIGHT_DEPARTED = 'FLIGHT_DEPARTED',
  FULL_CONTAINER_GATE_IN = 'FULL_CONTAINER_GATE_IN',
  FULL_CONTAINER_GATE_OUT = 'FULL_CONTAINER_GATE_OUT',
  GATE_IN = 'GATE_IN',
  GATE_IN_EMPTY = 'GATE_IN_EMPTY',
  GATE_OUT = 'GATE_OUT',
  GATE_OUT_EMPTY = 'GATE_OUT_EMPTY',
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  MANIFESTED = 'MANIFESTED',
  OTHER = 'OTHER',
  PICKUP = 'PICKUP',
  RECEIVED_FROM_FLIGHT = 'RECEIVED_FROM_FLIGHT',
  RECEIVED_FROM_SHIPPER = 'RECEIVED_FROM_SHIPPER',
  UNLOADED = 'UNLOADED',
  UNLOADING = 'UNLOADING',
  VESSEL_ARRIVED = 'VESSEL_ARRIVED',
  VESSEL_DEPARTED = 'VESSEL_DEPARTED',
}

export type MobileVehicleTracker = {
  __typename: 'MobileVehicleTracker';
  alias: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  phoneNumber: Maybe<Scalars['String']>;
  remoteId: Maybe<Scalars['String']>;
  trackerData: Maybe<Scalars['GraphQLJSON']>;
  trackerType: VehicleTrackerType;
  updatedAt: Maybe<Scalars['DateTime']>;
  vehicleId: Scalars['String'];
  vehicleTrackerId: Scalars['String'];
};

export type Mutation = {
  __typename: 'Mutation';
  abortTour: Maybe<Tour>;
  addCompanyTag: Scalars['Boolean'];
  addCredentialsToUser: Maybe<Credentials>;
  addOrUpdateDataSharingConsent: Maybe<Scalars['Boolean']>;
  addTourTag: Scalars['Boolean'];
  addUserToCompany: Maybe<UserInCompanyProfile>;
  addVehiclesToCompanyVehicleGroup: Maybe<Scalars['Boolean']>;
  allocateDummyMobileToTour: Maybe<Tour>;
  allocateVehiclesToTour: Maybe<Tour>;
  assignUserToTour: Maybe<TourAssignment>;
  changeUserMetadataInCompany: Maybe<UserInCompanyProfile>;
  changeUserRoleInCompany: Maybe<UserInCompanyProfile>;
  completeCompanySignup: Maybe<CompanySignupCompleted>;
  copyDashboard: Maybe<Dashboard>;
  createAllVehicleGrants: Maybe<Scalars['Boolean']>;
  createBulkInvitationSet: CreateBulkInvitationSetResult;
  createCompany: Maybe<Company>;
  createCompanyIdentifier: Maybe<CreateCompanyIdentifierResult>;
  createCompanyInvitations: Maybe<CreateCompanyInvitationsResult>;
  createCompanyPlace: Maybe<CompanyPlace>;
  createCompanyPlatform: Maybe<CompanyPlatform>;
  createCompanyRelationship: Maybe<CompanyRelationship>;
  createCompanyTourFilter: Maybe<CompanyTourFilter>;
  createCompanyTourFilterSharingToken: Maybe<Scalars['String']>;
  createCompanyTransportNetworkFilter: Maybe<CompanyTransportNetworkFilter>;
  createCompanyVehicleGroup: Maybe<CompanyVehicleGroup>;
  createDashboard: Maybe<Dashboard>;
  createExampleDashboard: Maybe<Dashboard>;
  createNote: Maybe<Note>;
  createOrUpdateFilterBasedTransportNotification: Scalars['String'];
  createOrUpdateRelationIdentifiers: Maybe<CreateOrUpdateRelationIdentifiersResult>;
  createOrUpdateTransportNotification: Scalars['String'];
  createPlatformSupportTicket: Maybe<Scalars['Boolean']>;
  createTelematicsIntegration: Maybe<TelematicsIntegration>;
  createTour: Maybe<Tour>;
  createTourStopSharingToken: Maybe<TourStop>;
  createUser: Maybe<User>;
  createUserFilter: Maybe<UserFilter>;
  createVehicleMobileAppTracker: MobileVehicleTracker;
  createVehicles: Scalars['Boolean'];
  createVisibilitySolutionsTicket: Maybe<Scalars['Boolean']>;
  createWidget: Maybe<Widget>;
  deleteBulkInvitationSet: DeleteBulkInvitationSetResult;
  deleteCompanyIdentifier: Maybe<DeleteCompanyIdentifierResult>;
  deleteCompanyPlace: Maybe<Scalars['Boolean']>;
  deleteCompanyPlatform: Scalars['Boolean'];
  deleteCompanyRelationship: Maybe<Scalars['Boolean']>;
  deleteCompanyTourFilter: Maybe<Scalars['Boolean']>;
  deleteCompanyTourFilterSharingToken: Maybe<Scalars['Boolean']>;
  deleteCompanyTransportNetworkFilter: Maybe<Scalars['Boolean']>;
  deleteCompanyVehicleGroup: Maybe<Scalars['Boolean']>;
  deleteDashboard: Maybe<Scalars['Boolean']>;
  deleteFilterBasedTransportNotification: Scalars['Boolean'];
  deleteTelematicsIntegration: Scalars['Boolean'];
  deleteTelematicsIntegrationSyncState: Scalars['Boolean'];
  deleteTour: Maybe<Scalars['Boolean']>;
  deleteTourStopSharingToken: Maybe<TourStop>;
  deleteTransportNotification: Scalars['Boolean'];
  deleteUser: Maybe<Scalars['Boolean']>;
  deleteUserAssignment: Maybe<Scalars['Boolean']>;
  deleteUserLoginToken: Scalars['Boolean'];
  deleteVehicle: Scalars['Boolean'];
  deleteVehicleTracker: Scalars['Boolean'];
  deleteVehicles: Scalars['Boolean'];
  deleteWidget: Maybe<Scalars['Boolean']>;
  error: Maybe<Scalars['Boolean']>;
  exposeSixfoldGeneratedPlace: Maybe<CompanyPlace>;
  forwardTour: Maybe<Tour>;
  ping: Maybe<Scalars['String']>;
  recalculateTour: Maybe<Tour>;
  removeCompanyTag: Scalars['Boolean'];
  removeCredentialsFromUser: Maybe<Scalars['Boolean']>;
  removeTourTag: Scalars['Boolean'];
  removeUserFilter: Maybe<Scalars['Boolean']>;
  removeUserFromCompany: Maybe<Scalars['Boolean']>;
  removeVehiclesFromCompanyVehicleGroup: Maybe<Scalars['Boolean']>;
  resendCompanyInvitation: Maybe<CreateCompanyInvitationsResult>;
  revokeAllVehicleGrants: Maybe<Scalars['Boolean']>;
  saveCustomerFeedback: Maybe<Scalars['Boolean']>;
  sendBulkInvitationSet: Maybe<SendBulkInvitationSetResult>;
  sendCompanyInvitation: Maybe<SendCompanyInvitationsResult>;
  setCompanyVATNumbers: Maybe<CompanyVatNumberChangeResult>;
  setVehicleGrantVisibility: Maybe<Scalars['Boolean']>;
  signupPageOpened: Maybe<Scalars['Boolean']>;
  signupPlatformCompany: Maybe<CompanySignupCompleted>;
  submitUnsupportedTelematicsProviderDetails: Scalars['Boolean'];
  trackTokenView: Scalars['Boolean'];
  unforwardTour: Maybe<Tour>;
  updateBulkInvitationSet: UpdateBulkInvitationSetResult;
  updateCompany: Maybe<Company>;
  updateCompanyConfiguration: Maybe<Company>;
  /**
   * Optimised version of updateCompany for flipping a single feature flag
   * useful for internal processes that want to control feature flags
   * If value is null, removes the feature flag (which is nearly always equivalent to setting it to false)
   * Returns true if the flag has been updated or error otherwise
   */
  updateCompanyFeatureFlag: Maybe<Scalars['Boolean']>;
  updateCompanyPlace: Maybe<CompanyPlace>;
  updateCompanyRelationshipOnboardingStatus: Maybe<Scalars['Boolean']>;
  updateCompanyRelationshipSignupStatus: Maybe<Scalars['Boolean']>;
  updateCompanySettings: Maybe<Company>;
  updateCompanyVehicleGroup: Maybe<CompanyVehicleGroup>;
  updateDashboard: Maybe<Dashboard>;
  updateTelematicsIntegration: Maybe<TelematicsIntegration>;
  updateTelematicsIntegrationConnectionParameters: Maybe<CompanyTelematicsIntegration>;
  updateTelematicsProvider: Maybe<TelematicsProvider>;
  updateTour: Maybe<Tour>;
  updateUnifiedCompanyOnboardingStatus: Maybe<Scalars['Boolean']>;
  updateUnifiedCompanySignupStatus: Maybe<Scalars['Boolean']>;
  updateUser: Maybe<User>;
  updateUserInCompanyConfiguration: Maybe<UserInCompany>;
  updateUserNotificationConfig: Maybe<UpdateUserNotificationConfigResponse>;
  updateViewerProfile: Maybe<User>;
  updateWidget: Maybe<Widget>;
  validateTelematicsIntegration: Maybe<TelematicsValidation>;
  validateTelematicsIntegrationConnectionParametersUpdate: Maybe<TelematicsValidation>;
  withdrawCompanyInvitation: WithdrawCompanyInvitationResult;
};

export type MutationAbortTourArgs = {
  tour_id: Scalars['Int'];
};

export type MutationAddCompanyTagArgs = {
  input: CompanyTagInput;
};

export type MutationAddCredentialsToUserArgs = {
  input: AddCredentialsToUserInput;
};

export type MutationAddOrUpdateDataSharingConsentArgs = {
  input: AddOrUpdateConsentInput;
};

export type MutationAddTourTagArgs = {
  tag_name: Scalars['String'];
  tour_id: Scalars['String'];
};

export type MutationAddUserToCompanyArgs = {
  input: AddUserToCompanyInput;
};

export type MutationAddVehiclesToCompanyVehicleGroupArgs = {
  input: AddVehiclesToCompanyVehicleGroupInput;
};

export type MutationAllocateDummyMobileToTourArgs = {
  input: DummyMobileTourAllocationInput;
};

export type MutationAllocateVehiclesToTourArgs = {
  input: TourAllocationInput;
};

export type MutationAssignUserToTourArgs = {
  input: AssignUserToTourInput;
};

export type MutationChangeUserMetadataInCompanyArgs = {
  input: ChangeUserMetadataInCompanyInput;
};

export type MutationChangeUserRoleInCompanyArgs = {
  input: ChangeUserRoleInCompanyInput;
};

export type MutationCopyDashboardArgs = {
  input?: InputMaybe<CopyDashboardInput>;
};

export type MutationCreateAllVehicleGrantsArgs = {
  input: CreateVehicleGrantsInput;
};

export type MutationCreateBulkInvitationSetArgs = {
  companyId: Scalars['String'];
  input: CreateBulkInvitationSetInput;
};

export type MutationCreateCompanyArgs = {
  input?: InputMaybe<CreateCompanyInput>;
};

export type MutationCreateCompanyIdentifierArgs = {
  input: CreateCompanyIdentifierInput;
};

export type MutationCreateCompanyInvitationsArgs = {
  companyId: Scalars['String'];
  input?: InputMaybe<CreateCompanyInvitationInput>;
};

export type MutationCreateCompanyPlaceArgs = {
  input: CreateCompanyPlaceInput;
};

export type MutationCreateCompanyPlatformArgs = {
  company_id: Scalars['String'];
  input?: InputMaybe<CompanyPlatformInput>;
};

export type MutationCreateCompanyRelationshipArgs = {
  input: CreateCompanyRelationshipInput;
};

export type MutationCreateCompanyTourFilterArgs = {
  input: CreateCompanyTourFilterInput;
};

export type MutationCreateCompanyTourFilterSharingTokenArgs = {
  companyTourFilterId: Scalars['String'];
};

export type MutationCreateCompanyTransportNetworkFilterArgs = {
  input: CreateCompanyTransportNetworkFilterInput;
};

export type MutationCreateCompanyVehicleGroupArgs = {
  input: CreateCompanyVehicleGroupInput;
};

export type MutationCreateDashboardArgs = {
  input?: InputMaybe<CreateDashboardInput>;
};

export type MutationCreateExampleDashboardArgs = {
  input?: InputMaybe<CreateExampleDashboardInput>;
};

export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};

export type MutationCreateOrUpdateFilterBasedTransportNotificationArgs = {
  input: CreateOrUpdateFilterBasedTransportNotificationInput;
};

export type MutationCreateOrUpdateRelationIdentifiersArgs = {
  input: CreateOrUpdateRelationIdentifiersInput;
};

export type MutationCreateOrUpdateTransportNotificationArgs = {
  input: CreateOrUpdateTransportNotificationInput;
};

export type MutationCreatePlatformSupportTicketArgs = {
  input: CreatePlatformSupportTicketInput;
};

export type MutationCreateTelematicsIntegrationArgs = {
  input: CreateTelematicsIntegrationInput;
};

export type MutationCreateTourArgs = {
  input: TourCreationInput;
};

export type MutationCreateTourStopSharingTokenArgs = {
  company_id: Scalars['String'];
  stop_id: Scalars['String'];
  tour_id: Scalars['String'];
};

export type MutationCreateUserArgs = {
  auth_service: Scalars['String'];
  input?: InputMaybe<UserAttributesInput>;
};

export type MutationCreateUserFilterArgs = {
  input: CreateUserFilterInput;
};

export type MutationCreateVehicleMobileAppTrackerArgs = {
  input: CreateVehicleMobileAppTrackerInput;
};

export type MutationCreateVehiclesArgs = {
  input: CreateVehiclesInput;
};

export type MutationCreateVisibilitySolutionsTicketArgs = {
  input: CreateVisibilitySolutionsTicketInput;
};

export type MutationCreateWidgetArgs = {
  input: CreateWidgetInput;
};

export type MutationDeleteBulkInvitationSetArgs = {
  companyId: Scalars['String'];
  input: DeleteBulkInvitationSetInput;
};

export type MutationDeleteCompanyIdentifierArgs = {
  input: DeleteCompanyIdentifierInput;
};

export type MutationDeleteCompanyPlaceArgs = {
  placeId: Scalars['String'];
};

export type MutationDeleteCompanyPlatformArgs = {
  company_id: Scalars['String'];
  input?: InputMaybe<DeleteCompanyPlatformInput>;
};

export type MutationDeleteCompanyRelationshipArgs = {
  input: DeleteCompanyRelationshipInput;
};

export type MutationDeleteCompanyTourFilterArgs = {
  companyTourFilterId: Scalars['String'];
};

export type MutationDeleteCompanyTourFilterSharingTokenArgs = {
  companyTourFilterId: Scalars['String'];
};

export type MutationDeleteCompanyTransportNetworkFilterArgs = {
  companyTransportNetworkFilterId: Scalars['String'];
};

export type MutationDeleteCompanyVehicleGroupArgs = {
  input: DeleteCompanyVehicleGroupInput;
};

export type MutationDeleteDashboardArgs = {
  dashboardId: Scalars['String'];
};

export type MutationDeleteFilterBasedTransportNotificationArgs = {
  input: DeleteFilterBasedTransportNotificationInput;
};

export type MutationDeleteTelematicsIntegrationArgs = {
  telematics_integration_id: Scalars['String'];
};

export type MutationDeleteTelematicsIntegrationSyncStateArgs = {
  telematics_integration_id: Scalars['String'];
};

export type MutationDeleteTourArgs = {
  input: TourDeletionInput;
};

export type MutationDeleteTourStopSharingTokenArgs = {
  company_id: Scalars['String'];
  stop_id: Scalars['String'];
  tour_id: Scalars['String'];
};

export type MutationDeleteTransportNotificationArgs = {
  input: DeleteTransportNotificationInput;
};

export type MutationDeleteUserArgs = {
  userId: Scalars['String'];
};

export type MutationDeleteUserAssignmentArgs = {
  companyId: Scalars['String'];
  tourId: Scalars['String'];
};

export type MutationDeleteVehicleArgs = {
  input: DeleteVehicleInput;
};

export type MutationDeleteVehicleTrackerArgs = {
  input: DeleteVehicleTrackerInput;
};

export type MutationDeleteVehiclesArgs = {
  input: DeleteVehiclesInput;
};

export type MutationDeleteWidgetArgs = {
  input: DeleteWidgetInput;
};

export type MutationErrorArgs = {
  message: Scalars['String'];
};

export type MutationExposeSixfoldGeneratedPlaceArgs = {
  input: ExposeSixfoldGeneratedPlaceInput;
};

export type MutationForwardTourArgs = {
  input: ForwardTourInput;
};

export type MutationPingArgs = {
  input: Scalars['String'];
};

export type MutationRecalculateTourArgs = {
  tour_id: Scalars['Int'];
};

export type MutationRemoveCompanyTagArgs = {
  input: CompanyTagInput;
};

export type MutationRemoveCredentialsFromUserArgs = {
  input: RemoveCredentialsFromUserInput;
};

export type MutationRemoveTourTagArgs = {
  tag_name: Scalars['String'];
  tour_id: Scalars['String'];
};

export type MutationRemoveUserFilterArgs = {
  userFilterId: Scalars['String'];
};

export type MutationRemoveUserFromCompanyArgs = {
  input: RemoveUserFromCompanyInput;
};

export type MutationRemoveVehiclesFromCompanyVehicleGroupArgs = {
  input: RemoveVehiclesFromCompanyVehicleGroupInput;
};

export type MutationResendCompanyInvitationArgs = {
  companyId: Scalars['String'];
  input?: InputMaybe<ResendCompanyInvitationInput>;
};

export type MutationRevokeAllVehicleGrantsArgs = {
  input: RevokeVehicleGrantsInput;
};

export type MutationSaveCustomerFeedbackArgs = {
  input: SaveCustomerFeedbackInput;
};

export type MutationSendBulkInvitationSetArgs = {
  companyId: Scalars['String'];
  input?: InputMaybe<SendBulkInvitationSetInput>;
};

export type MutationSendCompanyInvitationArgs = {
  companyId: Scalars['String'];
  dryRun?: InputMaybe<Scalars['Boolean']>;
  input: SendCompanyInvitationInput;
};

export type MutationSetCompanyVatNumbersArgs = {
  company_id: Scalars['String'];
  input?: InputMaybe<Array<InputMaybe<CompanyVatNumberInput>>>;
  retainMissingCountryCodes: Scalars['Boolean'];
};

export type MutationSetVehicleGrantVisibilityArgs = {
  input: VehicleGrantVisibilityInput;
};

export type MutationSignupPageOpenedArgs = {
  input: SignupPageOpenedInput;
};

export type MutationSignupPlatformCompanyArgs = {
  input?: InputMaybe<SignupPlatformCompanyInput>;
};

export type MutationSubmitUnsupportedTelematicsProviderDetailsArgs = {
  input: SubmitUnsupportedTelematicsProviderDetailsInput;
};

export type MutationUnforwardTourArgs = {
  input: UnforwardTourInput;
};

export type MutationUpdateBulkInvitationSetArgs = {
  companyId: Scalars['String'];
  input: UpdateBulkInvitationSetInput;
};

export type MutationUpdateCompanyArgs = {
  company_id: Scalars['String'];
  input?: InputMaybe<UpdateCompanyInput>;
};

export type MutationUpdateCompanyConfigurationArgs = {
  input: UpdateCompanyConfigurationInput;
};

export type MutationUpdateCompanyFeatureFlagArgs = {
  companyId: Scalars['String'];
  featureFlag: Scalars['String'];
  value?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateCompanyPlaceArgs = {
  input: UpdateCompanyPlaceInput;
};

export type MutationUpdateCompanyRelationshipOnboardingStatusArgs = {
  input: UpdateCompanyRelationshipOnboardingStatusInput;
};

export type MutationUpdateCompanyRelationshipSignupStatusArgs = {
  input: UpdateCompanyRelationshipSignupStatusInput;
};

export type MutationUpdateCompanySettingsArgs = {
  input: UpdateCompanySettingsInput;
};

export type MutationUpdateCompanyVehicleGroupArgs = {
  input: UpdateCompanyVehicleGroupInput;
};

export type MutationUpdateDashboardArgs = {
  input?: InputMaybe<UpdateDashboardInput>;
};

export type MutationUpdateTelematicsIntegrationArgs = {
  input: UpdateTelematicsIntegrationInput;
};

export type MutationUpdateTelematicsIntegrationConnectionParametersArgs = {
  input: UpdateTelematicsIntegrationConnectionParametersInput;
};

export type MutationUpdateTelematicsProviderArgs = {
  input: UpdateTelematicsProviderInput;
};

export type MutationUpdateTourArgs = {
  input: TourUpdateInput;
};

export type MutationUpdateUnifiedCompanyOnboardingStatusArgs = {
  companyId: Scalars['String'];
  input: UnifiedCompanyOnboardingStatusUpdateInput;
};

export type MutationUpdateUnifiedCompanySignupStatusArgs = {
  companyId: Scalars['String'];
  input: UnifiedCompanySignupStatusUpdateInput;
};

export type MutationUpdateUserArgs = {
  input?: InputMaybe<UserAttributesInput>;
  user_id: Scalars['String'];
};

export type MutationUpdateUserInCompanyConfigurationArgs = {
  input: UpdateUserInCompanyConfigurationInput;
};

export type MutationUpdateUserNotificationConfigArgs = {
  input: UpdateUserNotificationConfigInput;
};

export type MutationUpdateViewerProfileArgs = {
  input: UpdateViewerProfileInput;
};

export type MutationUpdateWidgetArgs = {
  input: UpdateWidgetInput;
};

export type MutationValidateTelematicsIntegrationArgs = {
  input: ValidateTelematicsIntegrationInput;
};

export type MutationValidateTelematicsIntegrationConnectionParametersUpdateArgs = {
  input: ValidateTelematicsIntegrationConnectionParametersUpdateInput;
};

export type MutationWithdrawCompanyInvitationArgs = {
  companyId: Scalars['String'];
  input: WithdrawCompanyInvitationInput;
};

export type NetworkRelation = {
  __typename: 'NetworkRelation';
  companyIdentifiers: Array<CompanyIdentifier>;
  companyName: Scalars['String'];
  companyReferenceName: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  onboardingStatus: Maybe<UnifiedCompanyRelationshipOnboardingStatus>;
  relationCompanyName: Scalars['String'];
  relationId: Scalars['String'];
  relationIdentifiers: Array<CompanyIdentifier>;
  relationType: NetworkRelationType;
  signupStatus: Maybe<UnifiedCompanyRelationshipSignupStatus>;
  tourStatusConsent: NetworkRelationConsentState;
  type: NetworkRelationType;
};

export type NetworkRelationConnection = {
  __typename: 'NetworkRelationConnection';
  edges: Maybe<Array<Maybe<NetworkRelationEdge>>>;
  pageInfo: PageInfo;
};

export enum NetworkRelationConsentState {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  REQUESTED = 'REQUESTED',
}

export type NetworkRelationEdge = {
  __typename: 'NetworkRelationEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<NetworkRelation>;
};

export type NetworkRelationFilter = {
  companyName?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<Array<UnifiedCompanyRelationshipOnboardingStatus>>;
  signupStatus?: InputMaybe<Array<UnifiedCompanyRelationshipSignupStatus>>;
  tourStatusConsent?: InputMaybe<Array<InputMaybe<NetworkRelationConsentState>>>;
};

export type NetworkRelationStatistics = {
  __typename: 'NetworkRelationStatistics';
  companyCountsByOnboardingStatus: Array<CompanyCountByOnboardingStatus>;
  companyCountsByOnboardingStatusAndDate: Array<CompanyCountByOnboardingStatus>;
  companyCountsBySignupStatus: Array<CompanyCountBySignupStatus>;
  companyCountsBySignupStatusAndDate: Array<CompanyCountBySignupStatus>;
};

export type NetworkRelationStatisticsCompanyCountsByOnboardingStatusAndDateArgs = {
  date: Scalars['String'];
};

export type NetworkRelationStatisticsCompanyCountsBySignupStatusAndDateArgs = {
  date: Scalars['String'];
};

export enum NetworkRelationType {
  EXISTING = 'EXISTING',
  INVITATION = 'INVITATION',
}

export enum NetworkRelationsSortKey {
  COMPANY_NAME = 'COMPANY_NAME',
  COMPANY_NAME_BY_DISTANCE = 'COMPANY_NAME_BY_DISTANCE',
  COMPANY_REFERENCE_NAME = 'COMPANY_REFERENCE_NAME',
  CONSENT_STATUS = 'CONSENT_STATUS',
  ONBOARDING_STATUS = 'ONBOARDING_STATUS',
  RELATION_COMPANY_NAME = 'RELATION_COMPANY_NAME',
  SIGNUP_STATUS = 'SIGNUP_STATUS',
}

export type NetworkRelationsSortOrderInput = {
  direction: SortDirection;
  key: NetworkRelationsSortKey;
};

export type NoiseCost = {
  __typename: 'NoiseCost';
  noiseCostValue: Scalars['Float'];
  transportMode: Scalars['String'];
};

export type Note = {
  __typename: 'Note';
  associatedDeliveries: Array<TourDelivery>;
  createdAt: Scalars['DateTime'];
  createdBy: NoteCreatedBy;
  noteId: Scalars['String'];
  noteOrigin: NoteOrigin;
  noteText: Scalars['String'];
  noteVisibility: NoteVisibility;
  stopId: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum NoteCreatedBy {
  CARRIER = 'CARRIER',
  DRIVER = 'DRIVER',
  SHIPPER = 'SHIPPER',
}

export enum NoteOrigin {
  EXTERNAL = 'EXTERNAL',
  VH_UI = 'VH_UI',
}

export enum NoteVisibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type NotificationTrigger = {
  __typename: 'NotificationTrigger';
  etaComparison: Maybe<Array<EtaComparison>>;
  failureReasons: Maybe<Array<TourFailureReasonType>>;
  warnings: Maybe<Array<TourWarningType>>;
};

export type NotificationTriggerInput = {
  etaComparison?: InputMaybe<Array<EtaComparisonInput>>;
  failureReasons?: InputMaybe<Array<TourFailureReasonType>>;
  warnings?: InputMaybe<Array<TourWarningType>>;
};

export type NullableUpload = {
  upload?: InputMaybe<Scalars['Upload']>;
};

export type OceanCostDetails = {
  __typename: 'OceanCostDetails';
  calculation: Maybe<Scalars['String']>;
  costPerDay: Maybe<Scalars['Float']>;
  daysBracket: Maybe<Scalars['String']>;
};

export type OceanDemurrage = {
  __typename: 'OceanDemurrage';
  costs: Maybe<Array<OceanDemurrageCost>>;
  currency: Scalars['String'];
  minRemainingFreeDays: Maybe<Scalars['Int']>;
  overallState: Maybe<Scalars['String']>;
  totalCost: Maybe<Scalars['Float']>;
};

export type OceanDemurrageCost = {
  __typename: 'OceanDemurrageCost';
  actualCost: Maybe<Scalars['Float']>;
  comments: Maybe<Scalars['String']>;
  contractReference: Maybe<Scalars['String']>;
  contractType: Maybe<Scalars['String']>;
  costDetails: Array<OceanCostDetails>;
  currency: Scalars['String'];
  endDate: Maybe<Scalars['String']>;
  freeTimeDays: Maybe<Scalars['Int']>;
  freeTimeType: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  overdueDays: Maybe<Scalars['Int']>;
  remainingFreeDays: Maybe<Scalars['Int']>;
  runningCost: Maybe<Scalars['Float']>;
  startDate: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  timeElapsedDays: Maybe<Scalars['Int']>;
};

export type OceanDemurrageFilter = {
  cost?: InputMaybe<Scalars['Float']>;
  currency?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  remainingFreeDays?: InputMaybe<Scalars['Int']>;
  states?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<DemurrageType>>;
};

export type OceanDemurrageFilterOutput = {
  __typename: 'OceanDemurrageFilterOutput';
  cost: Maybe<Scalars['Float']>;
  currency: Maybe<Scalars['String']>;
  names: Maybe<Array<Scalars['String']>>;
  remainingFreeDays: Maybe<Scalars['Int']>;
  states: Maybe<Array<Scalars['String']>>;
  types: Maybe<Array<DemurrageType>>;
};

export type OnboardingAction = {
  __typename: 'OnboardingAction';
  type: OnboardingActionKey;
};

export enum OnboardingActionKey {
  ALLOCATE_TRANSPORTS = 'ALLOCATE_TRANSPORTS',
  CONNECT_VEHICLES = 'CONNECT_VEHICLES',
  INVALID_INTEGRATION = 'INVALID_INTEGRATION',
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS',
}

export enum OperatorType {
  DIVISION = 'DIVISION',
}

export type OptionsArgument = {
  subType: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<Scalars['String']>>;
};

export type OrderItem = {
  __typename: 'OrderItem';
  handlingUnits: Maybe<Array<HandlingUnit>>;
  hazmatType: Maybe<Array<HazmatType>>;
  isHazardous: Maybe<Scalars['Boolean']>;
  itemHeight: Maybe<Scalars['Float']>;
  itemLength: Maybe<Scalars['Float']>;
  itemWidth: Maybe<Scalars['Float']>;
  linearUnit: Maybe<LinearUnit>;
  orderItemCustomFields: Maybe<Array<OrderItemCustomField>>;
  orderItemGroups: Maybe<Array<OrderItemGroup>>;
  orderItemId: Scalars['String'];
  orderItemName: Maybe<Scalars['String']>;
  pieceCount: Maybe<Scalars['Int']>;
  pieceDescription: Maybe<Scalars['String']>;
  pieceUnit: Maybe<PieceUnit>;
  volume: Maybe<Scalars['Float']>;
  volumeUnit: Maybe<VolumeUnit>;
  weightAmount: Maybe<Scalars['Float']>;
  weightUnit: Maybe<WeightUnit>;
};

export type OrderItemCustomField = {
  __typename: 'OrderItemCustomField';
  id: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type OrderItemCustomFieldConnection = {
  __typename: 'OrderItemCustomFieldConnection';
  edges: Maybe<Array<Maybe<OrderItemCustomFieldEdge>>>;
  pageInfo: PageInfo;
};

export type OrderItemCustomFieldEdge = {
  __typename: 'OrderItemCustomFieldEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<OrderItemCustomField>;
};

export type OrderItemCustomFieldInput = {
  productBrandValues?: InputMaybe<Array<Scalars['String']>>;
  productLineValues?: InputMaybe<Array<Scalars['String']>>;
};

export type OrderItemCustomFieldOutput = {
  __typename: 'OrderItemCustomFieldOutput';
  productBrandValues: Maybe<Array<Scalars['String']>>;
  productLineValues: Maybe<Array<Scalars['String']>>;
};

export type OrderItemCustomFieldSearch = {
  textSearch?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<OrderItemCustomFieldSearchType>;
};

export enum OrderItemCustomFieldSearchType {
  NAME = 'NAME',
  VALUE = 'VALUE',
}

export type OrderItemGroup = {
  __typename: 'OrderItemGroup';
  orderItemGroupDescription: Scalars['String'];
  orderItemGroupId: Scalars['String'];
  orderItemGroupName: Scalars['String'];
};

export type OrderItemGroupOutput = {
  __typename: 'OrderItemGroupOutput';
  orderItemGroupDescriptions: Maybe<Array<Scalars['String']>>;
  orderItemGroupNames: Maybe<Array<Scalars['String']>>;
};

export type OrderItemGroupsInput = {
  orderItemGroupDescriptions?: InputMaybe<Array<Scalars['String']>>;
  orderItemGroupNames?: InputMaybe<Array<Scalars['String']>>;
};

export type OrderItemIdentifier = {
  __typename: 'OrderItemIdentifier';
  orderItemId: Scalars['String'];
  orderItemName: Scalars['String'];
};

export type OrderItemIdentifierConnection = {
  __typename: 'OrderItemIdentifierConnection';
  edges: Maybe<Array<Maybe<OrderItemIdentifierEdge>>>;
  pageInfo: PageInfo;
};

export type OrderItemIdentifierEdge = {
  __typename: 'OrderItemIdentifierEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<OrderItemIdentifier>;
};

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Maybe<Scalars['String']>;
};

export enum PieceUnit {
  BOX = 'BOX',
  EACH = 'EACH',
  GALLON = 'GALLON',
  LITER = 'LITER',
  OUNCES = 'OUNCES',
  PALLET = 'PALLET',
}

export type PlaceGeodataInput = {
  geofences: Array<PlaceGeofenceInput>;
  location: PointLocationInput;
};

export type PlaceGeofence = CircleGeofence | PolygonGeofence;

export type PlaceGeofenceInput = {
  geofence: PlaceGeofenceUnionInput;
  zone: PlaceGeofenceZoneType;
};

export type PlaceGeofenceUnionInput = {
  circle?: InputMaybe<CircleGeofenceInput>;
  polygon?: InputMaybe<PolygonGeofenceInput>;
};

export type PlaceGeofenceWithZone = {
  __typename: 'PlaceGeofenceWithZone';
  geofence: PlaceGeofence;
  zone: PlaceGeofenceZoneType;
};

export enum PlaceGeofenceZoneType {
  ARRIVAL = 'ARRIVAL',
  DEPARTURE = 'DEPARTURE',
}

export type PlaceTextSearch = {
  fields: Array<PlaceTextSearchField>;
  query: Scalars['String'];
};

export enum PlaceTextSearchField {
  ADDRESSES = 'ADDRESSES',
  NAME = 'NAME',
  REFERENCE_ID = 'REFERENCE_ID',
}

export type Platform = {
  __typename: 'Platform';
  platform_branches: Array<Scalars['String']>;
  platform_id: Scalars['String'];
  platform_name: Scalars['String'];
};

export type PlatformCallbackMessage = {
  __typename: 'PlatformCallbackMessage';
  createdAt: Scalars['DateTime'];
  payload: Scalars['GraphQLJSON'];
  platform: Maybe<Platform>;
  subjectId: Scalars['String'];
  subjectType: Scalars['String'];
};

export type PlatformCompanyInvite = {
  __typename: 'PlatformCompanyInvite';
  inviteStatus: Maybe<CompanyInviteStatus>;
  invitedByCompany: CompanyProfile;
  invitedByUser: UserProfile;
  signupReferenceId: Maybe<Scalars['String']>;
  signupUrls: SignupUrls;
};

export type PlatformConnection = {
  __typename: 'PlatformConnection';
  edges: Maybe<Array<Maybe<PlatformEdge>>>;
  pageInfo: PageInfo;
};

export type PlatformEdge = {
  __typename: 'PlatformEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Platform>;
};

export type PlatformProfile = {
  __typename: 'PlatformProfile';
  platform_id: Scalars['String'];
  platform_name: Scalars['String'];
};

export type PointLocation = {
  __typename: 'PointLocation';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type PointLocationInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type PolygonGeofence = {
  __typename: 'PolygonGeofence';
  points: Array<PointLocation>;
};

export type PolygonGeofenceInput = {
  points: Array<PointLocationInput>;
};

export type Polyline = {
  __typename: 'Polyline';
  points: Scalars['String'];
};

export type Position = {
  __typename: 'Position';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type ProvidingVisibilityCompaniesFilterInput = {
  companyName?: InputMaybe<Scalars['String']>;
  tourStatusConsent?: InputMaybe<Array<ConsentState>>;
};

export enum ProvidingVisibilityCompaniesSortKey {
  COMPANY_NAME = 'COMPANY_NAME',
  CONSENT_STATUS = 'CONSENT_STATUS',
}

export type ProvidingVisibilityCompaniesSortOrderInput = {
  direction: SortDirection;
  key: ProvidingVisibilityCompaniesSortKey;
};

export type ProvidingVisibilityCompany = {
  __typename: 'ProvidingVisibilityCompany';
  companyId: Scalars['String'];
  companyIdentifiers: Array<CompanyIdentifier>;
  companyName: Maybe<Scalars['String']>;
  dateAdded: Scalars['DateTime'];
  relationIdentifiers: Array<CompanyIdentifier>;
  tourStatusConsent: ConsentState;
  transporeonId: Maybe<Scalars['String']>;
  vatNumbers: Array<CompanyVatNumber>;
};

export type ProvidingVisibilityCompanyConnection = {
  __typename: 'ProvidingVisibilityCompanyConnection';
  edges: Maybe<Array<Maybe<ProvidingVisibilityCompanyEdge>>>;
  pageInfo: PageInfo;
};

export type ProvidingVisibilityCompanyEdge = {
  __typename: 'ProvidingVisibilityCompanyEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<ProvidingVisibilityCompany>;
};

export type Query = {
  __typename: 'Query';
  acceptedTransporeonUserAgreement: Maybe<Scalars['Boolean']>;
  availableCompanyTags: Array<CompanyTag>;
  availableTourTags: Array<TourTag>;
  backofficeScopes: Array<Scalars['String']>;
  bigQueryMessages: Array<BigQueryMessageEntry>;
  companies: Maybe<CompanyConnection>;
  company: Maybe<Company>;
  companyInvitations: Array<CompanyInvite>;
  companyPlatform: Maybe<CompanyPlatform>;
  companyRelationships: Maybe<CompanyRelationshipConnection>;
  featureFlagMetadata: Array<FeatureFlagMetadata>;
  featureFlags: Maybe<FeatureFlags>;
  intercomTokenUrl: Scalars['String'];
  platforms: Maybe<PlatformConnection>;
  sensitiveConnectionData: Maybe<Scalars['GraphQLJSON']>;
  sensitiveData: Maybe<SensitiveData>;
  status: Maybe<Scalars['String']>;
  telematicsIntegration: Maybe<TelematicsIntegration>;
  telematicsIntegrations: Maybe<CountedTelematicsIntegrationConnection>;
  telematicsProvider: Maybe<TelematicsProvider>;
  telematicsProviders: TelematicsProviderConnection;
  token: Maybe<TokenResources>;
  tour: Maybe<Tour>;
  tours: CountedTourConnection;
  user: Maybe<User>;
  userNotificationConfig: Maybe<UserNotificationConfig>;
  users: Maybe<UserConnection>;
  vehicle: Maybe<Vehicle>;
  vehicleTrackerByTelematicsIntegrationAndVehicleDetails: Maybe<VehicleTrackerSearchResult>;
  vehicles: Maybe<VehicleConnection>;
  viewer: Maybe<User>;
};

export type QueryAcceptedTransporeonUserAgreementArgs = {
  companyId: Scalars['String'];
};

export type QueryBigQueryMessagesArgs = {
  from: Scalars['DateTime'];
  keys: Array<Scalars['String']>;
  messageTypes: Array<Scalars['String']>;
  to: Scalars['DateTime'];
  topics: Array<Scalars['String']>;
};

export type QueryCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  onboardingStatus?: InputMaybe<UnifiedCompanyOnboardingStatus>;
  onboardingStuckReason?: InputMaybe<UnifiedCompanyOnboardingStuckReason>;
  tagName?: InputMaybe<Scalars['String']>;
  textSearch?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CompanyTypeInput>;
};

export type QueryCompanyArgs = {
  company_id: Scalars['String'];
};

export type QueryCompanyInvitationsArgs = {
  company_id?: InputMaybe<Scalars['String']>;
};

export type QueryCompanyPlatformArgs = {
  company_id: Scalars['String'];
  platform_id: Scalars['String'];
};

export type QueryCompanyRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  relationshipType?: InputMaybe<CompanyRelationshipType>;
  sourceCompanyId?: InputMaybe<Scalars['String']>;
  targetCompanyId?: InputMaybe<Scalars['String']>;
};

export type QueryFeatureFlagMetadataArgs = {
  applies_to: Scalars['String'];
};

export type QueryFeatureFlagsArgs = {
  applies_to: Scalars['String'];
};

export type QueryIntercomTokenUrlArgs = {
  baseUrl: Scalars['String'];
};

export type QueryPlatformsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QuerySensitiveConnectionDataArgs = {
  captcha_token: Scalars['String'];
  telematics_integration_id: Scalars['String'];
};

export type QuerySensitiveDataArgs = {
  captcha_token: Scalars['String'];
  telematics_integration_id: Scalars['String'];
};

export type QueryTelematicsIntegrationArgs = {
  telematics_integration_id: Scalars['String'];
};

export type QueryTelematicsIntegrationsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type QueryTelematicsProviderArgs = {
  telematics_provider_id: Scalars['String'];
};

export type QueryTelematicsProvidersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasIntegrationInstructionsURL?: InputMaybe<Scalars['Boolean']>;
  isDeprecated?: InputMaybe<Scalars['Boolean']>;
  isExternalConfigurationNeeded?: InputMaybe<Scalars['Boolean']>;
  isGlobalAllocationForbidden?: InputMaybe<Scalars['Boolean']>;
  isIntegrationImplemented?: InputMaybe<Scalars['Boolean']>;
  isInternalUseOnly?: InputMaybe<Scalars['Boolean']>;
  isStandardTelematicsPushProvider?: InputMaybe<Scalars['Boolean']>;
  isVehicleManagementEnabled?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  textSearch?: InputMaybe<Scalars['String']>;
};

export type QueryTourArgs = {
  tour_id: Scalars['Int'];
};

export type QueryToursArgs = {
  allocationStatus?: InputMaybe<TourAllocationStatus>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']>>;
  carrierIds?: InputMaybe<Array<Scalars['String']>>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch>>;
  delayStatus?: InputMaybe<TourDelayStatusFilter>;
  delayTime?: InputMaybe<TimeRangeInput>;
  documentTypes?: InputMaybe<Array<DocumentType>>;
  endTimeEnd?: InputMaybe<Scalars['DateTime']>;
  endTimeStart?: InputMaybe<Scalars['DateTime']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  exceptionTypes?: InputMaybe<Array<Scalars['String']>>;
  failureReasons?: InputMaybe<Array<TourFailureReasonType>>;
  hasNotes?: InputMaybe<Scalars['Boolean']>;
  mainCarrierIds?: InputMaybe<Array<Scalars['String']>>;
  mainVisibilityIssues?: InputMaybe<Array<VisibilityIssueType>>;
  orderItemCustomFields?: InputMaybe<OrderItemCustomFieldInput>;
  orderItemGroups?: InputMaybe<OrderItemGroupsInput>;
  orderItemNames?: InputMaybe<Array<Scalars['String']>>;
  placeIds?: InputMaybe<Array<Scalars['String']>>;
  platformBranchId?: InputMaybe<Scalars['String']>;
  platformId?: InputMaybe<Scalars['String']>;
  shipperIds?: InputMaybe<Array<Scalars['String']>>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  sortOrder?: InputMaybe<TourSortOrder>;
  startTimeEnd?: InputMaybe<Scalars['DateTime']>;
  startTimeStart?: InputMaybe<Scalars['DateTime']>;
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  status?: InputMaybe<TourStatusFilter>;
  statuses?: InputMaybe<Array<TourStatusFilter>>;
  stopBookingLocationNames?: InputMaybe<Array<Scalars['String']>>;
  stopCompanyNames?: InputMaybe<Array<Scalars['String']>>;
  stopCountries?: InputMaybe<Array<Scalars['String']>>;
  stopFilters?: InputMaybe<Array<StopFilterInput>>;
  stopStates?: InputMaybe<Array<StopStatesInput>>;
  textSearch?: InputMaybe<TourTextSearch>;
  timeSpentAtStop?: InputMaybe<TimeRangeInput>;
  transportMode?: InputMaybe<Array<TourFilteringTransportMode>>;
  vehicleId?: InputMaybe<Scalars['String']>;
  vesselName?: InputMaybe<VesselNameFilterInput>;
  warnings?: InputMaybe<Array<TourWarningType>>;
  workingStopStatus?: InputMaybe<WorkingStopStatusFilter>;
  workingStopTimeslotBeginEnd?: InputMaybe<Scalars['DateTime']>;
};

export type QueryUserArgs = {
  user_id: Scalars['String'];
};

export type QueryUserNotificationConfigArgs = {
  company_id: Scalars['String'];
  user_id: Scalars['String'];
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryVehicleArgs = {
  vehicle_id: Scalars['String'];
};

export type QueryVehicleTrackerByTelematicsIntegrationAndVehicleDetailsArgs = {
  licensePlate?: InputMaybe<Scalars['String']>;
  remoteId?: InputMaybe<Scalars['String']>;
  telematicsIntegrationId: Scalars['String'];
};

export type QueryVehiclesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  company_id?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  integrationIds?: InputMaybe<Array<Scalars['String']>>;
  last?: InputMaybe<Scalars['Int']>;
  licensePlateSearch?: InputMaybe<Scalars['String']>;
  trackerTypes?: InputMaybe<Array<VehicleTrackerType>>;
};

export type RawExternalEvents = {
  __typename: 'RawExternalEvents';
  tourDemurrageDetentionLogs: Array<TourDemurrageDetentionLog>;
  tourExternalTracking: Array<TourExternalTracking>;
  tourExternalTrackingLogs: Array<TourExternalTrackingLog>;
  tourExternalTrackingRequests: Array<TourExternalTrackingRequest>;
};

export type ReceivingVisibilityCompaniesFilterInput = {
  companyName?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<Array<UnifiedCompanyRelationshipOnboardingStatus>>;
  signupStatus?: InputMaybe<Array<UnifiedCompanyRelationshipSignupStatus>>;
  tourStatusConsent?: InputMaybe<Array<InputMaybe<ConsentState>>>;
};

export enum ReceivingVisibilityCompaniesSortKey {
  COMPANY_NAME = 'COMPANY_NAME',
  CONSENT_STATUS = 'CONSENT_STATUS',
  ONBOARDING_STATUS = 'ONBOARDING_STATUS',
  SIGNUP_STATUS = 'SIGNUP_STATUS',
}

export type ReceivingVisibilityCompaniesSortOrderInput = {
  direction: SortDirection;
  key: ReceivingVisibilityCompaniesSortKey;
};

export type ReceivingVisibilityCompany = {
  __typename: 'ReceivingVisibilityCompany';
  companyId: Scalars['String'];
  companyIdentifiers: Array<CompanyIdentifier>;
  companyName: Maybe<Scalars['String']>;
  companyReferenceName: Maybe<Scalars['String']>;
  dateAdded: Scalars['DateTime'];
  latestPendingInvite: Maybe<CompanyInvite>;
  onboardingStatus: Maybe<UnifiedCompanyRelationshipOnboardingStatus>;
  onboardingStatusTransitionedAt: Maybe<Scalars['DateTime']>;
  onboardingStuckReason: Maybe<UnifiedCompanyOnboardingStuckReason>;
  relationIdentifiers: Array<CompanyIdentifier>;
  signupStatus: Maybe<UnifiedCompanyRelationshipSignupStatus>;
  signupStatusTransitionedAt: Maybe<Scalars['DateTime']>;
  tourStatusConsent: ConsentState;
  transporeonId: Maybe<Scalars['String']>;
  vatNumbers: Array<CompanyVatNumber>;
};

export type ReceivingVisibilityCompanyConnection = {
  __typename: 'ReceivingVisibilityCompanyConnection';
  edges: Maybe<Array<Maybe<ReceivingVisibilityCompanyEdge>>>;
  pageInfo: PageInfo;
};

export type ReceivingVisibilityCompanyEdge = {
  __typename: 'ReceivingVisibilityCompanyEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<ReceivingVisibilityCompany>;
};

export type Recipient = EmailRecipient | StopContactRecipient;

export type RecipientInput = {
  email?: InputMaybe<EmailRecipientInput>;
  stopContact?: InputMaybe<StopContactRecipientInput>;
};

export type RelatedTransportForListView = {
  __typename: 'RelatedTransportForListView';
  shipper_transport_number: Maybe<Scalars['String']>;
  tour_id: Scalars['Int'];
};

export type RemoveCredentialsFromUserInput = {
  type: CredentialType;
  userId: Scalars['String'];
  value: Scalars['String'];
};

export type RemoveUserFromCompanyInput = {
  companyId: Scalars['String'];
  userId: Scalars['String'];
};

export type RemoveVehiclesFromCompanyVehicleGroupInput = {
  companyId: Scalars['String'];
  companyVehicleGroupId: Scalars['String'];
  vehicleIds: Array<Scalars['String']>;
};

export type ResendCompanyInvitationInput = {
  externalInvitation: ExternalCompanyInvitation;
};

export type RestrictedVehicleStatus = {
  __typename: 'RestrictedVehicleStatus';
  heading: Maybe<Scalars['Float']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  source: VehicleSignalSource;
  timeBasedStatus: TimeBasedStatus;
  timestamp: Maybe<Scalars['DateTime']>;
  vehicle_id: Scalars['String'];
};

export type RevokeVehicleGrantsInput = {
  revokedByCompany: Scalars['String'];
  revokedFromCompany: Scalars['String'];
};

export type Route = {
  __typename: 'Route';
  legs: Array<RouteLeg>;
  provider: Scalars['String'];
};

export type RouteLeg = {
  __typename: 'RouteLeg';
  polyline: Polyline;
};

export type SaveCustomerFeedbackInput = {
  companyId: Scalars['String'];
  feedback: Scalars['String'];
};

export type SendBulkInvitationSetInput = {
  bulkInvitationSetId: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
  includeInviterInCC?: InputMaybe<Scalars['Boolean']>;
  selectInvitationType: BulkInvitationSendSelectType;
  selectedInvitationItemIds?: InputMaybe<Array<Scalars['String']>>;
};

export type SendBulkInvitationSetResult = {
  __typename: 'SendBulkInvitationSetResult';
  success: Scalars['Boolean'];
};

export type SendCompanyInvitationInput = {
  externalInvitation: ExternalCompanyInvitation;
};

export type SendCompanyInvitationsResult = {
  __typename: 'SendCompanyInvitationsResult';
  companyInviteId: Maybe<Scalars['String']>;
  invalidEmails: Array<Scalars['String']>;
  invalidIdentifiers: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SensitiveData = {
  __typename: 'SensitiveData';
  connectionData: Maybe<Scalars['GraphQLJSON']>;
  syncStateData: Maybe<Scalars['String']>;
};

export type SharedCompanyInvite = {
  __typename: 'SharedCompanyInvite';
  allowedSignups: Array<AllowedCompanyInviteSignup>;
  companyInviteId: Scalars['String'];
  invitedByCompany: Maybe<CompanyProfile>;
  invitedByUser: Maybe<UserProfile>;
  invitedCompany: Maybe<CompanyProfile>;
};

export type SharedCompanyTourFilter = {
  __typename: 'SharedCompanyTourFilter';
  name: Scalars['String'];
  owner: Maybe<CompanyProfile>;
  tour: Maybe<Tour>;
  tours: CountedTourConnection;
};

export type SharedCompanyTourFilterTourArgs = {
  tourId: Scalars['String'];
};

export type SharedCompanyTourFilterToursArgs = {
  sortOrder?: InputMaybe<TourSortOrder>;
  textSearch?: InputMaybe<TourTextSearch>;
};

export type SharedStopViewConfiguration = {
  __typename: 'SharedStopViewConfiguration';
  visibleTransportIdentifiers: Array<TransportIdentifier>;
};

export type SharedStopViewConfigurationInput = {
  visibleTransportIdentifiers: Array<TransportIdentifier>;
};

export type SharedStopViewConfigurationValueInput = {
  value?: InputMaybe<SharedStopViewConfigurationInput>;
};

export type SharedTourDelivery = {
  __typename: 'SharedTourDelivery';
  delivery: Maybe<TourDelivery>;
  tourStop: Maybe<SharedTourStop>;
};

export type SharedTourStop = {
  __typename: 'SharedTourStop';
  latestSignalTimestamp: Maybe<Scalars['DateTime']>;
  shipper: Maybe<CompanyProfile>;
  stop: TourStop;
  tourStatus: Maybe<Scalars['String']>;
  vehicleLicensePlateNumber: Maybe<Scalars['String']>;
  vehicleStatus: Maybe<VehicleStatus>;
};

export type SharedTourViewConfiguration = {
  __typename: 'SharedTourViewConfiguration';
  visibleTransportIdentifiers: Array<TransportIdentifier>;
};

export type SharedTourViewConfigurationInput = {
  visibleTransportIdentifiers: Array<TransportIdentifier>;
};

export type SharedTourViewConfigurationValueInput = {
  value?: InputMaybe<SharedTourViewConfigurationInput>;
};

export type SharedUserLogin = {
  __typename: 'SharedUserLogin';
  user: LoginUser;
};

export type SharingToken = {
  __typename: 'SharingToken';
  createdBy: Maybe<UserProfile>;
  token: Scalars['String'];
};

export enum ShipmentType {
  FCL = 'FCL',
  LCL = 'LCL',
}

export type ShipperReport = {
  __typename: 'ShipperReport';
  allocatedToursCount: Scalars['Int'];
  shipper: Maybe<CompanyProfile>;
  toursCount: Scalars['Int'];
  visibilityIndex: Maybe<Scalars['Float']>;
};

export type ShipperReportConnection = {
  __typename: 'ShipperReportConnection';
  edges: Maybe<Array<Maybe<ShipperReportEdge>>>;
  pageInfo: PageInfo;
};

export type ShipperReportEdge = {
  __typename: 'ShipperReportEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<ShipperReport>;
};

export enum SignupAction {
  CONNECT_VEHICLES = 'CONNECT_VEHICLES',
  INVALID_INTEGRATION = 'INVALID_INTEGRATION',
}

export type SignupPageOpenedCompanyInput = {
  companyId: Scalars['String'];
};

export type SignupPageOpenedInput = {
  company?: InputMaybe<SignupPageOpenedCompanyInput>;
  platformCompany?: InputMaybe<SignupPageOpenedPlatformCompanyInput>;
};

export type SignupPageOpenedPlatformCompanyInput = {
  companyIdOnPlatform: Scalars['String'];
  platformId: Scalars['String'];
};

export type SignupPlatformCompanyInput = {
  companyName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  fromPlatform: FromPlatformData;
  lastName: Scalars['String'];
  referralToken?: InputMaybe<Scalars['String']>;
};

export enum SignupType {
  EMAIL = 'EMAIL',
  TRANSPOREON_PLATFORM = 'TRANSPOREON_PLATFORM',
}

export type SignupUrls = {
  __typename: 'SignupUrls';
  newRegistrationUrl: Scalars['String'];
  updateRegistrationUrl: Scalars['String'];
};

export type SingleTourStopEstimate = {
  __typename: 'SingleTourStopEstimate';
  estimated_arrival: Maybe<Scalars['DateTime']>;
  estimated_arrival_accuracy: Maybe<Scalars['String']>;
  estimated_arrival_delay_in_seconds: Maybe<Scalars['Int']>;
  estimated_arrival_distance_in_meters: Maybe<Scalars['Int']>;
  estimated_arrival_regulated: Maybe<Scalars['DateTime']>;
  estimated_arrival_status: Maybe<Scalars['String']>;
  estimated_arrival_unregulated: Maybe<Scalars['DateTime']>;
  etaType: Maybe<EtaType>;
  externalEtaSource: Maybe<ExternalEtaSource>;
  stop_id: Maybe<Scalars['String']>;
};

export type Site = {
  __typename: 'Site';
  name: Scalars['String'];
  siteIdOnPlatform: Scalars['String'];
  site_id: Scalars['String'];
};

export type SiteConnection = {
  __typename: 'SiteConnection';
  edges: Maybe<Array<Maybe<SiteEdge>>>;
  pageInfo: PageInfo;
};

export type SiteEdge = {
  __typename: 'SiteEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Site>;
};

export type SiteProfile = {
  __typename: 'SiteProfile';
  name: Scalars['String'];
  site_id: Scalars['String'];
};

export enum SiteTourStatusFilter {
  AT_STOP = 'AT_STOP',
  EN_ROUTE = 'EN_ROUTE',
}

export enum SortDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export type StopAllocation = {
  __typename: 'StopAllocation';
  startTrackingAt: Maybe<Scalars['DateTime']>;
  stopId: Scalars['String'];
  vehicleId: Maybe<Scalars['String']>;
  vehicleLicensePlateNumber: Maybe<Scalars['String']>;
  vehicleTrackerTypes: Maybe<Array<VehicleTrackerType>>;
};

export type StopAllocationInput = {
  startTrackingAt?: InputMaybe<Scalars['DateTime']>;
  stopId: Scalars['String'];
  vehicleId?: InputMaybe<Scalars['String']>;
};

export type StopCompanyName = {
  __typename: 'StopCompanyName';
  value: Scalars['String'];
};

export type StopCompanyNameConnection = {
  __typename: 'StopCompanyNameConnection';
  edges: Maybe<Array<Maybe<StopCompanyNameEdge>>>;
  pageInfo: PageInfo;
};

export type StopCompanyNameEdge = {
  __typename: 'StopCompanyNameEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<StopCompanyName>;
};

export type StopContactRecipient = {
  __typename: 'StopContactRecipient';
  _: Maybe<Scalars['Boolean']>;
};

export type StopContactRecipientInput = {
  _?: InputMaybe<Scalars['Boolean']>;
};

export type StopEvent = {
  __typename: 'StopEvent';
  created_at: Scalars['DateTime'];
  event_id: Scalars['Int'];
  event_index: Scalars['Int'];
  event_name: StopEventName;
  event_time: Maybe<Scalars['DateTime']>;
  metadata: Maybe<StopEventMetadata>;
  stop_id: Scalars['Int'];
  tour_id: Scalars['Int'];
  vehicle_id: Maybe<Scalars['String']>;
  vehicle_lat: Maybe<Scalars['Float']>;
  vehicle_license_plate_number: Maybe<Scalars['String']>;
  vehicle_lng: Maybe<Scalars['Float']>;
  visibilitySource: Maybe<TourVisibilitySource>;
};

export type StopEventMetadata = {
  __typename: 'StopEventMetadata';
  algorithm: Maybe<Scalars['String']>;
  parameters: Maybe<Scalars['GraphQLJSON']>;
};

export enum StopEventName {
  APPROACH = 'APPROACH',
  /** @deprecated No longer supported */
  APPROACHING = 'APPROACHING',
  APPROACH_UNKNOWN = 'APPROACH_UNKNOWN',
  DELETE = 'DELETE',
  DEPART = 'DEPART',
  /** @deprecated No longer supported */
  DEPARTED = 'DEPARTED',
  DEPART_UNKNOWN = 'DEPART_UNKNOWN',
  NAVIGATE = 'NAVIGATE',
  SKIP = 'SKIP',
  TRACTOR_SWITCH = 'TRACTOR_SWITCH',
  TRACTOR_SWITCH_WAITING = 'TRACTOR_SWITCH_WAITING',
  VISIBILITY_ENDED = 'VISIBILITY_ENDED',
}

export type StopFilterInput = {
  bookingLocationNames?: InputMaybe<Array<Scalars['String']>>;
  companyNames?: InputMaybe<Array<Scalars['String']>>;
  countries?: InputMaybe<Array<Scalars['String']>>;
  placeIds?: InputMaybe<Array<Scalars['String']>>;
  states?: InputMaybe<Array<StopStatesInput>>;
  stopTypes?: InputMaybe<Array<FilterRoadStopType>>;
};

export type StopFilterOutput = {
  __typename: 'StopFilterOutput';
  bookingLocationNames: Maybe<Array<Scalars['String']>>;
  companyNames: Maybe<Array<Scalars['String']>>;
  countries: Maybe<Array<Scalars['String']>>;
  placeIds: Maybe<Array<Scalars['String']>>;
  states: Maybe<Array<CompanyStopStates>>;
  stopTypes: Maybe<Array<FilterRoadStopType>>;
};

export type StopLocationInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  streetAddress: Scalars['String'];
  zipcode: Scalars['String'];
};

export type StopStatesInput = {
  country: Scalars['String'];
  state: Scalars['String'];
};

export type StopTimeslotInput = {
  begin: Scalars['DateTime'];
  end: Scalars['DateTime'];
  timezone: Scalars['IANATimezone'];
};

export enum StopType {
  loading = 'loading',
  unloading = 'unloading',
}

export type SubmitUnsupportedTelematicsProviderDetailsInput = {
  companyId: Scalars['String'];
  telematicsContactPersonEmail: Scalars['String'];
  telematicsContactPersonFirstName: Scalars['String'];
  telematicsContactPersonLastName: Scalars['String'];
  telematicsContactPersonPhone?: InputMaybe<Scalars['String']>;
  telematicsProviderHomepage: Scalars['String'];
  telematicsProviderName: Scalars['String'];
};

export type TelematicsIntegration = {
  __typename: 'TelematicsIntegration';
  company: CompanyProfile;
  company_id: Scalars['String'];
  company_integration_id: Scalars['String'];
  connection_data: Maybe<Scalars['GraphQLJSON']>;
  createdAt: Scalars['DateTime'];
  createdBy: Maybe<UserProfile>;
  diagnosticComment: Maybe<Scalars['String']>;
  diagnosticStatus: Maybe<TelematicsIntegrationDiagnosticStatus>;
  enabled: Scalars['Boolean'];
  generatedToken: Maybe<Scalars['String']>;
  integrationApiDebugUrl: Scalars['String'];
  integrationSyncHistory: Maybe<Array<IntegrationSyncResult>>;
  isAllowedToUpdateCredentials: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  isImmutable: Scalars['Boolean'];
  telematicsProvider: TelematicsProvider;
  telematics_integration_id: Scalars['String'];
  telematics_provider_id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy: Maybe<UserProfile>;
};

export type TelematicsIntegrationConnection = {
  __typename: 'TelematicsIntegrationConnection';
  edges: Maybe<Array<Maybe<TelematicsIntegrationEdge>>>;
  pageInfo: PageInfo;
};

export enum TelematicsIntegrationDiagnosticStatus {
  INTERMITTENT = 'INTERMITTENT',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  INVESTIGATING = 'INVESTIGATING',
  PROVIDER_ERROR = 'PROVIDER_ERROR',
}

export type TelematicsIntegrationEdge = {
  __typename: 'TelematicsIntegrationEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<TelematicsIntegration>;
};

export type TelematicsProvider = {
  __typename: 'TelematicsProvider';
  activeTrucksCount: Scalars['Int'];
  connectionParameters: Array<ConnectionParameter>;
  contact_info: Maybe<TelematicsProviderContactInfo>;
  integrationInstructionsURL: Maybe<Scalars['String']>;
  isDeprecated: Maybe<Scalars['Boolean']>;
  isExternalConfigurationNeeded: Scalars['Boolean'];
  isGlobalAllocationForbidden: Maybe<Scalars['Boolean']>;
  isIntegrationImplemented: Scalars['Boolean'];
  isInternalUseOnly: Scalars['Boolean'];
  isStandardTelematicsPushProvider: Maybe<Scalars['Boolean']>;
  isVehicleManagementEnabled: Maybe<Scalars['Boolean']>;
  telematicsIntegrations: CountedTelematicsIntegrationConnection;
  telematicsProviderId: Scalars['String'];
  telematicsProviderName: Scalars['String'];
  totalTrucksCount: Scalars['Int'];
};

export type TelematicsProviderConnectionParametersArgs = {
  includeInternal?: InputMaybe<Scalars['Boolean']>;
};

export type TelematicsProviderTelematicsIntegrationsArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type TelematicsProviderConnection = {
  __typename: 'TelematicsProviderConnection';
  edges: Maybe<Array<Maybe<TelematicsProviderEdge>>>;
  pageInfo: PageInfo;
};

export type TelematicsProviderContactInfo = {
  __typename: 'TelematicsProviderContactInfo';
  comment: Maybe<Scalars['String']>;
  email_address: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['String']>;
};

export type TelematicsProviderContactInfoInput = {
  comment?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
};

export type TelematicsProviderEdge = {
  __typename: 'TelematicsProviderEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<TelematicsProvider>;
};

export type TelematicsValidation = {
  __typename: 'TelematicsValidation';
  areValidCredentials: Scalars['Boolean'];
};

export type TemperatureSensor = {
  __typename: 'TemperatureSensor';
  name: Scalars['String'];
  readings: Array<TemperatureSensorReading>;
};

export type TemperatureSensorReading = {
  __typename: 'TemperatureSensorReading';
  timestamp: Scalars['DateTime'];
  value: Scalars['Float'];
};

export enum TimeBasedStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type TimeRange = {
  __typename: 'TimeRange';
  comparison: ComparisonOperator;
  endInSeconds: Maybe<Scalars['Int']>;
  startInSeconds: Scalars['Int'];
  unit: TimeUnit;
};

export type TimeRangeInput = {
  comparison: ComparisonOperator;
  endInSeconds?: InputMaybe<Scalars['Int']>;
  startInSeconds: Scalars['Int'];
  unit?: InputMaybe<TimeUnit>;
};

export type TimeRangeSugarDate = {
  comparison: ExtendedComparisonOperator;
  endSugarDate?: InputMaybe<Scalars['String']>;
  startSugarDate?: InputMaybe<Scalars['String']>;
};

export type TimeRangeSugarDateOutput = {
  __typename: 'TimeRangeSugarDateOutput';
  comparison: ExtendedComparisonOperator;
  endSugarDate: Maybe<Scalars['String']>;
  startSugarDate: Maybe<Scalars['String']>;
};

export enum TimeUnit {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type Timeslot = {
  __typename: 'Timeslot';
  begin: Maybe<Scalars['DateTime']>;
  end: Maybe<Scalars['DateTime']>;
  timezone: Maybe<Scalars['IANATimezone']>;
};

export type TokenBrandingAsset = {
  __typename: 'TokenBrandingAsset';
  url: Scalars['String'];
};

export type TokenBrandingAssets = {
  __typename: 'TokenBrandingAssets';
  background: Maybe<TokenBrandingAsset>;
  logo: Maybe<TokenBrandingAsset>;
};

export enum TokenResourceType {
  COMPANY_INVITE = 'COMPANY_INVITE',
  COMPANY_PROFILE = 'COMPANY_PROFILE',
  COMPANY_RELATION = 'COMPANY_RELATION',
  COMPANY_SIGNUP = 'COMPANY_SIGNUP',
  COMPANY_TOUR_FILTER = 'COMPANY_TOUR_FILTER',
  INTEGRATION_SYNC = 'INTEGRATION_SYNC',
  PLATFORM_COMPANY_INVITE = 'PLATFORM_COMPANY_INVITE',
  TOUR = 'TOUR',
  TOUR_DELIVERY = 'TOUR_DELIVERY',
  TOUR_STOP = 'TOUR_STOP',
  TOUR_STOP_AUTO_GENERATED = 'TOUR_STOP_AUTO_GENERATED',
  USER_LOGIN = 'USER_LOGIN',
}

export type TokenResources = {
  __typename: 'TokenResources';
  branding: Maybe<TokenBrandingAssets>;
  companyInvite: Maybe<SharedCompanyInvite>;
  companyProfile: Maybe<CompanyProfile>;
  companyRelation: Maybe<CompanyProfile>;
  companyTourFilter: Maybe<SharedCompanyTourFilter>;
  platformCompanyInvite: Maybe<PlatformCompanyInvite>;
  resourceType: TokenResourceType;
  tour: Maybe<Tour>;
  tourDelivery: Maybe<SharedTourDelivery>;
  tourStop: Maybe<SharedTourStop>;
  userLogin: Maybe<SharedUserLogin>;
};

export type Tour = {
  __typename: 'Tour';
  abort_time: Maybe<Scalars['DateTime']>;
  aborted_by_user: Maybe<UserProfile>;
  activeVehicleAllocation: Maybe<TourVehicleAllocation>;
  airWayBill: Maybe<Scalars['String']>;
  allocatableVehicles: VehicleProfileConnection;
  allocationType: Maybe<TourVehicleAllocationType>;
  applicationDeepLinks: Maybe<Array<ApplicationDeepLink>>;
  assignedUser: Maybe<UserProfile>;
  billOfLading: Maybe<Scalars['String']>;
  bookingNumber: Maybe<Scalars['String']>;
  canAbortTour: Scalars['Boolean'];
  canAllocateToTrimbleMobileApp: Scalars['Boolean'];
  canBeModified: Scalars['Boolean'];
  carrier: Maybe<CompanyProfile>;
  carrierCode: Maybe<Scalars['String']>;
  carrierName: Maybe<Scalars['String']>;
  carrierPackageCount: Maybe<Scalars['String']>;
  carrier_id: Maybe<Scalars['String']>;
  complete_time: Maybe<Scalars['DateTime']>;
  containerNumber: Maybe<Scalars['String']>;
  containerType: Maybe<Scalars['String']>;
  createdBy: Maybe<CompanyProfile>;
  created_at: Scalars['DateTime'];
  currentVehicle: Maybe<VehicleAllocation>;
  customFields: Array<CustomField>;
  customerPackageCount: Maybe<Scalars['String']>;
  deliveries: Array<TourDelivery>;
  deliveryTree: Array<TourDeliveryTree>;
  documents: Array<Document>;
  drivenDistance: Maybe<TourDrivenDistance>;
  emissionsCalculationErrors: Array<CalculationError>;
  end_time: Maybe<Scalars['DateTime']>;
  events: Array<CombinedEvent>;
  exceptions: Array<TourException>;
  externalCarrier: Maybe<ExternalCarrier>;
  externalDeliveryEvents: Array<ExternalTourDeliveryEvent>;
  externalDeliveryEventsVisibleToCustomers: Array<ExternalTourDeliveryEvent>;
  externalEvents: Array<ExternalTourEvent>;
  externalEventsVisibleToCustomers: Array<ExternalTourEvent>;
  externalStopEvents: Array<ExternalTourStopEvent>;
  externalStopEventsVisibleToCustomers: Array<ExternalTourStopEvent>;
  failureReason: Maybe<TourFailureReason>;
  forwardingChain: Array<TourForwardingLink>;
  fuelBasedEmissions: Maybe<FuelBasedEmissions>;
  hasTemperatureData: Maybe<Scalars['Boolean']>;
  hasTransportNotifications: Scalars['Boolean'];
  impactDays: Maybe<Scalars['Int']>;
  isGlobalAllocation: Scalars['Boolean'];
  isGloballyAllocatedToViewerCompany: Scalars['Boolean'];
  isSendEtaToPlatformEnabled: Scalars['Boolean'];
  isSubcontractingEnabled: Scalars['Boolean'];
  isTourAccessedByCreator: Scalars['Boolean'];
  isTrackingDisabled: Scalars['Boolean'];
  latestSignal: Maybe<TourSignal>;
  loadType: Maybe<LoadType>;
  mainMode: Maybe<MainMode>;
  mainVisibilityIssue: Maybe<VisibilityIssue>;
  metadata: Maybe<Scalars['GraphQLJSON']>;
  milestones: Maybe<LocationsAndMilestones>;
  notes: Array<Note>;
  oceanDemurrage: Maybe<OceanDemurrage>;
  packageCount: Maybe<Scalars['String']>;
  parent_tour_id: Maybe<Scalars['String']>;
  plannedEmissions: Maybe<TourCalculatedEmissions>;
  platform: Maybe<Platform>;
  platformBranchId: Maybe<Scalars['String']>;
  platformCallbacks: Array<PlatformCallbackMessage>;
  platformTransportURL: Maybe<Scalars['String']>;
  platform_carrier_id: Maybe<Scalars['String']>;
  platform_shipper_id: Maybe<Scalars['String']>;
  platform_transport_number: Maybe<Scalars['String']>;
  rawExternalEvents: Maybe<RawExternalEvents>;
  recalculate_time: Maybe<Scalars['DateTime']>;
  relatedTransports: Array<Tour>;
  relatedTransportsForListView: Array<RelatedTransportForListView>;
  route: Maybe<Route>;
  sharingToken: Maybe<Scalars['String']>;
  shipmentType: Maybe<ShipmentType>;
  shipper: Maybe<CompanyProfile>;
  shipper_id: Maybe<Scalars['String']>;
  shipper_transport_number: Maybe<Scalars['String']>;
  site: Maybe<SiteProfile>;
  start_time: Maybe<Scalars['DateTime']>;
  status: Maybe<Scalars['String']>;
  status_events: Array<TourEvent>;
  stops: Array<TourStop>;
  subcontractor: Maybe<CompanyProfile>;
  suggestedVehicles: Array<VehicleProfile>;
  tags: Array<TourTag>;
  telemetryEmissions: Maybe<TourCalculatedEmissions>;
  tourAssignment: Maybe<TourAssignment>;
  tourDistance: Maybe<TourDistance>;
  tourEmissionsReceived: Maybe<TourEmissionsReceived>;
  tourRoute: Maybe<Route>;
  tourTrafficEntries: TourTrafficEntryConnection;
  tour_area: Maybe<Geofence>;
  tour_id: Scalars['Int'];
  tour_type: Maybe<TourType>;
  trackingAbandonTime: Maybe<Scalars['DateTime']>;
  trackingEndTime: Maybe<Scalars['DateTime']>;
  trackingSegments: Array<TrackingSegment>;
  trackingStartTime: Maybe<Scalars['DateTime']>;
  transitTime: Maybe<Scalars['Int']>;
  transportAlerts: Array<TransportAlert>;
  transportAlertsCount: Scalars['Int'];
  transportAlertsHighestSeverity: Maybe<TransportAlertSeverity>;
  transportGroupId: Maybe<Scalars['String']>;
  transportIdentifiers: Maybe<Array<Scalars['String']>>;
  transportMode: Maybe<TransportMode>;
  transportNotifications: Array<FilterBasedTransportNotification>;
  transport_id: Maybe<Scalars['String']>;
  tripDeliveryItems: Maybe<Array<TripDeliveryItem>>;
  tripLocations: Maybe<Array<TripLocation>>;
  tripSegments: Maybe<Array<TripSegment>>;
  tripsVehicleHistory: Maybe<Array<VehicleHistoryPolyline>>;
  tripsVehicleStatus: Maybe<VehicleStatus>;
  userGroupsOnPlatform: Array<UserGroupOnPlatform>;
  vehicleAllocationHistory: Array<TourVehicleAllocation>;
  vehicleAllocations: Array<TourVehicleAllocation>;
  vehicleBreakHistory: Maybe<Array<VehicleBreakHistoryEntry>>;
  vehicleHistory: Maybe<Array<VehicleHistoryPolyline>>;
  vehicleHistoryList: Maybe<Array<VehicleHistoryEntry>>;
  vehicleStatuses: Array<VehicleStatus>;
  vehicleTemperatures: Maybe<Array<VehicleTemperature>>;
  vehicle_status: Maybe<VehicleStatus>;
  vehicles: Array<VehicleProfile>;
  vesselNames: Maybe<TripVesselNames>;
  visibilityIndex: Maybe<Scalars['Float']>;
  visibilityIssues: Maybe<Array<Maybe<VisibilityIssue>>>;
  visibilitySegments: Array<VisibilitySegment>;
  visibilityServices: Array<VisibilityService>;
  visibilitySources: Array<TourVisibilitySource>;
  visibility_end_time: Maybe<Scalars['DateTime']>;
  visibility_start_time: Maybe<Scalars['DateTime']>;
  visibleTrackingStartTime: Maybe<Scalars['DateTime']>;
  visibleVehicleBreakHistory: Maybe<Array<VehicleBreakHistoryEntry>>;
  volume: Maybe<TourVolume>;
  warnings: Array<TourWarning>;
  working_stop_id: Maybe<Scalars['Int']>;
};

export type TourAllocatableVehiclesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  licensePlateSearch: Scalars['String'];
};

export type TourCanAllocateToTrimbleMobileAppArgs = {
  companyId: Scalars['String'];
};

export type TourCustomFieldsArgs = {
  includeDeliveries?: InputMaybe<Scalars['Boolean']>;
};

export type TourHasTransportNotificationsArgs = {
  companyId: Scalars['String'];
};

export type TourOceanDemurrageArgs = {
  type?: InputMaybe<DemurrageType>;
};

export type TourRouteArgs = {
  limit_to_remaining?: InputMaybe<Scalars['Boolean']>;
};

export type TourTourAssignmentArgs = {
  company_id?: InputMaybe<Scalars['String']>;
  tour_id?: InputMaybe<Scalars['Int']>;
};

export type TourTourTrafficEntriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  apiName: TrafficApiName;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  from: Scalars['DateTime'];
  last?: InputMaybe<Scalars['Int']>;
  to: Scalars['DateTime'];
};

export type TourTransportNotificationsArgs = {
  companyId: Scalars['String'];
};

export type TourTripsVehicleHistoryArgs = {
  simplificationTolerance?: InputMaybe<Scalars['Float']>;
};

export type TourVehicleBreakHistoryArgs = {
  end_time: Scalars['String'];
  start_time: Scalars['String'];
};

export type TourVehicleHistoryArgs = {
  simplificationTolerance?: InputMaybe<Scalars['Float']>;
};

export type TourVehicleHistoryListArgs = {
  end_time: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  simplification_tolerance?: InputMaybe<Scalars['Float']>;
  start_time: Scalars['String'];
};

export enum TourAllocationDeviceType {
  AIR = 'AIR',
  CONTAINER = 'CONTAINER',
  IOT_DEVICE = 'IOT_DEVICE',
  MOBILE = 'MOBILE',
  TRACTOR = 'TRACTOR',
  TRAILER = 'TRAILER',
  TRAIN = 'TRAIN',
  VESSEL = 'VESSEL',
}

export type TourAllocationInput = {
  allocatedByCompanyId?: InputMaybe<Scalars['String']>;
  allocationType: TourVehicleAllocationType;
  startTrackingAt?: InputMaybe<Scalars['DateTime']>;
  stopAllocations?: InputMaybe<Array<StopAllocationInput>>;
  tourId: Scalars['String'];
  vehicleId?: InputMaybe<Scalars['String']>;
};

export enum TourAllocationStatus {
  Allocated = 'Allocated',
  Unallocated = 'Unallocated',
}

export enum TourAllocationSwitchType {
  AUTO_SWITCH = 'AUTO_SWITCH',
}

export type TourAssignment = {
  __typename: 'TourAssignment';
  assignedToUser: Maybe<UserProfile>;
};

export type TourCalculatedEmissions = {
  __typename: 'TourCalculatedEmissions';
  cargo: Cargo;
  co2TankToWheelTonnes: Scalars['Float'];
  co2WellToTankTonnes: Scalars['Float'];
  co2WellToWheelTonnes: Scalars['Float'];
  emissionsIntensityGramPerTonneKms: Maybe<Scalars['Float']>;
  legs: Array<Leg>;
  routePolyline: Maybe<Scalars['String']>;
  transportActivityTonneKms: Maybe<Scalars['Float']>;
};

export enum TourCompanyRole {
  CARRIER = 'CARRIER',
  SHIPPER = 'SHIPPER',
}

export type TourConnection = {
  __typename: 'TourConnection';
  edges: Maybe<Array<Maybe<TourEdge>>>;
  pageInfo: PageInfo;
};

export type TourCountByFailureReason = {
  __typename: 'TourCountByFailureReason';
  count: Scalars['Int'];
  failureReason: Maybe<TourFailureReasonType>;
};

export type TourCreationInput = {
  companyId: Scalars['String'];
  creator_transport_number: Scalars['String'];
  delivery: TourDeliveryInput;
  shipperId?: InputMaybe<Scalars['String']>;
};

export type TourCustomFieldSearch = {
  fieldName: Scalars['String'];
  value: TourCustomFieldSearchValue;
};

export type TourCustomFieldSearchType = {
  __typename: 'TourCustomFieldSearchType';
  fieldName: Scalars['String'];
  value: TourCustomFieldSearchValueType;
};

export type TourCustomFieldSearchValue = {
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type TourCustomFieldSearchValueType = {
  __typename: 'TourCustomFieldSearchValueType';
  /** @deprecated Use values instead */
  value: Maybe<Scalars['String']>;
  values: Maybe<Array<Scalars['String']>>;
};

export enum TourDelayStatusFilter {
  /** @deprecated Use DELAYED_ON_ANY instead */
  DELAYED = 'DELAYED',
  DELAYED_DELIVERY = 'DELAYED_DELIVERY',
  DELAYED_ON_ALL = 'DELAYED_ON_ALL',
  DELAYED_ON_ANY = 'DELAYED_ON_ANY',
  DELAYED_PICKUP = 'DELAYED_PICKUP',
  ON_TIME = 'ON_TIME',
  UNKNOWN = 'UNKNOWN',
}

export type TourDeletionInput = {
  companyId: Scalars['String'];
  tourId: Scalars['String'];
};

export type TourDelivery = {
  __typename: 'TourDelivery';
  customFields: Array<CustomField>;
  deliveryId: Scalars['String'];
  deliveryNumber: Maybe<Scalars['String']>;
  deliveryTourId: Maybe<Scalars['String']>;
  incoterms: Maybe<TourIncoterms>;
  loadingLength: Maybe<TourLoadingLength>;
  mainMode: Maybe<MainMode>;
  orderDate: Maybe<Scalars['DateTime']>;
  orderItems: Maybe<Array<OrderItem>>;
  orderNumber: Maybe<Scalars['String']>;
  purchaseOrderNumber: Maybe<Scalars['String']>;
  sequenceNumber: Maybe<Scalars['Int']>;
  storagePositions: Maybe<Scalars['Int']>;
  transportUnit: Maybe<TourTransportUnit>;
  volume: Maybe<TourVolume>;
};

export type TourDeliveryInput = {
  deliveryNumber?: InputMaybe<Scalars['String']>;
  loading: TourStopInput;
  orderNumber?: InputMaybe<Scalars['String']>;
  purchaseOrderNumber?: InputMaybe<Scalars['String']>;
  unloading: TourStopInput;
};

export type TourDeliveryTree = {
  __typename: 'TourDeliveryTree';
  affectedTourId: Scalars['String'];
  customFields: Scalars['GraphQLJSON'];
  deliveryNumber: Maybe<Scalars['String']>;
  deliveryTreeId: Scalars['String'];
  loadingStop: Maybe<DeliveryStop>;
  orderNumber: Maybe<Scalars['String']>;
  parentDeliveryTreeId: Maybe<Scalars['String']>;
  platformDeliveryId: Scalars['String'];
  platformId: Scalars['String'];
  platformPreviousDeliveryId: Maybe<Scalars['String']>;
  platformSequenceNumber: Maybe<Scalars['Int']>;
  purchaseOrderNumber: Maybe<Scalars['String']>;
  rootDeliveryTreeId: Scalars['String'];
  rootPlatformDeliveryId: Scalars['String'];
  sequenceNumber: Scalars['Int'];
  sourceTourId: Maybe<Scalars['String']>;
  unloadingStop: Maybe<DeliveryStop>;
};

export type TourDemurrageDetentionLog = {
  __typename: 'TourDemurrageDetentionLog';
  content: Scalars['String'];
  createdAt: Scalars['String'];
  demurrageDetentionLogId: Scalars['String'];
  tourId: Scalars['String'];
};

export type TourDistance = {
  __typename: 'TourDistance';
  totalDistanceInMeters: Scalars['Float'];
};

export type TourDrivenDistance = {
  __typename: 'TourDrivenDistance';
  distanceInKilometers: Maybe<Scalars['Float']>;
  source: Maybe<Scalars['String']>;
};

export type TourEdge = {
  __typename: 'TourEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Tour>;
};

export type TourEmissionsReceived = {
  __typename: 'TourEmissionsReceived';
  epKgCo2: Maybe<Scalars['Float']>;
};

export type TourEvent = {
  __typename: 'TourEvent';
  created_at: Scalars['DateTime'];
  event_id: Scalars['Int'];
  event_index: Scalars['Int'];
  event_name: TourEventName;
  event_time: Maybe<Scalars['DateTime']>;
  metadata: Maybe<TourEventMetadata>;
  tour_id: Scalars['Int'];
  vehicle_id: Maybe<Scalars['String']>;
  vehicle_lat: Maybe<Scalars['Float']>;
  vehicle_license_plate_number: Maybe<Scalars['String']>;
  vehicle_lng: Maybe<Scalars['Float']>;
  visibilitySource: Maybe<TourVisibilitySource>;
  warning: Maybe<TourWarning>;
};

export type TourEventMetadata = {
  __typename: 'TourEventMetadata';
  algorithm: Maybe<Scalars['String']>;
  parameters: Maybe<Scalars['GraphQLJSON']>;
};

export enum TourEventName {
  ABANDON = 'ABANDON',
  ABORT = 'ABORT',
  /** @deprecated No longer supported */
  AT_STOP = 'AT_STOP',
  COMPLETE = 'COMPLETE',
  /** @deprecated No longer supported */
  COMPLETED = 'COMPLETED',
  DEALLOCATION = 'DEALLOCATION',
  DELETE = 'DELETE',
  /** @deprecated No longer supported */
  EN_ROUTE = 'EN_ROUTE',
  EVENT_STREAM_STARTED = 'EVENT_STREAM_STARTED',
  FAILED = 'FAILED',
  SHIPPER_VISIBLE = 'SHIPPER_VISIBLE',
  TRACKING_START = 'TRACKING_START',
  TRUCK_ALLOCATION = 'TRUCK_ALLOCATION',
  VISIBLE_TRACKING_START = 'VISIBLE_TRACKING_START',
  WARNING_ENDED = 'WARNING_ENDED',
  WARNING_TRIGGERED = 'WARNING_TRIGGERED',
}

export type TourException = {
  __typename: 'TourException';
  createdAt: Scalars['DateTime'];
  customExceptionType: Maybe<Scalars['String']>;
  customExceptionTypeDescription: Maybe<Scalars['String']>;
  deliveryIds: Array<Scalars['String']>;
  description: Scalars['String'];
  exceptionFiles: Array<ExceptionFile>;
  exceptionId: Scalars['String'];
  exceptionType: Maybe<ExceptionType>;
  stopId: Maybe<Scalars['String']>;
};

export type TourExternalTracking = {
  __typename: 'TourExternalTracking';
  createdAt: Scalars['String'];
  deletedAt: Maybe<Scalars['String']>;
  externalId: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  status: Maybe<Scalars['String']>;
  statusReason: Maybe<Scalars['String']>;
  tourId: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type TourExternalTrackingLog = {
  __typename: 'TourExternalTrackingLog';
  content: Scalars['String'];
  contentType: Scalars['String'];
  createdAt: Scalars['String'];
  provider: Scalars['String'];
  tourId: Scalars['String'];
};

export type TourExternalTrackingRequest = {
  __typename: 'TourExternalTrackingRequest';
  createdAt: Scalars['String'];
  requestPayload: Scalars['String'];
  responsePayload: Maybe<Scalars['String']>;
  responseStatus: Maybe<Scalars['String']>;
  tourId: Scalars['String'];
  trackingRequestId: Scalars['String'];
  url: Scalars['String'];
};

export type TourFailureReason = {
  __typename: 'TourFailureReason';
  metadata: Maybe<TourFailureReasonMetadata>;
  type: TourFailureReasonType;
};

export type TourFailureReasonMetadata = {
  __typename: 'TourFailureReasonMetadata';
  allocated_license_plates: Maybe<Scalars['String']>;
  nr_of_stops_visited: Maybe<Scalars['String']>;
};

export enum TourFailureReasonType {
  ABORTED = 'ABORTED',
  /** @deprecated No longer produced */
  BAD_TELEMETRY = 'BAD_TELEMETRY',
  /** @deprecated No longer produced */
  BAD_TELEMETRY_BAD_DATA = 'BAD_TELEMETRY_BAD_DATA',
  BAD_TELEMETRY_NO_MOVEMENT = 'BAD_TELEMETRY_NO_MOVEMENT',
  /** @deprecated No longer produced */
  BAD_TELEMETRY_SHORT_VISIBILITY = 'BAD_TELEMETRY_SHORT_VISIBILITY',
  /** @deprecated No longer produced */
  BAD_TELEMETRY_SMALL_MOVEMENT = 'BAD_TELEMETRY_SMALL_MOVEMENT',
  BAD_TOUR_DATA = 'BAD_TOUR_DATA',
  /** @deprecated No longer produced */
  BAD_TOUR_DATA_SHORT_DISTANCE = 'BAD_TOUR_DATA_SHORT_DISTANCE',
  /** @deprecated No longer produced */
  CARRIER_NOT_INTEGRATED = 'CARRIER_NOT_INTEGRATED',
  CARRIER_NOT_REGISTERED = 'CARRIER_NOT_REGISTERED',
  /** @deprecated No longer produced */
  CARRIER_NOT_SPECIFIED = 'CARRIER_NOT_SPECIFIED',
  DATA_SHARING_ISSUE = 'DATA_SHARING_ISSUE',
  /** @deprecated No longer produced */
  GEOCODING_SERVICE_FAILED = 'GEOCODING_SERVICE_FAILED',
  INSUFFICIENT_ALLOCATION = 'INSUFFICIENT_ALLOCATION',
  /** @deprecated No longer produced */
  MISSING_START_TELEMETRY = 'MISSING_START_TELEMETRY',
  MISSING_STOP_GEOCODING = 'MISSING_STOP_GEOCODING',
  MISSING_STOP_TIMESLOT = 'MISSING_STOP_TIMESLOT',
  MISSING_TELEMETRY = 'MISSING_TELEMETRY',
  NO_VEHICLE_ALLOCATED = 'NO_VEHICLE_ALLOCATED',
  /** @deprecated No longer produced */
  SIXFOLD_INCIDENT = 'SIXFOLD_INCIDENT',
  TRACTOR_SWITCH_NOT_DETECTED = 'TRACTOR_SWITCH_NOT_DETECTED',
  /** @deprecated No longer produced */
  UNSPECIFIED = 'UNSPECIFIED',
  /** @deprecated No longer produced */
  VEHICLE_NOT_INTEGRATED = 'VEHICLE_NOT_INTEGRATED',
  VISIBILITY_THRESHOLD_EXCEEDED = 'VISIBILITY_THRESHOLD_EXCEEDED',
  /** @deprecated No longer produced */
  WRONG_PLATFORM_COORDINATES = 'WRONG_PLATFORM_COORDINATES',
}

export type TourFilter = {
  __typename: 'TourFilter';
  airWayBillNumbers: Maybe<Array<Scalars['String']>>;
  allocationStatus: Maybe<TourAllocationStatus>;
  assignedToUserIds: Maybe<Array<Scalars['String']>>;
  billOfLadingNumbers: Maybe<Array<Scalars['String']>>;
  bookingNumbers: Maybe<Array<Scalars['String']>>;
  carrierCodes: Maybe<Array<Scalars['String']>>;
  carrierIds: Maybe<Array<Scalars['String']>>;
  carrierNames: Maybe<Array<Scalars['String']>>;
  containerNumbers: Maybe<Array<Scalars['String']>>;
  createdAtEnd: Maybe<Scalars['DateTime']>;
  createdAtStart: Maybe<Scalars['DateTime']>;
  customFields: Maybe<Array<TourCustomFieldSearchType>>;
  delayStatus: Maybe<TourDelayStatusFilter>;
  delayTime: Maybe<TimeRange>;
  demurrage: Maybe<OceanDemurrageFilterOutput>;
  documentTypes: Maybe<Array<DocumentType>>;
  endTimeEnd: Maybe<Scalars['DateTime']>;
  endTimeStart: Maybe<Scalars['DateTime']>;
  endTimeSugarTimezone: Maybe<Scalars['IANATimezone']>;
  exceptionTypes: Maybe<Array<Scalars['String']>>;
  failureReasons: Maybe<Array<TourFailureReasonType>>;
  hasNotes: Maybe<Scalars['Boolean']>;
  impactDays: Maybe<ImpactDaysOutput>;
  locations: Maybe<Array<TransportLocationOutput>>;
  mainCarrierIds: Maybe<Array<Scalars['String']>>;
  mainVisibilityIssues: Maybe<Array<VisibilityIssueType>>;
  orderItemCustomFields: Maybe<OrderItemCustomFieldOutput>;
  orderItemGroups: Maybe<OrderItemGroupOutput>;
  orderItemNames: Maybe<Array<Scalars['String']>>;
  placeIds: Maybe<Array<Scalars['String']>>;
  platformId: Maybe<Scalars['String']>;
  shipperIds: Maybe<Array<Scalars['String']>>;
  siteIds: Maybe<Array<Scalars['String']>>;
  sortOrder: Maybe<TourSortOrderType>;
  startTimeEnd: Maybe<Scalars['DateTime']>;
  startTimeStart: Maybe<Scalars['DateTime']>;
  startTimeSugarTimezone: Maybe<Scalars['IANATimezone']>;
  status: Maybe<TourStatusFilter>;
  statuses: Maybe<Array<TourStatusFilter>>;
  stopBookingLocationNames: Maybe<Array<Scalars['String']>>;
  stopCompanyNames: Maybe<Array<Scalars['String']>>;
  stopCountries: Maybe<Array<Scalars['String']>>;
  stopFilters: Maybe<Array<StopFilterOutput>>;
  stopStates: Maybe<Array<CompanyStopStates>>;
  textSearch: Maybe<TourTextSearchType>;
  timeSpentAtStop: Maybe<TimeRange>;
  transportAlerts: Maybe<TransportAlertsFilterOutput>;
  transportGroupIds: Maybe<Array<Scalars['String']>>;
  transportMode: Maybe<Array<TourFilteringTransportMode>>;
  vehicleId: Maybe<Scalars['String']>;
  vesselName: Maybe<VesselNameFilterOutput>;
  warnings: Maybe<Array<TourWarningType>>;
  workingStopStatus: Maybe<WorkingStopStatusFilter>;
  workingStopTimeslotBeginEnd: Maybe<Scalars['DateTime']>;
};

export type TourFilterInput = {
  airWayBillNumbers?: InputMaybe<Array<Scalars['String']>>;
  allocationStatus?: InputMaybe<TourAllocationStatus>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']>>;
  billOfLadingNumbers?: InputMaybe<Array<Scalars['String']>>;
  bookingNumbers?: InputMaybe<Array<Scalars['String']>>;
  carrierCodes?: InputMaybe<Array<Scalars['String']>>;
  carrierIds?: InputMaybe<Array<Scalars['String']>>;
  carrierNames?: InputMaybe<Array<Scalars['String']>>;
  containerNumbers?: InputMaybe<Array<Scalars['String']>>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch>>;
  delayStatus?: InputMaybe<TourDelayStatusFilter>;
  delayTime?: InputMaybe<TimeRangeInput>;
  demurrage?: InputMaybe<OceanDemurrageFilter>;
  documentTypes?: InputMaybe<Array<DocumentType>>;
  endTimeEnd?: InputMaybe<Scalars['DateTime']>;
  endTimeStart?: InputMaybe<Scalars['DateTime']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  exceptionTypes?: InputMaybe<Array<Scalars['String']>>;
  failureReasons?: InputMaybe<Array<TourFailureReasonType>>;
  hasNotes?: InputMaybe<Scalars['Boolean']>;
  impactDays?: InputMaybe<ImpactDaysFilter>;
  locations?: InputMaybe<Array<TransportLocation>>;
  mainCarrierIds?: InputMaybe<Array<Scalars['String']>>;
  mainVisibilityIssues?: InputMaybe<Array<VisibilityIssueType>>;
  orderItemCustomFields?: InputMaybe<OrderItemCustomFieldInput>;
  orderItemGroups?: InputMaybe<OrderItemGroupsInput>;
  orderItemNames?: InputMaybe<Array<Scalars['String']>>;
  placeIds?: InputMaybe<Array<Scalars['String']>>;
  platformBranchId?: InputMaybe<Scalars['String']>;
  platformId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<TripsTextSearch>;
  shipperId?: InputMaybe<Scalars['String']>;
  shipperIds?: InputMaybe<Array<Scalars['String']>>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  sortOrder?: InputMaybe<TourSortOrder>;
  startTimeEnd?: InputMaybe<Scalars['DateTime']>;
  startTimeStart?: InputMaybe<Scalars['DateTime']>;
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  status?: InputMaybe<TourStatusFilter>;
  statuses?: InputMaybe<Array<TourStatusFilter>>;
  stopBookingLocationNames?: InputMaybe<Array<Scalars['String']>>;
  stopCompanyNames?: InputMaybe<Array<Scalars['String']>>;
  stopCountries?: InputMaybe<Array<Scalars['String']>>;
  stopFilters?: InputMaybe<Array<StopFilterInput>>;
  stopStates?: InputMaybe<Array<StopStatesInput>>;
  textSearch?: InputMaybe<TourTextSearch>;
  timeSpentAtStop?: InputMaybe<TimeRangeInput>;
  transportAlerts?: InputMaybe<TransportAlertsFilterInput>;
  transportGroupIds?: InputMaybe<Array<Scalars['String']>>;
  transportMode?: InputMaybe<Array<TourFilteringTransportMode>>;
  tripsSearch?: InputMaybe<TripsTextSearch>;
  vehicleId?: InputMaybe<Scalars['String']>;
  vesselName?: InputMaybe<VesselNameFilterInput>;
  warnings?: InputMaybe<Array<TourWarningType>>;
  workingStopStatus?: InputMaybe<WorkingStopStatusFilter>;
  workingStopTimeslotBeginEnd?: InputMaybe<Scalars['DateTime']>;
};

export enum TourFilteringTransportMode {
  Air = 'Air',
  Ocean = 'Ocean',
  Road = 'Road',
}

export type TourForwardingLink = {
  __typename: 'TourForwardingLink';
  forwardedAt: Scalars['DateTime'];
  forwardedBy: Maybe<UserProfile>;
  from: Maybe<CompanyProfile>;
  to: CompanyProfile;
  triggeredByAllocationId: Maybe<Scalars['String']>;
};

export type TourIncoterms = {
  __typename: 'TourIncoterms';
  city: Maybe<Scalars['String']>;
  term: Maybe<Scalars['String']>;
};

export type TourLoadingLength = {
  __typename: 'TourLoadingLength';
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export type TourLocation = {
  __typename: 'TourLocation';
  address: Maybe<Address>;
  bookingLocationName: Maybe<Scalars['String']>;
  customerProvidedAddress: Maybe<Address>;
  gate: Maybe<Scalars['String']>;
  gate_id: Maybe<Scalars['String']>;
  location_id: Maybe<Scalars['String']>;
  location_reference_id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  place: Maybe<CompanyPlace>;
  position: Maybe<GeoPosition>;
};

export enum TourRemoteAllocationSource {
  PLATFORM_CARRIER_INTERFACE = 'PLATFORM_CARRIER_INTERFACE',
  PLATFORM_UI = 'PLATFORM_UI',
}

export type TourSignal = {
  __typename: 'TourSignal';
  source: TourSignalSource;
  timestamp: Scalars['DateTime'];
};

export enum TourSignalSource {
  CARRIER_API = 'CARRIER_API',
  FMS = 'FMS',
  MOBILE = 'MOBILE',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  TTC = 'TTC',
}

export enum TourSortKey {
  CARRIER_NAME = 'CARRIER_NAME',
  SHIPPER_NAME = 'SHIPPER_NAME',
  START_TIME = 'START_TIME',
  VEHICLE_LICENSE_PLATE_NUMBER = 'VEHICLE_LICENSE_PLATE_NUMBER',
  WORKING_STOP_TIMESLOT_BEGIN = 'WORKING_STOP_TIMESLOT_BEGIN',
}

export type TourSortOrder = {
  direction: SortDirection;
  key: TourSortKey;
};

export type TourSortOrderType = {
  __typename: 'TourSortOrderType';
  direction: SortDirection;
  key: TourSortKey;
};

export enum TourStatusFilter {
  ABANDONED = 'ABANDONED',
  ABORTED = 'ABORTED',
  ALL = 'ALL',
  ALLOCATED = 'ALLOCATED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
  UNALLOCATED = 'UNALLOCATED',
  UNFINISHED = 'UNFINISHED',
  UPCOMING = 'UPCOMING',
  WAITING = 'WAITING',
  WORKING = 'WORKING',
}

export type TourStop = {
  __typename: 'TourStop';
  actual_sequence_number: Maybe<Scalars['Int']>;
  arrival_delay_in_seconds: Maybe<Scalars['Int']>;
  arrival_time: Maybe<Scalars['DateTime']>;
  booking_id: Maybe<Scalars['String']>;
  deadline: Maybe<Scalars['DateTime']>;
  deliveries: Array<TourDelivery>;
  delivery_ids: Maybe<Array<Maybe<Scalars['String']>>>;
  departure_time: Maybe<Scalars['DateTime']>;
  display_sequence_number: Scalars['Int'];
  documents: Array<Document>;
  estimated_arrival: Maybe<Scalars['DateTime']>;
  estimated_arrival_accuracy: Maybe<Scalars['String']>;
  estimated_arrival_delay_in_seconds: Maybe<Scalars['Int']>;
  estimated_arrival_distance_in_meters: Maybe<Scalars['Int']>;
  estimated_arrival_regulated: Maybe<Scalars['DateTime']>;
  estimated_arrival_status: Maybe<Scalars['String']>;
  estimated_arrival_unregulated: Maybe<Scalars['DateTime']>;
  etaType: Maybe<EtaType>;
  etas: Maybe<Array<SingleTourStopEstimate>>;
  exceptions: Array<TourException>;
  expected_duration: Maybe<Scalars['String']>;
  externalEtaSource: Maybe<ExternalEtaSource>;
  geofenceZones: Array<GeofenceZone>;
  location: Maybe<TourLocation>;
  notes: Array<Note>;
  place_reference_id: Maybe<Scalars['String']>;
  platformSequenceNumber: Maybe<Scalars['Int']>;
  sequence_number: Maybe<Scalars['Int']>;
  sharingToken: Maybe<Scalars['String']>;
  sharingTokenV2: Maybe<SharingToken>;
  status: Maybe<Scalars['String']>;
  status_events: Array<StopEvent>;
  stop_id: Scalars['Int'];
  stop_reference_id: Maybe<Scalars['String']>;
  timeslot: Maybe<Timeslot>;
  type: Maybe<Scalars['String']>;
  vehicleAllocation: Maybe<VehicleAllocation>;
};

export type TourStopSharingTokenArgs = {
  company_id: Scalars['String'];
};

export type TourStopSharingTokenV2Args = {
  company_id: Scalars['String'];
};

export type TourStopInput = {
  location: StopLocationInput;
  timeslot: StopTimeslotInput;
  type: StopType;
};

export type TourTag = {
  __typename: 'TourTag';
  name: Scalars['String'];
};

export type TourTextSearch = {
  field: TourTextSearchField;
  query: Scalars['String'];
};

export enum TourTextSearchField {
  ALL = 'ALL',
  ALL_EXTENDED = 'ALL_EXTENDED',
  DELIVERY_NUMBER = 'DELIVERY_NUMBER',
  DELIVERY_ORDER_NUMBER = 'DELIVERY_ORDER_NUMBER',
  DELIVERY_PURCHASE_ORDER_NUMBER = 'DELIVERY_PURCHASE_ORDER_NUMBER',
  PLATFORM_TRANSPORT_NUMBER = 'PLATFORM_TRANSPORT_NUMBER',
  SHIPPER_TRANSPORT_NUMBER = 'SHIPPER_TRANSPORT_NUMBER',
  STOP_ADDRESS = 'STOP_ADDRESS',
  STOP_NAME = 'STOP_NAME',
  TOUR_ID = 'TOUR_ID',
  VEHICLE_LICENSE_PLATE_NUMBER = 'VEHICLE_LICENSE_PLATE_NUMBER',
}

export type TourTextSearchType = {
  __typename: 'TourTextSearchType';
  field: TourTextSearchField;
  query: Scalars['String'];
};

export type TourTrafficEntry = {
  __typename: 'TourTrafficEntry';
  entry: Maybe<Scalars['GraphQLJSON']>;
  timestamp: Maybe<Scalars['DateTime']>;
};

export type TourTrafficEntryConnection = {
  __typename: 'TourTrafficEntryConnection';
  edges: Maybe<Array<Maybe<TourTrafficEntryEdge>>>;
  pageInfo: PageInfo;
};

export type TourTrafficEntryEdge = {
  __typename: 'TourTrafficEntryEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<TourTrafficEntry>;
};

export type TourTransportUnit = {
  __typename: 'TourTransportUnit';
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export enum TourType {
  DELIVERY_TOUR = 'DELIVERY_TOUR',
  PARENT_TOUR = 'PARENT_TOUR',
}

export type TourUpdateInput = {
  companyId: Scalars['String'];
  creator_transport_number: Scalars['String'];
  delivery: TourDeliveryInput;
  shipperId?: InputMaybe<Scalars['String']>;
  tourId: Scalars['String'];
};

export type TourVehicleAllocation = {
  __typename: 'TourVehicleAllocation';
  allocatedAt: Maybe<Scalars['DateTime']>;
  allocatedByCompany: Maybe<CompanyProfile>;
  allocationDeviceType: Maybe<TourAllocationDeviceType>;
  allocationId: Maybe<Scalars['String']>;
  allocationSwitchType: Maybe<TourAllocationSwitchType>;
  allocationType: Maybe<TourVehicleAllocationType>;
  disableVehicleMatching: Maybe<Scalars['Boolean']>;
  remoteAllocationSource: Maybe<TourRemoteAllocationSource>;
  remoteTrackerId: Maybe<Scalars['String']>;
  source: TourVehicleAllocationSource;
  startTrackingAt: Maybe<Scalars['DateTime']>;
  stopAllocations: Maybe<Array<StopAllocation>>;
  vehicleId: Maybe<Scalars['String']>;
  vehicleLicensePlateNumber: Maybe<Scalars['String']>;
  vehicleTrackerTypes: Maybe<Array<VehicleTrackerType>>;
};

export enum TourVehicleAllocationSource {
  CARRIER_API = 'CARRIER_API',
  PLATFORM = 'PLATFORM',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  PLATFORM_VERSIONED = 'PLATFORM_VERSIONED',
  SIXFOLD = 'SIXFOLD',
  TOUR_BASED_STREAM = 'TOUR_BASED_STREAM',
  WEB_UI = 'WEB_UI',
}

export enum TourVehicleAllocationType {
  STOP_ALLOCATION = 'STOP_ALLOCATION',
  TOUR_ALLOCATION = 'TOUR_ALLOCATION',
}

export enum TourVisibilityPrecisionType {
  EVENT = 'EVENT',
  GPS = 'GPS',
}

export type TourVisibilitySource = {
  __typename: 'TourVisibilitySource';
  eventOrigin: Maybe<Scalars['String']>;
  primaryEventSource: TourVisibilitySourceType;
  sourceCompany: Maybe<CompanyProfile>;
  sourcePlatform: Maybe<PlatformProfile>;
  streamPrecision: Maybe<TourVisibilityPrecisionType>;
};

export enum TourVisibilitySourceType {
  CARRIER_API = 'CARRIER_API',
  FMS = 'FMS',
  IOT_TRACKER = 'IOT_TRACKER',
  MOBILE = 'MOBILE',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  TTC = 'TTC',
}

export type TourVolume = {
  __typename: 'TourVolume';
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export type TourWarning = {
  __typename: 'TourWarning';
  metadata: Maybe<Scalars['GraphQLJSON']>;
  severity: Maybe<TourWarningSeverity>;
  type: TourWarningType;
};

export enum TourWarningSeverity {
  DEBUG = 'DEBUG',
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export enum TourWarningType {
  ETA_AND_STATUSES_ONLY = 'ETA_AND_STATUSES_ONLY',
  ETA_EXPECT_BORDER_RELATED_DELAYS = 'ETA_EXPECT_BORDER_RELATED_DELAYS',
  ETA_PENDING_TRACTOR_SWITCH = 'ETA_PENDING_TRACTOR_SWITCH',
  ETA_PREREQUISITE_DELIVERY_DELAYED = 'ETA_PREREQUISITE_DELIVERY_DELAYED',
  ETA_STALE_VEHICLE_STATUS = 'ETA_STALE_VEHICLE_STATUS',
  LAST_MILESTONE_IS_NOT_COMPLETED = 'LAST_MILESTONE_IS_NOT_COMPLETED',
  STATUS_BASED_ON_PLATFORM_EVENTS = 'STATUS_BASED_ON_PLATFORM_EVENTS',
  STATUS_BREAK_OUTSIDE_OF_SECURED_PARKING_STOPS = 'STATUS_BREAK_OUTSIDE_OF_SECURED_PARKING_STOPS',
  STATUS_EARLY_LOADING = 'STATUS_EARLY_LOADING',
  STATUS_INVALID_TIMESLOT_DATA = 'STATUS_INVALID_TIMESLOT_DATA',
  STATUS_NO_DEPARTURE_TIME = 'STATUS_NO_DEPARTURE_TIME',
  STATUS_NO_ETA_TRANSPORT_MODE = 'STATUS_NO_ETA_TRANSPORT_MODE',
  STATUS_POSSIBLE_WRONG_VEHICLE = 'STATUS_POSSIBLE_WRONG_VEHICLE',
  STATUS_PREREQUISITE_DELIVERY_NOT_COMPLETED = 'STATUS_PREREQUISITE_DELIVERY_NOT_COMPLETED',
  STATUS_STOPS_OUT_OF_SEQUENCE = 'STATUS_STOPS_OUT_OF_SEQUENCE',
  STATUS_STOP_MISSING_GEOCODING = 'STATUS_STOP_MISSING_GEOCODING',
  STATUS_STOP_MISSING_TIMESLOT = 'STATUS_STOP_MISSING_TIMESLOT',
  STATUS_TELEMETRY_CONTAINS_LONG_JUMPS = 'STATUS_TELEMETRY_CONTAINS_LONG_JUMPS',
  STATUS_TRANSPORT_EVENT_TELEMETRY_MISMATCH = 'STATUS_TRANSPORT_EVENT_TELEMETRY_MISMATCH',
  STATUS_UNABLE_TO_REACH_SECURED_PARKING_STOPS_WITHIN_DEADLINE = 'STATUS_UNABLE_TO_REACH_SECURED_PARKING_STOPS_WITHIN_DEADLINE',
  STATUS_UNUSED_ALLOCATION = 'STATUS_UNUSED_ALLOCATION',
  STATUS_VEHICLE_DOING_ANOTHER_TOUR = 'STATUS_VEHICLE_DOING_ANOTHER_TOUR',
  STATUS_VEHICLE_NOT_INTEGRATED = 'STATUS_VEHICLE_NOT_INTEGRATED',
  TELEMETRY_MISSING_AT_STOP = 'TELEMETRY_MISSING_AT_STOP',
  UNSTABLE_ETA_HIDDEN = 'UNSTABLE_ETA_HIDDEN',
}

export type ToursCountByDelayStatus = {
  __typename: 'ToursCountByDelayStatus';
  count: Scalars['Int'];
  delayStatus: Maybe<TourDelayStatusFilter>;
};

export type ToursCountByStatus = {
  __typename: 'ToursCountByStatus';
  countByFailureReason: Array<TourCountByFailureReason>;
  countByTourDelayStatus: Array<ToursCountByDelayStatus>;
  totalCount: Scalars['Int'];
};

export type TrackingSegment = {
  __typename: 'TrackingSegment';
  trackingEndDate: Scalars['DateTime'];
  trackingStartDate: Scalars['DateTime'];
  vehicleId: Scalars['String'];
};

export enum TrafficApiName {
  CREATE_OR_UPDATE_TRANSPORT = 'CREATE_OR_UPDATE_TRANSPORT',
  CREATE_OR_UPDATE_VEHICLE_ALLOCATION = 'CREATE_OR_UPDATE_VEHICLE_ALLOCATION',
  FORWARD_TRANSPORT = 'FORWARD_TRANSPORT',
  STATUS_UPDATE_TRANSPORT = 'STATUS_UPDATE_TRANSPORT',
}

export type TransportAlert = {
  __typename: 'TransportAlert';
  alertId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  severity: TransportAlertSeverity;
  type: TransportAlertType;
};

export enum TransportAlertSeverity {
  BLOCKER = 'BLOCKER',
  CRITICAL = 'CRITICAL',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export enum TransportAlertType {
  CARRIER_DATA_FETCHED_USING_CONTAINER_NUMBER = 'CARRIER_DATA_FETCHED_USING_CONTAINER_NUMBER',
  CARRIER_NOT_FOUND = 'CARRIER_NOT_FOUND',
  CARRIER_NO_DATA = 'CARRIER_NO_DATA',
  CARRIER_REFERENCE_NOT_ACCEPTED = 'CARRIER_REFERENCE_NOT_ACCEPTED',
  CARRIER_UNSUPPORTED = 'CARRIER_UNSUPPORTED',
  CHANGE_IN_POD = 'CHANGE_IN_POD',
  CHANGE_IN_POL = 'CHANGE_IN_POL',
  CONTAINER_DELETED = 'CONTAINER_DELETED',
  CONTAINER_ROLLOVER_AT_POL = 'CONTAINER_ROLLOVER_AT_POL',
  CONTAINER_ROLLOVER_AT_TSP = 'CONTAINER_ROLLOVER_AT_TSP',
  DELAYED_ARRIVAL_AT_DESTINATION = 'DELAYED_ARRIVAL_AT_DESTINATION',
  DELAYED_ARRIVAL_AT_POD = 'DELAYED_ARRIVAL_AT_POD',
  DELAYED_DEPARTURE_FROM_POL = 'DELAYED_DEPARTURE_FROM_POL',
  DEVIATION_LOCATION_GROUP = 'DEVIATION_LOCATION_GROUP',
  DEVIATION_ROUTE = 'DEVIATION_ROUTE',
  INVALID_SHIPMENT_REFERENCE_BN = 'INVALID_SHIPMENT_REFERENCE_BN',
  INVALID_SHIPMENT_REFERENCE_BOL = 'INVALID_SHIPMENT_REFERENCE_BOL',
  PACKAGE_COUNT_MISMATCH = 'PACKAGE_COUNT_MISMATCH',
  POST_CARRIAGE_IN_DIFFERENT_CONTINENTS = 'POST_CARRIAGE_IN_DIFFERENT_CONTINENTS',
  POTENTIALLY_INCORRECT_ROUTE = 'POTENTIALLY_INCORRECT_ROUTE',
  PRE_CARRIAGE_IN_DIFFERENT_CONTINENTS = 'PRE_CARRIAGE_IN_DIFFERENT_CONTINENTS',
  REFERENCE_UPDATE = 'REFERENCE_UPDATE',
  UNKNOWN = 'UNKNOWN',
}

export type TransportAlertsFilterInput = {
  severities?: InputMaybe<Array<TransportAlertSeverity>>;
  types?: InputMaybe<Array<TransportAlertType>>;
};

export type TransportAlertsFilterOutput = {
  __typename: 'TransportAlertsFilterOutput';
  severities: Maybe<Array<TransportAlertSeverity>>;
  types: Maybe<Array<TransportAlertType>>;
};

export enum TransportIdentifier {
  DELIVERY_NUMBER = 'DELIVERY_NUMBER',
  ORDER_NUMBER = 'ORDER_NUMBER',
  PURCHASE_ORDER_NUMBER = 'PURCHASE_ORDER_NUMBER',
  TRANSPORT_NUMBER = 'TRANSPORT_NUMBER',
}

export enum TransportIdentifierType {
  CONTAINER = 'CONTAINER',
  DELIVERY = 'DELIVERY',
  IMO = 'IMO',
  LICENSE_PLATE = 'LICENSE_PLATE',
  MMSI = 'MMSI',
}

export type TransportLocation = {
  countries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  iatas?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  milestones?: InputMaybe<Array<InputMaybe<LocationMilestone>>>;
  names?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TransportLocationOutput = {
  __typename: 'TransportLocationOutput';
  countries: Maybe<Array<Maybe<Scalars['String']>>>;
  iatas: Maybe<Array<Maybe<Scalars['String']>>>;
  locodes: Maybe<Array<Maybe<Scalars['String']>>>;
  milestones: Maybe<Array<Maybe<LocationMilestoneOutput>>>;
  names: Maybe<Array<Maybe<Scalars['String']>>>;
  types: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TransportMethod = {
  __typename: 'TransportMethod';
  identifiers: Maybe<Array<TripTransportIdentifier>>;
  mode: TripTransportMode;
  name: Maybe<Scalars['String']>;
};

export enum TransportMode {
  AIR = 'AIR',
  MULTIMODAL = 'MULTIMODAL',
  RAIL = 'RAIL',
  RIVER = 'RIVER',
  ROAD = 'ROAD',
  SEA = 'SEA',
  UNKNOWN = 'UNKNOWN',
}

export type TransportNetworkCustomFieldSearchType = {
  __typename: 'TransportNetworkCustomFieldSearchType';
  fieldName: Scalars['String'];
  value: TransportNetworkCustomFieldSearchValueType;
};

export type TransportNetworkCustomFieldSearchValueType = {
  __typename: 'TransportNetworkCustomFieldSearchValueType';
  /** @deprecated Use values instead */
  value: Maybe<Scalars['String']>;
  values: Maybe<Array<Scalars['String']>>;
};

export type TransportNetworkFilter = {
  __typename: 'TransportNetworkFilter';
  carrierIds: Maybe<Array<Scalars['String']>>;
  customFields: Maybe<Array<TransportNetworkCustomFieldSearchType>>;
  endTimeEnd: Maybe<Scalars['DateTime']>;
  endTimeStart: Maybe<Scalars['DateTime']>;
  mainVisibilityIssues: Maybe<Array<VisibilityIssueType>>;
  onboardingStatus: Maybe<Array<UnifiedCompanyRelationshipOnboardingStatus>>;
  placeIds: Maybe<Array<Scalars['String']>>;
  relationIdentifierIds: Maybe<Array<Scalars['String']>>;
  shipperIds: Maybe<Array<Scalars['String']>>;
  siteIds: Maybe<Array<Scalars['String']>>;
  startTimeEnd: Scalars['DateTime'];
  startTimeStart: Scalars['DateTime'];
  stopBookingLocationNames: Maybe<Array<Scalars['String']>>;
  stopCountries: Maybe<Array<Scalars['String']>>;
  stopFilters: Maybe<Array<StopFilterOutput>>;
  stopStates: Maybe<Array<CompanyStopStates>>;
};

export type TripDeliveryItem = {
  __typename: 'TripDeliveryItem';
  id: Scalars['String'];
  name: Scalars['String'];
  type: TransportIdentifierType;
};

export type TripLocation = {
  __typename: 'TripLocation';
  country: Maybe<Scalars['String']>;
  id: Scalars['String'];
  identifiers: Maybe<Array<LocationIdentifier>>;
  name: Scalars['String'];
  position: Maybe<Position>;
  type: Maybe<TripLocationType>;
};

export enum TripLocationType {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  DESTINATION = 'DESTINATION',
  EMPTY_CONTAINER_DELIVERY = 'EMPTY_CONTAINER_DELIVERY',
  EMPTY_CONTAINER_GATE_IN = 'EMPTY_CONTAINER_GATE_IN',
  EMPTY_CONTAINER_GATE_OUT = 'EMPTY_CONTAINER_GATE_OUT',
  EMPTY_CONTAINER_PICKUP = 'EMPTY_CONTAINER_PICKUP',
  EMPTY_POD = 'EMPTY_POD',
  EMPTY_POL = 'EMPTY_POL',
  FULL_CONTAINER_GATE_IN = 'FULL_CONTAINER_GATE_IN',
  FULL_CONTAINER_GATE_OUT = 'FULL_CONTAINER_GATE_OUT',
  IN_TRANSIT_DESTINATION = 'IN_TRANSIT_DESTINATION',
  IN_TRANSIT_ORIGIN = 'IN_TRANSIT_ORIGIN',
  LOADING = 'LOADING',
  ORIGIN = 'ORIGIN',
  OTHER = 'OTHER',
  PORT_OF_DISCHARGE = 'PORT_OF_DISCHARGE',
  PORT_OF_LOADING = 'PORT_OF_LOADING',
  TRANSSHIPMENT = 'TRANSSHIPMENT',
  TRANSSHIPMENT_PORT = 'TRANSSHIPMENT_PORT',
  UNLOADING = 'UNLOADING',
}

export type TripLocationWithMilestones = {
  __typename: 'TripLocationWithMilestones';
  location: Maybe<TripLocation>;
  milestones: Array<TripMilestone>;
};

export type TripMilestone = {
  __typename: 'TripMilestone';
  actualTime: Maybe<DateTimeWithTimezone>;
  deliveryItemIds: Maybe<Array<Scalars['String']>>;
  estimatedTime: Maybe<DateTimeWithTimezone>;
  name: Scalars['String'];
  qualifier: Scalars['String'];
  scheduledTime: Maybe<DateTimeWithTimezone>;
};

export type TripSegment = {
  __typename: 'TripSegment';
  id: Scalars['String'];
  locations: Maybe<Array<TripLocationWithMilestones>>;
  polylines: Maybe<Array<TripSegmentPolyline>>;
  previousTripIds: Maybe<Array<Scalars['String']>>;
  transportMethod: Maybe<TransportMethod>;
};

export type TripSegmentPolyline = {
  __typename: 'TripSegmentPolyline';
  endLocationId: Scalars['String'];
  polyline: Polyline;
  startLocationId: Scalars['String'];
};

export type TripTransportIdentifier = {
  __typename: 'TripTransportIdentifier';
  type: TransportIdentifierType;
  value: Scalars['String'];
};

export enum TripTransportMode {
  BARGE = 'BARGE',
  PLANE = 'PLANE',
  RAIL = 'RAIL',
  ROAD = 'ROAD',
  UNKNOWN = 'UNKNOWN',
  VESSEL = 'VESSEL',
}

export type TripVesselNames = {
  __typename: 'TripVesselNames';
  pod: Maybe<Scalars['String']>;
  pol: Maybe<Scalars['String']>;
};

export enum TripsIdentifierType {
  air_way_bill = 'air_way_bill',
  bill_of_lading = 'bill_of_lading',
  booking_number = 'booking_number',
  carrier_code = 'carrier_code',
  carrier_name = 'carrier_name',
  container_number = 'container_number',
  ocean_demurrage_currency = 'ocean_demurrage_currency',
  package_count = 'package_count',
  vessel_name = 'vessel_name',
}

export type TripsOption = {
  __typename: 'TripsOption';
  value: Scalars['String'];
};

export type TripsOptionConnection = {
  __typename: 'TripsOptionConnection';
  edges: Maybe<Array<Maybe<TripsOptionEdge>>>;
  pageInfo: PageInfo;
};

export type TripsOptionEdge = {
  __typename: 'TripsOptionEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<TripsOption>;
};

export type TripsTextSearch = {
  field: TripsTextSearchField;
  query: Scalars['String'];
};

export enum TripsTextSearchField {
  AIR_WAY_BILL_NUMBER = 'AIR_WAY_BILL_NUMBER',
  ALL = 'ALL',
  BILL_OF_LADING_NUMBER = 'BILL_OF_LADING_NUMBER',
  BOOKING_NUMBER = 'BOOKING_NUMBER',
  EQUIPMENT_NUMBER = 'EQUIPMENT_NUMBER',
}

export type TripsTourIdentifier = {
  __typename: 'TripsTourIdentifier';
  companyId: Scalars['String'];
  identifierType: TripsIdentifierType;
  identifierValue: Scalars['String'];
};

export type TripsTourIdentifierConnection = {
  __typename: 'TripsTourIdentifierConnection';
  edges: Maybe<Array<Maybe<TripsTourIdentifierEdge>>>;
  pageInfo: PageInfo;
};

export type TripsTourIdentifierEdge = {
  __typename: 'TripsTourIdentifierEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<TripsTourIdentifier>;
};

export enum TripsTransportMode {
  AIR = 'AIR',
  OCEAN = 'OCEAN',
}

export type UnforwardTourInput = {
  tourId: Scalars['String'];
  unforwardByCompanyId: Scalars['String'];
  unforwardFromCompanyId: Scalars['String'];
};

export type UnifiedCompanyOnboardingEvent = {
  __typename: 'UnifiedCompanyOnboardingEvent';
  eventTime: Scalars['DateTime'];
  stuckReason: Maybe<UnifiedCompanyOnboardingStuckReason>;
  triggeredByUser: Maybe<UserProfile>;
  type: UnifiedCompanyOnboardingEventType;
  visibilitySource: Maybe<TourVisibilitySourceType>;
};

export enum UnifiedCompanyOnboardingEventType {
  COMPANY_REGISTERED = 'COMPANY_REGISTERED',
  FIRST_INTEGRATION_REMINDER_SENT = 'FIRST_INTEGRATION_REMINDER_SENT',
  FIRST_INVITE_SUBCONTRACTORS_REMINDER_SENT = 'FIRST_INVITE_SUBCONTRACTORS_REMINDER_SENT',
  FIRST_QUESTIONNAIRE_REMINDER_SENT = 'FIRST_QUESTIONNAIRE_REMINDER_SENT',
  INTEGRATION_HELP_REQUESTED = 'INTEGRATION_HELP_REQUESTED',
  MANUALLY_ONBOARDED = 'MANUALLY_ONBOARDED',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_RESET_TO_AUTOMATIC = 'ONBOARDING_RESET_TO_AUTOMATIC',
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  ONBOARDING_STUCK_AUTOMATIC = 'ONBOARDING_STUCK_AUTOMATIC',
  SECOND_INTEGRATION_REMINDER_SENT = 'SECOND_INTEGRATION_REMINDER_SENT',
  SECOND_INVITE_SUBCONTRACTORS_REMINDER_SENT = 'SECOND_INVITE_SUBCONTRACTORS_REMINDER_SENT',
  SECOND_QUESTIONNAIRE_REMINDER_SENT = 'SECOND_QUESTIONNAIRE_REMINDER_SENT',
  SIGNUP_PAGE_OPENED = 'SIGNUP_PAGE_OPENED',
  SURVEY_SUBMITTED = 'SURVEY_SUBMITTED',
  TELEMATICS_INTEGRATION_ADDED = 'TELEMATICS_INTEGRATION_ADDED',
  UNSUPPORTED_TELEMATICS_SUBMITTED = 'UNSUPPORTED_TELEMATICS_SUBMITTED',
  VISIBILITY_SOURCE_ACTIVATED = 'VISIBILITY_SOURCE_ACTIVATED',
  VISIBILITY_SOURCE_IDENTIFIED = 'VISIBILITY_SOURCE_IDENTIFIED',
}

export enum UnifiedCompanyOnboardingStatus {
  NOT_REGISTERED = 'NOT_REGISTERED',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  READY_FOR_ONBOARDING = 'READY_FOR_ONBOARDING',
  REGISTERED = 'REGISTERED',
}

export type UnifiedCompanyOnboardingStatusUpdateInput = {
  stuckReason?: InputMaybe<UnifiedCompanyOnboardingStuckReason>;
  update: UnifiedCompanyOnboardingStatusUpdateType;
};

export enum UnifiedCompanyOnboardingStatusUpdateType {
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  RESET_TO_AUTOMATIC = 'RESET_TO_AUTOMATIC',
}

export enum UnifiedCompanyOnboardingStuckReason {
  CARRIER_API_WORK_IN_PROGRESS = 'CARRIER_API_WORK_IN_PROGRESS',
  COMPANY_UNRESPONSIVE = 'COMPANY_UNRESPONSIVE',
  FMS_CREDENTIALS_NOT_PROVIDED = 'FMS_CREDENTIALS_NOT_PROVIDED',
  FMS_PROVIDER_UNRESPONSIVE = 'FMS_PROVIDER_UNRESPONSIVE',
  NO_VISIBILITY_PROVIDED = 'NO_VISIBILITY_PROVIDED',
  NO_VISIBILITY_SOURCE_IDENTIFIED = 'NO_VISIBILITY_SOURCE_IDENTIFIED',
  REFUSES_COOPERATION = 'REFUSES_COOPERATION',
  SUBCARRIER_ONBOARDING_BLOCKED = 'SUBCARRIER_ONBOARDING_BLOCKED',
}

export type UnifiedCompanyRelationshipOnboardingEvent = {
  __typename: 'UnifiedCompanyRelationshipOnboardingEvent';
  eventTime: Scalars['DateTime'];
  triggeredByUser: Maybe<UserProfile>;
  type: UnifiedCompanyRelationshipOnboardingEventType;
};

export enum UnifiedCompanyRelationshipOnboardingEventType {
  INVITE_REMINDER_SENT = 'INVITE_REMINDER_SENT',
  INVITE_SENT = 'INVITE_SENT',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_OUT_OF_SCOPE = 'ONBOARDING_OUT_OF_SCOPE',
  ONBOARDING_RESET_TO_AUTOMATIC = 'ONBOARDING_RESET_TO_AUTOMATIC',
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  ONBOARDING_STUCK_AUTOMATIC = 'ONBOARDING_STUCK_AUTOMATIC',
}

export enum UnifiedCompanyRelationshipOnboardingStatus {
  INVITED = 'INVITED',
  NOT_REGISTERED = 'NOT_REGISTERED',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  OUT_OF_SCOPE = 'OUT_OF_SCOPE',
  PLATFORM_REGISTERED = 'PLATFORM_REGISTERED',
  READY_FOR_ONBOARDING = 'READY_FOR_ONBOARDING',
  REGISTERED = 'REGISTERED',
}

export enum UnifiedCompanyRelationshipOnboardingStatusUpdateType {
  ONBOARDING_STUCK = 'ONBOARDING_STUCK',
  OUT_OF_SCOPE = 'OUT_OF_SCOPE',
  RESET_TO_AUTOMATIC = 'RESET_TO_AUTOMATIC',
}

export type UnifiedCompanyRelationshipSignupEvent = {
  __typename: 'UnifiedCompanyRelationshipSignupEvent';
  eventTime: Scalars['DateTime'];
  triggeredByUser: Maybe<UserProfile>;
  type: UnifiedCompanyRelationshipSignupEventType;
};

export enum UnifiedCompanyRelationshipSignupEventType {
  INVITE_REMINDER_SENT = 'INVITE_REMINDER_SENT',
  INVITE_SENT = 'INVITE_SENT',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_OUT_OF_SCOPE = 'ONBOARDING_OUT_OF_SCOPE',
  ONBOARDING_RESET_TO_AUTOMATIC = 'ONBOARDING_RESET_TO_AUTOMATIC',
  PLATFORM_SIGNUP_ENDED = 'PLATFORM_SIGNUP_ENDED',
  PLATFORM_SIGNUP_STARTED = 'PLATFORM_SIGNUP_STARTED',
}

export enum UnifiedCompanyRelationshipSignupStatus {
  INVITED = 'INVITED',
  NO_VISIBILITY_SOURCE = 'NO_VISIBILITY_SOURCE',
  ONBOARDED = 'ONBOARDED',
  OUT_OF_SCOPE = 'OUT_OF_SCOPE',
  REGISTERED = 'REGISTERED',
}

export enum UnifiedCompanyRelationshipSignupStatusUpdateType {
  OUT_OF_SCOPE = 'OUT_OF_SCOPE',
  RESET_TO_AUTOMATIC = 'RESET_TO_AUTOMATIC',
}

export type UnifiedCompanySignupEvent = {
  __typename: 'UnifiedCompanySignupEvent';
  eventTime: Scalars['DateTime'];
  triggeredByUser: Maybe<UserProfile>;
  type: UnifiedCompanySignupEventType;
  visibilitySource: Maybe<TourVisibilitySourceType>;
};

export enum UnifiedCompanySignupEventType {
  COMPANY_REGISTERED = 'COMPANY_REGISTERED',
  FIRST_INTEGRATION_REMINDER_SENT = 'FIRST_INTEGRATION_REMINDER_SENT',
  FIRST_INVITE_SUBCONTRACTORS_REMINDER_SENT = 'FIRST_INVITE_SUBCONTRACTORS_REMINDER_SENT',
  MANUALLY_ONBOARDED = 'MANUALLY_ONBOARDED',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_RESET_TO_AUTOMATIC = 'ONBOARDING_RESET_TO_AUTOMATIC',
  PLATFORM_ACTIVATED = 'PLATFORM_ACTIVATED',
  SECOND_INTEGRATION_REMINDER_SENT = 'SECOND_INTEGRATION_REMINDER_SENT',
  SECOND_INVITE_SUBCONTRACTORS_REMINDER_SENT = 'SECOND_INVITE_SUBCONTRACTORS_REMINDER_SENT',
  SIGNUP_PAGE_OPENED = 'SIGNUP_PAGE_OPENED',
  TELEMATICS_INTEGRATION_ADDED = 'TELEMATICS_INTEGRATION_ADDED',
  UNSUPPORTED_TELEMATICS_SUBMITTED = 'UNSUPPORTED_TELEMATICS_SUBMITTED',
  VISIBILITY_SOURCE_ACTIVATED = 'VISIBILITY_SOURCE_ACTIVATED',
  VISIBILITY_SOURCE_IDENTIFIED = 'VISIBILITY_SOURCE_IDENTIFIED',
}

export enum UnifiedCompanySignupStatus {
  INVITED = 'INVITED',
  NO_VISIBILITY_SOURCE = 'NO_VISIBILITY_SOURCE',
  ONBOARDED = 'ONBOARDED',
  REGISTERED = 'REGISTERED',
}

export type UnifiedCompanySignupStatusUpdateInput = {
  update: UnifiedCompanySignupStatusUpdateType;
};

export enum UnifiedCompanySignupStatusUpdateType {
  ONBOARDED = 'ONBOARDED',
  RESET_TO_AUTOMATIC = 'RESET_TO_AUTOMATIC',
}

export type UpdateBulkInvitationSetInput = {
  bulkInvitationSetId: Scalars['String'];
  itemIdsToDelete: Array<Scalars['String']>;
  itemsToCreate: Array<CreateBulkInvitationSetItemInput>;
  itemsToUpdate: Array<UpdateBulkInvitationSetItemInput>;
};

export type UpdateBulkInvitationSetItemInput = {
  bulkInvitationSetItemId: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
  recipients: Array<CompanyBulkInvitationRecipient>;
};

export type UpdateBulkInvitationSetResult = {
  __typename: 'UpdateBulkInvitationSetResult';
  success: Scalars['Boolean'];
};

export type UpdateCompanyConfigurationInput = {
  companyId: Scalars['String'];
  configuration: ConfigurationUpdateInput;
};

export type UpdateCompanyInput = {
  address?: InputMaybe<CompanyAddressInput>;
  company_name?: InputMaybe<Scalars['String']>;
  consigneeBackground?: InputMaybe<NullableUpload>;
  consigneeLogo?: InputMaybe<NullableUpload>;
  feature_flags?: InputMaybe<Scalars['GraphQLJSON']>;
  is_carrier?: InputMaybe<Scalars['Boolean']>;
  is_paying_customer?: InputMaybe<Scalars['Boolean']>;
  is_pilot?: InputMaybe<Scalars['Boolean']>;
  is_shipper?: InputMaybe<Scalars['Boolean']>;
  is_test?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCompanyPlaceInput = {
  companyId: Scalars['String'];
  geodata?: InputMaybe<Array<PlaceGeodataInput>>;
  location?: InputMaybe<PointLocationInput>;
  name: Scalars['String'];
  placeId: Scalars['String'];
  referenceId?: InputMaybe<Scalars['String']>;
  stopAddressIds: Array<Scalars['String']>;
};

export type UpdateCompanyRelationshipOnboardingStatusInput = {
  companyRelationshipId: Scalars['String'];
  stuckReason?: InputMaybe<UnifiedCompanyOnboardingStuckReason>;
  update: UnifiedCompanyRelationshipOnboardingStatusUpdateType;
};

export type UpdateCompanyRelationshipSignupStatusInput = {
  companyRelationshipId: Scalars['String'];
  update: UnifiedCompanyRelationshipSignupStatusUpdateType;
};

export type UpdateCompanySettingsInput = {
  companyId: Scalars['String'];
  settings: CompanySettingsInput;
};

export type UpdateCompanyVehicleGroupInput = {
  companyId: Scalars['String'];
  companyVehicleGroupId: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateDashboardInput = {
  dashboardId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  isExample?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  sharedWithUserIds?: InputMaybe<Array<Scalars['String']>>;
  sharingType: DashboardSharingType;
  widgetIdsOrder?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateTelematicsIntegrationConnectionParametersInput = {
  connectionData?: InputMaybe<Scalars['GraphQLJSON']>;
  telematicsIntegrationId: Scalars['String'];
};

export type UpdateTelematicsIntegrationInput = {
  company_integration_id?: InputMaybe<Scalars['String']>;
  connection_data?: InputMaybe<Scalars['GraphQLJSON']>;
  diagnosticComment?: InputMaybe<Scalars['String']>;
  diagnosticStatus?: InputMaybe<TelematicsIntegrationDiagnosticStatus>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  telematics_integration_id: Scalars['String'];
};

export type UpdateTelematicsProviderInput = {
  contact_info?: InputMaybe<TelematicsProviderContactInfoInput>;
  telematics_provider_id: Scalars['String'];
};

export type UpdateUserInCompanyConfigurationInput = {
  companyId: Scalars['String'];
  configuration: UserInCompanyConfigurationInput;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdateUserNotificationConfigInput = {
  action: Action;
  company_id: Scalars['String'];
  types: Array<EmailType>;
  user_id: Scalars['String'];
};

export type UpdateUserNotificationConfigResponse = {
  __typename: 'UpdateUserNotificationConfigResponse';
  success: Scalars['Boolean'];
};

export type UpdateViewerProfileInput = {
  locale?: InputMaybe<Scalars['String']>;
};

export type UpdateWidgetInput = {
  dashboardId: Scalars['String'];
  filters: Array<WidgetFilterInput>;
  name: Scalars['String'];
  widgetId: Scalars['String'];
};

export type User = {
  __typename: 'User';
  avatar_url: Maybe<Scalars['String']>;
  backoffice_access_enabled: Maybe<Scalars['Boolean']>;
  backoffice_role: Maybe<BackofficeRole>;
  backoffice_scopes: Maybe<Array<Scalars['String']>>;
  companies: Array<Company>;
  company: Maybe<Company>;
  companyIdAndVisibilityServicesForTour: Maybe<CompanyIdAndVisibilityServices>;
  created_at: Maybe<Scalars['DateTime']>;
  credentials: Array<Credentials>;
  deleted_at: Maybe<Scalars['DateTime']>;
  email: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  last_name: Maybe<Scalars['String']>;
  locale: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['DateTime']>;
  user_id: Scalars['String'];
};

export type UserCompanyArgs = {
  company_id: Scalars['String'];
};

export type UserCompanyIdAndVisibilityServicesForTourArgs = {
  tourId: Scalars['String'];
};

export type UserAttributesInput = {
  avatar_url?: InputMaybe<Scalars['String']>;
  backoffice_role?: InputMaybe<BackofficeRole>;
  backoffice_scopes?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type UserConnection = {
  __typename: 'UserConnection';
  edges: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename: 'UserEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<User>;
};

export type UserFilter = {
  __typename: 'UserFilter';
  filter: TourFilter;
  name: Scalars['String'];
  tourCount: Scalars['Int'];
  tours: Array<Tour>;
  userFilterId: Scalars['String'];
};

export type UserFilterToursArgs = {
  included_filter_ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type UserFilterConnection = {
  __typename: 'UserFilterConnection';
  edges: Maybe<Array<Maybe<UserFilterEdge>>>;
  pageInfo: PageInfo;
};

export type UserFilterEdge = {
  __typename: 'UserFilterEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<UserFilter>;
};

export type UserGroupOnPlatform = {
  __typename: 'UserGroupOnPlatform';
  company: Maybe<CompanyProfile>;
  groupId: Scalars['String'];
  name: Scalars['String'];
  platform: PlatformProfile;
  users: UserProfileConnection;
};

export type UserGroupOnPlatformUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserGroupOnPlatformConnection = {
  __typename: 'UserGroupOnPlatformConnection';
  edges: Maybe<Array<Maybe<UserGroupOnPlatformEdge>>>;
  pageInfo: PageInfo;
};

export type UserGroupOnPlatformEdge = {
  __typename: 'UserGroupOnPlatformEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<UserGroupOnPlatform>;
};

export type UserInCompany = {
  __typename: 'UserInCompany';
  configuration: UserInCompanyConfiguration;
  dashboard: Maybe<Dashboard>;
  dashboardsConnection: CountedDashboardsConnection;
  invitedByUser: Maybe<UserProfile>;
  metadata: Maybe<UserMetadataInCompany>;
  profile: UserProfile;
  role: UserRoleInCompany;
  transporeonPlatform: Maybe<UserOnPlatform>;
  user: User;
  userFiltersConnection: CountedUserFilterConnection;
};

export type UserInCompanyDashboardArgs = {
  dashboardId: Scalars['String'];
};

export type UserInCompanyDashboardsConnectionArgs = {
  queryType?: InputMaybe<DashboardQueryType>;
  search?: InputMaybe<Scalars['String']>;
};

export type UserInCompanyUserFiltersConnectionArgs = {
  filterType?: InputMaybe<FilterType>;
  textSearch?: InputMaybe<Scalars['String']>;
};

export type UserInCompanyConfiguration = {
  __typename: 'UserInCompanyConfiguration';
  vehicleDwellTimeThresholdMinutes: Scalars['Int'];
};

export type UserInCompanyConfigurationInput = {
  vehicleDwellTimeThresholdMinutes: Scalars['Int'];
};

export type UserInCompanyProfile = {
  __typename: 'UserInCompanyProfile';
  configuration: UserInCompanyConfiguration;
  invitedByUser: Maybe<UserProfile>;
  metadata: Maybe<UserMetadataInCompany>;
  profile: UserProfile;
  role: UserRoleInCompany;
  user: User;
  userOnPlatforms: Array<UserOnPlatformProfile>;
};

export type UserInCompanyProfileConnection = {
  __typename: 'UserInCompanyProfileConnection';
  edges: Maybe<Array<Maybe<UserInCompanyProfileEdge>>>;
  pageInfo: PageInfo;
};

export type UserInCompanyProfileEdge = {
  __typename: 'UserInCompanyProfileEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<UserInCompanyProfile>;
};

export type UserMetadataInCompany = {
  __typename: 'UserMetadataInCompany';
  persona: Maybe<UserPersonaInCompany>;
};

export type UserMetadataInCompanyInput = {
  persona?: InputMaybe<UserPersonaInCompany>;
};

export type UserNotificationConfig = {
  __typename: 'UserNotificationConfig';
  company_id: Scalars['String'];
  created_at: Scalars['String'];
  disabled_types: Array<EmailType>;
  updated_at: Scalars['String'];
  user_id: Scalars['String'];
  user_notification_config_id: Scalars['String'];
};

export type UserOnPlatform = {
  __typename: 'UserOnPlatform';
  userIdOnPlatform: Scalars['String'];
};

export type UserOnPlatformProfile = {
  __typename: 'UserOnPlatformProfile';
  company: CompanyProfile;
  platform: PlatformProfile;
  user: UserProfile;
  userIdOnPlatform: Scalars['String'];
};

export enum UserPersonaInCompany {
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  DEVELOPER = 'DEVELOPER',
  TRANSPORT_PLANNER = 'TRANSPORT_PLANNER',
  WAREHOUSE_MANAGER = 'WAREHOUSE_MANAGER',
}

export type UserProfile = {
  __typename: 'UserProfile';
  avatar_url: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  last_name: Maybe<Scalars['String']>;
  locale: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type UserProfileConnection = {
  __typename: 'UserProfileConnection';
  edges: Maybe<Array<Maybe<UserProfileEdge>>>;
  pageInfo: PageInfo;
};

export type UserProfileEdge = {
  __typename: 'UserProfileEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<UserProfile>;
};

export enum UserRoleInCompany {
  ADMIN = 'ADMIN',
  REGULAR_USER = 'REGULAR_USER',
}

export type ValidateTelematicsIntegrationConnectionParametersUpdateInput = {
  connectionData?: InputMaybe<Scalars['GraphQLJSON']>;
  telematicsIntegrationId: Scalars['String'];
};

export type ValidateTelematicsIntegrationInput = {
  connection_data?: InputMaybe<Scalars['GraphQLJSON']>;
  telematics_integration_id?: InputMaybe<Scalars['String']>;
  telematics_provider_id: Scalars['String'];
};

export type Vehicle = {
  __typename: 'Vehicle';
  boundingBox: Maybe<Array<PointLocation>>;
  breakHistory: Maybe<Array<VehicleBreakHistory>>;
  breakStatus: Maybe<VehicleBreakStatus>;
  company: CompanyProfile;
  created_at: Maybe<Scalars['DateTime']>;
  history: Maybe<Array<VehicleHistory>>;
  inGlobalPool: Scalars['Boolean'];
  isConstantPositionAccessAllowed: Scalars['Boolean'];
  latestVehicleTemperatures: Array<LatestVehicleTemperature>;
  license_plate_number: Maybe<Scalars['String']>;
  mapTilesTemplateURI: Maybe<Scalars['String']>;
  receivedGrant: Maybe<VehicleGrant>;
  sentGrants: Array<VehicleGrant>;
  sharedBy: Maybe<CompanyProfile>;
  sharedTo: Array<CompanyProfile>;
  status: Maybe<RestrictedVehicleStatus>;
  telematicsIntegrations: Array<TelematicsIntegration>;
  tours: Maybe<Array<Tour>>;
  updated_at: Maybe<Scalars['DateTime']>;
  vehicleMapState: Maybe<VehicleMapState>;
  vehicleTrackers: Array<VehicleTracker>;
  vehicle_id: Scalars['String'];
};

export type VehicleBreakHistoryArgs = {
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

export type VehicleHistoryArgs = {
  end_time: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  simplification_tolerance?: InputMaybe<Scalars['Float']>;
  start_time: Scalars['String'];
};

export type VehicleMapTilesTemplateUriArgs = {
  dwellTime?: InputMaybe<Scalars['Int']>;
  returnTimeBasedStatus?: InputMaybe<Scalars['Boolean']>;
  returnVehicleState?: InputMaybe<Scalars['Boolean']>;
};

export type VehicleToursArgs = {
  fromTime: Scalars['DateTime'];
  status?: InputMaybe<Array<TourStatusFilter>>;
  toTime: Scalars['DateTime'];
};

export type VehicleVehicleMapStateArgs = {
  dwellTimeThresholdMinutes?: InputMaybe<Scalars['Int']>;
};

export type VehicleVehicleTrackersArgs = {
  trackerTypes?: InputMaybe<Array<VehicleTrackerType>>;
};

export type VehicleAllocation = {
  __typename: 'VehicleAllocation';
  startTrackingAt: Maybe<Scalars['DateTime']>;
  vehicleId: Maybe<Scalars['String']>;
  vehicleLicensePlateNumber: Maybe<Scalars['String']>;
};

export type VehicleBreakHistory = {
  __typename: 'VehicleBreakHistory';
  breakId: Scalars['String'];
  center: BreakCenterPosition;
  configurationId: Scalars['String'];
  from: Scalars['DateTime'];
  to: Maybe<Scalars['DateTime']>;
};

export type VehicleBreakHistoryEntry = {
  __typename: 'VehicleBreakHistoryEntry';
  breaks: Array<VehicleBreakHistory>;
  vehicle_id: Scalars['String'];
};

export type VehicleBreakStatus = {
  __typename: 'VehicleBreakStatus';
  from: Scalars['DateTime'];
  to: Maybe<Scalars['DateTime']>;
  vehicleBreakStatusId: Scalars['String'];
};

export type VehicleConnection = {
  __typename: 'VehicleConnection';
  edges: Maybe<Array<Maybe<VehicleEdge>>>;
  pageInfo: PageInfo;
};

export type VehicleCountByStatus = {
  __typename: 'VehicleCountByStatus';
  active: Scalars['Int'];
  inActive: Scalars['Int'];
};

export type VehicleData = {
  __typename: 'VehicleData';
  identificator: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  type: TripTransportMode;
  vehicleId: Scalars['String'];
};

export type VehicleEdge = {
  __typename: 'VehicleEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Vehicle>;
};

export enum VehicleFilterSortKey {
  GRANTED_BY_COMPANY = 'GRANTED_BY_COMPANY',
  LAST_SIGNAL = 'LAST_SIGNAL',
  LICENSE_PLATE = 'LICENSE_PLATE',
  STATUS = 'STATUS',
}

export type VehicleFilterSortOrderInput = {
  direction: SortDirection;
  key: VehicleFilterSortKey;
};

export type VehicleGrant = {
  __typename: 'VehicleGrant';
  grantId: Scalars['String'];
  grantedAt: Scalars['DateTime'];
  grantedByCompany: CompanyProfile;
  grantedByUser: Maybe<UserProfile>;
  grantedToCompany: CompanyProfile;
  permissions: VehiclePermissions;
  vehicle: VehicleProfile;
};

export type VehicleGrantConnection = {
  __typename: 'VehicleGrantConnection';
  edges: Maybe<Array<Maybe<VehicleGrantEdge>>>;
  pageInfo: PageInfo;
};

export type VehicleGrantEdge = {
  __typename: 'VehicleGrantEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<VehicleGrant>;
};

export type VehicleGrantPartner = {
  __typename: 'VehicleGrantPartner';
  company: CompanyProfile;
  grantCount: Scalars['Int'];
};

export type VehicleGrantPartnerGrantCountArgs = {
  permissions?: InputMaybe<VehiclePermissionsInput>;
};

export type VehicleGrantPartnerConnection = {
  __typename: 'VehicleGrantPartnerConnection';
  edges: Maybe<Array<Maybe<VehicleGrantPartnerEdge>>>;
  pageInfo: PageInfo;
};

export type VehicleGrantPartnerEdge = {
  __typename: 'VehicleGrantPartnerEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<VehicleGrantPartner>;
};

export type VehicleGrantVisibilityInput = {
  grantedByCompanyId: Scalars['String'];
  grantedToCompanyId: Scalars['String'];
  isConstantPositionAccessAllowed: Scalars['Boolean'];
  vehicleId: Scalars['String'];
};

export type VehicleHistory = {
  __typename: 'VehicleHistory';
  company: Maybe<CompanyProfile>;
  created_at: Maybe<Scalars['String']>;
  hdg: Maybe<Scalars['Float']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  modeChangesLeft: Maybe<Scalars['Int']>;
  received_at: Maybe<Scalars['String']>;
  source: Maybe<HistorySource>;
  timestamp: Scalars['DateTime'];
  type: Maybe<HistoryType>;
  vehicleTracker: Maybe<VehicleTracker>;
  vehicleType: Maybe<Scalars['String']>;
};

export type VehicleHistoryEntry = {
  __typename: 'VehicleHistoryEntry';
  history: Array<VehicleHistory>;
  vehicle_id: Scalars['String'];
};

export type VehicleHistoryPolyline = {
  __typename: 'VehicleHistoryPolyline';
  metadata: VehicleHistoryPolylineMetadata;
  polyline: Polyline;
};

export type VehicleHistoryPolylineMetadata = {
  __typename: 'VehicleHistoryPolylineMetadata';
  endTime: Scalars['DateTime'];
  isTroubleshootingRange: Scalars['Boolean'];
  startTime: Scalars['DateTime'];
  type: VehicleHistoryPolylineType;
  vehicleId: Scalars['String'];
};

export enum VehicleHistoryPolylineType {
  TELEMETRY = 'TELEMETRY',
  TELEMETRY_GAP = 'TELEMETRY_GAP',
}

export enum VehicleMapState {
  ALLOCATED_BREAK = 'ALLOCATED_BREAK',
  ALLOCATED_MOVING = 'ALLOCATED_MOVING',
  LONG_BREAK = 'LONG_BREAK',
  STALE_GPS = 'STALE_GPS',
  UNALLOCATED_BREAK = 'UNALLOCATED_BREAK',
  UNALLOCATED_MOVING = 'UNALLOCATED_MOVING',
}

export type VehiclePermissions = {
  __typename: 'VehiclePermissions';
  withConstantPositionAccess: Scalars['Boolean'];
};

export type VehiclePermissionsInput = {
  withConstantPositionAccess: Scalars['Boolean'];
};

export type VehicleProfile = {
  __typename: 'VehicleProfile';
  isOutsideOfTransportPool: Maybe<Scalars['Boolean']>;
  license_plate_number: Maybe<Scalars['String']>;
  timeBasedStatus: Maybe<TimeBasedStatus>;
  vehicle_id: Scalars['String'];
};

export type VehicleProfileConnection = {
  __typename: 'VehicleProfileConnection';
  edges: Maybe<Array<Maybe<VehicleProfileEdge>>>;
  pageInfo: PageInfo;
};

export type VehicleProfileEdge = {
  __typename: 'VehicleProfileEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<VehicleProfile>;
};

export enum VehicleSignalSource {
  CARRIER_API = 'CARRIER_API',
  FMS = 'FMS',
  MOBILE = 'MOBILE',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
  TTC = 'TTC',
}

export enum VehicleState {
  ALLOCATED = 'ALLOCATED',
  ON_BREAK = 'ON_BREAK',
  UNALLOCATED = 'UNALLOCATED',
}

export type VehicleStateFilterInput = {
  breakDurationInMinutes?: InputMaybe<Scalars['Int']>;
  vehicleState: VehicleState;
};

export type VehicleStatus = {
  __typename: 'VehicleStatus';
  accuracyInMeters: Maybe<Scalars['Float']>;
  heading: Maybe<Scalars['Float']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  timestamp: Maybe<Scalars['DateTime']>;
  vehicle_id: Scalars['String'];
};

export type VehicleTemperature = {
  __typename: 'VehicleTemperature';
  compartmentName: Scalars['String'];
  sensors: Array<TemperatureSensor>;
};

export enum VehicleTemperatureEventLevel {
  TOUR = 'TOUR',
  VEHICLE = 'VEHICLE',
}

export type VehicleTemperatureSensorReading = {
  __typename: 'VehicleTemperatureSensorReading';
  compartmentDoorOpen: Maybe<Scalars['Boolean']>;
  compartmentId: Scalars['String'];
  sensorId: Scalars['String'];
  temperatureCelsius: Scalars['Float'];
};

export enum VehicleTemperatureSource {
  CARRIER_API = 'CARRIER_API',
  FMS = 'FMS',
  IOT_TRACKER = 'IOT_TRACKER',
  MOBILE = 'MOBILE',
  PLATFORM_API = 'PLATFORM_API',
  PLATFORM_RTV_API = 'PLATFORM_RTV_API',
}

export type VehicleTemperatureVisibilitySource = {
  __typename: 'VehicleTemperatureVisibilitySource';
  eventLevel: VehicleTemperatureEventLevel;
  eventOrigin: Maybe<Scalars['String']>;
  platformId: Maybe<Scalars['String']>;
  primaryEventSource: VehicleTemperatureSource;
  sourceCompanyId: Maybe<Scalars['String']>;
};

export type VehicleTracker = FmsVehicleTracker | MobileVehicleTracker;

export type VehicleTrackerSearchResult = {
  __typename: 'VehicleTrackerSearchResult';
  licensePlate: Scalars['String'];
  remoteId: Scalars['String'];
  telematicsIntegrationId: Scalars['String'];
  vehicleId: Scalars['String'];
  vehicleTrackerId: Scalars['String'];
};

export type VehicleTrackerTour = {
  __typename: 'VehicleTrackerTour';
  shipper: Maybe<CompanyProfile>;
  shipper_transport_number: Maybe<Scalars['String']>;
  startTime: Maybe<Scalars['DateTime']>;
  status: Maybe<Scalars['String']>;
  stops: Array<VehicleTrackerTourStop>;
  tour_id: Scalars['Int'];
};

export type VehicleTrackerTourConnection = {
  __typename: 'VehicleTrackerTourConnection';
  edges: Maybe<Array<Maybe<VehicleTrackerTourEdge>>>;
  pageInfo: PageInfo;
};

export type VehicleTrackerTourEdge = {
  __typename: 'VehicleTrackerTourEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<VehicleTrackerTour>;
};

export type VehicleTrackerTourStop = {
  __typename: 'VehicleTrackerTourStop';
  actual_arrival_time: Maybe<Scalars['DateTime']>;
  arrival_delay_in_seconds: Maybe<Scalars['Int']>;
  /** @deprecated Use actual_arrival_time instead */
  arrival_time: Maybe<Scalars['DateTime']>;
  departure_time: Maybe<Scalars['DateTime']>;
  estimated_arrival_time: Maybe<Scalars['DateTime']>;
  location: Maybe<TourLocation>;
  sequence_number: Scalars['Int'];
  status: Maybe<Scalars['String']>;
  stop_id: Scalars['Int'];
  timeslot: Maybe<Timeslot>;
  type: Maybe<Scalars['String']>;
};

export enum VehicleTrackerType {
  FMS_DEVICE = 'FMS_DEVICE',
  MOBILE_APP = 'MOBILE_APP',
}

export enum VehicleType {
  BARGE = 'BARGE',
  RAIL = 'RAIL',
  TERMINAL = 'TERMINAL',
  TRACTOR = 'TRACTOR',
  TRAILER = 'TRAILER',
  UNKNOWN = 'UNKNOWN',
  VESSEL = 'VESSEL',
}

export type VesselNameFilterInput = {
  current?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fromPol?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  toPod?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type VesselNameFilterOutput = {
  __typename: 'VesselNameFilterOutput';
  current: Maybe<Array<Maybe<Scalars['String']>>>;
  fromPol: Maybe<Array<Maybe<Scalars['String']>>>;
  toPod: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VisibilityIssue = {
  __typename: 'VisibilityIssue';
  index: Scalars['Float'];
  type: VisibilityIssueType;
};

export enum VisibilityIssueType {
  EARLY_LOADING_VISIT = 'EARLY_LOADING_VISIT',
  INFREQUENT_TELEMETRY = 'INFREQUENT_TELEMETRY',
  LATE_ALLOCATION = 'LATE_ALLOCATION',
  LATE_TELEMETRY = 'LATE_TELEMETRY',
  MISSING_TELEMETRY = 'MISSING_TELEMETRY',
  NO_VEHICLE_ALLOCATED = 'NO_VEHICLE_ALLOCATED',
  UNVISITED_STOPS = 'UNVISITED_STOPS',
  VEHICLE_NOT_INTEGRATED = 'VEHICLE_NOT_INTEGRATED',
}

export type VisibilitySegment = {
  __typename: 'VisibilitySegment';
  vehicleId: Scalars['String'];
  visibilityEndDate: Scalars['DateTime'];
  visibilityStartDate: Scalars['DateTime'];
};

export enum VisibilityService {
  VISIBILITY_AIR = 'VISIBILITY_AIR',
  VISIBILITY_CARBON = 'VISIBILITY_CARBON',
  VISIBILITY_OCEAN = 'VISIBILITY_OCEAN',
  VISIBILITY_RAIL = 'VISIBILITY_RAIL',
  VISIBILITY_ROAD = 'VISIBILITY_ROAD',
}

export enum VolumeUnit {
  CUBIC_FEET = 'CUBIC_FEET',
  CUBIC_METERS = 'CUBIC_METERS',
  GALLONS = 'GALLONS',
  LITERS = 'LITERS',
  OUNCES = 'OUNCES',
}

export enum WeightUnit {
  GRAMS = 'GRAMS',
  KILOGRAMS = 'KILOGRAMS',
  METRIC_TONS = 'METRIC_TONS',
  OUNCES = 'OUNCES',
  POUNDS = 'POUNDS',
  SHORT_TONS = 'SHORT_TONS',
}

export type Widget = {
  __typename: 'Widget';
  dashboardId: Scalars['String'];
  filters: Array<WidgetFilter>;
  name: Maybe<Scalars['String']>;
  type: WidgetType;
  widgetId: Scalars['String'];
  widgetResult: Maybe<Scalars['String']>;
};

export type WidgetConnection = {
  __typename: 'WidgetConnection';
  edges: Maybe<Array<Maybe<WidgetEdge>>>;
  pageInfo: PageInfo;
};

export type WidgetEdge = {
  __typename: 'WidgetEdge';
  cursor: Maybe<Scalars['String']>;
  node: Maybe<Widget>;
};

export type WidgetFilter = {
  __typename: 'WidgetFilter';
  filter: TourFilter;
  tourCount: Scalars['Int'];
};

export type WidgetFilterInput = {
  filter: TourFilterInput;
};

export type WidgetPreview = {
  __typename: 'WidgetPreview';
  filters: Array<WidgetFilter>;
  widgetResult: Maybe<Scalars['String']>;
};

export enum WidgetType {
  COUNT = 'COUNT',
  RATIO = 'RATIO',
}

export type WithdrawCompanyInvitationInput = {
  companyInvitationId: Scalars['String'];
};

export type WithdrawCompanyInvitationResult = {
  __typename: 'WithdrawCompanyInvitationResult';
  success: Scalars['Boolean'];
};

export enum WorkingStopStatusFilter {
  AT_LOADING = 'AT_LOADING',
  AT_UNLOADING = 'AT_UNLOADING',
  TOWARDS_LOADING = 'TOWARDS_LOADING',
  TOWARDS_UNLOADING = 'TOWARDS_UNLOADING',
}

export type ViewerCompanyQueryVariables = Exact<{
  company_id: Scalars['String'];
}>;

export type ViewerCompanyQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              company_name: string | null;
              is_carrier: boolean | null;
              is_shipper: boolean | null;
              arePlacesEnabled: boolean | null;
              defaultVehicleDwellTimeThresholdMinutes: number | null;
              shouldSeeUpcomingTransportsTab: boolean;
              isTrimbleVisibilityCustomer: boolean;
              onboardingStatus: UnifiedCompanyOnboardingStatus | null;
              isUIDisabled: boolean;
              isOceanUIEnabled: boolean;
              isAirUIEnabled: boolean;
              isInvitationFlowDisabled: boolean;
              me:
                | ({
                    role: UserRoleInCompany;
                    profile: {
                      user_id: string;
                      first_name: string | null;
                      last_name: string | null;
                      email: string | null;
                    } & { __typename: 'UserProfile' };
                    transporeonPlatform: ({ userIdOnPlatform: string } & { __typename: 'UserOnPlatform' }) | null;
                    configuration: { vehicleDwellTimeThresholdMinutes: number } & {
                      __typename: 'UserInCompanyConfiguration';
                    };
                  } & { __typename: 'UserInCompany' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type ViewerCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerCompaniesQuery = {
  viewer:
    | ({
        user_id: string;
        companies: Array<
          {
            company_id: string;
            company_name: string | null;
            is_carrier: boolean | null;
            is_shipper: boolean | null;
            isTrimbleVisibilityCustomer: boolean;
            onboardingStatus: UnifiedCompanyOnboardingStatus | null;
          } & { __typename: 'Company' }
        >;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanyUsersQueryVariables = Exact<{
  company_id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type CompanyUsersQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              is_shipper: boolean | null;
              isPresentOnTransporeonPlatform: boolean | null;
              users: {
                count: number;
                users: {
                  edges: Array<
                    | ({
                        node:
                          | ({
                              role: UserRoleInCompany;
                              profile: {
                                user_id: string;
                                first_name: string | null;
                                last_name: string | null;
                                email: string | null;
                              } & { __typename: 'UserProfile' };
                            } & { __typename: 'UserInCompanyProfile' })
                          | null;
                      } & { __typename: 'UserInCompanyProfileEdge' })
                    | null
                  > | null;
                  pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                } & { __typename: 'UserInCompanyProfileConnection' };
              } & { __typename: 'CountedUserInCompanyProfileConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type RemoveUserFromCompanyMutationVariables = Exact<{
  input: RemoveUserFromCompanyInput;
}>;

export type RemoveUserFromCompanyMutation = { removeUserFromCompany: boolean | null } & { __typename: 'Mutation' };

export type ChangeUserRoleInCompanyMutationVariables = Exact<{
  input: ChangeUserRoleInCompanyInput;
}>;

export type ChangeUserRoleInCompanyMutation = {
  changeUserRoleInCompany:
    | ({
        role: UserRoleInCompany;
        profile: { user_id: string; first_name: string | null; last_name: string | null; email: string | null } & {
          __typename: 'UserProfile';
        };
      } & { __typename: 'UserInCompanyProfile' })
    | null;
} & { __typename: 'Mutation' };

export type AddUserToCompanyMutationVariables = Exact<{
  input: AddUserToCompanyInput;
}>;

export type AddUserToCompanyMutation = {
  addUserToCompany:
    | ({
        role: UserRoleInCompany;
        profile: { user_id: string; first_name: string | null; last_name: string | null; email: string | null } & {
          __typename: 'UserProfile';
        };
      } & { __typename: 'UserInCompanyProfile' })
    | null;
} & { __typename: 'Mutation' };

export type IsPresentOnTransporeonPlatformQueryVariables = Exact<{
  company_id: Scalars['String'];
}>;

export type IsPresentOnTransporeonPlatformQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({ company_id: string; isPresentOnTransporeonPlatform: boolean | null } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type UpdateUserInCompanyConfigurationMutationVariables = Exact<{
  input: UpdateUserInCompanyConfigurationInput;
}>;

export type UpdateUserInCompanyConfigurationMutation = {
  updateUserInCompanyConfiguration:
    | ({
        role: UserRoleInCompany;
        profile: { user_id: string; first_name: string | null; last_name: string | null; email: string | null } & {
          __typename: 'UserProfile';
        };
        configuration: { vehicleDwellTimeThresholdMinutes: number } & { __typename: 'UserInCompanyConfiguration' };
      } & { __typename: 'UserInCompany' })
    | null;
} & { __typename: 'Mutation' };

export type TourStopDetailsFragment = {
  tourStatus: string | null;
  latestSignalTimestamp: string | null;
  vehicleLicensePlateNumber: string | null;
  stop: {
    stop_id: number;
    status: string | null;
    type: string | null;
    externalEtaSource: ExternalEtaSource | null;
    estimated_arrival_status: string | null;
    estimated_arrival_accuracy: string | null;
    estimated_arrival: string | null;
    estimated_arrival_distance_in_meters: number | null;
    estimated_arrival_delay_in_seconds: number | null;
    arrival_delay_in_seconds: number | null;
    arrival_time: string | null;
    departure_time: string | null;
    deadline: string | null;
    display_sequence_number: number;
    location:
      | ({
          name: string | null;
          location_id: string | null;
          bookingLocationName: string | null;
          gate: string | null;
          gate_id: string | null;
          address:
            | ({ full_address: string | null; city: string | null; country: string | null } & { __typename: 'Address' })
            | null;
          position: ({ lat: number | null; lng: number | null } & { __typename: 'GeoPosition' }) | null;
        } & { __typename: 'TourLocation' })
      | null;
    deliveries: Array<
      {
        deliveryId: string;
        deliveryNumber: string | null;
        orderNumber: string | null;
        purchaseOrderNumber: string | null;
      } & { __typename: 'TourDelivery' }
    >;
    timeslot:
      | ({ begin: string | null; end: string | null; timezone: string | null } & { __typename: 'Timeslot' })
      | null;
  } & { __typename: 'TourStop' };
  vehicleStatus:
    | ({
        latitude: number | null;
        longitude: number | null;
        heading: number | null;
        timestamp: string | null;
        accuracyInMeters: number | null;
      } & { __typename: 'VehicleStatus' })
    | null;
  shipper: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
} & { __typename: 'SharedTourStop' };

export type TokenResourcesQueryVariables = Exact<{ [key: string]: never }>;

export type TokenResourcesQuery = {
  token:
    | ({
        tourStop:
          | ({
              stop: {
                deliveries: Array<
                  {
                    orderItems: Array<
                      {
                        orderItemId: string;
                        orderItemName: string | null;
                        pieceCount: number | null;
                        pieceDescription: string | null;
                        pieceUnit: PieceUnit | null;
                        volume: number | null;
                        volumeUnit: VolumeUnit | null;
                        weightAmount: number | null;
                        weightUnit: WeightUnit | null;
                        isHazardous: boolean | null;
                        itemHeight: number | null;
                        itemLength: number | null;
                        itemWidth: number | null;
                        linearUnit: LinearUnit | null;
                        orderItemCustomFields: Array<
                          { name: string; value: string } & { __typename: 'OrderItemCustomField' }
                        > | null;
                      } & { __typename: 'OrderItem' }
                    > | null;
                  } & { __typename: 'TourDelivery' }
                >;
                documents: Array<
                  {
                    documentId: string;
                    stopId: string | null;
                    documentType: DocumentType;
                    documentUrl: string;
                    description: string | null;
                    createdAt: string;
                    associatedDeliveries: Array<
                      { deliveryId: string; deliveryNumber: string | null } & { __typename: 'TourDelivery' }
                    >;
                  } & { __typename: 'Document' }
                >;
                notes: Array<
                  {
                    noteId: string;
                    stopId: string | null;
                    noteText: string;
                    noteVisibility: NoteVisibility;
                    noteOrigin: NoteOrigin;
                    createdBy: NoteCreatedBy;
                    createdAt: string;
                    updatedAt: string;
                    associatedDeliveries: Array<
                      { deliveryId: string; deliveryNumber: string | null } & { __typename: 'TourDelivery' }
                    >;
                  } & { __typename: 'Note' }
                >;
                exceptions: Array<
                  {
                    description: string;
                    stopId: string | null;
                    deliveryIds: Array<string>;
                    exceptionId: string;
                    exceptionType: ExceptionType | null;
                    customExceptionType: string | null;
                    customExceptionTypeDescription: string | null;
                    createdAt: string;
                    exceptionFiles: Array<
                      { documentUrl: string; platformDocumentId: string; description: string | null } & {
                        __typename: 'ExceptionFile';
                      }
                    >;
                  } & { __typename: 'TourException' }
                >;
              } & { __typename: 'TourStop' };
            } & TourStopDetailsFragment & { __typename: 'SharedTourStop' })
          | null;
        tourDelivery:
          | ({ tourStop: (TourStopDetailsFragment & { __typename: 'SharedTourStop' }) | null } & {
              __typename: 'SharedTourDelivery';
            })
          | null;
        branding:
          | ({
              background: ({ url: string } & { __typename: 'TokenBrandingAsset' }) | null;
              logo: ({ url: string } & { __typename: 'TokenBrandingAsset' }) | null;
            } & { __typename: 'TokenBrandingAssets' })
          | null;
      } & { __typename: 'TokenResources' })
    | null;
} & { __typename: 'Query' };

export type TrackTokenViewMutationVariables = Exact<{ [key: string]: never }>;

export type TrackTokenViewMutation = { trackTokenView: boolean } & { __typename: 'Mutation' };

export type ResourceTypeFromTokenQueryVariables = Exact<{ [key: string]: never }>;

export type ResourceTypeFromTokenQuery = {
  token: ({ resourceType: TokenResourceType } & { __typename: 'TokenResources' }) | null;
} & { __typename: 'Query' };

export type SaveCustomerFeedbackMutationVariables = Exact<{
  input: SaveCustomerFeedbackInput;
}>;

export type SaveCustomerFeedbackMutation = { saveCustomerFeedback: boolean | null } & { __typename: 'Mutation' };

export type CustomerPortalBrandingFragment = {
  branding:
    | ({
        background: ({ url: string } & { __typename: 'TokenBrandingAsset' }) | null;
        logo: ({ url: string } & { __typename: 'TokenBrandingAsset' }) | null;
      } & { __typename: 'TokenBrandingAssets' })
    | null;
} & { __typename: 'TokenResources' };

export type CustomerPortalTourDetailsFragment = {
  tour_id: number;
  shipper_transport_number: string | null;
  transportIdentifiers: Array<string> | null;
  status: string | null;
  working_stop_id: number | null;
  start_time: string | null;
  visibility_start_time: string | null;
  visibleTrackingStartTime: string | null;
  visibility_end_time: string | null;
  hasTemperatureData: boolean | null;
  transport_id: string | null;
  platform_shipper_id: string | null;
  platform_carrier_id: string | null;
  allocationType: TourVehicleAllocationType | null;
  canBeModified: boolean;
  isGlobalAllocation: boolean;
  isGloballyAllocatedToViewerCompany: boolean;
  warnings: Array<
    {
      type: TourWarningType;
      severity: TourWarningSeverity | null;
      metadata: { [key: string]: string | number | boolean } | null;
    } & { __typename: 'TourWarning' }
  >;
  carrier: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
  shipper: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
  subcontractor: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
  vehicleHistory: Array<
    {
      polyline: { points: string } & { __typename: 'Polyline' };
      metadata: {
        isTroubleshootingRange: boolean;
        type: VehicleHistoryPolylineType;
        startTime: string;
        endTime: string;
      } & { __typename: 'VehicleHistoryPolylineMetadata' };
    } & { __typename: 'VehicleHistoryPolyline' }
  > | null;
  vehicle_status:
    | ({
        timestamp: string | null;
        latitude: number | null;
        longitude: number | null;
        heading: number | null;
        accuracyInMeters: number | null;
      } & { __typename: 'VehicleStatus' })
    | null;
  failureReason: ({ type: TourFailureReasonType } & { __typename: 'TourFailureReason' }) | null;
  currentVehicle:
    | ({ vehicleId: string | null; vehicleLicensePlateNumber: string | null } & { __typename: 'VehicleAllocation' })
    | null;
  vehicleAllocations: Array<
    { vehicleId: string | null; vehicleLicensePlateNumber: string | null } & { __typename: 'TourVehicleAllocation' }
  >;
  activeVehicleAllocation:
    | ({
        vehicleId: string | null;
        vehicleLicensePlateNumber: string | null;
        vehicleTrackerTypes: Array<VehicleTrackerType> | null;
        allocationDeviceType: TourAllocationDeviceType | null;
        remoteTrackerId: string | null;
      } & { __typename: 'TourVehicleAllocation' })
    | null;
  visibleVehicleBreakHistory: Array<
    {
      vehicle_id: string;
      breaks: Array<
        {
          breakId: string;
          from: string;
          to: string | null;
          center: { lat: number; lng: number } & { __typename: 'BreakCenterPosition' };
        } & { __typename: 'VehicleBreakHistory' }
      >;
    } & { __typename: 'VehicleBreakHistoryEntry' }
  > | null;
  route:
    | ({ legs: Array<{ polyline: { points: string } & { __typename: 'Polyline' } } & { __typename: 'RouteLeg' }> } & {
        __typename: 'Route';
      })
    | null;
  latestSignal: ({ source: TourSignalSource; timestamp: string } & { __typename: 'TourSignal' }) | null;
  customFields: Array<
    { fieldName: string; values: Array<{ value: string } & { __typename: 'CustomFieldValue' }> } & {
      __typename: 'CustomField';
    }
  >;
  stops: Array<
    {
      stop_id: number;
      status: string | null;
      type: string | null;
      display_sequence_number: number;
      externalEtaSource: ExternalEtaSource | null;
      estimated_arrival: string | null;
      estimated_arrival_status: string | null;
      estimated_arrival_accuracy: string | null;
      estimated_arrival_delay_in_seconds: number | null;
      estimated_arrival_distance_in_meters: number | null;
      arrival_delay_in_seconds: number | null;
      arrival_time: string | null;
      departure_time: string | null;
      deadline: string | null;
      geofenceZones?: Array<
        {
          zone: GeofenceZoneType;
          geofence:
            | ({
                type: GeofenceType;
                radiusInMeters: number;
                origin: { lat: number; lng: number } & { __typename: 'Position' };
              } & { __typename: 'GeofenceCircle' })
            | ({ type: GeofenceType; points: Array<{ lat: number; lng: number } & { __typename: 'Position' }> } & {
                __typename: 'GeofencePolygon';
              });
        } & { __typename: 'GeofenceZone' }
      >;
      vehicleAllocation:
        | ({ vehicleId: string | null; vehicleLicensePlateNumber: string | null } & { __typename: 'VehicleAllocation' })
        | null;
      timeslot:
        | ({ begin: string | null; end: string | null; timezone: string | null } & { __typename: 'Timeslot' })
        | null;
      deliveries: Array<
        {
          deliveryId: string;
          deliveryNumber: string | null;
          orderNumber: string | null;
          purchaseOrderNumber: string | null;
          orderDate: string | null;
          orderItems: Array<
            {
              orderItemId: string;
              orderItemName: string | null;
              pieceCount: number | null;
              pieceDescription: string | null;
              pieceUnit: PieceUnit | null;
              volume: number | null;
              volumeUnit: VolumeUnit | null;
              weightAmount: number | null;
              weightUnit: WeightUnit | null;
              isHazardous: boolean | null;
              itemHeight: number | null;
              itemLength: number | null;
              itemWidth: number | null;
              linearUnit: LinearUnit | null;
              orderItemCustomFields: Array<
                { name: string; value: string } & { __typename: 'OrderItemCustomField' }
              > | null;
            } & { __typename: 'OrderItem' }
          > | null;
        } & { __typename: 'TourDelivery' }
      >;
      location:
        | ({
            location_id: string | null;
            name: string | null;
            bookingLocationName: string | null;
            gate: string | null;
            gate_id: string | null;
            address:
              | ({
                  full_address: string | null;
                  city: string | null;
                  country: string | null;
                  street_address: string | null;
                  state: string | null;
                  zipcode: string | null;
                  shortAreaCode: string | null;
                } & { __typename: 'Address' })
              | null;
            position:
              | ({ lat: number | null; lng: number | null; provider: string | null } & { __typename: 'GeoPosition' })
              | null;
          } & { __typename: 'TourLocation' })
        | null;
    } & { __typename: 'TourStop' }
  >;
  events: Array<
    | ({
        stop_id: number;
        tour_id: number;
        event_id: number;
        vehicle_id: string | null;
        vehicle_lat: number | null;
        vehicle_lng: number | null;
        vehicle_license_plate_number: string | null;
        event_index: number;
        event_time: string | null;
        created_at: string;
        stop_event_name: StopEventName;
        visibilitySource:
          | ({
              primaryEventSource: TourVisibilitySourceType;
              sourcePlatform:
                | ({ platform_id: string; platform_name: string } & { __typename: 'PlatformProfile' })
                | null;
            } & { __typename: 'TourVisibilitySource' })
          | null;
      } & { __typename: 'StopEvent' })
    | ({
        tour_id: number;
        event_id: number;
        vehicle_id: string | null;
        vehicle_lat: number | null;
        vehicle_lng: number | null;
        event_index: number;
        event_time: string | null;
        created_at: string;
        tour_event_name: TourEventName;
        warning:
          | ({ type: TourWarningType; severity: TourWarningSeverity | null } & { __typename: 'TourWarning' })
          | null;
        visibilitySource:
          | ({
              primaryEventSource: TourVisibilitySourceType;
              sourcePlatform:
                | ({ platform_id: string; platform_name: string } & { __typename: 'PlatformProfile' })
                | null;
            } & { __typename: 'TourVisibilitySource' })
          | null;
      } & { __typename: 'TourEvent' })
  >;
  documents: Array<
    {
      documentId: string;
      stopId: string | null;
      documentType: DocumentType;
      documentUrl: string;
      description: string | null;
      createdAt: string;
      associatedDeliveries: Array<
        { deliveryId: string; deliveryNumber: string | null } & { __typename: 'TourDelivery' }
      >;
    } & { __typename: 'Document' }
  >;
  notes: Array<
    {
      noteId: string;
      stopId: string | null;
      noteText: string;
      noteVisibility: NoteVisibility;
      noteOrigin: NoteOrigin;
      createdBy: NoteCreatedBy;
      createdAt: string;
      updatedAt: string;
      associatedDeliveries: Array<
        { deliveryId: string; deliveryNumber: string | null } & { __typename: 'TourDelivery' }
      >;
    } & { __typename: 'Note' }
  >;
  exceptions: Array<
    {
      description: string;
      stopId: string | null;
      deliveryIds: Array<string>;
      exceptionId: string;
      exceptionType: ExceptionType | null;
      customExceptionType: string | null;
      customExceptionTypeDescription: string | null;
      createdAt: string;
      exceptionFiles: Array<
        { documentUrl: string; platformDocumentId: string; description: string | null } & {
          __typename: 'ExceptionFile';
        }
      >;
    } & { __typename: 'TourException' }
  >;
} & { __typename: 'Tour' };

export type ToursFromTokenQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  textSearch?: InputMaybe<TourTextSearch>;
}>;

export type ToursFromTokenQuery = {
  token:
    | ({
        companyTourFilter:
          | ({
              owner: ({ company_name: string | null; company_id: string } & { __typename: 'CompanyProfile' }) | null;
              tours: {
                count: number;
                tours: {
                  edges: Array<
                    ({ node: (ShipmentFragment & { __typename: 'Tour' }) | null } & { __typename: 'TourEdge' }) | null
                  > | null;
                  pageInfo: { hasNextPage: boolean; startCursor: string | null; endCursor: string | null } & {
                    __typename: 'PageInfo';
                  };
                } & { __typename: 'TourConnection' };
              } & { __typename: 'CountedTourConnection' };
            } & { __typename: 'SharedCompanyTourFilter' })
          | null;
      } & CustomerPortalBrandingFragment & { __typename: 'TokenResources' })
    | null;
} & { __typename: 'Query' };

export type TourFromTokenQueryVariables = Exact<{
  tourId: Scalars['String'];
  skipGeofenceZones?: InputMaybe<Scalars['Boolean']>;
}>;

export type TourFromTokenQuery = {
  token:
    | ({
        companyTourFilter:
          | ({
              owner: ({ company_name: string | null; company_id: string } & { __typename: 'CompanyProfile' }) | null;
              tour:
                | ({
                    vehicleTemperatures: Array<
                      {
                        compartmentName: string;
                        sensors: Array<
                          {
                            name: string;
                            readings: Array<
                              { timestamp: string; value: number } & { __typename: 'TemperatureSensorReading' }
                            >;
                          } & { __typename: 'TemperatureSensor' }
                        >;
                      } & { __typename: 'VehicleTemperature' }
                    > | null;
                  } & CustomerPortalTourDetailsFragment & { __typename: 'Tour' })
                | null;
            } & { __typename: 'SharedCompanyTourFilter' })
          | null;
      } & CustomerPortalBrandingFragment & { __typename: 'TokenResources' })
    | null;
} & { __typename: 'Query' };

export type TrackCustomerPortalViewMutationVariables = Exact<{ [key: string]: never }>;

export type TrackCustomerPortalViewMutation = { trackTokenView: boolean } & { __typename: 'Mutation' };

export type CreatePlatformSupportTicketMutationVariables = Exact<{
  input: CreatePlatformSupportTicketInput;
}>;

export type CreatePlatformSupportTicketMutation = { createPlatformSupportTicket: boolean | null } & {
  __typename: 'Mutation';
};

export type CreateVisibilitySolutionsTicketMutationVariables = Exact<{
  input: CreateVisibilitySolutionsTicketInput;
}>;

export type CreateVisibilitySolutionsTicketMutation = { createVisibilitySolutionsTicket: boolean | null } & {
  __typename: 'Mutation';
};

export type DashboardsQueryVariables = Exact<{
  companyId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  queryType?: InputMaybe<DashboardQueryType>;
}>;

export type DashboardsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              me:
                | ({
                    dashboardsConnection: {
                      dashboards: {
                        edges: Array<
                          | ({
                              node:
                                | ({ dashboardId: string; name: string; isOwnedByMe: boolean } & {
                                    __typename: 'Dashboard';
                                  })
                                | null;
                            } & { __typename: 'DashboardEdge' })
                          | null
                        > | null;
                        pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                      } & { __typename: 'DashboardConnection' };
                    } & { __typename: 'CountedDashboardsConnection' };
                  } & { __typename: 'UserInCompany' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type DashboardsCountQueryVariables = Exact<{
  companyId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
}>;

export type DashboardsCountQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              me:
                | ({
                    createdByMe: { count: number } & { __typename: 'CountedDashboardsConnection' };
                    sharedWithMe: { count: number } & { __typename: 'CountedDashboardsConnection' };
                  } & { __typename: 'UserInCompany' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type DashboardsFirstIdQueryVariables = Exact<{
  company_id: Scalars['String'];
}>;

export type DashboardsFirstIdQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              me:
                | ({
                    dashboardsConnection: {
                      count: number;
                      dashboards: {
                        edges: Array<
                          | ({ node: ({ dashboardId: string } & { __typename: 'Dashboard' }) | null } & {
                              __typename: 'DashboardEdge';
                            })
                          | null
                        > | null;
                      } & { __typename: 'DashboardConnection' };
                    } & { __typename: 'CountedDashboardsConnection' };
                  } & { __typename: 'UserInCompany' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type DashboardFragment = {
  dashboardId: string;
  name: string;
  description: string | null;
  sharingType: DashboardSharingType;
  isOwnedByMe: boolean;
  canUserEdit: boolean;
  isExample: boolean;
  sharedWithUsers: Array<
    { user_id: string; email: string | null; first_name: string | null; last_name: string | null } & {
      __typename: 'UserProfile';
    }
  > | null;
  owner: { user_id: string; email: string | null; first_name: string | null; last_name: string | null } & {
    __typename: 'UserProfile';
  };
} & { __typename: 'Dashboard' };

export type DashboardQueryVariables = Exact<{
  company_id: Scalars['String'];
  dashboardId: Scalars['String'];
}>;

export type DashboardQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              me:
                | ({ dashboard: (DashboardFragment & { __typename: 'Dashboard' }) | null } & {
                    __typename: 'UserInCompany';
                  })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CreateDashboardMutationVariables = Exact<{
  input: CreateDashboardInput;
}>;

export type CreateDashboardMutation = { createDashboard: (DashboardFragment & { __typename: 'Dashboard' }) | null } & {
  __typename: 'Mutation';
};

export type CreateExampleDashboardMutationVariables = Exact<{
  input: CreateExampleDashboardInput;
}>;

export type CreateExampleDashboardMutation = {
  createExampleDashboard: (DashboardFragment & { __typename: 'Dashboard' }) | null;
} & { __typename: 'Mutation' };

export type UpdateDashboardMutationVariables = Exact<{
  input: UpdateDashboardInput;
}>;

export type UpdateDashboardMutation = { updateDashboard: (DashboardFragment & { __typename: 'Dashboard' }) | null } & {
  __typename: 'Mutation';
};

export type DeleteDashboardMutationVariables = Exact<{
  dashboardId: Scalars['String'];
}>;

export type DeleteDashboardMutation = { deleteDashboard: boolean | null } & { __typename: 'Mutation' };

export type CopyDashboardMutationVariables = Exact<{
  input: CopyDashboardInput;
}>;

export type CopyDashboardMutation = { copyDashboard: (DashboardFragment & { __typename: 'Dashboard' }) | null } & {
  __typename: 'Mutation';
};

export type CompanyQueryVariables = Exact<{
  company_id: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type CompanyQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              users: {
                users: {
                  edges: Array<
                    | ({
                        node:
                          | ({
                              profile: {
                                user_id: string;
                                email: string | null;
                                first_name: string | null;
                                last_name: string | null;
                              } & { __typename: 'UserProfile' };
                            } & { __typename: 'UserInCompanyProfile' })
                          | null;
                      } & { __typename: 'UserInCompanyProfileEdge' })
                    | null
                  > | null;
                  pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                } & { __typename: 'UserInCompanyProfileConnection' };
              } & { __typename: 'CountedUserInCompanyProfileConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type WidgetFragment = {
  widgetId: string;
  dashboardId: string;
  name: string | null;
  type: WidgetType;
  widgetResult: string | null;
  filters: Array<
    { tourCount: number; filter: FilterFragment & { __typename: 'TourFilter' } } & { __typename: 'WidgetFilter' }
  >;
} & { __typename: 'Widget' };

export type WidgetsQueryVariables = Exact<{
  company_id: Scalars['String'];
  dashboardId: Scalars['String'];
  timezone?: InputMaybe<Scalars['IANATimezone']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type WidgetsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              me:
                | ({
                    dashboard:
                      | ({
                          dashboardId: string;
                          widgets: {
                            count: number;
                            widgets: {
                              edges: Array<
                                | ({ node: (WidgetFragment & { __typename: 'Widget' }) | null } & {
                                    __typename: 'WidgetEdge';
                                  })
                                | null
                              > | null;
                              pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                            } & { __typename: 'WidgetConnection' };
                          } & { __typename: 'CountedWidgetsConnection' };
                        } & { __typename: 'Dashboard' })
                      | null;
                  } & { __typename: 'UserInCompany' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type WidgetsPreviewQueryVariables = Exact<{
  company_id: Scalars['String'];
  filters: Array<WidgetFilterInput> | WidgetFilterInput;
  type: WidgetType;
  operator?: InputMaybe<OperatorType>;
}>;

export type WidgetsPreviewQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              widgetPreview:
                | ({
                    widgetResult: string | null;
                    filters: Array<
                      { tourCount: number; filter: FilterFragment & { __typename: 'TourFilter' } } & {
                        __typename: 'WidgetFilter';
                      }
                    >;
                  } & { __typename: 'WidgetPreview' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CreateWidgetMutationVariables = Exact<{
  input: CreateWidgetInput;
}>;

export type CreateWidgetMutation = { createWidget: (WidgetFragment & { __typename: 'Widget' }) | null } & {
  __typename: 'Mutation';
};

export type UpdateWidgetMutationVariables = Exact<{
  input: UpdateWidgetInput;
}>;

export type UpdateWidgetMutation = { updateWidget: (WidgetFragment & { __typename: 'Widget' }) | null } & {
  __typename: 'Mutation';
};

export type DeleteWidgetMutationVariables = Exact<{
  input: DeleteWidgetInput;
}>;

export type DeleteWidgetMutation = { deleteWidget: boolean | null } & { __typename: 'Mutation' };

export type CreateCompanyDataNetworkFilterMutationVariables = Exact<{
  companyFilterInput: CreateCompanyTransportNetworkFilterInput;
}>;

export type CreateCompanyDataNetworkFilterMutation = {
  createCompanyTransportNetworkFilter:
    | ({ companyTransportNetworkFilterId: string; name: string } & { __typename: 'CompanyTransportNetworkFilter' })
    | null;
} & { __typename: 'Mutation' };

export type DeleteCompanyDataNetworkFilterMutationVariables = Exact<{
  companyTransportNetworkFilterId: Scalars['String'];
}>;

export type DeleteCompanyDataNetworkFilterMutation = { deleteCompanyTransportNetworkFilter: boolean | null } & {
  __typename: 'Mutation';
};

export type InvitedCompanyQueryVariables = Exact<{
  companyId: Scalars['String'];
  invitationId: Scalars['String'];
}>;

export type InvitedCompanyQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              companyInvitation:
                | ({
                    companyInviteId: string;
                    companyName: string | null;
                    status: CompanyInviteStatus;
                    recipients: Array<{ email: string; locale: string } & { __typename: 'CompanyInvitationRecipient' }>;
                    historyLog: Array<
                      {
                        type: InvitationHistoryLogItemType | null;
                        time: string;
                        performedByEmail: string | null;
                        inviteSentByLabel: string | null;
                        recipients: Array<
                          { email: string; locale: string } & { __typename: 'InvitationHistoryItemRecipient' }
                        > | null;
                      } & { __typename: 'InvitationHistoryItem' }
                    >;
                    identifiers: Array<
                      { companyIdentifierId: string; identifierValue: string } & { __typename: 'CompanyIdentifier' }
                    > | null;
                  } & { __typename: 'CompanyInvite' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type ProvidingVisibilityCompaniesQueryVariables = Exact<{
  companyId: Scalars['String'];
  filter: ProvidingVisibilityCompaniesFilterInput;
  sort?: InputMaybe<ProvidingVisibilityCompaniesSortOrderInput>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type ProvidingVisibilityCompaniesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              providingVisibilityCompanies:
                | ({
                    count: number;
                    companies: {
                      edges: Array<
                        | ({
                            node:
                              | ({
                                  companyId: string;
                                  companyName: string | null;
                                  dateAdded: string;
                                  tourStatusConsent: ConsentState;
                                  companyIdentifiers: Array<
                                    {
                                      companyIdentifierId: string;
                                      identifierType: CompanyIdentifierType;
                                      identifierValue: string;
                                    } & { __typename: 'CompanyIdentifier' }
                                  >;
                                } & { __typename: 'ProvidingVisibilityCompany' })
                              | null;
                          } & { __typename: 'ProvidingVisibilityCompanyEdge' })
                        | null
                      > | null;
                      pageInfo: { hasNextPage: boolean; startCursor: string | null; endCursor: string | null } & {
                        __typename: 'PageInfo';
                      };
                    } & { __typename: 'ProvidingVisibilityCompanyConnection' };
                  } & { __typename: 'CountedProvidingVisibilityCompaniesConnection' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type ProvidingVisibilityCompanyQueryVariables = Exact<{
  companyId: Scalars['String'];
  customerId: Scalars['String'];
}>;

export type ProvidingVisibilityCompanyQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              canChangeTourStatusConsent: boolean;
              providingVisibilityCompany:
                | ({
                    companyId: string;
                    companyName: string | null;
                    dateAdded: string;
                    tourStatusConsent: ConsentState;
                    transporeonId: string | null;
                    vatNumbers: Array<{ vatNumber: string } & { __typename: 'CompanyVatNumber' }>;
                    companyIdentifiers: Array<
                      {
                        companyId: string;
                        companyIdentifierId: string;
                        identifierType: CompanyIdentifierType;
                        identifierValue: string;
                        discriminatorCountry: string | null;
                        source: string;
                      } & { __typename: 'CompanyIdentifier' }
                    >;
                  } & { __typename: 'ProvidingVisibilityCompany' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type ReceivingVisibilityCompaniesQueryVariables = Exact<{
  companyId: Scalars['String'];
  filter: ReceivingVisibilityCompaniesFilterInput;
  sort?: InputMaybe<ReceivingVisibilityCompaniesSortOrderInput>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type ReceivingVisibilityCompaniesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              receivingVisibilityCompanies:
                | ({
                    count: number;
                    companies: {
                      edges: Array<
                        | ({
                            node:
                              | ({
                                  companyId: string;
                                  companyName: string | null;
                                  dateAdded: string;
                                  tourStatusConsent: ConsentState;
                                  onboardingStatus: UnifiedCompanyRelationshipOnboardingStatus | null;
                                  latestPendingInvite:
                                    | ({
                                        companyInviteId: string;
                                        deadlineAt: string;
                                        invitedAt: string;
                                        recipient: { name: string; email: string } & {
                                          __typename: 'CompanyInviteRecipient';
                                        };
                                        metadata: { locale: string } & { __typename: 'CompanyInviteMetadata' };
                                      } & { __typename: 'CompanyInvite' })
                                    | null;
                                } & { __typename: 'ReceivingVisibilityCompany' })
                              | null;
                          } & { __typename: 'ReceivingVisibilityCompanyEdge' })
                        | null
                      > | null;
                      pageInfo: { hasNextPage: boolean; startCursor: string | null; endCursor: string | null } & {
                        __typename: 'PageInfo';
                      };
                    } & { __typename: 'ReceivingVisibilityCompanyConnection' };
                  } & { __typename: 'CountedReceivingVisibilityCompaniesConnection' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type NetworkRelationStatisticsQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;

export type NetworkRelationStatisticsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              networkRelationStatistics: {
                companyCountsByOnboardingStatus: Array<
                  { count: number; onboardingStatus: UnifiedCompanyRelationshipOnboardingStatus | null } & {
                    __typename: 'CompanyCountByOnboardingStatus';
                  }
                >;
              } & { __typename: 'NetworkRelationStatistics' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type SignupNetworkRelationStatisticsQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;

export type SignupNetworkRelationStatisticsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              networkRelationStatistics: {
                companyCountsBySignupStatus: Array<
                  { count: number; signupStatus: UnifiedCompanyRelationshipSignupStatus | null } & {
                    __typename: 'CompanyCountBySignupStatus';
                  }
                >;
              } & { __typename: 'NetworkRelationStatistics' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type NetworkRelationStatisticsByDateQueryVariables = Exact<{
  companyId: Scalars['String'];
  firstDate: Scalars['String'];
  secondDate: Scalars['String'];
}>;

export type NetworkRelationStatisticsByDateQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              networkRelationStatistics: {
                firstDateStatistics: Array<
                  { count: number; onboardingStatus: UnifiedCompanyRelationshipOnboardingStatus | null } & {
                    __typename: 'CompanyCountByOnboardingStatus';
                  }
                >;
                secondDateStatistics: Array<
                  { count: number; onboardingStatus: UnifiedCompanyRelationshipOnboardingStatus | null } & {
                    __typename: 'CompanyCountByOnboardingStatus';
                  }
                >;
              } & { __typename: 'NetworkRelationStatistics' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type SignupNetworkRelationStatisticsByDateQueryVariables = Exact<{
  companyId: Scalars['String'];
  firstDate: Scalars['String'];
  secondDate: Scalars['String'];
}>;

export type SignupNetworkRelationStatisticsByDateQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              networkRelationStatistics: {
                firstDateStatistics: Array<
                  { count: number; signupStatus: UnifiedCompanyRelationshipSignupStatus | null } & {
                    __typename: 'CompanyCountBySignupStatus';
                  }
                >;
                secondDateStatistics: Array<
                  { count: number; signupStatus: UnifiedCompanyRelationshipSignupStatus | null } & {
                    __typename: 'CompanyCountBySignupStatus';
                  }
                >;
              } & { __typename: 'NetworkRelationStatistics' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type NetworkRelationCompaniesQueryVariables = Exact<{
  companyId: Scalars['String'];
  filter: NetworkRelationFilter;
  sort?: InputMaybe<NetworkRelationsSortOrderInput>;
  includeSignupStatusInReport?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type NetworkRelationCompaniesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              networkRelations:
                | ({
                    count: number;
                    reportDownloadURI: string;
                    networkRelations: {
                      edges: Array<
                        | ({
                            node:
                              | ({
                                  entityId: string;
                                  companyName: string;
                                  relationCompanyName: string;
                                  companyReferenceName: string | null;
                                  type: NetworkRelationType;
                                  onboardingStatus: UnifiedCompanyRelationshipOnboardingStatus | null;
                                  signupStatus: UnifiedCompanyRelationshipSignupStatus | null;
                                  tourStatusConsent: NetworkRelationConsentState;
                                  companyIdentifiers: Array<
                                    {
                                      companyIdentifierId: string;
                                      identifierType: CompanyIdentifierType;
                                      identifierValue: string;
                                    } & { __typename: 'CompanyIdentifier' }
                                  >;
                                  relationIdentifiers: Array<
                                    { companyIdentifierId: string; identifierValue: string } & {
                                      __typename: 'CompanyIdentifier';
                                    }
                                  >;
                                } & { __typename: 'NetworkRelation' })
                              | null;
                          } & { __typename: 'NetworkRelationEdge' })
                        | null
                      > | null;
                      pageInfo: { hasNextPage: boolean; startCursor: string | null; endCursor: string | null } & {
                        __typename: 'PageInfo';
                      };
                    } & { __typename: 'NetworkRelationConnection' };
                  } & { __typename: 'CountedCompanyRelationConnection' })
                | null;
              acceptedInvitations: ({ count: number } & { __typename: 'CountedCompanyRelationConnection' }) | null;
              pendingInvitations: ({ count: number } & { __typename: 'CountedCompanyRelationConnection' }) | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type ReceivingVisibilityCompanyQueryVariables = Exact<{
  companyId: Scalars['String'];
  subcontractorId: Scalars['String'];
}>;

export type ReceivingVisibilityCompanyQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              invitationHistoryLogs: Array<
                {
                  performedByEmail: string | null;
                  inviteSentByLabel: string | null;
                  time: string;
                  type: InvitationHistoryLogItemType | null;
                  recipients: Array<
                    { email: string; locale: string } & { __typename: 'InvitationHistoryItemRecipient' }
                  > | null;
                } & { __typename: 'InvitationHistoryItem' }
              >;
              receivingVisibilityCompany:
                | ({
                    companyId: string;
                    transporeonId: string | null;
                    companyName: string | null;
                    companyReferenceName: string | null;
                    dateAdded: string;
                    tourStatusConsent: ConsentState;
                    onboardingStatus: UnifiedCompanyRelationshipOnboardingStatus | null;
                    onboardingStuckReason: UnifiedCompanyOnboardingStuckReason | null;
                    onboardingStatusTransitionedAt: string | null;
                    signupStatus: UnifiedCompanyRelationshipSignupStatus | null;
                    signupStatusTransitionedAt: string | null;
                    vatNumbers: Array<{ vatNumber: string } & { __typename: 'CompanyVatNumber' }>;
                    companyIdentifiers: Array<
                      {
                        companyId: string;
                        companyIdentifierId: string;
                        identifierType: CompanyIdentifierType;
                        identifierValue: string;
                        discriminatorCountry: string | null;
                        source: string;
                      } & { __typename: 'CompanyIdentifier' }
                    >;
                    relationIdentifiers: Array<
                      { companyIdentifierId: string; identifierValue: string } & { __typename: 'CompanyIdentifier' }
                    >;
                    latestPendingInvite:
                      | ({
                          companyInviteId: string;
                          deadlineAt: string;
                          invitedAt: string;
                          recipient: { name: string; email: string } & { __typename: 'CompanyInviteRecipient' };
                          metadata: { locale: string } & { __typename: 'CompanyInviteMetadata' };
                        } & { __typename: 'CompanyInvite' })
                      | null;
                  } & { __typename: 'ReceivingVisibilityCompany' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type DataNetworkPerformanceReportQueryVariables = Exact<{
  type: DataNetworkReportType;
  sort?: InputMaybe<DataNetworkReportSortOrderInput>;
  companyId: Scalars['String'];
  siteIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  placeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch> | TourCustomFieldSearch>;
  carrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  shipperIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  startTimeEnd?: InputMaybe<Scalars['DateTime']>;
  startTimeStart: Scalars['DateTime'];
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  endTimeStart?: InputMaybe<Scalars['DateTime']>;
  endTimeEnd?: InputMaybe<Scalars['DateTime']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  stopCountries?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  stopStates?: InputMaybe<Array<StopStatesInput> | StopStatesInput>;
  mainVisibilityIssues?: InputMaybe<Array<VisibilityIssueType> | VisibilityIssueType>;
  onboardingStatus?: InputMaybe<
    Array<UnifiedCompanyRelationshipOnboardingStatus> | UnifiedCompanyRelationshipOnboardingStatus
  >;
  stopBookingLocationNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  stopFilters?: InputMaybe<Array<StopFilterInput> | StopFilterInput>;
  skipPlatforms: Scalars['Boolean'];
  skipTotalCount: Scalars['Boolean'];
  skipUpcomingCount: Scalars['Boolean'];
  skipWorkingCount: Scalars['Boolean'];
  skipCompletedCount: Scalars['Boolean'];
  skipCancelledCount: Scalars['Boolean'];
  skipUnassignedCount: Scalars['Boolean'];
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type DataNetworkPerformanceReportQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              dataNetworkVisibilityReport:
                | ({
                    reportDownloadURI: string;
                    companyOverview: {
                      companyId: string;
                      visibilityIndex: number | null;
                      visibilityIndexChange: number | null;
                      assignmentPercentage: number | null;
                      assignmentPercentageChange: number | null;
                      tourTotalCount?: number;
                      tourUpcomingCount?: number;
                      tourUnassignedCount?: number;
                      tourCompletedCount?: number;
                      tourCancelledCount?: number;
                      tourWorkingCount?: number;
                    } & { __typename: 'CompanyOverview' };
                    companies: {
                      count: number;
                      companies: {
                        edges: Array<
                          | ({
                              node:
                                | ({
                                    companyId: string;
                                    companyName: string | null;
                                    onboardingStatus: UnifiedCompanyRelationshipOnboardingStatus | null;
                                    onboardingStuckReason: UnifiedCompanyOnboardingStuckReason | null;
                                    tourTotalCount?: number;
                                    tourUpcomingCount?: number;
                                    tourWorkingCount?: number;
                                    tourCompletedCount?: number;
                                    tourCancelledCount?: number;
                                    tourUnassignedCount?: number;
                                    visibilityIndex: number | null;
                                    visibilityIndexChange: number | null;
                                    mainVisibilityIssueType: VisibilityIssueType | null;
                                    assignmentPercentage: number | null;
                                    assignmentPercentageChange: number | null;
                                    id: string;
                                    latestPendingInvite:
                                      | ({
                                          companyInviteId: string;
                                          deadlineAt: string;
                                          invitedAt: string;
                                          recipient: { name: string; email: string } & {
                                            __typename: 'CompanyInviteRecipient';
                                          };
                                          metadata: { locale: string } & { __typename: 'CompanyInviteMetadata' };
                                        } & { __typename: 'CompanyInvite' })
                                      | null;
                                    platforms?: Array<
                                      { platform_id: string; platform_name: string; company_id_on_platform: string } & {
                                        __typename: 'CompanyPlatform';
                                      }
                                    >;
                                    relationIdentifiers: Array<
                                      { companyIdentifierId: string; identifierValue: string } & {
                                        __typename: 'CompanyIdentifier';
                                      }
                                    >;
                                  } & { __typename: 'DataNetworkVisibilityReportCompany' })
                                | null;
                            } & { __typename: 'DataNetworkVisibilityReportCompanyEdge' })
                          | null
                        > | null;
                        pageInfo: { hasNextPage: boolean; startCursor: string | null; endCursor: string | null } & {
                          __typename: 'PageInfo';
                        };
                      } & { __typename: 'DataNetworkVisibilityReportCompanyConnection' };
                    } & { __typename: 'CountedDataNetworkVisibilityReportCompanyConnection' };
                  } & { __typename: 'DataNetworkVisibilityReport' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type DataNetworkTransportGraphsQueryVariables = Exact<{
  type: DataNetworkReportType;
  companyId: Scalars['String'];
  siteIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  placeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch> | TourCustomFieldSearch>;
  carrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  shipperIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  startTimeEnd?: InputMaybe<Scalars['DateTime']>;
  startTimeStart: Scalars['DateTime'];
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  endTimeStart?: InputMaybe<Scalars['DateTime']>;
  endTimeEnd?: InputMaybe<Scalars['DateTime']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  stopCountries?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  onboardingStatus?: InputMaybe<
    Array<UnifiedCompanyRelationshipOnboardingStatus> | UnifiedCompanyRelationshipOnboardingStatus
  >;
  stopStates?: InputMaybe<Array<StopStatesInput> | StopStatesInput>;
  stopFilters?: InputMaybe<Array<StopFilterInput> | StopFilterInput>;
  mainVisibilityIssues?: InputMaybe<Array<VisibilityIssueType> | VisibilityIssueType>;
}>;

export type DataNetworkTransportGraphsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              dataNetworkVisibilityReport:
                | ({
                    workingTours: {
                      totalCount: number;
                      countByTourDelayStatus: Array<
                        { count: number; delayStatus: TourDelayStatusFilter | null } & {
                          __typename: 'ToursCountByDelayStatus';
                        }
                      >;
                    } & { __typename: 'ToursCountByStatus' };
                    completedTours: {
                      totalCount: number;
                      countByTourDelayStatus: Array<
                        { count: number; delayStatus: TourDelayStatusFilter | null } & {
                          __typename: 'ToursCountByDelayStatus';
                        }
                      >;
                    } & { __typename: 'ToursCountByStatus' };
                    cancelledTours: {
                      totalCount: number;
                      countByFailureReason: Array<
                        { count: number; failureReason: TourFailureReasonType | null } & {
                          __typename: 'TourCountByFailureReason';
                        }
                      >;
                    } & { __typename: 'ToursCountByStatus' };
                  } & { __typename: 'DataNetworkVisibilityReport' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type AddOrUpdateDataSharingConsentMutationVariables = Exact<{
  input: AddOrUpdateConsentInput;
}>;

export type AddOrUpdateDataSharingConsentMutation = { addOrUpdateDataSharingConsent: boolean | null } & {
  __typename: 'Mutation';
};

export type CompanyDataNetworkFiltersQueryVariables = Exact<{
  companyId: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type CompanyDataNetworkFiltersQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              me: ({ role: UserRoleInCompany } & { __typename: 'UserInCompany' }) | null;
              companyTransportNetworkFiltersConnection: {
                count: number;
                companyTransportNetworkFilters: {
                  edges: Array<
                    | ({
                        node:
                          | ({
                              companyTransportNetworkFilterId: string;
                              name: string;
                              filter: {
                                onboardingStatus: Array<UnifiedCompanyRelationshipOnboardingStatus> | null;
                                startTimeStart: string;
                                startTimeEnd: string;
                                endTimeStart: string | null;
                                endTimeEnd: string | null;
                                siteIds: Array<string> | null;
                                carrierIds: Array<string> | null;
                                shipperIds: Array<string> | null;
                                relationIdentifierIds: Array<string> | null;
                                placeIds: Array<string> | null;
                                stopCountries: Array<string> | null;
                                stopBookingLocationNames: Array<string> | null;
                                mainVisibilityIssues: Array<VisibilityIssueType> | null;
                                customFields: Array<
                                  {
                                    fieldName: string;
                                    value: { values: Array<string> | null } & {
                                      __typename: 'TransportNetworkCustomFieldSearchValueType';
                                    };
                                  } & { __typename: 'TransportNetworkCustomFieldSearchType' }
                                > | null;
                                stopStates: Array<
                                  { state: string; country: string } & { __typename: 'CompanyStopStates' }
                                > | null;
                                stopFilters: Array<
                                  {
                                    placeIds: Array<string> | null;
                                    bookingLocationNames: Array<string> | null;
                                    countries: Array<string> | null;
                                    stopTypes: Array<FilterRoadStopType> | null;
                                    states: Array<
                                      { country: string; state: string } & { __typename: 'CompanyStopStates' }
                                    > | null;
                                  } & { __typename: 'StopFilterOutput' }
                                > | null;
                              } & { __typename: 'TransportNetworkFilter' };
                            } & { __typename: 'CompanyTransportNetworkFilter' })
                          | null;
                      } & { __typename: 'CompanyTransportNetworkFilterEdge' })
                    | null
                  > | null;
                  pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                } & { __typename: 'CompanyTransportNetworkFilterConnection' };
              } & { __typename: 'CountedCompanyTransportNetworkFilterConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanyExistingRelationshipsQueryVariables = Exact<{
  companyId: Scalars['String'];
  companyNameSearch: Scalars['String'];
}>;

export type CompanyExistingRelationshipsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              networkRelations:
                | ({
                    networkRelations: {
                      edges: Array<
                        | ({
                            node:
                              | ({
                                  entityId: string;
                                  companyName: string;
                                  relationCompanyName: string;
                                  companyReferenceName: string | null;
                                  type: NetworkRelationType;
                                } & { __typename: 'NetworkRelation' })
                              | null;
                          } & { __typename: 'NetworkRelationEdge' })
                        | null
                      > | null;
                    } & { __typename: 'NetworkRelationConnection' };
                  } & { __typename: 'CountedCompanyRelationConnection' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type SendCompanyInvitationMutationVariables = Exact<{
  companyId: Scalars['String'];
  input: SendCompanyInvitationInput;
  dryRun: Scalars['Boolean'];
}>;

export type SendCompanyInvitationMutation = {
  sendCompanyInvitation:
    | ({
        success: boolean;
        invalidEmails: Array<string>;
        invalidIdentifiers: Array<string>;
        companyInviteId: string | null;
      } & { __typename: 'SendCompanyInvitationsResult' })
    | null;
} & { __typename: 'Mutation' };

export type CreateCompanyInvitationsMutationVariables = Exact<{
  companyId: Scalars['String'];
  input: CreateCompanyInvitationInput;
}>;

export type CreateCompanyInvitationsMutation = {
  createCompanyInvitations:
    | ({ success: boolean; invalidEmails: Array<string> } & { __typename: 'CreateCompanyInvitationsResult' })
    | null;
} & { __typename: 'Mutation' };

export type ResendCompanyInvitationMutationVariables = Exact<{
  companyId: Scalars['String'];
  input: ResendCompanyInvitationInput;
}>;

export type ResendCompanyInvitationMutation = {
  resendCompanyInvitation:
    | ({ success: boolean; invalidEmails: Array<string> } & { __typename: 'CreateCompanyInvitationsResult' })
    | null;
} & { __typename: 'Mutation' };

export type CreateBulkInvitationSetMutationVariables = Exact<{
  companyId: Scalars['String'];
  input: CreateBulkInvitationSetInput;
}>;

export type CreateBulkInvitationSetMutation = {
  createBulkInvitationSet: { success: boolean; bulkInvitationSetId: string } & {
    __typename: 'CreateBulkInvitationSetResult';
  };
} & { __typename: 'Mutation' };

export type UpdateBulkInvitationSetMutationVariables = Exact<{
  companyId: Scalars['String'];
  input: UpdateBulkInvitationSetInput;
}>;

export type UpdateBulkInvitationSetMutation = {
  updateBulkInvitationSet: { success: boolean } & { __typename: 'UpdateBulkInvitationSetResult' };
} & { __typename: 'Mutation' };

export type DeleteBulkInvitationSetMutationVariables = Exact<{
  companyId: Scalars['String'];
  input: DeleteBulkInvitationSetInput;
}>;

export type DeleteBulkInvitationSetMutation = {
  deleteBulkInvitationSet: { success: boolean } & { __typename: 'DeleteBulkInvitationSetResult' };
} & { __typename: 'Mutation' };

export type SendBulkInvitationSetMutationVariables = Exact<{
  companyId: Scalars['String'];
  input?: InputMaybe<SendBulkInvitationSetInput>;
}>;

export type SendBulkInvitationSetMutation = {
  sendBulkInvitationSet: ({ success: boolean } & { __typename: 'SendBulkInvitationSetResult' }) | null;
} & { __typename: 'Mutation' };

export type WithdrawCompanyInvitationMutationVariables = Exact<{
  companyId: Scalars['String'];
  input: WithdrawCompanyInvitationInput;
}>;

export type WithdrawCompanyInvitationMutation = {
  withdrawCompanyInvitation: { success: boolean } & { __typename: 'WithdrawCompanyInvitationResult' };
} & { __typename: 'Mutation' };

export type BulkInvitationSetsQueryVariables = Exact<{
  companyId: Scalars['String'];
  bulkInvitationSetId: Scalars['String'];
  bulkInvitationSetIdDefined: Scalars['Boolean'];
  companyName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<BulkInvitationSetItemStatus>;
  isInvalid?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  sortKey: BulkInvitationSetsSortKey;
  sortDirection: SortDirection;
}>;

export type BulkInvitationSetsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              latestBulkInvitationSetId: string | null;
              bulkInvitationSets: Array<
                {
                  bulkInvitationSetId: string;
                  companyId: string;
                  createdByUserId: string;
                  updatedByUserId: string;
                  createdAt: string;
                  updatedAt: string;
                  metadata: { fileName: string | null; fileSize: string | null } & {
                    __typename: 'BulkInvitationMetadata';
                  };
                } & { __typename: 'BulkInvitationSet' }
              >;
              bulkInvitationSet?:
                | ({
                    bulkInvitationSetId: string;
                    companyId: string;
                    createdByUserId: string;
                    updatedByUserId: string;
                    createdAt: string;
                    updatedAt: string;
                    metadata: { fileName: string | null; fileSize: string | null } & {
                      __typename: 'BulkInvitationMetadata';
                    };
                    statistics: {
                      itemCountByStatus: Array<
                        { count: number; status: BulkInvitationSetItemStatus } & { __typename: 'ItemCountByStatus' }
                      >;
                    } & { __typename: 'BulkInvitationSetStatistics' };
                    items: {
                      count: number;
                      items: {
                        edges: Array<
                          | ({
                              node:
                                | ({
                                    bulkInvitationSetItemId: string;
                                    bulkInvitationSetId: string;
                                    companyName: string | null;
                                    companyReferenceName: string | null;
                                    relationCompanyName: string | null;
                                    companyInvitationId: string | null;
                                    invitedCompanyId: string | null;
                                    status: BulkInvitationSetItemStatus;
                                    isDataValid: boolean;
                                    createdByUserId: string;
                                    updatedByUserId: string;
                                    createdAt: string;
                                    updatedAt: string;
                                    recipients: Array<
                                      { email: string; locale: string | null } & {
                                        __typename: 'BulkInvitationRecipient';
                                      }
                                    >;
                                  } & { __typename: 'BulkInvitationSetItem' })
                                | null;
                            } & { __typename: 'BulkInvitationSetItemEdge' })
                          | null
                        > | null;
                        pageInfo: { hasNextPage: boolean; startCursor: string | null; endCursor: string | null } & {
                          __typename: 'PageInfo';
                        };
                      } & { __typename: 'BulkInvitationSetItemConnection' };
                      statistics: {
                        itemCountByStatus: Array<
                          { count: number; status: BulkInvitationSetItemStatus } & { __typename: 'ItemCountByStatus' }
                        >;
                      } & { __typename: 'BulkInvitationSetStatistics' };
                    } & { __typename: 'CountedBulkInvitationSetItemConnection' };
                  } & { __typename: 'BulkInvitationSet' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type BulkInvitationSetItemQueryVariables = Exact<{
  companyId: Scalars['String'];
  bulkInvitationSetId: Scalars['String'];
  bulkInvitationSetItemId: Scalars['String'];
}>;

export type BulkInvitationSetItemQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              bulkInvitationSetItem:
                | ({
                    bulkInvitationSetItemId: string;
                    companyInvitationId: string | null;
                    companyName: string | null;
                    status: BulkInvitationSetItemStatus;
                    isDataValid: boolean;
                    createdAt: string;
                    updatedAt: string;
                    recipients: Array<
                      { email: string; locale: string | null } & { __typename: 'BulkInvitationRecipient' }
                    >;
                  } & { __typename: 'BulkInvitationSetItem' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CreateOrUpdateRelationIdentifiersMutationVariables = Exact<{
  input: CreateOrUpdateRelationIdentifiersInput;
}>;

export type CreateOrUpdateRelationIdentifiersMutation = {
  createOrUpdateRelationIdentifiers:
    | ({ success: boolean; invalidIdentifiers: Array<string> } & {
        __typename: 'CreateOrUpdateRelationIdentifiersResult';
      })
    | null;
} & { __typename: 'Mutation' };

export type RelationIdentifierSearchQueryVariables = Exact<{
  companyId: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  relationIdentifierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type RelationIdentifierSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              relationIdentifiersConnection:
                | ({
                    edges: Array<
                      | ({
                          node:
                            | ({ companyId: string; companyIdentifierId: string; identifierValue: string } & {
                                __typename: 'CompanyIdentifier';
                              })
                            | null;
                        } & { __typename: 'CompanyIdentifierEdge' })
                      | null
                    > | null;
                  } & { __typename: 'CompanyIdentifierConnection' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type FleetVehicleDetailsFragment = {
  license_plate_number: string | null;
  isConstantPositionAccessAllowed: boolean;
  company: { company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' };
  status: ({ timestamp: string | null } & { __typename: 'RestrictedVehicleStatus' }) | null;
  vehicleTrackers: Array<
    | { __typename: 'FMSVehicleTracker' }
    | ({
        vehicleTrackerId: string;
        phoneNumber: string | null;
        trackerType: VehicleTrackerType;
        alias: string | null;
      } & { __typename: 'MobileVehicleTracker' })
  >;
} & { __typename: 'Vehicle' };

export type FleetReceivedGrantFragment = {
  receivedGrant:
    | ({
        grantId: string;
        grantedByCompany: { company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' };
      } & { __typename: 'VehicleGrant' })
    | null;
} & { __typename: 'Vehicle' };

export type FleetTourAllocationDetailsFragment = {
  tour_id: number;
  status: string | null;
  shipper_transport_number: string | null;
  transportIdentifiers: Array<string> | null;
  start_time: string | null;
  end_time: string | null;
  isGloballyAllocatedToViewerCompany: boolean;
  failureReason: ({ type: TourFailureReasonType } & { __typename: 'TourFailureReason' }) | null;
  shipper: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
  stops: Array<
    {
      stop_id: number;
      type: string | null;
      status: string | null;
      location:
        | ({
            name: string | null;
            address:
              | ({ country: string | null; city: string | null; state: string | null; shortAreaCode: string | null } & {
                  __typename: 'Address';
                })
              | null;
          } & { __typename: 'TourLocation' })
        | null;
    } & { __typename: 'TourStop' }
  >;
} & { __typename: 'Tour' };

export type FleetVehicleHistoryFragment = {
  vehicleHistory: Array<
    {
      polyline: { points: string } & { __typename: 'Polyline' };
      metadata: { type: VehicleHistoryPolylineType; startTime: string; endTime: string } & {
        __typename: 'VehicleHistoryPolylineMetadata';
      };
    } & { __typename: 'VehicleHistoryPolyline' }
  > | null;
} & { __typename: 'Tour' };

export type FleetTourDetailsFragment = {
  tour_id: number;
  shipper_transport_number: string | null;
  status: string | null;
  start_time: string | null;
  end_time: string | null;
  working_stop_id: number | null;
  isGlobalAllocation: boolean;
  isGloballyAllocatedToViewerCompany: boolean;
  warnings: Array<{ type: TourWarningType; severity: TourWarningSeverity | null } & { __typename: 'TourWarning' }>;
  failureReason: ({ type: TourFailureReasonType } & { __typename: 'TourFailureReason' }) | null;
  shipper: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
  vehicleAllocations: Array<{ vehicleLicensePlateNumber: string | null } & { __typename: 'TourVehicleAllocation' }>;
  stops: Array<
    {
      stop_id: number;
      type: string | null;
      status: string | null;
      deadline: string | null;
      estimated_arrival_delay_in_seconds: number | null;
      estimated_arrival_status: string | null;
      estimated_arrival_distance_in_meters: number | null;
      arrival_delay_in_seconds: number | null;
      arrival_time: string | null;
      location:
        | ({
            location_id: string | null;
            name: string | null;
            address:
              | ({
                  full_address: string | null;
                  city: string | null;
                  country: string | null;
                  street_address: string | null;
                  state: string | null;
                  zipcode: string | null;
                  shortAreaCode: string | null;
                } & { __typename: 'Address' })
              | null;
          } & { __typename: 'TourLocation' })
        | null;
    } & { __typename: 'TourStop' }
  >;
} & { __typename: 'Tour' };

export type AllFleetVehiclesQueryVariables = Exact<{
  companyId: Scalars['String'];
  fromTime: Scalars['DateTime'];
  toTime: Scalars['DateTime'];
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  licensePlateSearch?: InputMaybe<Scalars['String']>;
  vehicleStateFilter?: InputMaybe<VehicleStateFilterInput>;
  dwellTimeThresholdMinutes?: InputMaybe<Scalars['Int']>;
}>;

export type AllFleetVehiclesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              vehicles:
                | ({
                    vehicles:
                      | ({
                          count: number;
                          vehicles: {
                            edges: Array<
                              | ({
                                  node:
                                    | ({
                                        vehicle_id: string;
                                        vehicleMapState: VehicleMapState | null;
                                        tours: Array<FleetTourDetailsFragment & { __typename: 'Tour' }> | null;
                                      } & FleetVehicleDetailsFragment &
                                        FleetReceivedGrantFragment & { __typename: 'Vehicle' })
                                    | null;
                                } & { __typename: 'VehicleEdge' })
                              | null
                            > | null;
                            pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                          } & { __typename: 'VehicleConnection' };
                        } & { __typename: 'CountedVehicleConnection' })
                      | null;
                  } & { __typename: 'CompanyVehicles' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type FleetGroupVehiclesQueryVariables = Exact<{
  companyId: Scalars['String'];
  vehicleGroupId: Scalars['String'];
  fromTime: Scalars['DateTime'];
  toTime: Scalars['DateTime'];
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  licensePlateSearch?: InputMaybe<Scalars['String']>;
  vehicleStateFilter?: InputMaybe<VehicleStateFilterInput>;
  dwellTimeThresholdMinutes?: InputMaybe<Scalars['Int']>;
}>;

export type FleetGroupVehiclesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              companyVehicleGroup:
                | ({
                    companyVehicleGroupId: string;
                    name: string;
                    vehiclesConnection: {
                      count: number;
                      vehicles:
                        | ({
                            edges: Array<
                              | ({
                                  node:
                                    | ({
                                        vehicle_id: string;
                                        vehicleMapState: VehicleMapState | null;
                                        tours: Array<FleetTourDetailsFragment & { __typename: 'Tour' }> | null;
                                      } & FleetVehicleDetailsFragment &
                                        FleetReceivedGrantFragment & { __typename: 'Vehicle' })
                                    | null;
                                } & { __typename: 'VehicleEdge' })
                              | null
                            > | null;
                            pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                          } & { __typename: 'VehicleConnection' })
                        | null;
                    } & { __typename: 'CountedVehicleInGroupConnection' };
                  } & { __typename: 'CompanyVehicleGroup' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type VehicleGroupVehiclesQueryVariables = Exact<{
  companyId: Scalars['String'];
  vehicleGroupId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type VehicleGroupVehiclesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              companyVehicleGroup:
                | ({
                    companyVehicleGroupId: string;
                    name: string;
                    vehiclesConnection: {
                      vehicles:
                        | ({
                            edges: Array<
                              | ({
                                  node:
                                    | ({
                                        vehicle_id: string;
                                        license_plate_number: string | null;
                                      } & FleetReceivedGrantFragment & { __typename: 'Vehicle' })
                                    | null;
                                } & { __typename: 'VehicleEdge' })
                              | null
                            > | null;
                            pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                          } & { __typename: 'VehicleConnection' })
                        | null;
                    } & { __typename: 'CountedVehicleInGroupConnection' };
                  } & { __typename: 'CompanyVehicleGroup' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type VehicleGroupsQueryVariables = Exact<{
  company_id: Scalars['String'];
}>;

export type VehicleGroupsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              companyVehicleGroups: Array<
                ({ companyVehicleGroupId: string; name: string } & { __typename: 'CompanyVehicleGroup' }) | null
              > | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type VehicleGroupSearchVehiclesQueryVariables = Exact<{
  companyId: Scalars['String'];
  licensePlateSearch: Scalars['String'];
}>;

export type VehicleGroupSearchVehiclesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              vehicles:
                | ({
                    allocatable:
                      | ({
                          vehicles: {
                            edges: Array<
                              | ({
                                  node:
                                    | ({
                                        vehicle_id: string;
                                        license_plate_number: string | null;
                                      } & FleetReceivedGrantFragment & { __typename: 'Vehicle' })
                                    | null;
                                } & { __typename: 'VehicleEdge' })
                              | null
                            > | null;
                          } & { __typename: 'VehicleConnection' };
                        } & { __typename: 'CountedVehicleConnection' })
                      | null;
                  } & { __typename: 'CompanyVehicles' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CreateVehicleGroupMutationVariables = Exact<{
  input: CreateCompanyVehicleGroupInput;
}>;

export type CreateVehicleGroupMutation = {
  createCompanyVehicleGroup:
    | ({ companyVehicleGroupId: string; name: string } & { __typename: 'CompanyVehicleGroup' })
    | null;
} & { __typename: 'Mutation' };

export type UpdateVehicleGroupMutationVariables = Exact<{
  updateTitleInput: UpdateCompanyVehicleGroupInput;
  addVehiclesInput: AddVehiclesToCompanyVehicleGroupInput;
  removeVehiclesInput: RemoveVehiclesFromCompanyVehicleGroupInput;
}>;

export type UpdateVehicleGroupMutation = {
  addVehiclesToCompanyVehicleGroup: boolean | null;
  removeVehiclesFromCompanyVehicleGroup: boolean | null;
  updateCompanyVehicleGroup:
    | ({ companyVehicleGroupId: string; name: string } & { __typename: 'CompanyVehicleGroup' })
    | null;
} & { __typename: 'Mutation' };

export type DeleteCompanyVehicleGroupMutationVariables = Exact<{
  input: DeleteCompanyVehicleGroupInput;
}>;

export type DeleteCompanyVehicleGroupMutation = { deleteCompanyVehicleGroup: boolean | null } & {
  __typename: 'Mutation';
};

export type CreateTourMutationVariables = Exact<{
  input: TourCreationInput;
}>;

export type CreateTourMutation = { createTour: (FleetTourDetailsFragment & { __typename: 'Tour' }) | null } & {
  __typename: 'Mutation';
};

export type UpdateTourMutationVariables = Exact<{
  input: TourUpdateInput;
}>;

export type UpdateTourMutation = { updateTour: ({ tour_id: number } & { __typename: 'Tour' }) | null } & {
  __typename: 'Mutation';
};

export type UnallocatedShipmentsQueryVariables = Exact<{
  company_id: Scalars['String'];
  role: TourCompanyRole;
  vehicleId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  textSearch?: InputMaybe<TourTextSearch>;
  sortOrder?: InputMaybe<TourSortOrder>;
}>;

export type UnallocatedShipmentsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              is_carrier: boolean | null;
              vehicle: ({ vehicle_id: string; license_plate_number: string | null } & { __typename: 'Vehicle' }) | null;
              tours: {
                count: number;
                tours: {
                  edges: Array<
                    | ({ node: (FleetTourAllocationDetailsFragment & { __typename: 'Tour' }) | null } & {
                        __typename: 'TourEdge';
                      })
                    | null
                  > | null;
                  pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                } & { __typename: 'TourConnection' };
              } & { __typename: 'CountedTourConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type UnallocatedCompanyShipmentsQueryVariables = Exact<{
  company_id: Scalars['String'];
  role: TourCompanyRole;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  textSearch?: InputMaybe<TourTextSearch>;
  sortOrder?: InputMaybe<TourSortOrder>;
}>;

export type UnallocatedCompanyShipmentsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              is_carrier: boolean | null;
              tours: {
                count: number;
                tours: {
                  edges: Array<
                    | ({ node: (FleetTourAllocationDetailsFragment & { __typename: 'Tour' }) | null } & {
                        __typename: 'TourEdge';
                      })
                    | null
                  > | null;
                  pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                } & { __typename: 'TourConnection' };
              } & { __typename: 'CountedTourConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TransportDetailsQueryVariables = Exact<{
  company_id: Scalars['String'];
  tourId: Scalars['Int'];
  vehicleId: Scalars['String'];
  skipSharingToken?: InputMaybe<Scalars['Boolean']>;
  skipPlace?: InputMaybe<Scalars['Boolean']>;
  skipExternalEvents?: InputMaybe<Scalars['Boolean']>;
  skipTemperature?: InputMaybe<Scalars['Boolean']>;
  skipShortAreaCodes?: InputMaybe<Scalars['Boolean']>;
  skipNotifications?: InputMaybe<Scalars['Boolean']>;
  skipHasNotifications?: InputMaybe<Scalars['Boolean']>;
  includeDeliveriesCustomFieldsInTourCustomFields?: InputMaybe<Scalars['Boolean']>;
  skipDeliveriesCustomFields?: InputMaybe<Scalars['Boolean']>;
  skipDocuments?: InputMaybe<Scalars['Boolean']>;
  skipNotes?: InputMaybe<Scalars['Boolean']>;
  skipExceptions?: InputMaybe<Scalars['Boolean']>;
  skipGeofenceZones?: InputMaybe<Scalars['Boolean']>;
}>;

export type TransportDetailsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tour: ({ canBeModified: boolean } & TourContainerDetailsFragment & { __typename: 'Tour' }) | null;
              vehicle: ({ vehicle_id: string; license_plate_number: string | null } & { __typename: 'Vehicle' }) | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TransportWithoutVehicleDetailsQueryVariables = Exact<{
  company_id: Scalars['String'];
  tourId: Scalars['Int'];
  skipSharingToken?: InputMaybe<Scalars['Boolean']>;
  skipPlace?: InputMaybe<Scalars['Boolean']>;
  skipExternalEvents?: InputMaybe<Scalars['Boolean']>;
  skipTemperature?: InputMaybe<Scalars['Boolean']>;
  skipShortAreaCodes?: InputMaybe<Scalars['Boolean']>;
  skipNotifications?: InputMaybe<Scalars['Boolean']>;
  skipHasNotifications?: InputMaybe<Scalars['Boolean']>;
  includeDeliveriesCustomFieldsInTourCustomFields?: InputMaybe<Scalars['Boolean']>;
  skipDeliveriesCustomFields?: InputMaybe<Scalars['Boolean']>;
  skipDocuments?: InputMaybe<Scalars['Boolean']>;
  skipNotes?: InputMaybe<Scalars['Boolean']>;
  skipExceptions?: InputMaybe<Scalars['Boolean']>;
  skipGeofenceZones?: InputMaybe<Scalars['Boolean']>;
}>;

export type TransportWithoutVehicleDetailsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tour: ({ canBeModified: boolean } & TourContainerDetailsFragment & { __typename: 'Tour' }) | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type UpdateTransportDetailsQueryVariables = Exact<{
  company_id: Scalars['String'];
  tourId: Scalars['Int'];
}>;

export type UpdateTransportDetailsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tour:
                | ({
                    tour_id: number;
                    shipper_transport_number: string | null;
                    stops: Array<
                      {
                        stop_id: number;
                        display_sequence_number: number;
                        type: string | null;
                        location:
                          | ({
                              address:
                                | ({
                                    city: string | null;
                                    country: string | null;
                                    street_address: string | null;
                                    zipcode: string | null;
                                  } & { __typename: 'Address' })
                                | null;
                            } & { __typename: 'TourLocation' })
                          | null;
                        timeslot: ({ begin: string | null; end: string | null } & { __typename: 'Timeslot' }) | null;
                        deliveries: Array<
                          {
                            deliveryId: string;
                            purchaseOrderNumber: string | null;
                            deliveryNumber: string | null;
                            orderNumber: string | null;
                          } & { __typename: 'TourDelivery' }
                        >;
                      } & { __typename: 'TourStop' }
                    >;
                  } & { __typename: 'Tour' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type DeleteTourMutationVariables = Exact<{
  input: TourDeletionInput;
}>;

export type DeleteTourMutation = { deleteTour: boolean | null } & { __typename: 'Mutation' };

export type VehicleDetailsQueryVariables = Exact<{
  companyId: Scalars['String'];
  vehicleId: Scalars['String'];
  returnVehicleState: Scalars['Boolean'];
  dwellTimeThresholdMinutes?: InputMaybe<Scalars['Int']>;
}>;

export type VehicleDetailsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              vehicle:
                | ({
                    vehicle_id: string;
                    license_plate_number: string | null;
                    isConstantPositionAccessAllowed: boolean;
                    mapTilesTemplateURI: string | null;
                    vehicleMapState: VehicleMapState | null;
                    boundingBox: Array<{ lat: number; lng: number } & { __typename: 'PointLocation' }> | null;
                    status:
                      | ({ timestamp: string | null; source: VehicleSignalSource } & {
                          __typename: 'RestrictedVehicleStatus';
                        })
                      | null;
                  } & { __typename: 'Vehicle' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type MapViewVehiclesTileQueryVariables = Exact<{
  companyId: Scalars['String'];
  vehicleGroupIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  vehicleStateFilter?: InputMaybe<VehicleStateFilterInput>;
  licensePlateSearch?: InputMaybe<Scalars['String']>;
  ownVehiclesOnly: Scalars['Boolean'];
  returnVehicleState: Scalars['Boolean'];
  timeBasedStatusFilter?: InputMaybe<TimeBasedStatus>;
  dwellTime?: InputMaybe<Scalars['Int']>;
}>;

export type MapViewVehiclesTileQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              vehicles:
                | ({
                    all:
                      | ({
                          mapTilesTemplateURI: string | null;
                          boundingBox: Array<{ lat: number; lng: number } & { __typename: 'PointLocation' }> | null;
                        } & { __typename: 'CountedVehicleConnection' })
                      | null;
                  } & { __typename: 'CompanyVehicles' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type FleetVehicleToursQueryVariables = Exact<{
  companyId: Scalars['String'];
  vehicleId: Scalars['String'];
  status?: InputMaybe<Array<TourStatusFilter> | TourStatusFilter>;
  fromTime: Scalars['DateTime'];
  toTime: Scalars['DateTime'];
  dwellTimeThresholdMinutes?: InputMaybe<Scalars['Int']>;
}>;

export type FleetVehicleToursQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              vehicle:
                | ({
                    vehicle_id: string;
                    vehicleMapState: VehicleMapState | null;
                    boundingBox: Array<{ lat: number; lng: number } & { __typename: 'PointLocation' }> | null;
                    breakStatus:
                      | ({ vehicleBreakStatusId: string; from: string; to: string | null } & {
                          __typename: 'VehicleBreakStatus';
                        })
                      | null;
                    tours: Array<
                      FleetTourDetailsFragment & FleetVehicleHistoryFragment & { __typename: 'Tour' }
                    > | null;
                  } & FleetVehicleDetailsFragment &
                    FleetReceivedGrantFragment & { __typename: 'Vehicle' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TelematicsProviderDetailsFragment = {
  telematicsProviderId: string;
  telematicsProviderName: string;
  integrationInstructionsURL: string | null;
  isExternalConfigurationNeeded: boolean;
  isIntegrationImplemented: boolean;
  isStandardTelematicsPushProvider: boolean | null;
  isDeprecated: boolean | null;
  connectionParameters: Array<
    {
      fieldName: string;
      fieldType: ConnectionParameterFieldType;
      isOptional: boolean;
      fieldDisplayName: string | null;
      defaultValue: string | null;
      enumFieldValues: Array<
        { value: string; display_name: string | null; is_default: boolean } & {
          __typename: 'ConnectionParameterEnumValue';
        }
      >;
    } & { __typename: 'ConnectionParameter' }
  >;
} & { __typename: 'TelematicsProvider' };

export type CompanyTelematicsIntegrationDetailsFragment = {
  companyId: string;
  telematicsIntegrationId: string;
  diagnosticStatus: TelematicsIntegrationDiagnosticStatus | null;
  updatedAt: string;
  connectionData: { [key: string]: string | number | boolean } | null;
  enabled: boolean;
  isAllowedToUpdateCredentials: boolean;
  telematicsProvider: TelematicsProviderDetailsFragment & { __typename: 'TelematicsProvider' };
} & { __typename: 'CompanyTelematicsIntegration' };

export type CompanyIntegrationsQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;

export type CompanyIntegrationsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              telematicsIntegrations: Array<
                {
                  companyId: string;
                  telematicsIntegrationId: string;
                  diagnosticStatus: TelematicsIntegrationDiagnosticStatus | null;
                  updatedAt: string;
                  enabled: boolean;
                  isAllowedToUpdateCredentials: boolean;
                  lastSuccess: string | null;
                  telematicsProvider: {
                    isDeprecated: boolean | null;
                    isIntegrationImplemented: boolean;
                    telematicsProviderId: string;
                    telematicsProviderName: string;
                  } & { __typename: 'TelematicsProvider' };
                } & { __typename: 'CompanyTelematicsIntegration' }
              >;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanyIntegrationQueryVariables = Exact<{
  companyId: Scalars['String'];
  telematicsIntegrationId: Scalars['String'];
}>;

export type CompanyIntegrationQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              telematicsIntegration:
                | (CompanyTelematicsIntegrationDetailsFragment & { __typename: 'CompanyTelematicsIntegration' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TelematicsProvidersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  textSearch?: InputMaybe<Scalars['String']>;
}>;

export type TelematicsProvidersQuery = {
  telematicsProviders: {
    edges: Array<
      | ({
          node:
            | ({ telematicsProviderId: string; telematicsProviderName: string; isIntegrationImplemented: boolean } & {
                __typename: 'TelematicsProvider';
              })
            | null;
        } & { __typename: 'TelematicsProviderEdge' })
      | null
    > | null;
    pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
  } & { __typename: 'TelematicsProviderConnection' };
} & { __typename: 'Query' };

export type TelematicsProviderQueryVariables = Exact<{
  telematicsProviderId: Scalars['String'];
}>;

export type TelematicsProviderQuery = {
  telematicsProvider: (TelematicsProviderDetailsFragment & { __typename: 'TelematicsProvider' }) | null;
} & { __typename: 'Query' };

export type CreateTelematicsIntegrationMutationVariables = Exact<{
  input: CreateTelematicsIntegrationInput;
}>;

export type CreateTelematicsIntegrationMutation = {
  createTelematicsIntegration:
    | ({
        telematics_integration_id: string;
        telematics_provider_id: string;
        enabled: boolean;
        updatedAt: string;
        diagnosticStatus: TelematicsIntegrationDiagnosticStatus | null;
        company_id: string;
        isAllowedToUpdateCredentials: boolean;
        generatedToken: string | null;
        telematicsProvider: {
          isIntegrationImplemented: boolean;
          telematicsProviderId: string;
          telematicsProviderName: string;
          isDeprecated: boolean | null;
        } & { __typename: 'TelematicsProvider' };
      } & { __typename: 'TelematicsIntegration' })
    | null;
} & { __typename: 'Mutation' };

export type UpdateTelematicsIntegrationConnectionParametersMutationVariables = Exact<{
  input: UpdateTelematicsIntegrationConnectionParametersInput;
}>;

export type UpdateTelematicsIntegrationConnectionParametersMutation = {
  updateTelematicsIntegrationConnectionParameters:
    | (CompanyTelematicsIntegrationDetailsFragment & { __typename: 'CompanyTelematicsIntegration' })
    | null;
} & { __typename: 'Mutation' };

export type ValidateTelematicsIntegrationUpdateMutationVariables = Exact<{
  input: ValidateTelematicsIntegrationConnectionParametersUpdateInput;
}>;

export type ValidateTelematicsIntegrationUpdateMutation = {
  validateTelematicsIntegrationConnectionParametersUpdate:
    | ({ areValidCredentials: boolean } & { __typename: 'TelematicsValidation' })
    | null;
} & { __typename: 'Mutation' };

export type ValidateTelematicsIntegrationMutationVariables = Exact<{
  input: ValidateTelematicsIntegrationInput;
}>;

export type ValidateTelematicsIntegrationMutation = {
  validateTelematicsIntegration: ({ areValidCredentials: boolean } & { __typename: 'TelematicsValidation' }) | null;
} & { __typename: 'Mutation' };

export type SubmitUnsupportedTelematicsProviderDetailsMutationVariables = Exact<{
  input: SubmitUnsupportedTelematicsProviderDetailsInput;
}>;

export type SubmitUnsupportedTelematicsProviderDetailsMutation = {
  submitUnsupportedTelematicsProviderDetails: boolean;
} & { __typename: 'Mutation' };

export type TransportNotificationFragment = {
  notificationId: string;
  name: string;
  createdByUserId: string;
  createdAt: string;
  notificationTrigger:
    | ({
        failureReasons: Array<TourFailureReasonType> | null;
        warnings: Array<TourWarningType> | null;
        etaComparison: Array<
          {
            comparisonTerm: ComparisonTerm;
            comparisonValueInSeconds: number;
            stopId: string | null;
            comparisonUnit: ComparisonUnit;
            remainingTimeInSeconds: number | null;
            remainingTimeUnit: ComparisonUnit | null;
          } & { __typename: 'EtaComparison' }
        > | null;
      } & { __typename: 'NotificationTrigger' })
    | null;
  recipientsV2: Array<
    ({ email: string; locale: string } & { __typename: 'EmailRecipient' }) | { __typename: 'StopContactRecipient' }
  >;
  transportFilter: FilterFragment & { __typename: 'TourFilter' };
} & { __typename: 'FilterBasedTransportNotification' };

export type DeleteFilterBasedTransportNotificationMutationVariables = Exact<{
  input: DeleteFilterBasedTransportNotificationInput;
}>;

export type DeleteFilterBasedTransportNotificationMutation = { deleteFilterBasedTransportNotification: boolean } & {
  __typename: 'Mutation';
};

export type CreateOrUpdateFilterBasedTransportNotificationMutationVariables = Exact<{
  input: CreateOrUpdateFilterBasedTransportNotificationInput;
}>;

export type CreateOrUpdateFilterBasedTransportNotificationMutation = {
  createOrUpdateFilterBasedTransportNotification: string;
} & { __typename: 'Mutation' };

export type CompanyFilterBasedNotificationsQueryVariables = Exact<{
  company_id: Scalars['String'];
}>;

export type CompanyFilterBasedNotificationsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              filterBasedTransportNotifications: Array<
                TransportNotificationFragment & { __typename: 'FilterBasedTransportNotification' }
              >;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanyFilterBasedNotificationQueryVariables = Exact<{
  company_id: Scalars['String'];
  notificationId: Scalars['String'];
}>;

export type CompanyFilterBasedNotificationQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              filterBasedTransportNotification:
                | (TransportNotificationFragment & { __typename: 'FilterBasedTransportNotification' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanyOnboardingActionQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;

export type CompanyOnboardingActionQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              onboardingAction: ({ type: OnboardingActionKey } & { __typename: 'OnboardingAction' }) | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type AddressDetailsFragment = {
  stopAddressId: string;
  companyName: string | null;
  streetAddress: string | null;
  postalCode: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  invalidFields: Array<CompanyStopAddressField> | null;
  place: ({ place_id: string; name: string } & { __typename: 'CompanyPlace' }) | null;
} & { __typename: 'CompanyStopAddress' };

export type PlaceDetailsFragment = {
  name: string;
  place_id: string;
  isSixfoldGenerated: boolean;
  referenceId: string | null;
  addresses: Array<
    {
      stopAddressId: string;
      companyName: string | null;
      streetAddress: string | null;
      postalCode: string | null;
      city: string | null;
      state: string | null;
      country: string | null;
    } & { __typename: 'CompanyStopAddress' }
  >;
} & { __typename: 'CompanyPlace' };

export type TourDataFragment = {
  tour_id: number;
  shipper_transport_number: string | null;
  working_stop_id: number | null;
  carrier: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
  activeVehicleAllocation:
    | ({
        vehicleLicensePlateNumber: string | null;
        stopAllocations: Array<{ vehicleLicensePlateNumber: string | null } & { __typename: 'StopAllocation' }> | null;
      } & { __typename: 'TourVehicleAllocation' })
    | null;
  stops: Array<
    {
      stop_id: number;
      estimated_arrival: string | null;
      estimated_arrival_status: string | null;
      estimated_arrival_distance_in_meters: number | null;
      arrival_time: string | null;
      timeslot:
        | ({ begin: string | null; end: string | null; timezone: string | null } & { __typename: 'Timeslot' })
        | null;
    } & { __typename: 'TourStop' }
  >;
} & { __typename: 'Tour' };

export type PlacesQueryVariables = Exact<{
  companyId: Scalars['String'];
  textSearch?: InputMaybe<PlaceTextSearch>;
  includeSixfoldGenerated?: InputMaybe<Scalars['Boolean']>;
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type PlacesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              placesConnection: {
                mapTilesTemplateURI: string | null;
                count: number;
                mapTilesBounds:
                  | ({
                      ne: { lat: number; lng: number } & { __typename: 'PointLocation' };
                      sw: { lat: number; lng: number } & { __typename: 'PointLocation' };
                    } & { __typename: 'Bounds' })
                  | null;
                places: {
                  edges: Array<
                    | ({ node: (PlaceDetailsFragment & { __typename: 'CompanyPlace' }) | null } & {
                        __typename: 'CompanyPlaceEdge';
                      })
                    | null
                  > | null;
                  pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                } & { __typename: 'CompanyPlaceConnection' };
              } & { __typename: 'CountedCompanyPlaceConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type PlaceQueryVariables = Exact<{
  companyId: Scalars['String'];
  placeId: Scalars['String'];
  includeStopAddresses: Scalars['Boolean'];
}>;

export type PlaceQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              place:
                | ({
                    name: string;
                    place_id: string;
                    referenceId: string | null;
                    isSixfoldGenerated: boolean;
                    geofences: Array<
                      {
                        zone: PlaceGeofenceZoneType;
                        geofence:
                          | ({
                              radius_meters: number;
                              origin: { lat: number; lng: number } & { __typename: 'PointLocation' };
                            } & { __typename: 'CircleGeofence' })
                          | ({ points: Array<{ lat: number; lng: number } & { __typename: 'PointLocation' }> } & {
                              __typename: 'PolygonGeofence';
                            });
                      } & { __typename: 'PlaceGeofenceWithZone' }
                    >;
                    addresses: Array<
                      {
                        location: ({ lat: number; lng: number } & { __typename: 'PointLocation' }) | null;
                      } & AddressDetailsFragment & { __typename: 'CompanyStopAddress' }
                    >;
                    location: { lat: number; lng: number } & { __typename: 'PointLocation' };
                    possibleAddresses?: {
                      edges: Array<
                        | ({
                            node:
                              | ({
                                  location: ({ lat: number; lng: number } & { __typename: 'PointLocation' }) | null;
                                } & AddressDetailsFragment & { __typename: 'CompanyStopAddress' })
                              | null;
                          } & { __typename: 'CompanyStopAddressEdge' })
                        | null
                      > | null;
                    } & { __typename: 'CompanyStopAddressConnection' };
                  } & { __typename: 'CompanyPlace' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CreatePlaceMutationVariables = Exact<{
  input: CreateCompanyPlaceInput;
}>;

export type CreatePlaceMutation = {
  createCompanyPlace: (PlaceDetailsFragment & { __typename: 'CompanyPlace' }) | null;
} & { __typename: 'Mutation' };

export type UpdatePlaceMutationVariables = Exact<{
  input: UpdateCompanyPlaceInput;
}>;

export type UpdatePlaceMutation = {
  updateCompanyPlace: (PlaceDetailsFragment & { __typename: 'CompanyPlace' }) | null;
} & { __typename: 'Mutation' };

export type ExposeSixfoldGeneratedPlaceMutationVariables = Exact<{
  input: ExposeSixfoldGeneratedPlaceInput;
}>;

export type ExposeSixfoldGeneratedPlaceMutation = {
  exposeSixfoldGeneratedPlace: (PlaceDetailsFragment & { __typename: 'CompanyPlace' }) | null;
} & { __typename: 'Mutation' };

export type DeletePlaceMutationVariables = Exact<{
  placeId: Scalars['String'];
}>;

export type DeletePlaceMutation = { deleteCompanyPlace: boolean | null } & { __typename: 'Mutation' };

export type AddressesQueryVariables = Exact<{
  companyId: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type AddressesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              stopAddressesConnection: {
                mapTilesTemplateURI: string | null;
                count: number;
                mapTilesBounds:
                  | ({
                      ne: { lat: number; lng: number } & { __typename: 'PointLocation' };
                      sw: { lat: number; lng: number } & { __typename: 'PointLocation' };
                    } & { __typename: 'Bounds' })
                  | null;
                addresses: {
                  edges: Array<
                    | ({ node: (AddressDetailsFragment & { __typename: 'CompanyStopAddress' }) | null } & {
                        __typename: 'CompanyStopAddressEdge';
                      })
                    | null
                  > | null;
                  pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                } & { __typename: 'CompanyStopAddressConnection' };
              } & { __typename: 'CountedCompanyStopAddressConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type AddressQueryVariables = Exact<{
  companyId: Scalars['String'];
  addressId: Scalars['String'];
}>;

export type AddressQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              stopAddress:
                | ({
                    location: ({ lat: number; lng: number } & { __typename: 'PointLocation' }) | null;
                    closeByAddresses: {
                      edges: Array<
                        | ({
                            node:
                              | ({
                                  location: ({ lat: number; lng: number } & { __typename: 'PointLocation' }) | null;
                                } & AddressDetailsFragment & { __typename: 'CompanyStopAddress' })
                              | null;
                          } & { __typename: 'CompanyStopAddressEdge' })
                        | null
                      > | null;
                    } & { __typename: 'CompanyStopAddressConnection' };
                  } & AddressDetailsFragment & { __typename: 'CompanyStopAddress' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type PlaceShipmentsQueryVariables = Exact<{
  companyId: Scalars['String'];
  placeId: Scalars['String'];
  arrivalTimeBefore?: InputMaybe<Scalars['DateTime']>;
}>;

export type PlaceShipmentsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              place:
                | ({
                    place_id: string;
                    approaching: {
                      count: number;
                      tours: {
                        edges: Array<
                          | ({ node: (TourDataFragment & { __typename: 'Tour' }) | null } & { __typename: 'TourEdge' })
                          | null
                        > | null;
                      } & { __typename: 'TourConnection' };
                    } & { __typename: 'CountedPlaceTourConnection' };
                    atSite: {
                      count: number;
                      tours: {
                        edges: Array<
                          | ({ node: (TourDataFragment & { __typename: 'Tour' }) | null } & { __typename: 'TourEdge' })
                          | null
                        > | null;
                      } & { __typename: 'TourConnection' };
                    } & { __typename: 'CountedPlaceTourConnection' };
                  } & { __typename: 'CompanyPlace' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanyProfileQueryVariables = Exact<{
  company_id: Scalars['String'];
}>;

export type CompanyProfileQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              company_name: string | null;
              vatNumbers: Array<{ vatNumber: string } & { __typename: 'CompanyVatNumber' }>;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanySettingsQueryVariables = Exact<{
  company_id: Scalars['String'];
}>;

export type CompanySettingsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              settings: { shareOwnVehiclesInGlobalPool: boolean; allowAllocatingFromGlobalPool: boolean } & {
                __typename: 'CompanySettings';
              };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type UpdateCompanySettingsMutationVariables = Exact<{
  input: UpdateCompanySettingsInput;
}>;

export type UpdateCompanySettingsMutation = {
  updateCompanySettings: ({ company_id: string } & { __typename: 'Company' }) | null;
} & { __typename: 'Mutation' };

export type SetCompanyVatNumbersMutationVariables = Exact<{
  company_id: Scalars['String'];
  input: Array<CompanyVatNumberInput> | CompanyVatNumberInput;
  retainMissingCountryCodes: Scalars['Boolean'];
}>;

export type SetCompanyVatNumbersMutation = {
  setCompanyVATNumbers: ({ success: boolean } & { __typename: 'CompanyVatNumberChangeResult' }) | null;
} & { __typename: 'Mutation' };

export type UserNotificationConfigQueryVariables = Exact<{
  user_id: Scalars['String'];
  company_id: Scalars['String'];
}>;

export type UserNotificationConfigQuery = {
  userNotificationConfig:
    | ({
        user_notification_config_id: string;
        user_id: string;
        company_id: string;
        disabled_types: Array<EmailType>;
      } & { __typename: 'UserNotificationConfig' })
    | null;
} & { __typename: 'Query' };

export type UpdateUserNotificationConfigMutationVariables = Exact<{
  input: UpdateUserNotificationConfigInput;
}>;

export type UpdateUserNotificationConfigMutation = {
  updateUserNotificationConfig: ({ success: boolean } & { __typename: 'UpdateUserNotificationConfigResponse' }) | null;
} & { __typename: 'Mutation' };

export type CreateUserFilterMutationVariables = Exact<{
  input: CreateUserFilterInput;
}>;

export type CreateUserFilterMutation = {
  createUserFilter: ({ userFilterId: string } & { __typename: 'UserFilter' }) | null;
} & { __typename: 'Mutation' };

export type CompanyFiltersWithToursQueryVariables = Exact<{
  companyId: Scalars['String'];
  included_filter_ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type CompanyFiltersWithToursQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              me: ({ role: UserRoleInCompany } & { __typename: 'UserInCompany' }) | null;
              companyTourFiltersConnection: {
                count: number;
                companyTourFilters: {
                  edges: Array<
                    | ({
                        node:
                          | ({
                              companyTourFilterId: string;
                              sharingToken: string | null;
                              name: string;
                              tourCount: number;
                              filter: FilterFragment & { __typename: 'TourFilter' };
                              tours: Array<ShipmentFragment & { __typename: 'Tour' }>;
                            } & { __typename: 'CompanyTourFilter' })
                          | null;
                      } & { __typename: 'CompanyTourFilterEdge' })
                    | null
                  > | null;
                  pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                } & { __typename: 'CompanyTourFilterConnection' };
              } & { __typename: 'CountedCompanyTourFilterConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanyAndPersonalFilterTotalCountsQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;

export type CompanyAndPersonalFilterTotalCountsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              me:
                | ({
                    role: UserRoleInCompany;
                    userFiltersConnection: { count: number } & { __typename: 'CountedUserFilterConnection' };
                  } & { __typename: 'UserInCompany' })
                | null;
              companyTourFiltersConnection: { count: number } & { __typename: 'CountedCompanyTourFilterConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type RemoveUserFilterMutationVariables = Exact<{
  userFilterId: Scalars['String'];
}>;

export type RemoveUserFilterMutation = { removeUserFilter: boolean | null } & { __typename: 'Mutation' };

export type RemoveCompanyFilterMutationVariables = Exact<{
  companyFilterId: Scalars['String'];
}>;

export type RemoveCompanyFilterMutation = { deleteCompanyTourFilter: boolean | null } & { __typename: 'Mutation' };

export type CreateCompanyFilterMutationVariables = Exact<{
  companyFilterInput: CreateCompanyTourFilterInput;
}>;

export type CreateCompanyFilterMutation = {
  createCompanyTourFilter: ({ companyTourFilterId: string; name: string } & { __typename: 'CompanyTourFilter' }) | null;
} & { __typename: 'Mutation' };

export type CreateCompanyTourFilterSharingTokenMutationVariables = Exact<{
  companyTourFilterId: Scalars['String'];
}>;

export type CreateCompanyTourFilterSharingTokenMutation = { createCompanyTourFilterSharingToken: string | null } & {
  __typename: 'Mutation';
};

export type DeleteCompanyTourFilterSharingTokenMutationVariables = Exact<{
  companyTourFilterId: Scalars['String'];
}>;

export type DeleteCompanyTourFilterSharingTokenMutation = { deleteCompanyTourFilterSharingToken: boolean | null } & {
  __typename: 'Mutation';
};

export type ShipmentCountsQueryVariables = Exact<{
  company_id: Scalars['String'];
  role: TourCompanyRole;
}>;

export type ShipmentCountsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              unallocated: { count: number } & { __typename: 'CountedTourConnection' };
              upcoming: { count: number } & { __typename: 'CountedTourConnection' };
              on_time: { count: number } & { __typename: 'CountedTourConnection' };
              delayed: { count: number } & { __typename: 'CountedTourConnection' };
              unknown: { count: number } & { __typename: 'CountedTourConnection' };
              working: { count: number } & { __typename: 'CountedTourConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type ShipmentFragment = {
  tour_id: number;
  status: string | null;
  working_stop_id: number | null;
  shipper_transport_number: string | null;
  transportIdentifiers: Array<string> | null;
  isGlobalAllocation: boolean;
  isGloballyAllocatedToViewerCompany: boolean;
  activeVehicleAllocation:
    | ({
        vehicleLicensePlateNumber: string | null;
        stopAllocations: Array<{ vehicleLicensePlateNumber: string | null } & { __typename: 'StopAllocation' }> | null;
      } & { __typename: 'TourVehicleAllocation' })
    | null;
  warnings: Array<{ type: TourWarningType; severity: TourWarningSeverity | null } & { __typename: 'TourWarning' }>;
  exceptions: Array<
    {
      description: string;
      stopId: string | null;
      deliveryIds: Array<string>;
      exceptionId: string;
      exceptionType: ExceptionType | null;
      customExceptionType: string | null;
      customExceptionTypeDescription: string | null;
      createdAt: string;
      exceptionFiles: Array<
        { documentUrl: string; platformDocumentId: string; description: string | null } & {
          __typename: 'ExceptionFile';
        }
      >;
    } & { __typename: 'TourException' }
  >;
  carrier: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
  shipper: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
  subcontractor: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
  stops: Array<
    {
      stop_id: number;
      type: string | null;
      status: string | null;
      estimated_arrival_accuracy: string | null;
      estimated_arrival_status: string | null;
      estimated_arrival_delay_in_seconds: number | null;
      estimated_arrival: string | null;
      arrival_delay_in_seconds: number | null;
      arrival_time: string | null;
      departure_time: string | null;
      display_sequence_number: number;
      timeslot:
        | ({ begin: string | null; end: string | null; timezone: string | null } & { __typename: 'Timeslot' })
        | null;
      location:
        | ({
            name: string | null;
            address: ({ country: string | null; city: string | null } & { __typename: 'Address' }) | null;
            position: ({ lat: number | null; lng: number | null } & { __typename: 'GeoPosition' }) | null;
          } & { __typename: 'TourLocation' })
        | null;
    } & { __typename: 'TourStop' }
  >;
  assignedUser:
    | ({ user_id: string; first_name: string | null; last_name: string | null; email: string | null } & {
        __typename: 'UserProfile';
      })
    | null;
} & { __typename: 'Tour' };

export type ShipmentsQueryVariables = Exact<{
  company_id: Scalars['String'];
  role: TourCompanyRole;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<TourStatusFilter> | TourStatusFilter>;
  endTimeAfter?: InputMaybe<Scalars['DateTime']>;
  endTimeBefore?: InputMaybe<Scalars['DateTime']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  startTimeAfter?: InputMaybe<Scalars['DateTime']>;
  startTimeBefore?: InputMaybe<Scalars['DateTime']>;
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  delayStatus?: InputMaybe<TourDelayStatusFilter>;
  textSearch?: InputMaybe<TourTextSearch>;
  sortOrder?: InputMaybe<TourSortOrder>;
  siteIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  carrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  shipperIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  mainCarrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch> | TourCustomFieldSearch>;
  failureReasons?: InputMaybe<Array<TourFailureReasonType> | TourFailureReasonType>;
  warnings?: InputMaybe<Array<TourWarningType> | TourWarningType>;
  workingStopTimeslotBeginEnd?: InputMaybe<Scalars['DateTime']>;
  stopCountries?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  stopBookingLocationNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  placeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  timezone?: InputMaybe<Scalars['IANATimezone']>;
  delayTime?: InputMaybe<TimeRangeInput>;
  timeAtStop?: InputMaybe<TimeRangeInput>;
  workingStopStatus?: InputMaybe<WorkingStopStatusFilter>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  allocationStatus?: InputMaybe<TourAllocationStatus>;
  tourIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  stopStates?: InputMaybe<Array<StopStatesInput> | StopStatesInput>;
  documentTypes?: InputMaybe<Array<DocumentType> | DocumentType>;
  exceptionTypes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  orderItemNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  orderItemCustomFields?: InputMaybe<OrderItemCustomFieldInput>;
  hasNotes?: InputMaybe<Scalars['Boolean']>;
  stopCompanyNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  stopFilters?: InputMaybe<Array<StopFilterInput> | StopFilterInput>;
  mainVisibilityIssues?: InputMaybe<Array<VisibilityIssueType> | VisibilityIssueType>;
}>;

export type ShipmentsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tours: {
                count: number;
                reportDownloadURI: string | null;
                tours: {
                  edges: Array<
                    ({ node: (ShipmentFragment & { __typename: 'Tour' }) | null } & { __typename: 'TourEdge' }) | null
                  > | null;
                  pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                } & { __typename: 'TourConnection' };
              } & { __typename: 'CountedTourConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type ShipmentsReportDownloadQueryVariables = Exact<{
  company_id: Scalars['String'];
  role: TourCompanyRole;
  status?: InputMaybe<Array<TourStatusFilter> | TourStatusFilter>;
  endTimeAfter?: InputMaybe<Scalars['DateTime']>;
  endTimeBefore?: InputMaybe<Scalars['DateTime']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  startTimeAfter?: InputMaybe<Scalars['DateTime']>;
  startTimeBefore?: InputMaybe<Scalars['DateTime']>;
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  delayStatus?: InputMaybe<TourDelayStatusFilter>;
  textSearch?: InputMaybe<TourTextSearch>;
  sortOrder?: InputMaybe<TourSortOrder>;
  siteIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  carrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  shipperIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  mainCarrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch> | TourCustomFieldSearch>;
  failureReasons?: InputMaybe<Array<TourFailureReasonType> | TourFailureReasonType>;
  warnings?: InputMaybe<Array<TourWarningType> | TourWarningType>;
  workingStopTimeslotBeginEnd?: InputMaybe<Scalars['DateTime']>;
  stopCountries?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  stopBookingLocationNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  placeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  timezone?: InputMaybe<Scalars['IANATimezone']>;
  delayTime?: InputMaybe<TimeRangeInput>;
  timeAtStop?: InputMaybe<TimeRangeInput>;
  workingStopStatus?: InputMaybe<WorkingStopStatusFilter>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  allocationStatus?: InputMaybe<TourAllocationStatus>;
  stopStates?: InputMaybe<Array<StopStatesInput> | StopStatesInput>;
  documentTypes?: InputMaybe<Array<DocumentType> | DocumentType>;
  exceptionTypes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  hasNotes?: InputMaybe<Scalars['Boolean']>;
  stopCompanyNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  stopFilters?: InputMaybe<Array<StopFilterInput> | StopFilterInput>;
  mainVisibilityIssues?: InputMaybe<Array<VisibilityIssueType> | VisibilityIssueType>;
}>;

export type ShipmentsReportDownloadQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tours: { reportDownloadURI: string | null } & { __typename: 'CountedTourConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type MapShipmentFragment = {
  tour_id: number;
  status: string | null;
  working_stop_id: number | null;
  stops: Array<
    {
      stop_id: number;
      status: string | null;
      arrival_delay_in_seconds: number | null;
      estimated_arrival_status: string | null;
      location:
        | ({ position: ({ lat: number | null; lng: number | null } & { __typename: 'GeoPosition' }) | null } & {
            __typename: 'TourLocation';
          })
        | null;
    } & { __typename: 'TourStop' }
  >;
} & { __typename: 'Tour' };

export type ShipmentsTilesQueryVariables = Exact<{
  company_id: Scalars['String'];
  role: TourCompanyRole;
  status?: InputMaybe<Array<TourStatusFilter> | TourStatusFilter>;
  endTimeAfter?: InputMaybe<Scalars['DateTime']>;
  endTimeBefore?: InputMaybe<Scalars['DateTime']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  startTimeAfter?: InputMaybe<Scalars['DateTime']>;
  startTimeBefore?: InputMaybe<Scalars['DateTime']>;
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  delayStatus?: InputMaybe<TourDelayStatusFilter>;
  textSearch?: InputMaybe<TourTextSearch>;
  siteIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  carrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  shipperIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch> | TourCustomFieldSearch>;
  failureReasons?: InputMaybe<Array<TourFailureReasonType> | TourFailureReasonType>;
  warnings?: InputMaybe<Array<TourWarningType> | TourWarningType>;
  workingStopTimeslotBeginEnd?: InputMaybe<Scalars['DateTime']>;
  stopCountries?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  stopBookingLocationNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  placeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  delayTime?: InputMaybe<TimeRangeInput>;
  timeAtStop?: InputMaybe<TimeRangeInput>;
  workingStopStatus?: InputMaybe<WorkingStopStatusFilter>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  allocationStatus?: InputMaybe<TourAllocationStatus>;
  stopStates?: InputMaybe<Array<StopStatesInput> | StopStatesInput>;
  documentTypes?: InputMaybe<Array<DocumentType> | DocumentType>;
  exceptionTypes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  orderItemNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  orderItemCustomFields?: InputMaybe<OrderItemCustomFieldInput>;
  hasNotes?: InputMaybe<Scalars['Boolean']>;
  stopFilters?: InputMaybe<Array<StopFilterInput> | StopFilterInput>;
}>;

export type ShipmentsTilesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tours: {
                mapTilesTemplateURI: string | null;
                mapTilesBounds:
                  | ({
                      ne: { lat: number; lng: number } & { __typename: 'PointLocation' };
                      sw: { lat: number; lng: number } & { __typename: 'PointLocation' };
                    } & { __typename: 'Bounds' })
                  | null;
              } & { __typename: 'CountedTourConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type ShipmentsLocationsQueryVariables = Exact<{
  company_id: Scalars['String'];
  role: TourCompanyRole;
  status?: InputMaybe<Array<TourStatusFilter> | TourStatusFilter>;
  endTimeAfter?: InputMaybe<Scalars['DateTime']>;
  endTimeBefore?: InputMaybe<Scalars['DateTime']>;
  endTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  startTimeAfter?: InputMaybe<Scalars['DateTime']>;
  startTimeBefore?: InputMaybe<Scalars['DateTime']>;
  startTimeSugarTimezone?: InputMaybe<Scalars['IANATimezone']>;
  delayStatus?: InputMaybe<TourDelayStatusFilter>;
  textSearch?: InputMaybe<TourTextSearch>;
  siteIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  carrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  shipperIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch> | TourCustomFieldSearch>;
  failureReasons?: InputMaybe<Array<TourFailureReasonType> | TourFailureReasonType>;
  warnings?: InputMaybe<Array<TourWarningType> | TourWarningType>;
  workingStopTimeslotBeginEnd?: InputMaybe<Scalars['DateTime']>;
  stopCountries?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  stopBookingLocationNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  placeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  delayTime?: InputMaybe<TimeRangeInput>;
  timeAtStop?: InputMaybe<TimeRangeInput>;
  workingStopStatus?: InputMaybe<WorkingStopStatusFilter>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  allocationStatus?: InputMaybe<TourAllocationStatus>;
  stopStates?: InputMaybe<Array<StopStatesInput> | StopStatesInput>;
  documentTypes?: InputMaybe<Array<DocumentType> | DocumentType>;
  exceptionTypes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  orderItemNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  orderItemCustomFields?: InputMaybe<OrderItemCustomFieldInput>;
  hasNotes?: InputMaybe<Scalars['Boolean']>;
  stopCompanyNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  stopFilters?: InputMaybe<Array<StopFilterInput> | StopFilterInput>;
  mainVisibilityIssues?: InputMaybe<Array<VisibilityIssueType> | VisibilityIssueType>;
}>;

export type ShipmentsLocationsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tours: {
                tours: {
                  edges: Array<
                    | ({ node: (MapShipmentFragment & { __typename: 'Tour' }) | null } & { __typename: 'TourEdge' })
                    | null
                  > | null;
                } & { __typename: 'TourConnection' };
              } & { __typename: 'CountedTourConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type FocusedMapShipmentQueryVariables = Exact<{
  company_id: Scalars['String'];
  tour_id: Scalars['Int'];
}>;

export type FocusedMapShipmentQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tour:
                | ({
                    vehicleHistory: Array<
                      {
                        polyline: { points: string } & { __typename: 'Polyline' };
                        metadata: {
                          isTroubleshootingRange: boolean;
                          type: VehicleHistoryPolylineType;
                          startTime: string;
                          endTime: string;
                        } & { __typename: 'VehicleHistoryPolylineMetadata' };
                      } & { __typename: 'VehicleHistoryPolyline' }
                    > | null;
                    vehicle_status:
                      | ({
                          timestamp: string | null;
                          latitude: number | null;
                          longitude: number | null;
                          heading: number | null;
                          accuracyInMeters: number | null;
                        } & { __typename: 'VehicleStatus' })
                      | null;
                    route:
                      | ({
                          legs: Array<
                            { polyline: { points: string } & { __typename: 'Polyline' } } & { __typename: 'RouteLeg' }
                          >;
                        } & { __typename: 'Route' })
                      | null;
                    visibleVehicleBreakHistory: Array<
                      {
                        vehicle_id: string;
                        breaks: Array<
                          {
                            breakId: string;
                            from: string;
                            to: string | null;
                            center: { lat: number; lng: number } & { __typename: 'BreakCenterPosition' };
                          } & { __typename: 'VehicleBreakHistory' }
                        >;
                      } & { __typename: 'VehicleBreakHistoryEntry' }
                    > | null;
                  } & ShipmentFragment & { __typename: 'Tour' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type ShipmentsFilterOptionCountsQueryVariables = Exact<{
  company_id: Scalars['String'];
  transportMode: TourFilteringTransportMode;
}>;

export type ShipmentsFilterOptionCountsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              hasHadAnyDocuments: boolean;
              hasHadAnyExceptions: boolean;
              hasHadAnyNotes: boolean;
              placesConnection: { hasAny: boolean } & { __typename: 'CountedCompanyPlaceConnection' };
              sitesConnection: { hasAny: boolean } & { __typename: 'CountedSiteConnection' };
              carriersConnection: { hasAny: boolean } & { __typename: 'CountedCarrierConnection' };
              shippersConnection: { hasAny: boolean } & { __typename: 'CountedShipperConnection' };
              mainCarriersConnection: { hasAny: boolean } & { __typename: 'CountedMainCarrierConnection' };
              bookingLocations: { hasAny: boolean } & { __typename: 'CountedCompanyBookingLocation' };
              connectedCustomFields: Array<{ name: string } & { __typename: 'ConnectedCustomField' }>;
              users: { hasAny: boolean } & { __typename: 'CountedUserInCompanyProfileConnection' };
              stopStatesIdentifiersConnection: { hasAny: boolean } & {
                __typename: 'CountedCompanyStopStatesConnection';
              };
              orderItemIdentifiersConnection: { hasAny: boolean } & { __typename: 'CountedOrderItemConnection' };
              orderItemCustomFieldIdentifiersConnection: { hasAny: boolean } & {
                __typename: 'CountedOrderItemCustomFieldConnection';
              };
              stopCompanyNameIdentifiersConnection: { hasAny: boolean } & {
                __typename: 'CountedStopCompanyNameConnection';
              };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TransportPlacesSearchQueryVariables = Exact<{
  company_id: Scalars['String'];
  textSearch?: InputMaybe<PlaceTextSearch>;
  first?: InputMaybe<Scalars['Int']>;
  placeIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type TransportPlacesSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              placesConnection: {
                places: {
                  edges: Array<
                    | ({ node: ({ name: string; place_id: string } & { __typename: 'CompanyPlace' }) | null } & {
                        __typename: 'CompanyPlaceEdge';
                      })
                    | null
                  > | null;
                } & { __typename: 'CompanyPlaceConnection' };
              } & { __typename: 'CountedCompanyPlaceConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TransportSitesSearchQueryVariables = Exact<{
  company_id: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  siteIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type TransportSitesSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              sitesConnection: {
                sites: {
                  edges: Array<
                    | ({ node: ({ name: string; site_id: string } & { __typename: 'Site' }) | null } & {
                        __typename: 'SiteEdge';
                      })
                    | null
                  > | null;
                } & { __typename: 'SiteConnection' };
              } & { __typename: 'CountedSiteConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TransportUsersSearchQueryVariables = Exact<{
  company_id: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  userIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type TransportUsersSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              users: {
                users: {
                  edges: Array<
                    | ({
                        node:
                          | ({
                              profile: {
                                user_id: string;
                                email: string | null;
                                first_name: string | null;
                                last_name: string | null;
                              } & { __typename: 'UserProfile' };
                            } & { __typename: 'UserInCompanyProfile' })
                          | null;
                      } & { __typename: 'UserInCompanyProfileEdge' })
                    | null
                  > | null;
                } & { __typename: 'UserInCompanyProfileConnection' };
              } & { __typename: 'CountedUserInCompanyProfileConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TransportCarriersSearchQueryVariables = Exact<{
  company_id: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  carrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type TransportCarriersSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              carriersConnection: {
                carriers: {
                  edges: Array<
                    | ({
                        node:
                          | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                          | null;
                      } & { __typename: 'CompanyProfileEdge' })
                    | null
                  > | null;
                } & { __typename: 'CompanyProfileConnection' };
              } & { __typename: 'CountedCarrierConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TransportShippersSearchQueryVariables = Exact<{
  company_id: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  shipperIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type TransportShippersSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              shippersConnection: {
                shippers: {
                  edges: Array<
                    | ({
                        node:
                          | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                          | null;
                      } & { __typename: 'CompanyProfileEdge' })
                    | null
                  > | null;
                } & { __typename: 'CompanyProfileConnection' };
              } & { __typename: 'CountedShipperConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TransportMainCarriersSearchQueryVariables = Exact<{
  company_id: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  mainCarrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type TransportMainCarriersSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              mainCarriersConnection: {
                mainCarriers: {
                  edges: Array<
                    | ({
                        node:
                          | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                          | null;
                      } & { __typename: 'CompanyProfileEdge' })
                    | null
                  > | null;
                } & { __typename: 'CompanyProfileConnection' };
              } & { __typename: 'CountedMainCarrierConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TransportBookingLocationsSearchQueryVariables = Exact<{
  company_id: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type TransportBookingLocationsSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              bookingLocations: {
                bookingLocations: {
                  edges: Array<
                    | ({ node: ({ bookingLocationName: string } & { __typename: 'CompanyBookingLocation' }) | null } & {
                        __typename: 'CompanyBookingLocationEdge';
                      })
                    | null
                  > | null;
                } & { __typename: 'CompanyBookingLocationConnection' };
              } & { __typename: 'CountedCompanyBookingLocation' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TransportStopCompanyNamesQueryVariables = Exact<{
  companyId: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
}>;

export type TransportStopCompanyNamesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              stopCompanyNameIdentifiersConnection: {
                stopCompanyNameIdentifiers: {
                  edges: Array<
                    | ({ node: ({ value: string } & { __typename: 'StopCompanyName' }) | null } & {
                        __typename: 'StopCompanyNameEdge';
                      })
                    | null
                  > | null;
                } & { __typename: 'StopCompanyNameConnection' };
              } & { __typename: 'CountedStopCompanyNameConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type FilterFragment = {
  carrierIds: Array<string> | null;
  shipperIds: Array<string> | null;
  mainCarrierIds: Array<string> | null;
  platformId: string | null;
  startTimeStart: string | null;
  startTimeEnd: string | null;
  startTimeSugarTimezone: string | null;
  endTimeStart: string | null;
  endTimeEnd: string | null;
  endTimeSugarTimezone: string | null;
  statuses: Array<TourStatusFilter> | null;
  delayStatus: TourDelayStatusFilter | null;
  siteIds: Array<string> | null;
  failureReasons: Array<TourFailureReasonType> | null;
  warnings: Array<TourWarningType> | null;
  workingStopTimeslotBeginEnd: string | null;
  stopCountries: Array<string> | null;
  stopBookingLocationNames: Array<string> | null;
  placeIds: Array<string> | null;
  workingStopStatus: WorkingStopStatusFilter | null;
  assignedToUserIds: Array<string> | null;
  allocationStatus: TourAllocationStatus | null;
  carrierNames: Array<string> | null;
  carrierCodes: Array<string> | null;
  transportGroupIds: Array<string> | null;
  containerNumbers: Array<string> | null;
  bookingNumbers: Array<string> | null;
  billOfLadingNumbers: Array<string> | null;
  transportMode: Array<TourFilteringTransportMode> | null;
  airWayBillNumbers: Array<string> | null;
  documentTypes: Array<DocumentType> | null;
  exceptionTypes: Array<string> | null;
  orderItemNames: Array<string> | null;
  hasNotes: boolean | null;
  stopCompanyNames: Array<string> | null;
  mainVisibilityIssues: Array<VisibilityIssueType> | null;
  customFields: Array<
    {
      fieldName: string;
      value: { values: Array<string> | null } & { __typename: 'TourCustomFieldSearchValueType' };
    } & { __typename: 'TourCustomFieldSearchType' }
  > | null;
  delayTime:
    | ({ startInSeconds: number; endInSeconds: number | null; unit: TimeUnit; comparison: ComparisonOperator } & {
        __typename: 'TimeRange';
      })
    | null;
  timeSpentAtStop:
    | ({ startInSeconds: number; endInSeconds: number | null; unit: TimeUnit; comparison: ComparisonOperator } & {
        __typename: 'TimeRange';
      })
    | null;
  locations: Array<
    {
      types: Array<string | null> | null;
      countries: Array<string | null> | null;
      locodes: Array<string | null> | null;
      iatas: Array<string | null> | null;
      names: Array<string | null> | null;
      milestones: Array<
        | ({
            milestoneQualifier: string;
            delayTime:
              | ({
                  criteria: string;
                  anyTimerangeCriteria: boolean | null;
                  timerange:
                    | ({
                        comparison: DelayComparisonOperator;
                        startInSeconds: number;
                        delayUnit: DelayTimerangeUnit;
                      } & { __typename: 'DelayTimeRangeOutput' })
                    | null;
                } & { __typename: 'MilestoneDelayOutput' })
              | null;
            timelineEvents: Array<
              {
                criteria: string;
                timerange:
                  | ({
                      comparison: ExtendedComparisonOperator;
                      startSugarDate: string | null;
                      endSugarDate: string | null;
                    } & { __typename: 'TimeRangeSugarDateOutput' })
                  | null;
              } & { __typename: 'LocationMilestoneTimelineEventOutput' }
            > | null;
          } & { __typename: 'LocationMilestoneOutput' })
        | null
      > | null;
    } & { __typename: 'TransportLocationOutput' }
  > | null;
  demurrage:
    | ({
        names: Array<string> | null;
        states: Array<string> | null;
        cost: number | null;
        currency: string | null;
        remainingFreeDays: number | null;
        types: Array<DemurrageType> | null;
      } & { __typename: 'OceanDemurrageFilterOutput' })
    | null;
  transportAlerts:
    | ({ severities: Array<TransportAlertSeverity> | null; types: Array<TransportAlertType> | null } & {
        __typename: 'TransportAlertsFilterOutput';
      })
    | null;
  impactDays:
    | ({ comparison: ImpactDaysComparison; startInSeconds: number } & { __typename: 'ImpactDaysOutput' })
    | null;
  stopStates: Array<{ country: string; state: string } & { __typename: 'CompanyStopStates' }> | null;
  vesselName:
    | ({
        current: Array<string | null> | null;
        fromPol: Array<string | null> | null;
        toPod: Array<string | null> | null;
      } & { __typename: 'VesselNameFilterOutput' })
    | null;
  orderItemCustomFields:
    | ({ productBrandValues: Array<string> | null; productLineValues: Array<string> | null } & {
        __typename: 'OrderItemCustomFieldOutput';
      })
    | null;
  stopFilters: Array<
    {
      placeIds: Array<string> | null;
      companyNames: Array<string> | null;
      bookingLocationNames: Array<string> | null;
      countries: Array<string> | null;
      stopTypes: Array<FilterRoadStopType> | null;
      states: Array<{ country: string; state: string } & { __typename: 'CompanyStopStates' }> | null;
    } & { __typename: 'StopFilterOutput' }
  > | null;
} & { __typename: 'TourFilter' };

export type PersonalTourFiltersQueryVariables = Exact<{
  companyId: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  filterType?: InputMaybe<FilterType>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type PersonalTourFiltersQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              me:
                | ({
                    userFiltersConnection: {
                      count: number;
                      userFilters: {
                        edges: Array<
                          | ({
                              node:
                                | ({
                                    userFilterId: string;
                                    name: string;
                                    filter: FilterFragment & { __typename: 'TourFilter' };
                                  } & { __typename: 'UserFilter' })
                                | null;
                            } & { __typename: 'UserFilterEdge' })
                          | null
                        > | null;
                        pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                      } & { __typename: 'UserFilterConnection' };
                    } & { __typename: 'CountedUserFilterConnection' };
                  } & { __typename: 'UserInCompany' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanyTourFiltersQueryVariables = Exact<{
  companyId: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  filterType?: InputMaybe<FilterType>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type CompanyTourFiltersQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              companyTourFiltersConnection: {
                count: number;
                companyTourFilters: {
                  edges: Array<
                    | ({
                        node:
                          | ({
                              companyTourFilterId: string;
                              sharingToken: string | null;
                              name: string;
                              filter: FilterFragment & { __typename: 'TourFilter' };
                            } & { __typename: 'CompanyTourFilter' })
                          | null;
                      } & { __typename: 'CompanyTourFilterEdge' })
                    | null
                  > | null;
                  pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                } & { __typename: 'CompanyTourFilterConnection' };
              } & { __typename: 'CountedCompanyTourFilterConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanyCustomFieldsQueryVariables = Exact<{
  companyId: Scalars['String'];
  transportMode: TourFilteringTransportMode;
}>;

export type CompanyCustomFieldsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              connectedCustomFields: Array<{ name: string } & { __typename: 'ConnectedCustomField' }>;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanyCustomFieldQueryVariables = Exact<{
  companyId: Scalars['String'];
  fieldName: Scalars['String'];
  transportMode: TourFilteringTransportMode;
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type CompanyCustomFieldQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              customField:
                | ({
                    valuesConnection: {
                      values: {
                        edges: Array<
                          | ({ node: ({ value: string } & { __typename: 'CustomFieldValue' }) | null } & {
                              __typename: 'CustomFieldValueEdge';
                            })
                          | null
                        > | null;
                      } & { __typename: 'CustomFieldValueConnection' };
                    } & { __typename: 'CountedCustomFieldValueConnection' };
                  } & { __typename: 'ConnectedCustomField' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TransportCountryAndStateSearchQueryVariables = Exact<{
  company_id: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type TransportCountryAndStateSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              stopStatesIdentifiersConnection: {
                stopStatesIdentifiers: {
                  edges: Array<
                    | ({ node: ({ country: string; state: string } & { __typename: 'CompanyStopStates' }) | null } & {
                        __typename: 'CompanyStopStatesEdge';
                      })
                    | null
                  > | null;
                } & { __typename: 'CompanyStopStatesConnection' };
              } & { __typename: 'CountedCompanyStopStatesConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type OrderItemsSearchQueryVariables = Exact<{
  company_id: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type OrderItemsSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              orderItemIdentifiersConnection: {
                orderItemIdentifiers: {
                  edges: Array<
                    | ({
                        node:
                          | ({ orderItemId: string; orderItemName: string } & { __typename: 'OrderItemIdentifier' })
                          | null;
                      } & { __typename: 'OrderItemIdentifierEdge' })
                    | null
                  > | null;
                } & { __typename: 'OrderItemIdentifierConnection' };
              } & { __typename: 'CountedOrderItemConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type OrderItemCustomFieldSearchQueryVariables = Exact<{
  company_id: Scalars['String'];
  search?: InputMaybe<OrderItemCustomFieldSearch>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type OrderItemCustomFieldSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              orderItemCustomFieldIdentifiersConnection: {
                orderItemCustomFieldIdentifiers: {
                  edges: Array<
                    | ({
                        node:
                          | ({ name: string; value: string; id: string } & { __typename: 'OrderItemCustomField' })
                          | null;
                      } & { __typename: 'OrderItemCustomFieldEdge' })
                    | null
                  > | null;
                } & { __typename: 'OrderItemCustomFieldConnection' };
              } & { __typename: 'CountedOrderItemCustomFieldConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type SharingTokenFragment = {
  token: string;
  createdBy:
    | ({ user_id: string; first_name: string | null; last_name: string | null } & { __typename: 'UserProfile' })
    | null;
} & { __typename: 'SharingToken' };

export type TourContainerDetailsFragment = {
  tour_id: number;
  transport_id: string | null;
  platform_shipper_id: string | null;
  platform_carrier_id: string | null;
  shipper_transport_number: string | null;
  start_time: string | null;
  status: string | null;
  working_stop_id: number | null;
  visibility_start_time: string | null;
  visibility_end_time: string | null;
  visibleTrackingStartTime: string | null;
  trackingAbandonTime: string | null;
  allocationType: TourVehicleAllocationType | null;
  hasTemperatureData: boolean | null;
  canAbortTour: boolean;
  isGlobalAllocation: boolean;
  isGloballyAllocatedToViewerCompany: boolean;
  canBeModified: boolean;
  isTourAccessedByCreator: boolean;
  platformTransportURL: string | null;
  platformBranchId: string | null;
  platform_transport_number: string | null;
  visibilityIndex: number | null;
  sharingToken: string | null;
  hasTransportNotifications?: boolean;
  plannedEmissions: ({ co2WellToWheelTonnes: number } & { __typename: 'TourCalculatedEmissions' }) | null;
  fuelBasedEmissions: ({ co2WellToWheelTonnes: number | null } & { __typename: 'FuelBasedEmissions' }) | null;
  failureReason:
    | ({
        type: TourFailureReasonType;
        metadata:
          | ({ nr_of_stops_visited: string | null; allocated_license_plates: string | null } & {
              __typename: 'TourFailureReasonMetadata';
            })
          | null;
      } & { __typename: 'TourFailureReason' })
    | null;
  warnings: Array<
    {
      type: TourWarningType;
      severity: TourWarningSeverity | null;
      metadata: { [key: string]: string | number | boolean } | null;
    } & { __typename: 'TourWarning' }
  >;
  vehicleAllocations: Array<
    { vehicleId: string | null; vehicleLicensePlateNumber: string | null } & { __typename: 'TourVehicleAllocation' }
  >;
  activeVehicleAllocation:
    | ({
        vehicleId: string | null;
        vehicleLicensePlateNumber: string | null;
        vehicleTrackerTypes: Array<VehicleTrackerType> | null;
        remoteTrackerId: string | null;
        allocationDeviceType: TourAllocationDeviceType | null;
      } & { __typename: 'TourVehicleAllocation' })
    | null;
  currentVehicle:
    | ({ vehicleId: string | null; vehicleLicensePlateNumber: string | null } & { __typename: 'VehicleAllocation' })
    | null;
  customFields: Array<
    { fieldName: string; values: Array<{ value: string } & { __typename: 'CustomFieldValue' }> } & {
      __typename: 'CustomField';
    }
  >;
  platform: ({ platform_id: string; platform_name: string } & { __typename: 'Platform' }) | null;
  externalTourEvents: Array<
    {
      eventId: string;
      timestamp: string;
      eventSource: ExternalEventSource;
      externalEventSource: string | null;
      qualifier: ExternalTourEventQualifier;
      externalEventQualifier: string;
      licensePlateNumber: string | null;
      remoteId: string | null;
      vehicleType: VehicleType | null;
      locationName: string | null;
    } & { __typename: 'ExternalTourEvent' }
  >;
  externalDeliveryEvents: Array<
    {
      eventId: string;
      timestamp: string;
      eventSource: ExternalEventSource;
      externalEventSource: string | null;
      externalEventQualifier: string;
      delivery: ({ deliveryId: string; deliveryNumber: string | null } & { __typename: 'TourDelivery' }) | null;
    } & { __typename: 'ExternalTourDeliveryEvent' }
  >;
  externalStopEvents: Array<
    {
      eventId: string;
      timestamp: string;
      eventSource: ExternalEventSource;
      externalEventSource: string | null;
      externalEventQualifier: string;
      stopId: string;
    } & { __typename: 'ExternalTourStopEvent' }
  >;
  events: Array<
    | ({
        stop_id: number;
        tour_id: number;
        event_id: number;
        vehicle_id: string | null;
        vehicle_lat: number | null;
        vehicle_lng: number | null;
        vehicle_license_plate_number: string | null;
        event_index: number;
        event_time: string | null;
        created_at: string;
        stop_event_name: StopEventName;
        visibilitySource:
          | ({
              primaryEventSource: TourVisibilitySourceType;
              sourcePlatform:
                | ({ platform_id: string; platform_name: string } & { __typename: 'PlatformProfile' })
                | null;
            } & { __typename: 'TourVisibilitySource' })
          | null;
      } & { __typename: 'StopEvent' })
    | ({
        tour_id: number;
        event_id: number;
        vehicle_id: string | null;
        vehicle_lat: number | null;
        vehicle_lng: number | null;
        vehicle_license_plate_number: string | null;
        event_index: number;
        event_time: string | null;
        created_at: string;
        tour_event_name: TourEventName;
        warning:
          | ({ type: TourWarningType; severity: TourWarningSeverity | null } & { __typename: 'TourWarning' })
          | null;
        metadata:
          | ({ parameters: { [key: string]: string | number | boolean } | null } & { __typename: 'TourEventMetadata' })
          | null;
        visibilitySource:
          | ({
              primaryEventSource: TourVisibilitySourceType;
              sourcePlatform:
                | ({ platform_id: string; platform_name: string } & { __typename: 'PlatformProfile' })
                | null;
            } & { __typename: 'TourVisibilitySource' })
          | null;
      } & { __typename: 'TourEvent' })
  >;
  applicationDeepLinks: Array<{ applicationName: string; url: string } & { __typename: 'ApplicationDeepLink' }> | null;
  stops: Array<
    {
      stop_id: number;
      status: string | null;
      type: string | null;
      display_sequence_number: number;
      externalEtaSource: ExternalEtaSource | null;
      estimated_arrival: string | null;
      estimated_arrival_status: string | null;
      estimated_arrival_accuracy: string | null;
      estimated_arrival_delay_in_seconds: number | null;
      estimated_arrival_distance_in_meters: number | null;
      arrival_delay_in_seconds: number | null;
      arrival_time: string | null;
      departure_time: string | null;
      deadline: string | null;
      sharingTokenV2?: (SharingTokenFragment & { __typename: 'SharingToken' }) | null;
      geofenceZones?: Array<
        {
          zone: GeofenceZoneType;
          geofence:
            | ({
                type: GeofenceType;
                radiusInMeters: number;
                origin: { lat: number; lng: number } & { __typename: 'Position' };
              } & { __typename: 'GeofenceCircle' })
            | ({ type: GeofenceType; points: Array<{ lat: number; lng: number } & { __typename: 'Position' }> } & {
                __typename: 'GeofencePolygon';
              });
        } & { __typename: 'GeofenceZone' }
      >;
      vehicleAllocation:
        | ({ vehicleId: string | null; vehicleLicensePlateNumber: string | null } & { __typename: 'VehicleAllocation' })
        | null;
      timeslot:
        | ({ begin: string | null; end: string | null; timezone: string | null } & { __typename: 'Timeslot' })
        | null;
      deliveries: Array<
        {
          deliveryId: string;
          deliveryNumber: string | null;
          orderNumber: string | null;
          purchaseOrderNumber: string | null;
          orderDate: string | null;
          customFields?: Array<
            { fieldName: string; values: Array<{ value: string } & { __typename: 'CustomFieldValue' }> } & {
              __typename: 'CustomField';
            }
          >;
          orderItems: Array<
            {
              orderItemId: string;
              orderItemName: string | null;
              pieceCount: number | null;
              pieceDescription: string | null;
              pieceUnit: PieceUnit | null;
              volume: number | null;
              volumeUnit: VolumeUnit | null;
              weightAmount: number | null;
              weightUnit: WeightUnit | null;
              isHazardous: boolean | null;
              itemHeight: number | null;
              itemLength: number | null;
              itemWidth: number | null;
              linearUnit: LinearUnit | null;
              orderItemCustomFields: Array<
                { name: string; value: string } & { __typename: 'OrderItemCustomField' }
              > | null;
            } & { __typename: 'OrderItem' }
          > | null;
        } & { __typename: 'TourDelivery' }
      >;
      location:
        | ({
            location_id: string | null;
            name: string | null;
            bookingLocationName: string | null;
            gate_id: string | null;
            gate: string | null;
            place?: ({ place_id: string; name: string } & { __typename: 'CompanyPlace' }) | null;
            address:
              | ({
                  full_address: string | null;
                  city: string | null;
                  country: string | null;
                  street_address: string | null;
                  state: string | null;
                  zipcode: string | null;
                  stopAddressId: string | null;
                  shortAreaCode?: string | null;
                } & { __typename: 'Address' })
              | null;
            position:
              | ({ lat: number | null; lng: number | null; provider: string | null } & { __typename: 'GeoPosition' })
              | null;
          } & { __typename: 'TourLocation' })
        | null;
    } & { __typename: 'TourStop' }
  >;
  shipper: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
  carrier: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
  subcontractor: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
  vehicleHistory: Array<
    {
      polyline: { points: string } & { __typename: 'Polyline' };
      metadata: {
        type: VehicleHistoryPolylineType;
        startTime: string;
        endTime: string;
        isTroubleshootingRange: boolean;
      } & { __typename: 'VehicleHistoryPolylineMetadata' };
    } & { __typename: 'VehicleHistoryPolyline' }
  > | null;
  vehicleTemperatures?: Array<
    {
      compartmentName: string;
      sensors: Array<
        {
          name: string;
          readings: Array<{ timestamp: string; value: number } & { __typename: 'TemperatureSensorReading' }>;
        } & { __typename: 'TemperatureSensor' }
      >;
    } & { __typename: 'VehicleTemperature' }
  > | null;
  vehicle_status:
    | ({
        timestamp: string | null;
        latitude: number | null;
        longitude: number | null;
        heading: number | null;
        accuracyInMeters: number | null;
      } & { __typename: 'VehicleStatus' })
    | null;
  visibleVehicleBreakHistory: Array<
    {
      vehicle_id: string;
      breaks: Array<
        {
          breakId: string;
          from: string;
          to: string | null;
          center: { lat: number; lng: number } & { __typename: 'BreakCenterPosition' };
        } & { __typename: 'VehicleBreakHistory' }
      >;
    } & { __typename: 'VehicleBreakHistoryEntry' }
  > | null;
  route:
    | ({ legs: Array<{ polyline: { points: string } & { __typename: 'Polyline' } } & { __typename: 'RouteLeg' }> } & {
        __typename: 'Route';
      })
    | null;
  latestSignal: ({ source: TourSignalSource; timestamp: string } & { __typename: 'TourSignal' }) | null;
  mainVisibilityIssue: ({ type: VisibilityIssueType } & { __typename: 'VisibilityIssue' }) | null;
  transportNotifications?: Array<
    {
      notificationId: string;
      createdByUserId: string;
      createdAt: string;
      notificationTrigger:
        | ({
            failureReasons: Array<TourFailureReasonType> | null;
            warnings: Array<TourWarningType> | null;
            etaComparison: Array<
              {
                comparisonTerm: ComparisonTerm;
                comparisonValueInSeconds: number;
                stopId: string | null;
                comparisonUnit: ComparisonUnit;
                remainingTimeInSeconds: number | null;
                remainingTimeUnit: ComparisonUnit | null;
              } & { __typename: 'EtaComparison' }
            > | null;
          } & { __typename: 'NotificationTrigger' })
        | null;
      recipientsV2: Array<
        ({ email: string; locale: string } & { __typename: 'EmailRecipient' }) | { __typename: 'StopContactRecipient' }
      >;
    } & { __typename: 'FilterBasedTransportNotification' }
  >;
  documents?: Array<
    {
      documentId: string;
      stopId: string | null;
      documentType: DocumentType;
      documentUrl: string;
      description: string | null;
      createdAt: string;
      associatedDeliveries: Array<
        { deliveryId: string; deliveryNumber: string | null } & { __typename: 'TourDelivery' }
      >;
    } & { __typename: 'Document' }
  >;
  notes?: Array<
    {
      noteId: string;
      stopId: string | null;
      noteText: string;
      noteVisibility: NoteVisibility;
      noteOrigin: NoteOrigin;
      createdBy: NoteCreatedBy;
      createdAt: string;
      updatedAt: string;
      associatedDeliveries: Array<
        { deliveryId: string; deliveryNumber: string | null } & { __typename: 'TourDelivery' }
      >;
    } & { __typename: 'Note' }
  >;
  exceptions?: Array<
    {
      description: string;
      stopId: string | null;
      deliveryIds: Array<string>;
      exceptionId: string;
      exceptionType: ExceptionType | null;
      customExceptionType: string | null;
      customExceptionTypeDescription: string | null;
      createdAt: string;
      exceptionFiles: Array<
        { documentUrl: string; platformDocumentId: string; description: string | null } & {
          __typename: 'ExceptionFile';
        }
      >;
    } & { __typename: 'TourException' }
  >;
} & { __typename: 'Tour' };

export type CompanyTourQueryVariables = Exact<{
  company_id: Scalars['String'];
  tour_id: Scalars['Int'];
  skipSharingToken?: InputMaybe<Scalars['Boolean']>;
  skipPlace?: InputMaybe<Scalars['Boolean']>;
  skipExternalEvents: Scalars['Boolean'];
  skipTemperature: Scalars['Boolean'];
  skipShortAreaCodes?: InputMaybe<Scalars['Boolean']>;
  skipNotifications?: InputMaybe<Scalars['Boolean']>;
  skipHasNotifications?: InputMaybe<Scalars['Boolean']>;
  includeDeliveriesCustomFieldsInTourCustomFields?: InputMaybe<Scalars['Boolean']>;
  skipDeliveriesCustomFields?: InputMaybe<Scalars['Boolean']>;
  skipDocuments?: InputMaybe<Scalars['Boolean']>;
  skipNotes?: InputMaybe<Scalars['Boolean']>;
  skipExceptions?: InputMaybe<Scalars['Boolean']>;
  skipGeofenceZones?: InputMaybe<Scalars['Boolean']>;
}>;

export type CompanyTourQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tour:
                | ({
                    tourAssignment:
                      | ({
                          assignedToUser:
                            | ({
                                user_id: string;
                                first_name: string | null;
                                last_name: string | null;
                                email: string | null;
                              } & { __typename: 'UserProfile' })
                            | null;
                        } & { __typename: 'TourAssignment' })
                      | null;
                  } & TourContainerDetailsFragment & { __typename: 'Tour' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TokenTourQueryVariables = Exact<{
  company_id?: InputMaybe<Scalars['String']>;
  skipSharingToken?: InputMaybe<Scalars['Boolean']>;
  skipPlace?: InputMaybe<Scalars['Boolean']>;
  skipExternalEvents: Scalars['Boolean'];
  skipTemperature: Scalars['Boolean'];
  skipShortAreaCodes?: InputMaybe<Scalars['Boolean']>;
  skipNotifications?: InputMaybe<Scalars['Boolean']>;
  skipHasNotifications?: InputMaybe<Scalars['Boolean']>;
  includeDeliveriesCustomFieldsInTourCustomFields?: InputMaybe<Scalars['Boolean']>;
  skipDeliveriesCustomFields?: InputMaybe<Scalars['Boolean']>;
  skipDocuments?: InputMaybe<Scalars['Boolean']>;
  skipNotes?: InputMaybe<Scalars['Boolean']>;
  skipExceptions?: InputMaybe<Scalars['Boolean']>;
  skipGeofenceZones?: InputMaybe<Scalars['Boolean']>;
}>;

export type TokenTourQuery = {
  token:
    | ({ tour: (TourContainerDetailsFragment & { __typename: 'Tour' }) | null } & { __typename: 'TokenResources' })
    | null;
} & { __typename: 'Query' };

export type CompanyIdAndVisibilityServicesForTourQueryVariables = Exact<{
  tourId: Scalars['String'];
}>;

export type CompanyIdAndVisibilityServicesForTourQuery = {
  viewer:
    | ({
        user_id: string;
        companyIdAndVisibilityServicesForTour:
          | ({ companyId: string; visibilityServices: Array<VisibilityService> | null } & {
              __typename: 'CompanyIdAndVisibilityServices';
            })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TokenTourVisibilityServicesQueryVariables = Exact<{ [key: string]: never }>;

export type TokenTourVisibilityServicesQuery = {
  token:
    | ({ tour: ({ tour_id: number; visibilityServices: Array<VisibilityService> } & { __typename: 'Tour' }) | null } & {
        __typename: 'TokenResources';
      })
    | null;
} & { __typename: 'Query' };

export type CompanyUserQueryVariables = Exact<{
  companyId: Scalars['String'];
  userId: Scalars['String'];
}>;

export type CompanyUserQuery = {
  company:
    | ({
        company_id: string;
        user:
          | ({
              profile: {
                user_id: string;
                first_name: string | null;
                last_name: string | null;
                email: string | null;
              } & { __typename: 'UserProfile' };
            } & { __typename: 'UserInCompanyProfile' })
          | null;
      } & { __typename: 'Company' })
    | null;
} & { __typename: 'Query' };

export type AssignUserToTourMutationVariables = Exact<{
  input: AssignUserToTourInput;
}>;

export type AssignUserToTourMutation = {
  assignUserToTour:
    | ({
        assignedToUser:
          | ({ user_id: string; first_name: string | null; last_name: string | null; email: string | null } & {
              __typename: 'UserProfile';
            })
          | null;
      } & { __typename: 'TourAssignment' })
    | null;
} & { __typename: 'Mutation' };

export type DeleteUserAssignmentMutationVariables = Exact<{
  tourId: Scalars['String'];
  companyId: Scalars['String'];
}>;

export type DeleteUserAssignmentMutation = { deleteUserAssignment: boolean | null } & { __typename: 'Mutation' };

export type CreateOrUpdateTransportNotificationMutationVariables = Exact<{
  input: CreateOrUpdateTransportNotificationInput;
}>;

export type CreateOrUpdateTransportNotificationMutation = { createOrUpdateTransportNotification: string } & {
  __typename: 'Mutation';
};

export type DeleteTransportNotificationMutationVariables = Exact<{
  input: DeleteTransportNotificationInput;
}>;

export type DeleteTransportNotificationMutation = { deleteTransportNotification: boolean } & { __typename: 'Mutation' };

export type CreateNoteMutationVariables = Exact<{
  input: CreateNoteInput;
}>;

export type CreateNoteMutation = {
  createNote:
    | ({
        noteId: string;
        stopId: string | null;
        noteText: string;
        noteVisibility: NoteVisibility;
        noteOrigin: NoteOrigin;
        createdBy: NoteCreatedBy;
        createdAt: string;
        updatedAt: string;
        associatedDeliveries: Array<
          { deliveryId: string; deliveryNumber: string | null } & { __typename: 'TourDelivery' }
        >;
      } & { __typename: 'Note' })
    | null;
} & { __typename: 'Mutation' };

export type AbortTourMutationVariables = Exact<{
  tour_id: Scalars['Int'];
}>;

export type AbortTourMutation = {
  abortTour: ({ tour_id: number; status: string | null } & { __typename: 'Tour' }) | null;
} & { __typename: 'Mutation' };

export type TourStatusFragment = { tour_id: number; status: string | null; canAbortTour: boolean } & {
  __typename: 'Tour';
};

export type CreateTourStopSharingTokenMutationVariables = Exact<{
  tour_id: Scalars['String'];
  stop_id: Scalars['String'];
  company_id: Scalars['String'];
}>;

export type CreateTourStopSharingTokenMutation = {
  createTourStopSharingToken:
    | ({ stop_id: number; sharingTokenV2: (SharingTokenFragment & { __typename: 'SharingToken' }) | null } & {
        __typename: 'TourStop';
      })
    | null;
} & { __typename: 'Mutation' };

export type DeleteTourStopSharingTokenMutationVariables = Exact<{
  tour_id: Scalars['String'];
  stop_id: Scalars['String'];
  company_id: Scalars['String'];
}>;

export type DeleteTourStopSharingTokenMutation = {
  deleteTourStopSharingToken:
    | ({ stop_id: number; sharingTokenV2: (SharingTokenFragment & { __typename: 'SharingToken' }) | null } & {
        __typename: 'TourStop';
      })
    | null;
} & { __typename: 'Mutation' };

export type TourStopsTokensFragment = {
  tour_id: number;
  stops: Array<
    { stop_id: number; sharingTokenV2: (SharingTokenFragment & { __typename: 'SharingToken' }) | null } & {
      __typename: 'TourStop';
    }
  >;
} & { __typename: 'Tour' };

export type TourFragment = { tour_id: number; status: string | null } & { __typename: 'Tour' };

export type FilteredCompanyVehiclesQueryVariables = Exact<{
  companyId: Scalars['String'];
  licensePlateSearch: Scalars['String'];
  tourId: Scalars['Int'];
}>;

export type FilteredCompanyVehiclesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tour:
                | ({
                    tour_id: number;
                    allocatableVehicles: {
                      edges: Array<
                        | ({
                            node:
                              | ({
                                  vehicle_id: string;
                                  license_plate_number: string | null;
                                  timeBasedStatus: TimeBasedStatus | null;
                                } & { __typename: 'VehicleProfile' })
                              | null;
                          } & { __typename: 'VehicleProfileEdge' })
                        | null
                      > | null;
                    } & { __typename: 'VehicleProfileConnection' };
                  } & { __typename: 'Tour' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type FilteredGlobalPoolVehiclesQueryVariables = Exact<{
  companyId: Scalars['String'];
  licensePlateSearch: Scalars['String'];
  tourId: Scalars['Int'];
}>;

export type FilteredGlobalPoolVehiclesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tour:
                | ({
                    tour_id: number;
                    allocatableVehicles: {
                      edges: Array<
                        | ({
                            node:
                              | ({
                                  vehicle_id: string;
                                  license_plate_number: string | null;
                                  isOutsideOfTransportPool: boolean | null;
                                  timeBasedStatus: TimeBasedStatus | null;
                                } & { __typename: 'VehicleProfile' })
                              | null;
                          } & { __typename: 'VehicleProfileEdge' })
                        | null
                      > | null;
                    } & { __typename: 'VehicleProfileConnection' };
                  } & { __typename: 'Tour' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanyMobileVehicleTrackersQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;

export type CompanyMobileVehicleTrackersQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              vehicles:
                | ({
                    allocatable:
                      | ({
                          vehicles: {
                            edges: Array<
                              | ({
                                  node:
                                    | ({
                                        vehicle_id: string;
                                        vehicleTrackers: Array<
                                          | { __typename: 'FMSVehicleTracker' }
                                          | ({
                                              vehicleTrackerId: string;
                                              vehicleId: string;
                                              alias: string | null;
                                              phoneNumber: string | null;
                                            } & { __typename: 'MobileVehicleTracker' })
                                        >;
                                      } & { __typename: 'Vehicle' })
                                    | null;
                                } & { __typename: 'VehicleEdge' })
                              | null
                            > | null;
                          } & { __typename: 'VehicleConnection' };
                        } & { __typename: 'CountedVehicleConnection' })
                      | null;
                  } & { __typename: 'CompanyVehicles' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanySubcarriersQueryVariables = Exact<{
  companyId: Scalars['String'];
  first: Scalars['Int'];
  textSearch: Scalars['String'];
}>;

export type CompanySubcarriersQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              carriersConnection: {
                carriers: {
                  edges: Array<
                    | ({
                        node:
                          | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                          | null;
                      } & { __typename: 'CompanyProfileEdge' })
                    | null
                  > | null;
                } & { __typename: 'CompanyProfileConnection' };
              } & { __typename: 'CountedCarrierConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TourAllocationPopupQueryVariables = Exact<{
  companyId: Scalars['String'];
  tourId: Scalars['Int'];
}>;

export type TourAllocationPopupQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              isMobileAllocationEnabled: boolean;
              isGlobalAllocationEnabled: boolean;
              tour:
                | ({
                    tour_id: number;
                    allocationType: TourVehicleAllocationType | null;
                    isSubcontractingEnabled: boolean;
                    canAllocateToTrimbleMobileApp: boolean;
                    activeVehicleAllocation:
                      | ({
                          vehicleId: string | null;
                          vehicleLicensePlateNumber: string | null;
                          vehicleTrackerTypes: Array<VehicleTrackerType> | null;
                        } & { __typename: 'TourVehicleAllocation' })
                      | null;
                    customFields: Array<
                      { fieldName: string; values: Array<{ value: string } & { __typename: 'CustomFieldValue' }> } & {
                        __typename: 'CustomField';
                      }
                    >;
                    subcontractor:
                      | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                      | null;
                    vehicleAllocations: Array<
                      {
                        source: TourVehicleAllocationSource;
                        vehicleId: string | null;
                        allocationType: TourVehicleAllocationType | null;
                        vehicleLicensePlateNumber: string | null;
                      } & { __typename: 'TourVehicleAllocation' }
                    >;
                    stops: Array<
                      {
                        stop_id: number;
                        type: string | null;
                        display_sequence_number: number;
                        vehicleAllocation:
                          | ({ vehicleId: string | null; vehicleLicensePlateNumber: string | null } & {
                              __typename: 'VehicleAllocation';
                            })
                          | null;
                        location:
                          | ({
                              name: string | null;
                              position:
                                | ({ lat: number | null; lng: number | null } & { __typename: 'GeoPosition' })
                                | null;
                            } & { __typename: 'TourLocation' })
                          | null;
                      } & { __typename: 'TourStop' }
                    >;
                    suggestedVehicles: Array<
                      { vehicleId: string; licensePlate: string | null } & { __typename: 'VehicleProfile' }
                    >;
                  } & { __typename: 'Tour' })
                | null;
              vehicles:
                | ({ allocatable: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null } & {
                    __typename: 'CompanyVehicles';
                  })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TourAllocationDetailsFragment = {
  tour_id: number;
  status: string | null;
  allocationType: TourVehicleAllocationType | null;
  isSubcontractingEnabled: boolean;
  activeVehicleAllocation:
    | ({
        vehicleId: string | null;
        vehicleLicensePlateNumber: string | null;
        vehicleTrackerTypes: Array<VehicleTrackerType> | null;
      } & { __typename: 'TourVehicleAllocation' })
    | null;
  vehicleAllocations: Array<
    {
      source: TourVehicleAllocationSource;
      vehicleId: string | null;
      allocationType: TourVehicleAllocationType | null;
      vehicleLicensePlateNumber: string | null;
    } & { __typename: 'TourVehicleAllocation' }
  >;
  stops: Array<
    {
      type: string | null;
      stop_id: number;
      vehicleAllocation:
        | ({ vehicleId: string | null; vehicleLicensePlateNumber: string | null } & { __typename: 'VehicleAllocation' })
        | null;
    } & { __typename: 'TourStop' }
  >;
  subcontractor: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
} & { __typename: 'Tour' };

export type AllocateVehiclesMutationVariables = Exact<{
  input: TourAllocationInput;
}>;

export type AllocateVehiclesMutation = {
  allocateVehiclesToTour: (TourAllocationDetailsFragment & { __typename: 'Tour' }) | null;
} & { __typename: 'Mutation' };

export type AllocateDummyMobileToTourMutationVariables = Exact<{
  input: DummyMobileTourAllocationInput;
}>;

export type AllocateDummyMobileToTourMutation = {
  allocateDummyMobileToTour: (TourAllocationDetailsFragment & { __typename: 'Tour' }) | null;
} & { __typename: 'Mutation' };

export type CreateVehicleMobileAppTrackerMutationVariables = Exact<{
  input: CreateVehicleMobileAppTrackerInput;
}>;

export type CreateVehicleMobileAppTrackerMutation = {
  createVehicleMobileAppTracker: { vehicleTrackerId: string } & { __typename: 'MobileVehicleTracker' };
} & { __typename: 'Mutation' };

export type ForwardTourMutationVariables = Exact<{
  input: ForwardTourInput;
}>;

export type ForwardTourMutation = { forwardTour: (TourAllocationDetailsFragment & { __typename: 'Tour' }) | null } & {
  __typename: 'Mutation';
};

export type UnforwardTourMutationVariables = Exact<{
  input: UnforwardTourInput;
}>;

export type UnforwardTourMutation = {
  unforwardTour: (TourAllocationDetailsFragment & { __typename: 'Tour' }) | null;
} & { __typename: 'Mutation' };

export type TripsLocationOptionsQueryVariables = Exact<{
  company_id: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  subType: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  transportMode: TripsTransportMode;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type TripsLocationOptionsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tripsOptionsConnection: {
                options:
                  | ({
                      edges: Array<
                        | ({ node: ({ value: string } & { __typename: 'TripsOption' }) | null } & {
                            __typename: 'TripsOptionEdge';
                          })
                        | null
                      > | null;
                    } & { __typename: 'TripsOptionConnection' })
                  | null;
              } & { __typename: 'CountedTripsOptionConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type DemurrageFragmentDetailsFragment = {
  minRemainingFreeDays: number | null;
  totalCost: number | null;
  overallState: string | null;
  currency: string;
  costs: Array<
    {
      name: string | null;
      contractReference: string | null;
      state: string | null;
      startDate: string | null;
      endDate: string | null;
      remainingFreeDays: number | null;
      overdueDays: number | null;
      runningCost: number | null;
      actualCost: number | null;
      currency: string;
      freeTimeType: string | null;
      contractType: string | null;
      costDetails: Array<
        { daysBracket: string | null; costPerDay: number | null; calculation: string | null } & {
          __typename: 'OceanCostDetails';
        }
      >;
    } & { __typename: 'OceanDemurrageCost' }
  > | null;
} & { __typename: 'OceanDemurrage' };

export type TripsContainerDetailsFragment = {
  tour_id: number;
  platformTransportURL: string | null;
  shipper_transport_number: string | null;
  status: string | null;
  sharingToken: string | null;
  billOfLading: string | null;
  bookingNumber: string | null;
  containerNumber: string | null;
  carrierName: string | null;
  carrierCode: string | null;
  transportGroupId: string | null;
  containerType: string | null;
  airWayBill: string | null;
  customerPackageCount: string | null;
  carrierPackageCount: string | null;
  transportAlertsHighestSeverity: TransportAlertSeverity | null;
  visibilityServices: Array<VisibilityService>;
  carrier: ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }) | null;
  tourEmissionsReceived: ({ epKgCo2: number | null } & { __typename: 'TourEmissionsReceived' }) | null;
  tripsVehicleStatus:
    | ({
        vehicle_id: string;
        latitude: number | null;
        longitude: number | null;
        heading: number | null;
        timestamp: string | null;
        accuracyInMeters: number | null;
      } & { __typename: 'VehicleStatus' })
    | null;
  tripsVehicleHistory: Array<
    {
      polyline: { points: string } & { __typename: 'Polyline' };
      metadata: { type: VehicleHistoryPolylineType } & { __typename: 'VehicleHistoryPolylineMetadata' };
    } & { __typename: 'VehicleHistoryPolyline' }
  > | null;
  tourRoute:
    | ({ legs: Array<{ polyline: { points: string } & { __typename: 'Polyline' } } & { __typename: 'RouteLeg' }> } & {
        __typename: 'Route';
      })
    | null;
  customFields: Array<
    { fieldName: string; values: Array<{ value: string } & { __typename: 'CustomFieldValue' }> } & {
      __typename: 'CustomField';
    }
  >;
  events: Array<
    | ({
        stop_id: number;
        tour_id: number;
        event_id: number;
        vehicle_id: string | null;
        vehicle_lat: number | null;
        vehicle_lng: number | null;
        vehicle_license_plate_number: string | null;
        event_index: number;
        event_time: string | null;
        created_at: string;
        stop_event_name: StopEventName;
        visibilitySource:
          | ({
              primaryEventSource: TourVisibilitySourceType;
              sourcePlatform:
                | ({ platform_id: string; platform_name: string } & { __typename: 'PlatformProfile' })
                | null;
            } & { __typename: 'TourVisibilitySource' })
          | null;
      } & { __typename: 'StopEvent' })
    | ({
        event_id: number;
        tour_id: number;
        vehicle_id: string | null;
        vehicle_lat: number | null;
        vehicle_lng: number | null;
        event_index: number;
        event_time: string | null;
        created_at: string;
        tour_event_name: TourEventName;
        warning:
          | ({ type: TourWarningType; severity: TourWarningSeverity | null } & { __typename: 'TourWarning' })
          | null;
        visibilitySource:
          | ({
              primaryEventSource: TourVisibilitySourceType;
              sourcePlatform:
                | ({ platform_id: string; platform_name: string } & { __typename: 'PlatformProfile' })
                | null;
            } & { __typename: 'TourVisibilitySource' })
          | null;
      } & { __typename: 'TourEvent' })
  >;
  stops: Array<
    {
      stop_id: number;
      display_sequence_number: number;
      location: ({ name: string | null } & { __typename: 'TourLocation' }) | null;
      deliveries: Array<
        {
          deliveryId: string;
          deliveryNumber: string | null;
          orderNumber: string | null;
          purchaseOrderNumber: string | null;
        } & { __typename: 'TourDelivery' }
      >;
    } & { __typename: 'TourStop' }
  >;
  externalTourEvents: Array<
    {
      eventId: string;
      timestamp: string;
      eventSource: ExternalEventSource;
      externalEventSource: string | null;
      qualifier: ExternalTourEventQualifier;
      externalEventQualifier: string;
      licensePlateNumber: string | null;
      remoteId: string | null;
      vehicleType: VehicleType | null;
      locationName: string | null;
    } & { __typename: 'ExternalTourEvent' }
  >;
  milestones:
    | ({
        vehicles: Array<
          { vehicleId: string; type: TripTransportMode; name: string | null; identificator: string | null } & {
            __typename: 'VehicleData';
          }
        >;
        locations: Array<
          {
            name: string;
            country: string | null;
            type: LocationType | null;
            locationCode: string | null;
            iataCode: string | null;
            position: ({ lat: number; lng: number } & { __typename: 'Position' }) | null;
            milestones: Array<
              {
                vehicleId: string | null;
                milestone: string;
                qualifier: MilestoneQualifier | null;
                estimatedTimeOfArrivalSource: string | null;
                actualTimeOfArrivalSource: string | null;
                scheduledTimeOfArrivalSource: string | null;
                estimatedTimeOfArrival:
                  | ({ dateTime: string; timezone: string } & { __typename: 'DateTimeWithTimezone' })
                  | null;
                actualTimeOfArrival:
                  | ({ dateTime: string; timezone: string } & { __typename: 'DateTimeWithTimezone' })
                  | null;
                scheduledTimeOfArrival:
                  | ({ dateTime: string; timezone: string } & { __typename: 'DateTimeWithTimezone' })
                  | null;
              } & { __typename: 'Milestone' }
            >;
          } & { __typename: 'MilestoneLocation' }
        >;
      } & { __typename: 'LocationsAndMilestones' })
    | null;
  oceanDemurragePOD: (DemurrageFragmentDetailsFragment & { __typename: 'OceanDemurrage' }) | null;
  oceanDemurragePOL: (DemurrageFragmentDetailsFragment & { __typename: 'OceanDemurrage' }) | null;
  transportAlerts: Array<
    {
      alertId: string;
      type: TransportAlertType;
      severity: TransportAlertSeverity;
      description: string | null;
      createdAt: string;
    } & { __typename: 'TransportAlert' }
  >;
  assignedUser:
    | ({ user_id: string; first_name: string | null; last_name: string | null; email: string | null } & {
        __typename: 'UserProfile';
      })
    | null;
  relatedTransports: Array<{ sharingToken?: string | null } & ShipmentFragment & { __typename: 'Tour' }>;
  applicationDeepLinks: Array<{ applicationName: string; url: string } & { __typename: 'ApplicationDeepLink' }> | null;
} & { __typename: 'Tour' };

export type TripsDetailsQueryVariables = Exact<{
  company_id: Scalars['String'];
  tour_id: Scalars['Int'];
  skipSharingToken?: InputMaybe<Scalars['Boolean']>;
}>;

export type TripsDetailsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({ company_id: string; tour: (TripsContainerDetailsFragment & { __typename: 'Tour' }) | null } & {
              __typename: 'Company';
            })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TripsTokenDetailsQueryVariables = Exact<{
  skipSharingToken?: InputMaybe<Scalars['Boolean']>;
}>;

export type TripsTokenDetailsQuery = {
  token:
    | ({ tour: (TripsContainerDetailsFragment & { __typename: 'Tour' }) | null } & { __typename: 'TokenResources' })
    | null;
} & { __typename: 'Query' };

export type TripsIdentifiersSearchQueryVariables = Exact<{
  company_id: Scalars['String'];
  types: Array<TripsIdentifierType> | TripsIdentifierType;
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  identifierValues?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  transportMode: TripsTransportMode;
}>;

export type TripsIdentifiersSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tripsIdentifiersConnection: {
                tourIdentifiers: {
                  edges: Array<
                    | ({ node: ({ identifierValue: string } & { __typename: 'TripsTourIdentifier' }) | null } & {
                        __typename: 'TripsTourIdentifierEdge';
                      })
                    | null
                  > | null;
                } & { __typename: 'TripsTourIdentifierConnection' };
              } & { __typename: 'CountedTripsIdentifiersConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TransportGroupIdsSearchQueryVariables = Exact<{
  company_id: Scalars['String'];
  textSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  transportGroupIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type TransportGroupIdsSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              companyTransportGroupsConnection: {
                companyTransportGroups: {
                  edges: Array<
                    | ({ node: ({ transportGroupId: string } & { __typename: 'CompanyTransportGroup' }) | null } & {
                        __typename: 'CompanyTransportGroupEdge';
                      })
                    | null
                  > | null;
                } & { __typename: 'CompanyTransportGroupConnection' };
              } & { __typename: 'CountedCompanyTransportGroupConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanyExceptionsSearchQueryVariables = Exact<{
  company_id: Scalars['String'];
}>;

export type CompanyExceptionsSearchQuery = {
  viewer:
    | ({
        user_id: string;
        company: ({ company_id: string; customExceptionTypes: Array<string> } & { __typename: 'Company' }) | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type DemurrageFragment = {
  minRemainingFreeDays: number | null;
  totalCost: number | null;
  currency: string;
  overallState: string | null;
} & { __typename: 'OceanDemurrage' };

export type TripsQueryVariables = Exact<{
  company_id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  textSearch?: InputMaybe<TripsTextSearch>;
  siteIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  carrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  mainCarrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  status?: InputMaybe<Array<TourStatusFilter> | TourStatusFilter>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch> | TourCustomFieldSearch>;
  locations?: InputMaybe<Array<TransportLocation> | TransportLocation>;
  demurrage?: InputMaybe<OceanDemurrageFilter>;
  transportAlerts?: InputMaybe<TransportAlertsFilterInput>;
  carrierNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  carrierCodes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  transportGroupIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  containerNumbers?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  bookingNumbers?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  billOfLadingNumbers?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  timezone?: InputMaybe<Scalars['IANATimezone']>;
  impactDays?: InputMaybe<ImpactDaysFilter>;
  transportMode: Array<TourFilteringTransportMode> | TourFilteringTransportMode;
  airWayBillNumbers?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  vesselName?: InputMaybe<VesselNameFilterInput>;
}>;

export type TripsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tripsTours: {
                count: number;
                reportDownloadURI: string | null;
                tours: {
                  edges: Array<
                    | ({
                        cursor: string | null;
                        node:
                          | ({
                              tour_id: number;
                              shipper_transport_number: string | null;
                              billOfLading: string | null;
                              bookingNumber: string | null;
                              containerNumber: string | null;
                              carrierName: string | null;
                              carrierCode: string | null;
                              transportGroupId: string | null;
                              containerType: string | null;
                              transitTime: number | null;
                              impactDays: number | null;
                              status: string | null;
                              airWayBill: string | null;
                              customerPackageCount: string | null;
                              carrierPackageCount: string | null;
                              transportAlertsCount: number;
                              transportAlertsHighestSeverity: TransportAlertSeverity | null;
                              customFields: Array<
                                {
                                  fieldName: string;
                                  values: Array<{ value: string } & { __typename: 'CustomFieldValue' }>;
                                } & { __typename: 'CustomField' }
                              >;
                              vesselNames:
                                | ({ pol: string | null; pod: string | null } & { __typename: 'TripVesselNames' })
                                | null;
                              stops: Array<
                                {
                                  stop_id: number;
                                  deliveries: Array<
                                    {
                                      deliveryId: string;
                                      deliveryNumber: string | null;
                                      orderNumber: string | null;
                                      purchaseOrderNumber: string | null;
                                    } & { __typename: 'TourDelivery' }
                                  >;
                                } & { __typename: 'TourStop' }
                              >;
                              carrier:
                                | ({ company_id: string; company_name: string | null } & {
                                    __typename: 'CompanyProfile';
                                  })
                                | null;
                              milestones:
                                | ({
                                    locations: Array<
                                      {
                                        name: string;
                                        locationCode: string | null;
                                        iataCode: string | null;
                                        type: LocationType | null;
                                        milestones: Array<
                                          {
                                            milestone: string;
                                            qualifier: MilestoneQualifier | null;
                                            scheduledTimeOfArrivalSource: string | null;
                                            estimatedTimeOfArrivalSource: string | null;
                                            actualTimeOfArrivalSource: string | null;
                                            vehicleId: string | null;
                                            scheduledTimeOfArrival:
                                              | ({ dateTime: string; timezone: string } & {
                                                  __typename: 'DateTimeWithTimezone';
                                                })
                                              | null;
                                            estimatedTimeOfArrival:
                                              | ({ dateTime: string; timezone: string } & {
                                                  __typename: 'DateTimeWithTimezone';
                                                })
                                              | null;
                                            actualTimeOfArrival:
                                              | ({ dateTime: string; timezone: string } & {
                                                  __typename: 'DateTimeWithTimezone';
                                                })
                                              | null;
                                          } & { __typename: 'Milestone' }
                                        >;
                                      } & { __typename: 'MilestoneLocation' }
                                    >;
                                    vehicles: Array<
                                      { vehicleId: string; name: string | null } & { __typename: 'VehicleData' }
                                    >;
                                  } & { __typename: 'LocationsAndMilestones' })
                                | null;
                              oceanDemurragePOD: (DemurrageFragment & { __typename: 'OceanDemurrage' }) | null;
                              oceanDemurragePOL: (DemurrageFragment & { __typename: 'OceanDemurrage' }) | null;
                              assignedUser:
                                | ({
                                    user_id: string;
                                    first_name: string | null;
                                    last_name: string | null;
                                    email: string | null;
                                  } & { __typename: 'UserProfile' })
                                | null;
                              relatedTransportsForListView: Array<
                                { tour_id: number; shipper_transport_number: string | null } & {
                                  __typename: 'RelatedTransportForListView';
                                }
                              >;
                            } & { __typename: 'Tour' })
                          | null;
                      } & { __typename: 'TourEdge' })
                    | null
                  > | null;
                  pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                } & { __typename: 'TourConnection' };
              } & { __typename: 'CountedTripsTourConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type TripsToursReportDownloadQueryVariables = Exact<{
  company_id: Scalars['String'];
  textSearch?: InputMaybe<TripsTextSearch>;
  siteIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  carrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  mainCarrierIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  assignedToUserIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  status?: InputMaybe<Array<TourStatusFilter> | TourStatusFilter>;
  customFields?: InputMaybe<Array<TourCustomFieldSearch> | TourCustomFieldSearch>;
  locations?: InputMaybe<Array<TransportLocation> | TransportLocation>;
  demurrage?: InputMaybe<OceanDemurrageFilter>;
  carrierNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  carrierCodes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  transportAlerts?: InputMaybe<TransportAlertsFilterInput>;
  transportGroupIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  containerNumbers?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  bookingNumbers?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  billOfLadingNumbers?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  timezone?: InputMaybe<Scalars['IANATimezone']>;
  impactDays?: InputMaybe<ImpactDaysFilter>;
  airWayBillNumbers?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  transportMode?: InputMaybe<Array<TourFilteringTransportMode> | TourFilteringTransportMode>;
  vesselName?: InputMaybe<VesselNameFilterInput>;
}>;

export type TripsToursReportDownloadQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              tripsTours: { reportDownloadURI: string | null } & { __typename: 'CountedTripsTourConnection' };
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type CompanyFilterOptionsQueryVariables = Exact<{
  company_id: Scalars['String'];
  transportMode: TripsTransportMode;
  customFieldsTransportMode: TourFilteringTransportMode;
}>;

export type CompanyFilterOptionsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              sitesConnection: { hasAny: boolean } & { __typename: 'CountedSiteConnection' };
              carriersConnection: { hasAny: boolean } & { __typename: 'CountedCarrierConnection' };
              mainCarriersConnection: { hasAny: boolean } & { __typename: 'CountedMainCarrierConnection' };
              users: { hasAny: boolean } & { __typename: 'CountedUserInCompanyProfileConnection' };
              billOfLadingNumbers: { hasAny: boolean } & { __typename: 'CountedTripsIdentifiersConnection' };
              bookingNumbers: { hasAny: boolean } & { __typename: 'CountedTripsIdentifiersConnection' };
              containerNumbers: { hasAny: boolean } & { __typename: 'CountedTripsIdentifiersConnection' };
              carrierIdentifiers: { hasAny: boolean } & { __typename: 'CountedTripsIdentifiersConnection' };
              airWayBillNumbers: { hasAny: boolean } & { __typename: 'CountedTripsIdentifiersConnection' };
              vesselName: { hasAny: boolean } & { __typename: 'CountedTripsIdentifiersConnection' };
              companyTransportGroupsConnection: { hasAny: boolean } & {
                __typename: 'CountedCompanyTransportGroupConnection';
              };
              connectedCustomFields: Array<{ name: string } & { __typename: 'ConnectedCustomField' }>;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type ChangeUserProfileMutationVariables = Exact<{
  input: UpdateViewerProfileInput;
}>;

export type ChangeUserProfileMutation = {
  updateViewerProfile: ({ user_id: string; locale: string | null } & { __typename: 'User' }) | null;
} & { __typename: 'Mutation' };

export type UserProfileQueryVariables = Exact<{
  companyId: Scalars['String'];
  skipCompany: Scalars['Boolean'];
}>;

export type UserProfileQuery = {
  viewer:
    | ({
        user_id: string;
        email: string | null;
        first_name: string | null;
        last_name: string | null;
        locale: string | null;
        backoffice_access_enabled: boolean | null;
        company?:
          | ({ company_id: string; me: ({ role: UserRoleInCompany } & { __typename: 'UserInCompany' }) | null } & {
              __typename: 'Company';
            })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type VehicleManagementMapVehicleDetailsQueryVariables = Exact<{
  companyId: Scalars['String'];
  vehicleId: Scalars['String'];
}>;

export type VehicleManagementMapVehicleDetailsQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              vehicle:
                | ({
                    vehicle_id: string;
                    license_plate_number: string | null;
                    status:
                      | ({ timestamp: string | null; timeBasedStatus: TimeBasedStatus } & {
                          __typename: 'RestrictedVehicleStatus';
                        })
                      | null;
                    boundingBox: Array<{ lat: number; lng: number } & { __typename: 'PointLocation' }> | null;
                  } & { __typename: 'Vehicle' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type VehicleManagementVehicleDetailsSidepanelQueryVariables = Exact<{
  companyId: Scalars['String'];
  vehicleId: Scalars['String'];
}>;

export type VehicleManagementVehicleDetailsSidepanelQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              vehicle:
                | ({
                    vehicle_id: string;
                    license_plate_number: string | null;
                    isConstantPositionAccessAllowed: boolean;
                    mapTilesTemplateURI: string | null;
                    created_at: string | null;
                    boundingBox: Array<{ lat: number; lng: number } & { __typename: 'PointLocation' }> | null;
                    status:
                      | ({ timestamp: string | null; timeBasedStatus: TimeBasedStatus } & {
                          __typename: 'RestrictedVehicleStatus';
                        })
                      | null;
                    sharedTo: Array<
                      { company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' }
                    >;
                    sharedBy:
                      | ({ company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' })
                      | null;
                    vehicleTrackers: Array<
                      | ({
                          vehicleTrackerId: string;
                          trackerType: VehicleTrackerType;
                          telematicsIntegration:
                            | ({
                                telematicsIntegrationId: string;
                                isAllowedToUpdateCredentials: boolean;
                                enabled: boolean;
                                diagnosticStatus: TelematicsIntegrationDiagnosticStatus | null;
                                telematicsProvider: { telematicsProviderId: string; telematicsProviderName: string } & {
                                  __typename: 'TelematicsProvider';
                                };
                              } & { __typename: 'CompanyTelematicsIntegration' })
                            | null;
                        } & { __typename: 'FMSVehicleTracker' })
                      | ({
                          vehicleTrackerId: string;
                          trackerType: VehicleTrackerType;
                          phoneNumber: string | null;
                          alias: string | null;
                        } & { __typename: 'MobileVehicleTracker' })
                    >;
                  } & { __typename: 'Vehicle' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type VehicleManagementVehicleDetailsSidepanelDedicatedByQueryVariables = Exact<{
  companyId: Scalars['String'];
  vehicleId: Scalars['String'];
}>;

export type VehicleManagementVehicleDetailsSidepanelDedicatedByQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              vehicle:
                | ({
                    vehicle_id: string;
                    license_plate_number: string | null;
                    isConstantPositionAccessAllowed: boolean;
                    mapTilesTemplateURI: string | null;
                    boundingBox: Array<{ lat: number; lng: number } & { __typename: 'PointLocation' }> | null;
                    status:
                      | ({ timestamp: string | null; timeBasedStatus: TimeBasedStatus } & {
                          __typename: 'RestrictedVehicleStatus';
                        })
                      | null;
                  } & { __typename: 'Vehicle' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type VehicleManagementDedicatedVehiclesQueryVariables = Exact<{
  companyId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  licensePlateSearch?: InputMaybe<Scalars['String']>;
  sorting: VehicleFilterSortOrderInput;
  timeBasedStatus?: InputMaybe<TimeBasedStatus>;
  grantedByPartnersFilter?: InputMaybe<Scalars['Boolean']>;
  ownVehiclesOnly?: InputMaybe<Scalars['Boolean']>;
}>;

export type VehicleManagementDedicatedVehiclesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              vehicles:
                | ({
                    dedicatedVehicles:
                      | ({
                          count: number;
                          mapTilesTemplateURI: string | null;
                          vehiclesReportDownloadURI: string | null;
                          boundingBox: Array<{ lat: number; lng: number } & { __typename: 'PointLocation' }> | null;
                          vehicles: {
                            edges: Array<
                              | ({
                                  node:
                                    | ({
                                        vehicle_id: string;
                                        license_plate_number: string | null;
                                        sharedTo: Array<
                                          { company_id: string; company_name: string | null } & {
                                            __typename: 'CompanyProfile';
                                          }
                                        >;
                                        sharedBy:
                                          | ({ company_id: string; company_name: string | null } & {
                                              __typename: 'CompanyProfile';
                                            })
                                          | null;
                                        status:
                                          | ({ timestamp: string | null; timeBasedStatus: TimeBasedStatus } & {
                                              __typename: 'RestrictedVehicleStatus';
                                            })
                                          | null;
                                        vehicleTrackers: Array<
                                          | ({
                                              vehicleTrackerId: string;
                                              trackerType: VehicleTrackerType;
                                              telematicsIntegration:
                                                | ({
                                                    telematicsIntegrationId: string;
                                                    telematicsProvider: {
                                                      telematicsProviderId: string;
                                                      telematicsProviderName: string;
                                                    } & { __typename: 'TelematicsProvider' };
                                                  } & { __typename: 'CompanyTelematicsIntegration' })
                                                | null;
                                            } & { __typename: 'FMSVehicleTracker' })
                                          | ({
                                              vehicleTrackerId: string;
                                              trackerType: VehicleTrackerType;
                                              phoneNumber: string | null;
                                              alias: string | null;
                                            } & { __typename: 'MobileVehicleTracker' })
                                        >;
                                      } & { __typename: 'Vehicle' })
                                    | null;
                                } & { __typename: 'VehicleEdge' })
                              | null
                            > | null;
                            pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                          } & { __typename: 'VehicleConnection' };
                        } & { __typename: 'CountedVehicleConnection' })
                      | null;
                    allVehiclesAllCount: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null;
                    allVehiclesActiveCount: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null;
                    allVehiclesInactiveCount: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null;
                    myVehiclesAllCount: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null;
                    myVehiclesActiveCount: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null;
                    myVehiclesInactiveCount: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null;
                    dedicatedByPartnersAllCount:
                      | ({ count: number } & { __typename: 'CountedVehicleConnection' })
                      | null;
                    dedicatedByPartnersActiveCount:
                      | ({ count: number } & { __typename: 'CountedVehicleConnection' })
                      | null;
                    dedicatedByPartnersInactiveCount:
                      | ({ count: number } & { __typename: 'CountedVehicleConnection' })
                      | null;
                  } & { __typename: 'CompanyVehicles' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type VehicleManagementDedicatedToPartnerVehiclesQueryVariables = Exact<{
  companyId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  licensePlateSearch?: InputMaybe<Scalars['String']>;
  partnerId: Scalars['String'];
  partnerIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  sorting: VehicleFilterSortOrderInput;
  timeBasedStatus?: InputMaybe<TimeBasedStatus>;
  permissions?: InputMaybe<VehiclePermissionsInput>;
}>;

export type VehicleManagementDedicatedToPartnerVehiclesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              partner:
                | ({
                    company: { company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' };
                  } & { __typename: 'VehicleGrantPartner' })
                | null;
              vehicles:
                | ({
                    dedicatedToPartners:
                      | ({
                          mapTilesTemplateURI: string | null;
                          count: number;
                          boundingBox: Array<{ lat: number; lng: number } & { __typename: 'PointLocation' }> | null;
                          vehicles: {
                            edges: Array<
                              | ({
                                  node:
                                    | ({
                                        vehicle_id: string;
                                        license_plate_number: string | null;
                                        sharedTo: Array<
                                          { company_id: string; company_name: string | null } & {
                                            __typename: 'CompanyProfile';
                                          }
                                        >;
                                        status:
                                          | ({ timestamp: string | null; timeBasedStatus: TimeBasedStatus } & {
                                              __typename: 'RestrictedVehicleStatus';
                                            })
                                          | null;
                                        sentGrants: Array<
                                          {
                                            grantId: string;
                                            permissions: { withConstantPositionAccess: boolean } & {
                                              __typename: 'VehiclePermissions';
                                            };
                                          } & { __typename: 'VehicleGrant' }
                                        >;
                                      } & { __typename: 'Vehicle' })
                                    | null;
                                } & { __typename: 'VehicleEdge' })
                              | null
                            > | null;
                            pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                          } & { __typename: 'VehicleConnection' };
                        } & { __typename: 'CountedVehicleConnection' })
                      | null;
                    totalCount: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null;
                    activeCount: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null;
                    inactiveCount: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null;
                  } & { __typename: 'CompanyVehicles' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type SetVehicleConstantPositionAccessMutationVariables = Exact<{
  input: VehicleGrantVisibilityInput;
}>;

export type SetVehicleConstantPositionAccessMutation = { setVehicleGrantVisibility: boolean | null } & {
  __typename: 'Mutation';
};

export type VehicleManagementDedicatedToPartnersListQueryVariables = Exact<{
  companyId: Scalars['String'];
  partnerNameSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type VehicleManagementDedicatedToPartnersListQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              vehicleGrantPartners: {
                partners: {
                  edges: Array<
                    | ({
                        node:
                          | ({
                              grantCountWithoutVisibility: number;
                              grantCountWithVisibility: number;
                              company: { company_id: string; company_name: string | null } & {
                                __typename: 'CompanyProfile';
                              };
                            } & { __typename: 'VehicleGrantPartner' })
                          | null;
                      } & { __typename: 'VehicleGrantPartnerEdge' })
                    | null
                  > | null;
                  pageInfo: { endCursor: string | null; hasNextPage: boolean } & { __typename: 'PageInfo' };
                } & { __typename: 'VehicleGrantPartnerConnection' };
              } & { __typename: 'CountedVehicleGrantPartnerConnection' };
              vehicles:
                | ({
                    dedicatedToPartnersAllCountByStatus: { active: number; inActive: number } & {
                      __typename: 'VehicleCountByStatus';
                    };
                  } & { __typename: 'CompanyVehicles' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type VehicleManagementDedicatedByPartnersListQueryVariables = Exact<{
  companyId: Scalars['String'];
  partnerNameSearch?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type VehicleManagementDedicatedByPartnersListQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              vehicleGrantPartners: {
                partners: {
                  edges: Array<
                    | ({
                        node:
                          | ({
                              grantCountWithVisibility: number;
                              company: { company_id: string; company_name: string | null } & {
                                __typename: 'CompanyProfile';
                              };
                            } & { __typename: 'VehicleGrantPartner' })
                          | null;
                      } & { __typename: 'VehicleGrantPartnerEdge' })
                    | null
                  > | null;
                  pageInfo: { endCursor: string | null; hasNextPage: boolean } & { __typename: 'PageInfo' };
                } & { __typename: 'VehicleGrantPartnerConnection' };
              } & { __typename: 'CountedVehicleGrantPartnerConnection' };
              vehicles:
                | ({
                    dedicatedByPartnersAllCount:
                      | ({ count: number } & { __typename: 'CountedVehicleConnection' })
                      | null;
                    dedicatedByPartnersActiveCount:
                      | ({ count: number } & { __typename: 'CountedVehicleConnection' })
                      | null;
                    dedicatedByPartnersInactiveCount:
                      | ({ count: number } & { __typename: 'CountedVehicleConnection' })
                      | null;
                  } & { __typename: 'CompanyVehicles' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type VehicleManagementDedicatedByPartnerVehiclesQueryVariables = Exact<{
  companyId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  licensePlateSearch?: InputMaybe<Scalars['String']>;
  sorting: VehicleFilterSortOrderInput;
  partnerId: Scalars['String'];
  partnerIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  timeBasedStatus?: InputMaybe<TimeBasedStatus>;
}>;

export type VehicleManagementDedicatedByPartnerVehiclesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              partner:
                | ({
                    company: { company_id: string; company_name: string | null } & { __typename: 'CompanyProfile' };
                  } & { __typename: 'VehicleGrantPartner' })
                | null;
              vehicles:
                | ({
                    dedicatedByPartners:
                      | ({
                          mapTilesTemplateURI: string | null;
                          count: number;
                          boundingBox: Array<{ lat: number; lng: number } & { __typename: 'PointLocation' }> | null;
                          vehicles: {
                            edges: Array<
                              | ({
                                  node:
                                    | ({
                                        vehicle_id: string;
                                        license_plate_number: string | null;
                                        status:
                                          | ({ timestamp: string | null; timeBasedStatus: TimeBasedStatus } & {
                                              __typename: 'RestrictedVehicleStatus';
                                            })
                                          | null;
                                      } & { __typename: 'Vehicle' })
                                    | null;
                                } & { __typename: 'VehicleEdge' })
                              | null
                            > | null;
                            pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                          } & { __typename: 'VehicleConnection' };
                        } & { __typename: 'CountedVehicleConnection' })
                      | null;
                    totalCount: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null;
                    activeCount: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null;
                    inactiveCount: ({ count: number } & { __typename: 'CountedVehicleConnection' }) | null;
                  } & { __typename: 'CompanyVehicles' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };

export type VehicleManagementIntegrationVehiclesQueryVariables = Exact<{
  companyId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  licensePlateSearch?: InputMaybe<Scalars['String']>;
  sorting: VehicleFilterSortOrderInput;
  integrationIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type VehicleManagementIntegrationVehiclesQuery = {
  viewer:
    | ({
        user_id: string;
        company:
          | ({
              company_id: string;
              vehicles:
                | ({
                    integrationVehicles:
                      | ({
                          count: number;
                          vehicles: {
                            edges: Array<
                              | ({
                                  node:
                                    | ({
                                        vehicle_id: string;
                                        license_plate_number: string | null;
                                        vehicleTrackers: Array<
                                          | ({
                                              vehicleTrackerId: string;
                                              trackerType: VehicleTrackerType;
                                              telematicsIntegration:
                                                | ({
                                                    telematicsIntegrationId: string;
                                                    telematicsProvider: {
                                                      telematicsProviderId: string;
                                                      telematicsProviderName: string;
                                                    } & { __typename: 'TelematicsProvider' };
                                                  } & { __typename: 'CompanyTelematicsIntegration' })
                                                | null;
                                            } & { __typename: 'FMSVehicleTracker' })
                                          | ({
                                              vehicleTrackerId: string;
                                              trackerType: VehicleTrackerType;
                                              phoneNumber: string | null;
                                              alias: string | null;
                                            } & { __typename: 'MobileVehicleTracker' })
                                        >;
                                      } & { __typename: 'Vehicle' })
                                    | null;
                                } & { __typename: 'VehicleEdge' })
                              | null
                            > | null;
                            pageInfo: { hasNextPage: boolean; endCursor: string | null } & { __typename: 'PageInfo' };
                          } & { __typename: 'VehicleConnection' };
                        } & { __typename: 'CountedVehicleConnection' })
                      | null;
                  } & { __typename: 'CompanyVehicles' })
                | null;
            } & { __typename: 'Company' })
          | null;
      } & { __typename: 'User' })
    | null;
} & { __typename: 'Query' };
